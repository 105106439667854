import { React, PropTypes, Field } from '../../dependencies';

import CheckboxWithLabel from './CheckboxWithLabel';

function CheckboxField({ name, label }) {
    return (
        <Field
            {...{
                name,
                label,
                component: CheckboxWithLabel,
                format: CheckboxField.formatValue,
            }}
        />
    );
}

CheckboxField.formatValue = value => Boolean(value);

CheckboxField.propTypes = {
    label: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
};

export default CheckboxField;
