import { React, ExpensesList, useSelector } from '../../dependencies';

import { reportApiSelector, hasCurrentReportFiltersSelector } from '../../services/selectors';

import List from './List';

const { ListWrapper, ListHeader, ExpensesListLoader } = ExpensesList;

function ReportExpenses() {
    const { inProgress, success } = useSelector(reportApiSelector);
    const hasCurrentReportFilters = useSelector(hasCurrentReportFiltersSelector);

    return (
        <ListWrapper>
            <ListHeader />
            <ExpensesListLoader show={inProgress || !success || !hasCurrentReportFilters}>
                <List />
            </ExpensesListLoader>
        </ListWrapper>
    );
}

export default ReportExpenses;
