import { Consts, round, sagaEffects, Files, Log, Tags } from '../../../dependencies';
import { types } from '../../../constants';

const { all, select } = sagaEffects;

function* fileFromObjectURL(objectURL, name, options) {
    try {
        const response = yield fetch(objectURL);
        const buffer = yield response.arrayBuffer();
        const file = new File([buffer], name, options);

        return file;
    } catch (e) {
        Log.error(e);
        return null;
    }
}

export function* transformExpenseFormData(data) {
    const {
        type,
        number,
        issuedOn,
        receivedOn,
        dueOn,
        currency,
        documentType,
        items,
        tags,
        files,
        fileUrl,
        vatMode,
        taxDeductible,
        variableSymbol,
        taxableFulfillmentDue,
        originalNumber,
        supplier,
        paymentMethod,
        note,
        startAt,
    } = data;

    const uploadedFiles = files.filter(file => file.uploaded).map(file => file.id);
    const filesToUpload = yield all(
        files
            .filter(file => !file.uploaded)
            .map(({ url, name, type }) =>
                fileFromObjectURL(url, name, {
                    type,
                }),
            ),
    );
    const urlsToBeRevoked = files.filter(file => !file.uploaded).map(file => file.url);

    const fileIds = yield Files.uploadFiles(filesToUpload);

    const vatFromBase = Consts.vatMode.BASE === vatMode;

    const payload = {
        type,
        supplierId: supplier,
        number,
        originalNumber: originalNumber || null,
        variableSymbol: variableSymbol || null,
        issuedOn: issuedOn || startAt,
        receivedOn,
        taxableFulfillmentDue,
        // NOTE: 'dueOn' isn't require, therefore the value will be null,
        // and therefore: 'new Date(null) ===  new Date(0) === Thu Jan 01 1970 01:00:00 GMT+0100'
        dueOn,
        currency: currency.toUpperCase(),
        documentType,
        taxDeductible,
        fileIds: fileIds.concat(uploadedFiles),
        fileUrls: [fileUrl].filter(Boolean),
        tags: yield select(Tags.selectors.mapTagNamesToTagIds, tags),
        items: items.map(({ name, vatRate, units, quantity, pricePerUnit }) => ({
            name: name || null,
            units: units || null,
            quantity,
            vatRate,
            unitPriceWithoutVat: vatFromBase ? pricePerUnit : round(pricePerUnit / (1 + vatRate), 2),
            unitPriceWithVat: vatFromBase ? round(pricePerUnit * (1 + vatRate), 2) : pricePerUnit,
        })),
        vatFromBase,
        paymentMethod,
    };

    if (type === types.STATISTIC) {
        const statisticalFields = [
            'type',
            'supplierId',
            'number',
            'issuedOn',
            'currency',
            'taxDeductible',
            'tags',
            'items',
            'vatFromBase',
            'paymentMethod',
        ];
        const statisticalPayload = {
            note,
            documentType: Consts.documentType.OTHER,
        };

        for (const field of statisticalFields) {
            statisticalPayload[field] = payload[field];
        }

        return { urlsToBeRevoked, payload: statisticalPayload };
    }

    return { urlsToBeRevoked, payload };
}
