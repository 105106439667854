import { React, FelaComponent, childrenPropType } from '../../dependencies';

import * as styles from './DatesWrapper.styles';

function DatesWrapper({ children }) {
    return <FelaComponent style={styles.wrapper}>{children}</FelaComponent>;
}

DatesWrapper.propTypes = {
    children: childrenPropType.isRequired,
};

export default DatesWrapper;
