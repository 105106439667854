import { ActiveCompanyActions, sagaEffects } from '../../dependencies';

import { fetchMembershipsRequest } from '../actions';
import { isOwnerSelector } from '../selectors';

const { takeEvery, put, select } = sagaEffects;

export default function* () {
    yield takeEvery(ActiveCompanyActions.types.SET_ACTIVE_COMPANY_RESOLVE, function* (action) {
        const isOwner = yield select(isOwnerSelector);

        if (isOwner && !action.options.newCompany) {
            yield put(fetchMembershipsRequest());
        }
    });
}
