import d from '../defaultValues';
import { convertValue } from '../helpers';

const supplierTemplate = {
    name: d.string,
    street: d.string,
    city: d.string,
    zipCode: d.string,
    country: d.string,
    vatNumber: d.string,
    registrationNumber: d.string,
    createdAt: d.string,
    updatedAt: d.string,
    companyId: convertValue(String),
    id: convertValue(String),
};

export default supplierTemplate;
