import { ReduxUtils } from '../dependencies';

export const Buckets = ReduxUtils.strictObjectAccess({
    DAYS: 'days',
    WEEKS: 'weeks',
    MONTHS: 'months',
    QUARTERS: 'quarters',
});

export const Views = ReduxUtils.strictObjectAccess({
    DOUGHNUT: 'DOUGHNUT',
    TAGS: 'TAGS',
    TOGGLE_VIEW: 'TOGGLE_VIEW',
});

export const NEW_REPORT = 'new';
