export const content = () => ({
    padding: '24px 30px 16px',
    display: 'grid',
    height: 'calc(100% - 64px)',
    gridTemplateRows: 'repeat(2, auto) 1fr',
});

export const card = () => ({
    gridTemplateRows: '64px 1fr',
    height: 496,
});

export const header = () => ({
    justifyContent: 'space-between',
    padding: '0 30px',
});

export const cardExtend = {
    card,
    header,
};

export const cardLoaderExtend = {
    background: card,
};
