// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"colors.less"}]);
// Exports
exports.locals = {
	"white": "#fff",
	"background": "#f8f6f6",
	"grey1": "#f6f6f6",
	"blueGrey4": "#9393a2",
	"blueGrey3": "#c3c3cb",
	"blueGrey2": "#dbdbe5",
	"blueGrey1": "#ededf2",
	"text": "#10092d",
	"textHover": "#4d4080",
	"danger": "#ff4a1e",
	"dangerHover": "#cc1600",
	"secondary": "#0547ff",
	"primary": "#ff2106",
	"primarySelection": "#ffdbd6",
	"primaryHover": "#e01800",
	"primaryLight1": "#ffa599",
	"positive": "#bbffdb",
	"negative": "#febcb3",
	"info": "#0547ff"
};
module.exports = exports;
