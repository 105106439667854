import { React, PropTypes, Space, CheckboxField } from '../../../dependencies';

function TaxDeductible({ formatMessage }) {
    return (
        <div>
            <Space top={27} />

            <CheckboxField
                {...{
                    name: 'taxDeductible',
                    label: formatMessage({
                        id: 'expense.form.taxDeductible.label',
                    }),
                }}
            />
        </div>
    );
}

TaxDeductible.propTypes = {
    formatMessage: PropTypes.func.isRequired,
};

export default TaxDeductible;
