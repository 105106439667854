import {
    summarySelectorFactory,
    ExpensesSelectors,
    decimalDifferenceSelectorFactory,
    CompaniesSelectors,
} from '../../dependencies';

const itemsSelector = state => ExpensesSelectors.updateExpenseFormSelector(state, 'items');
const vatModeSelector = state => ExpensesSelectors.updateExpenseFormSelector(state, 'vatMode');
const currencySelector = state => ExpensesSelectors.updateExpenseFormSelector(state, 'currency');

export const summarySelector = summarySelectorFactory([
    itemsSelector,
    vatModeSelector,
    currencySelector,
    CompaniesSelectors.activeCompanyCurrencySelector,
]);

export const decimalDifferenceSelector = decimalDifferenceSelectorFactory([summarySelector, vatModeSelector]);
