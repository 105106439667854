import {
    React,
    PropTypes,
    FelaComponent,
    TextField,
    NumberField,
    InputWrapper,
    Icons,
    Config,
} from '../../../../dependencies';

import * as styles from './Row.styles';
import { formatFields, parseFields } from './Row.utils';

const { decimalSeparator } = Config.numberInput;

function Row({ member, intl: { formatMessage }, onRemove, removable, quantityDataAreVisible }) {
    return (
        <>
            <InputWrapper>
                <TextField
                    {...{
                        name: `${member}.name`,
                        label: formatMessage({
                            id: 'expense.form.item.name.label',
                        }),
                        placeholder: formatMessage({
                            id: 'expense.form.item.name.label',
                        }),
                    }}
                />
            </InputWrapper>

            {quantityDataAreVisible && (
                <InputWrapper>
                    <NumberField
                        {...{
                            name: `${member}.quantity`,
                            label: formatMessage({
                                id: 'expense.form.item.quantity.label',
                            }),
                            placeholder: formatMessage({
                                id: 'expense.form.item.quantity.label',
                            }),
                            parse: parseFields.quantity,
                        }}
                    />
                </InputWrapper>
            )}

            {quantityDataAreVisible && (
                <InputWrapper>
                    <TextField
                        {...{
                            name: `${member}.units`,
                            placeholder: formatMessage({
                                id: 'expense.form.item.units.placeholder',
                            }),
                            label: formatMessage({
                                id: 'expense.form.item.units.label',
                            }),
                        }}
                    />
                </InputWrapper>
            )}

            <InputWrapper>
                <NumberField
                    {...{
                        name: `${member}.pricePerUnit`,
                        label: formatMessage({
                            id: 'expense.form.item.pricePerUnit.label',
                        }),
                        placeholder: formatMessage({
                            id: 'expense.form.item.pricePerUnit.label',
                        }),
                        decimalSeparator,
                        parse: parseFields.pricePerUnit,
                    }}
                />
            </InputWrapper>

            <InputWrapper>
                <NumberField
                    {...{
                        name: `${member}.vatRate`,
                        label: formatMessage({
                            id: 'expense.form.item.vatRate.label',
                        }),
                        placeholder: formatMessage({
                            id: 'expense.form.item.vatRate.label',
                        }),
                        parse: parseFields.vatRate,
                        format: formatFields.vatRate,
                        decimalSeparator,
                    }}
                />
            </InputWrapper>

            {removable ? (
                <FelaComponent style={styles.buttonRemove}>
                    {({ className: btnClassName }) => (
                        <button type="button" onClick={onRemove} className={btnClassName}>
                            <FelaComponent style={styles.iconRemove}>
                                {({ className }) => <Icons.Remove {...{ className }} />}
                            </FelaComponent>
                        </button>
                    )}
                </FelaComponent>
            ) : (
                <div />
            )}
        </>
    );
}

Row.propTypes = {
    intl: PropTypes.shape().isRequired,
    member: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    removable: PropTypes.bool.isRequired,
    quantityDataAreVisible: PropTypes.bool.isRequired,
};

export default Row;
