import { React, PropTypes, FormattedMessage, Link, ButtonPrimary, connectFela } from '../../../dependencies';

import * as Styles from './ButtonLink.styles';

function ButtonLink({ Icon, messageId, to, styles }) {
    return (
        <Link to={to} className={styles.buttonLink}>
            <ButtonPrimary>
                <Icon className={styles.icon} />
                <span>
                    <FormattedMessage id={messageId} />
                </span>
            </ButtonPrimary>
        </Link>
    );
}

ButtonLink.propTypes = {
    messageId: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    Icon: PropTypes.func.isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default connectFela(Styles)(ButtonLink);
