import { takeLatestRequest, sagaEffects, authApi, config, Log, ErrorsUtils } from '../../dependencies';

import { types, fetchNewReportSuccess, fetchNewReportFailure, fetchNewReportInvalidate } from '../actions';
import { newReportDataSelector } from '../selectors';

import { formatResponse } from './utils';

const { put, select } = sagaEffects;

function* fetchNewReport(_, cancelToken) {
    try {
        const { filters, hiddenTags, companyId } = yield select(newReportDataSelector);
        const payload = {
            filters,
            hiddenTags,
            companyId,
            temp: true,
        };

        const { data } = yield* authApi.post(config.api.reports, payload, {
            cancelToken,
        });

        yield put(fetchNewReportSuccess(formatResponse(data)));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e);

        yield put(fetchNewReportFailure(errorMessage));
    }
}

export default function* () {
    const actionTypes = {
        REQUEST: types.FETCH_NEW_REPORT_REQUEST,
        cancelTask: fetchNewReportInvalidate,
    };

    yield takeLatestRequest(actionTypes, fetchNewReport);
}
