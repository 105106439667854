import {
    config,
    authApi,
    takeLatestRequest,
    Log,
    sagaEffects,
    Modals,
    Consts,
    change,
    Config,
    ErrorsUtils,
} from '../../dependencies';

import { createSupplierSuccess, createSupplierFailure, createSupplierInvalidate } from '../actions';
import types from '../actionTypes';

const { put } = sagaEffects;

function* createSupplier(action, cancelToken) {
    const { data, startSubmit, stopSubmit, meta } = action;

    try {
        yield startSubmit();

        const { data: supplier } = yield* authApi.post(config.api.suppliers, data, {
            cancelToken,
        });

        const supplierId = String(supplier.id);

        yield put(createSupplierSuccess(supplierId, supplier));

        yield put(Modals.actions.closeModal(Consts.modals.CREATE_SUPPLIER));

        if (meta.fillSupplierField) {
            yield put(change(Config.forms.createExpense, 'supplier', supplierId));
        }

        yield stopSubmit();
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.supplier.api.create',
            },
        });

        yield put(createSupplierFailure(errorMessage));

        yield stopSubmit({
            _error: errorMessage,
        });
    }
}

export default function* createSupplierWatcher() {
    const actionTypes = {
        REQUEST: types.CREATE_SUPPLIER_REQUEST,
        cancelTask: createSupplierInvalidate,
    };

    yield takeLatestRequest(actionTypes, createSupplier);
}
