import { createSelector } from '../../dependencies';

import { decodeOrderParamsSelector } from './orderUrlParam';
import { decodeExpensesFilterParamsSelector } from './filterUrlParams';

export const expensesUrlParamsSelector = createSelector(
    [decodeExpensesFilterParamsSelector, decodeOrderParamsSelector],
    (filterParams, orderParams) => ({
        ...filterParams,
        ...orderParams,
    }),
);
