import { connect, compose } from '../dependencies';

import Body from '../components/Body';

import { expenseDetailBodySelector } from '../services/selectors';

const mapStateToProps = (state, { expenseId }) => {
    return {
        expense: expenseDetailBodySelector(state, expenseId),
    };
};

export default compose(connect(mapStateToProps))(Body);
