import { config, authApi, Log, sagaEffects, Normalizers, ErrorsUtils } from '../../dependencies';

import { types, fetchMembershipsSuccess, fetchMembershipsFailure } from '../actions';

const { put, takeLeading, retry } = sagaEffects;

function* fetchMemberships(action) {
    try {
        const { data } = yield* authApi.get(config.api.memberships, action.payload);

        const { result, entities } = Normalizers.memberships(data);

        yield put(
            fetchMembershipsSuccess({
                ids: result,
                byId: entities.memberships,
            }),
        );
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.memberships.api.get',
            },
        });

        yield put(fetchMembershipsFailure(errorMessage));
    }
}

export default function* () {
    yield takeLeading(types.FETCH_MEMBERSHIPS_REQUEST, function* (action) {
        yield retry(3, 1000, fetchMemberships, action);
    });
}
