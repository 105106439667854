export const modal = ({ theme }) => ({
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: '2.5rem 1fr',

    fontSize: '1rem',
    fontWeight: 500,
    letterSpacing: 0.01,
    lineHeight: 1.5,

    color: theme.colors.text,
});
