import types from '../actionTypes';

export const fetchAresDataRequest = formId => ({
    type: types.FETCH_ARES_DATA_REQUEST,
    payload: {
        formId,
    },
});

export const fetchAresDataSuccess = (registrationNumber, data) => ({
    type: types.FETCH_ARES_DATA_SUCCESS,
    payload: {
        registrationNumber,
        data,
    },
});

export const fetchAresDataFailure = error => ({
    type: types.FETCH_ARES_DATA_FAILURE,
    error,
});

export const fetchAresDataInvalidate = () => ({
    type: types.FETCH_ARES_DATA_CANCEL,
});
