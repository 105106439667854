import {
    config,
    authApi,
    takeLatestRequest,
    Log,
    sagaEffects,
    push,
    Consts,
    ErrorsUtils,
    messageActions,
    Actions,
} from '../../dependencies';

import { routePaths, types as expenseTypes } from '../../constants';

import { types, deleteExpenseSuccess, deleteExpenseFailure, deleteExpenseInvalidate } from '../actions';
import { expenseTypeSelector, expenseSelector } from '../selectors';

const { put, select } = sagaEffects;

function* deleteExpense(action, cancelToken) {
    const { id } = action.meta;
    const { number } = yield select(expenseSelector, id);
    const values = {
        number,
    };

    try {
        const requestConfig = {
            uriParams: { id },
            cancelToken,
        };

        const expenseType = yield select(expenseTypeSelector, id);

        yield* authApi.delete(config.api.expense, requestConfig);

        yield put(Actions.resetAllExpenses());
        yield put(Actions.resetAllReports());

        switch (expenseType) {
            case expenseTypes.DEFAULT:
            case expenseTypes.STATISTIC:
                yield put(push(routePaths.EXPENSES));
                break;

            case expenseTypes.TEMPLATE:
                yield put(push(Consts.routes.LISTS_EXPENSES_RECURRING));
                break;

            default:
        }

        yield put(deleteExpenseSuccess(id));

        yield put(
            messageActions.displaySuccessMessage({
                id: 'success.expense.delete',
                values,
            }),
        );
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            status: {
                404: {
                    id: 'error.expense.api.delete.status.404',
                    values,
                },
            },
            fallback: {
                id: 'error.expense.api.delete',
                values,
            },
        });

        yield put(deleteExpenseFailure(id, errorMessage));

        yield put(messageActions.displayErrorMessage(errorMessage));
    }
}

export default function* deleteExpenseWatcher() {
    const actionTypes = {
        REQUEST: types.DELETE_EXPENSE_REQUEST,
        cancelTask: deleteExpenseInvalidate,
        requestIdSelector: action => action.meta.userId,
    };

    yield takeLatestRequest(actionTypes, deleteExpense);
}
