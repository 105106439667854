import {
    config,
    authApi,
    takeLatestRequest,
    Log,
    sagaEffects,
    push,
    Normalizers,
    Suppliers,
    Utils,
    Tags,
    ExpenseTriggers,
    messageActions,
    ErrorsUtils,
    Actions,
} from '../../dependencies';
import { routePaths, types as expenseTypes } from '../../constants';

import {
    types,
    createExpenseSuccess,
    createExpenseFailure,
    createExpenseInvalidate,
    fetchNextExpenseNumberReset,
} from '../actions';
import { nextExpenseNumberSelector, toolboxFormValuesSelector } from '../selectors';

import { transformExpenseFormData } from './transformFormData';

const { put, take, select } = sagaEffects;

function* createExpenseTrigger(expenseId, { name, period, startAt, endAt }) {
    yield put(
        ExpenseTriggers.actions.createExpenseTriggerRequest(expenseId, {
            expenseId,
            period,
            startAt,
            endAt,
            name,
        }),
    );

    const { types: expenseTriggerTypes } = ExpenseTriggers.actions;

    const action = yield take([
        expenseTriggerTypes.CREATE_EXPENSE_TRIGGER_SUCCESS,
        expenseTriggerTypes.CREATE_EXPENSE_TRIGGER_FAILURE,
        expenseTriggerTypes.CREATE_EXPENSE_TRIGGER_CANCEL,
    ]);

    switch (action.type) {
        case expenseTriggerTypes.CREATE_EXPENSE_TRIGGER_FAILURE:
            throw action.error;
        case expenseTriggerTypes.CREATE_EXPENSE_TRIGGER_SUCCESS:
            return action.meta.id;
        default:
            return null;
    }
}

function* getExpenseNumber(formData) {
    // Supported types for GET /api/v1/expenses/next?{type},{issued} are 'default' and 'statistics'
    const expenseType = formData.type === expenseTypes.TEMPLATE ? expenseTypes.DEFAULT : formData.type;

    const fetchedNumber = yield select(state => nextExpenseNumberSelector(state, expenseType));

    // NOTE: if user sets it own value, don't change it. Otherwise, fetch next expense number
    const shouldBackendGenerateNumber = !formData.number || formData.number === fetchedNumber;

    return shouldBackendGenerateNumber ? undefined : formData.number;
}

function* createExpense(action, cancelToken) {
    const { data: formData, startSubmit, stopSubmit, reset } = action;
    let error = null;

    try {
        yield startSubmit();

        const number = yield getExpenseNumber(formData);
        const { recurring } = yield select(toolboxFormValuesSelector);

        const { payload, urlsToBeRevoked } = yield transformExpenseFormData({
            ...formData,
            number,
        });

        const { data } = yield* authApi.post(config.api.expenses, payload, {
            cancelToken,
        });

        let expenseTriggerId = null;
        if (recurring) {
            expenseTriggerId = yield createExpenseTrigger(String(data.id), formData);
        }

        // revoke object urls only when the request successfully finished
        Utils.revokeUrls(urlsToBeRevoked);

        yield put(Actions.resetAllExpenses());
        yield put(Actions.resetAllReports());

        const { result: id, entities } = Normalizers.expense(data);

        for (const [supplierId, supplier] of Object.entries(entities.suppliers || {})) {
            yield put(Suppliers.actions.fetchSupplierSuccess(supplierId, supplier));
        }

        yield put(Tags.actions.addTags(Tags.utils.formatPayload(entities.tags)));

        const expense = entities.expenses[id];

        yield put(createExpenseSuccess(id, expense));

        const expenseDetailPage =
            expense.type === expenseTypes.TEMPLATE
                ? routePaths.EXPENSES_RECURRING_ITEM.replace(':expenseTriggerId', expenseTriggerId)
                : routePaths.EXPENSES_ITEM.replace(':expenseId', id);

        yield put(push(expenseDetailPage));

        yield put(fetchNextExpenseNumberReset(payload.type));

        yield reset();

        yield put(
            messageActions.displaySuccessMessage({
                id: 'success.expense.create',
                values: {
                    number: expense.number,
                },
            }),
        );
    } catch (e) {
        Log.error(e);

        error = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.expense.api.create',
            },
        });

        yield put(createExpenseFailure(error));
    } finally {
        yield stopSubmit({
            _error: error,
        });
    }
}

export default function* createExpenseWatcher() {
    const actionTypes = {
        REQUEST: types.CREATE_EXPENSE_REQUEST,
        cancelTask: createExpenseInvalidate,
    };

    yield takeLatestRequest(actionTypes, createExpense);
}
