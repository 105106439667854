import {
    React,
    PropTypes,
    ButtonPrimary,
    FormattedDate,
    isSameYear,
    isSameDay,
    isSameMonth,
    Icons,
    FormattedMessage,
    connectFela,
} from '../../dependencies';

import { RangePropType } from '../propTypes';

import * as Styles from './ButtonWithRange.styles';

function formatRangeValue({ from, to, relativePeriod }) {
    return {
        relativePeriod,
        from: typeof from === 'string' ? new Date(from) : from,
        to: typeof to === 'string' ? new Date(to) : to,
    };
}

function ButtonWithRange({ value, toggleDropdown, styles }) {
    const [rangeValue, setRangeValue] = React.useState(formatRangeValue(value));

    React.useEffect(() => {
        setRangeValue(formatRangeValue(value));
    }, [value]);

    const day = isSameDay(rangeValue.from, rangeValue.to) ? undefined : ButtonWithRange.dateFormat.day;
    const month = isSameMonth(rangeValue.from, rangeValue.to) ? undefined : ButtonWithRange.dateFormat.month;
    const year = isSameYear(rangeValue.from, rangeValue.to) ? undefined : ButtonWithRange.dateFormat.year;

    return (
        <ButtonPrimary type="button" id="togglePicker" onClick={toggleDropdown}>
            <Icons.Calender className={styles.iconCalender} />

            {rangeValue.relativePeriod ? (
                <FormattedMessage id={`dateRangePicker.staticRanges.${rangeValue.relativePeriod}`} />
            ) : (
                <>
                    {(day || month || year) && (
                        <>
                            <FormattedDate
                                {...{
                                    ...ButtonWithRange.dateFormat,
                                    value: rangeValue.from,
                                    day,
                                    month,
                                    year,
                                }}
                            />
                            <span className={styles.dash}>-</span>
                        </>
                    )}
                    <FormattedDate
                        {...{
                            ...ButtonWithRange.dateFormat,
                            value: rangeValue.to,
                        }}
                    />
                </>
            )}
        </ButtonPrimary>
    );
}

ButtonWithRange.dateFormat = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};

ButtonWithRange.propTypes = {
    value: RangePropType.isRequired,
    toggleDropdown: PropTypes.func.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(ButtonWithRange);
