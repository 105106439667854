import { React, PropTypes, DatePickerField, injectIntl, config, InputWrapper, Utils } from '../../../dependencies';

function IssuedOn({ intl, disabled, labelId }) {
    return (
        <InputWrapper>
            <DatePickerField
                {...{
                    name: 'issuedOn',
                    label: intl.formatMessage({
                        id: labelId,
                    }),
                    displayFormat: config.date,
                    disabled,
                    allowClear: false,
                    parse: Utils.parseMomentDateInput,
                }}
            />
        </InputWrapper>
    );
}

IssuedOn.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
    labelId: PropTypes.string,
};

IssuedOn.defaultProps = {
    labelId: 'expense.form.issuedOn.label',
};

export default injectIntl(IssuedOn);
