import { React, PropTypes, FelaComponent, FormattedMessage, Icons } from '../../dependencies';

import * as styles from './InfoBlock.styles';

function InfoBlock({ messageId }) {
    return (
        <FelaComponent style={styles.infoBlock}>
            <FelaComponent style={styles.icon}>{({ className }) => <Icons.Info {...{ className }} />}</FelaComponent>

            <FelaComponent style={styles.text} as="p">
                <FormattedMessage id={messageId} />
            </FelaComponent>
        </FelaComponent>
    );
}

InfoBlock.propTypes = {
    messageId: PropTypes.string.isRequired,
};

export default InfoBlock;
