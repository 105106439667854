import { React, PropTypes, FelaComponent, combineRules, Tooltip, childrenPropType } from '../../dependencies';

import * as styles from './TooltipWrapper.styles';

function TooltipWrapper({ title, placement, children, style }) {
    return (
        <FelaComponent style={combineRules(styles.tooltipWrapper, style)}>
            {tooltipWrapper => (
                <Tooltip
                    placement={placement}
                    overlayClassName={tooltipWrapper.className}
                    title={title}
                    destroyTooltipOnHide
                >
                    {children}
                </Tooltip>
            )}
        </FelaComponent>
    );
}

TooltipWrapper.propTypes = {
    children: childrenPropType.isRequired,
    title: PropTypes.node.isRequired,
    placement: PropTypes.string,
    style: PropTypes.func,
};

TooltipWrapper.defaultProps = {
    placement: 'top',
    style: null,
};

export default TooltipWrapper;
