import { React, PropTypes, FelaComponent, childrenPropType, noop, combineRules } from '../../dependencies';

import * as styles from './InputWrapper.styles';
import sizes from './InputWrapper.sizes';

function InputWrapper({ children, style, labelMarginLeft, size, gridArea }) {
    return (
        <FelaComponent
            {...{
                style: combineRules(styles.input, style),
                labelMarginLeft,
                size,
                gridArea,
            }}
        >
            {children}
        </FelaComponent>
    );
}

InputWrapper.propTypes = {
    children: childrenPropType.isRequired,
    style: PropTypes.func,
    labelMarginLeft: PropTypes.number,
    size: PropTypes.oneOf(Object.values(sizes)),
    gridArea: PropTypes.string,
};

InputWrapper.defaultProps = {
    style: noop,
    labelMarginLeft: 12,
    size: sizes.L,
    gridArea: null,
};

InputWrapper.sizes = sizes;

export default InputWrapper;
