import { connect, ReduxUtils, Consts } from '../dependencies';

import ButtonRunExport from '../components/RecurringExportList/ButtonRunExport';
import { runExportManuallyRequest } from '../services/actions';

const { CREATE } = Consts.apiTypes;
const { EXPORTS } = Consts.entityKeys;

const mapStateToPros = (state, { exportId }) => {
    const { inProgress } = ReduxUtils.apiSelector(state, EXPORTS, CREATE, exportId);

    return {
        loading: inProgress,
    };
};

const mapDispatchToProps = (dispatch, { exportId }) => ({
    onClick(e) {
        e.stopPropagation();
        dispatch(runExportManuallyRequest(exportId));
    },
});

const mergeProps = (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
});

export default connect(mapStateToPros, mapDispatchToProps, mergeProps)(ButtonRunExport);
