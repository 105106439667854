export const bar = ({ width }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: width === null ? 'unset' : width,
});

export const topLabel = ({ theme: { colors } }) => ({
    marginBottom: 8,
    letterSpacing: '0.1px',
    fontSize: '0.875rem',
    color: colors.text,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    zIndex: 2,
    position: 'relative',
});

export const bottomLabel = ({ theme: { colors } }) => ({
    marginTop: 8,
    letterSpacing: '0.1px',
    fontSize: '0.875rem',
    color: colors.blueGrey4,
    flexShrink: 0,
    textAlign: 'center',
});

export const column = ({ height, theme: { colors }, maxHeight }) => ({
    backgroundColor: colors.info,
    opacity: height,
    width: '100%',
    borderRadius: 1,
    height: Math.round(maxHeight * height),
});
