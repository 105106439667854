import { React } from '../../dependencies';

import Loader from './Loader';
import { Colors } from './Loader.constants';

function DataLoader(props) {
    return <Loader {...props} />;
}

DataLoader.defaultProps = {
    size: '24px',
    color: Colors.BLUE,
    minHeight: 'unset',
};

export default DataLoader;
