import { React, PropTypes, Container, Page, Wrapper, AlertSuccess } from '../dependencies';

import Nav from '../containers/Nav';
import Tags from '../containers/Tags';
import Header from '../containers/Header';
import Body from '../containers/Body';

function ExpenseDetail({ expenseId, expenseTriggerId }) {
    return (
        <Container>
            <Page>
                <Nav {...{ expenseId, expenseTriggerId }} />
                <Wrapper>
                    <AlertSuccess expenseId={expenseId} />

                    <Tags expenseId={expenseId} />

                    <Header expenseId={expenseId} />

                    <Body expenseId={expenseId} />
                </Wrapper>
            </Page>
        </Container>
    );
}

ExpenseDetail.propTypes = {
    expenseId: PropTypes.string.isRequired,
    expenseTriggerId: PropTypes.string,
};

ExpenseDetail.defaultProps = {
    expenseTriggerId: '',
};

const arePropsEqual = (prevProps, { expenseId, expenseTriggerId }) =>
    prevProps.expenseId === expenseId && prevProps.expenseTriggerId === expenseTriggerId;

export default React.memo(ExpenseDetail, arePropsEqual);
