// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"dimensions.less"}]);
// Exports
exports.locals = {
	"navbarHeight": "88",
	"containerWidth": "1440",
	"inputMaxWidth": "735",
	"inputMediumMaxWidth": "360",
	"inputRowGap": "24"
};
module.exports = exports;
