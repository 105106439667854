import { React, FelaComponent, FormattedMessage } from '../../dependencies';

import * as styles from './Label.styles';

function Label() {
    return (
        <FelaComponent style={styles.label} as="label">
            <FormattedMessage id="expenses.filters.dateRange.label" />
        </FelaComponent>
    );
}

export default Label;
