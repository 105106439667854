import validateRegularForm from './RegularForm';
import validateStatisticalForm from './StatisticalForm';
import validateRecurringRegularForm from './RecurringRegularForm';
import validateRecurringStatisticalForm from './RecurringStatisticalForm';

export default function getValidateFunction({ recurring, statistic }) {
    if (recurring) {
        return statistic ? validateRecurringStatisticalForm : validateRecurringRegularForm;
    }

    return statistic ? validateStatisticalForm : validateRegularForm;
}
