import { compose, reduxForm, submitForm, injectIntl, UsersActions, connect } from '../../dependencies';

import ChangePasswordForm from '../../components/ChangePasswordForm';
import { Forms } from '../../constants';

import validate from './ChangePasswordForm.validate';

const form = Forms.CHANGE_PASSWORD;

export default compose(
    injectIntl,
    connect(null, {
        onSubmit: submitForm(form, UsersActions.updateAuthUserPassword),
    }),
    reduxForm({
        form,
        validate,
    }),
)(ChangePasswordForm);
