import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const actionTypes = {
    REQUEST: types.FETCH_DISPOSABLE_EXPORT_REQUEST,
    SUCCESS: types.FETCH_DISPOSABLE_EXPORT_SUCCESS,
    FAILURE: types.FETCH_DISPOSABLE_EXPORT_FAILURE,
    CANCEL: types.FETCH_DISPOSABLE_EXPORT_CANCEL,
};

export default ReduxUtils.basicApiReducer({
    actionTypes,
});
