import { React, PropTypes, FormattedMessage, Icons, connectFela } from '../../../../dependencies';

import * as Styles from './DropArea.styles';

const { DocumentNew } = Icons;

function DropArea({ styles }) {
    return (
        <button type="button" className={styles.dropArea}>
            <DocumentNew className={styles.icon} />

            <div className={styles.row}>
                <span className={styles.description}>
                    <FormattedMessage id="expense.form.dropzone.description" />
                </span>

                <span className={styles.link}>
                    <FormattedMessage id="expense.form.dropzone.link" />
                </span>
            </div>
        </button>
    );
}

DropArea.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(DropArea);
