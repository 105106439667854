import React from 'react';

const Exit = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <g transform="translate(-2.000000, -2.000000)">
                <path d="M12,2 L12,6 L14,6 L14,4 L20,4 L20,20 L14,20 L14,18 L12,18 L12,22 L22,22 L22,2 L12,2 Z M7.938,8.408 L10.366,11 L2,11 L2,13 L10.366,13 L7.938,15.544 L9.392,16.905 L14.063,11.958 L9.392,7.06 L7.938,8.408 Z" />
            </g>
        </g>
    </svg>
);

export default Exit;
