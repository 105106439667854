import { compose, reduxForm, injectIntl, submitForm } from '../dependencies';

import TagForm from '../components/TagForm';

import { Forms } from '../constants';
import { createTagRequest } from '../services/actions';

import validate from './TagForm.validate';

export default compose(
    reduxForm({
        form: Forms.CREATE_FORM,
        validate,
        onSubmit: (values, dispatch) => {
            const submit = submitForm(Forms.CREATE_FORM, createTagRequest);
            dispatch(submit(values));
        },
    }),
    injectIntl,
)(TagForm);
