export const headerWrapper = ({ theme: { colors, zIndex } }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: zIndex.navbar,

    width: '100%',

    backgroundColor: colors.white,
    background: colors.background,
});

export const header = ({ theme: { dimensions } }) => ({
    display: 'flex',

    width: dimensions.containerWidth,
    padding: '0 60px',
    maxWidth: '100%',
    height: dimensions.navbarHeight,
    margin: '0 auto',
});
