import { fetchDependencies, connect, compose, ReduxUtils, Consts } from '../dependencies';

import * as Actions from '../services/actions';

const { EXPENSE_TRIGGER } = Consts.entityKeys;
const { FETCH } = Consts.apiTypes;

function fetchExpenseTrigger(id) {
    const mapStateToProps = (state, { expenseTriggerId }) => {
        const itemId = expenseTriggerId || id;

        return {
            fetchExpenseTriggerApi: ReduxUtils.apiSelector(state, EXPENSE_TRIGGER, FETCH, itemId),
            expenseTriggerId: itemId,
        };
    };

    const mapDispatchToProps = {
        fetchExpenseTriggerRequest: Actions.fetchExpenseTriggerRequest,
        fetchExpenseTriggerInvalidate: Actions.fetchExpenseTriggerInvalidate,
    };

    const fetchConfig = {
        onLoad({ fetchExpenseTriggerApi: { inProgress, success }, fetchExpenseTriggerRequest, expenseTriggerId }) {
            if (!inProgress && !success) {
                fetchExpenseTriggerRequest(expenseTriggerId);
            }
        },
        onUnload({ fetchExpenseTriggerApi: { inProgress }, fetchExpenseTriggerInvalidate, expenseTriggerId }) {
            if (inProgress) {
                fetchExpenseTriggerInvalidate(expenseTriggerId);
            }
        },
    };

    return WrappedComponent =>
        compose(
            connect(
                mapStateToProps,
                mapDispatchToProps,
            ),
            fetchDependencies(fetchConfig),
        )(WrappedComponent);
}

export default fetchExpenseTrigger;
