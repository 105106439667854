import { React, UI, PropTypes } from '../../dependencies';

import { useSelectCompany } from '../../hooks';

const { Icons } = UI;

const PickCompany = ({ styles }) => {
    const { value: activeValue, onChange, options } = useSelectCompany(true);

    return (
        <div className={styles.list}>
            {options.map(({ value, label }) => (
                <button key={value} className={styles.row} onClick={() => onChange(value)} type="button">
                    <div className={styles.iconWrapper}>
                        {value === activeValue && <Icons.Ok className={styles.iconSelected} />}
                    </div>
                    <span className={styles.label}>{label}</span>
                </button>
            ))}
        </div>
    );
};

PickCompany.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default PickCompany;
