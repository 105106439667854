import { theme } from '../dependencies';

export const colors = {
    POSITIVE: theme.colors.positive,
    NEGATIVE: theme.colors.negative,
    NEUTRAL: theme.colors.blueGrey2,
};

export const FILTER_DEFAULT_VALUE = '';
export const FILTER_DEBOUNCE_TIME = 500;

export const Forms = {
    CREATE_FORM: 'createForm',
    UPDATE_FORM: 'updateForm',
};
