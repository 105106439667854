import { sagaEffects, LOCATION_CHANGE, ReactGA } from '../../dependencies';

const { takeEvery } = sagaEffects;

export default function*() {
    yield takeEvery(LOCATION_CHANGE, function(action) {
        const { pathname } = action.payload.location;
        ReactGA.pageview(pathname);
    });
}
