import { React, PropTypes, FelaComponent, childrenPropType, connectFela } from '../../dependencies';

import FelaKeyframe from '../FelaKeyframe';

import * as Styles from './Loader.styles';
import { Colors } from './Loader.constants';

const { spin, ...FelaStyles } = Styles;

function Loader({ children, show, size, color, styles, rules }) {
    if (show) {
        return (
            <div className={styles.wrapper}>
                <FelaKeyframe keyframe={spin}>
                    {animationName => (
                        <FelaComponent
                            {...{
                                color,
                                size,
                                style: rules.spinner,
                                animationName,
                            }}
                        >
                            {spinner => <div className={spinner.className} />}
                        </FelaComponent>
                    )}
                </FelaKeyframe>
            </div>
        );
    }

    return <>{children}</>;
}

Loader.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    children: childrenPropType,
    show: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    color: PropTypes.oneOf(Object.values(Colors)),
    minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Loader.defaultProps = {
    children: null,
    show: true,
    size: '75px',
    type: 'block',
    color: Colors.RED,
    minHeight: '350px',
};

export default connectFela(FelaStyles)(Loader);
