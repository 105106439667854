import { useSelector } from '../dependencies';

import { noActiveCompanyIdSelector } from '../services/selectors';

/**
 * @returns {boolean}
 */
export function useNoActiveCompanyId() {
    return useSelector(noActiveCompanyIdSelector);
}
