import types from './types';

export const submitCreateExportForm = () => ({
    type: types.SUBMIT_CREATE_EXPORT_FORM,
});

export const submitUpdateExportForm = (exportId, serviceKeyId) => ({
    type: types.SUBMIT_UPDATE_EXPORT_FORM,
    exportId,
    serviceKeyId,
});

export const deleteExportWithServiceKey = (exportId, serviceKeyId) => ({
    type: types.DELETE_EXPORT_WITH_SERVICE_KEY,
    exportId,
    serviceKeyId,
});
