import types from './types';

export { types };

export const setFilterUrlParams = params => ({
    type: types.SET_FILTER_URL_PARAMS,
    payload: params,
});

export const setOrderUrlParam = ({ order, orderBy }) => ({
    type: types.SET_ORDER_URL_PARAM,
    payload: {
        order,
        orderBy,
    },
});
