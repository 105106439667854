import { sagaEffects } from '../../dependencies';

import createMembership from './createMembership';
import deleteMembership from './deleteMembership';
import updateMembership from './updateMembership';
import fetchMemberships from './fetchMemberships';
import refetchMemberships from './refetchMemberships';

import singles from './singles';

const { all } = sagaEffects;

export default function* expensesSaga() {
    yield all([
        fetchMemberships(),
        deleteMembership(),
        createMembership(),
        updateMembership(),
        singles(),
        refetchMemberships(),
    ]);
}
