import { React, useSelector, useDispatch } from '../dependencies';

import { fetchServiceKeyRequest, fetchServiceKeyInvalidate } from '../services/actions';
import { fetchServiceKeyApiSelector } from '../services/selectors';

export default function useFetchServiceKey(id) {
    const api = useSelector(state => fetchServiceKeyApiSelector(state, id));
    const dispatch = useDispatch();
    const request = () => dispatch(fetchServiceKeyRequest(id));

    React.useEffect(() => {
        if (!api.inProgress && !api.success) {
            request();
        }

        return () => {
            if (api.inProgress) {
                dispatch(fetchServiceKeyInvalidate(id));
            }
        };
        // eslint-disable-next-line
    }, [id]);

    const { inProgress, success, error } = api;

    return {
        inProgress,
        success,
        error: {
            retry: request,
            message: error,
        },
    };
}
