import { combineReducers } from '../../../../dependencies';

import ids from './ids';
import byId from './byId';
import byExpenseId from './byExpenseId';

export default combineReducers({
    byId,
    ids,
    byExpenseId,
});
