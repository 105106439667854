import { config, authApi, Log, sagaEffects, ErrorsUtils } from '../../dependencies';

import { deleteExportSuccess, deleteExportFailure, types } from '../actions';

const { put, takeEvery } = sagaEffects;

function* deleteExport(action) {
    const { id } = action.meta;
    try {
        yield* authApi.delete(config.api.export, {
            uriParams: {
                id,
            },
        });

        yield put(deleteExportSuccess(id));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.export.api.delete',
            },
        });

        yield put(deleteExportFailure(id, errorMessage));
    }
}

export default function* () {
    yield takeEvery(types.DELETE_EXPORT_REQUEST, deleteExport);
}
