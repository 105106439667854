import { React, PropTypes, childrenPropType, FelaComponent } from '../../dependencies';

import * as styles from './Page.styles';

function Page({ children, margin }) {
    return (
        <FelaComponent style={styles.page} margin={margin}>
            {children}
        </FelaComponent>
    );
}

Page.propTypes = {
    children: childrenPropType.isRequired,
    margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Page.defaultProps = {
    margin: '0 0 48px',
};

export default Page;
