import {
    React,
    PropTypes,
    Chip,
    chipTypes,
    FormattedMessage,
    SelectInput,
    Utils,
    InputWrapper,
    connectFela,
    noop,
} from '../../dependencies';

import ButtonAdd from './ButtonAdd';

import * as Styles from './AddTag.styles';

function AddTag({ options, disabled, intl, onChange, small, styles, rules, buttonNewTag }) {
    const [isFocused, setFocus] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [onBlurValue, setOnBlurValue] = React.useState('');

    const handleValueSubmit = React.useCallback(
        nextValue => {
            if (nextValue && !onBlurValue) {
                onChange(nextValue);
                setValue('');
                setFocus(false);
            }
        },
        [setValue, onChange, setFocus, onBlurValue],
    );

    const handleInputKeyDown = React.useCallback(
        e => {
            if (e.key === 'Enter') {
                handleValueSubmit();
            }
        },
        [handleValueSubmit],
    );

    const handleButtonAddClick = React.useCallback(
        e => {
            e.stopPropagation();

            if (onBlurValue) {
                onChange(onBlurValue);
                setValue('');
                setOnBlurValue('');
                setFocus(false);
            }
        },
        [onBlurValue, setFocus, setOnBlurValue, onChange, setValue],
    );

    const handleChipClick = React.useCallback(() => {
        setFocus(true);
    }, [setFocus]);

    const handleBlur = React.useCallback(() => {
        setOnBlurValue(value);
    }, [setOnBlurValue, value]);

    React.useEffect(() => {
        if (isFocused) {
            const input = document.querySelector('#addTagInput input');
            if (input) {
                input.focus();
            }
        }
    });

    const closeDropdown = React.useCallback(() => {
        if (isFocused) {
            setFocus(false);
            setValue('');
            setOnBlurValue('');
        }
    }, [setFocus, isFocused]);

    return (
        <div className={styles.wrapper}>
            {isFocused ? (
                <span className="add-tag">
                    <button type="button" className={styles.overlay} onFocus={closeDropdown} />
                    <InputWrapper size={InputWrapper.sizes.S} style={rules.inputWrapper}>
                        <SelectInput
                            {...{
                                id: 'addTagInput',
                                placeholder: intl.formatMessage({
                                    id: 'tags.placeholder',
                                }),
                                options,
                                disabled,
                                input: {
                                    onSearch: setValue,
                                    onBlur: handleBlur,
                                    onChange: noop,
                                    onSelect: handleValueSubmit,
                                    onKeyPress: handleInputKeyDown,
                                },
                                filterOption: Utils.filterOption,
                                showSearch: true,
                                defaultOpen: true,
                                showArrow: false,
                                size: small ? 'small' : undefined,
                                open: true,
                                dropdownRender: menuNode => (
                                    <>
                                        {buttonNewTag && (
                                            <>
                                                <ButtonAdd onClick={handleButtonAddClick} />
                                                <div className={styles.divider} />
                                            </>
                                        )}
                                        {menuNode}
                                    </>
                                ),
                            }}
                        />
                    </InputWrapper>
                </span>
            ) : (
                <Chip
                    {...{
                        id: 'addTag',
                        type: chipTypes.ADD,
                        size: small ? Chip.sizes.S : undefined,
                        onClick: handleChipClick,
                    }}
                >
                    <FormattedMessage id="tags.new" />
                </Chip>
            )}
        </div>
    );
}

AddTag.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    small: PropTypes.bool.isRequired,
    intl: PropTypes.shape().isRequired,
    styles: PropTypes.shape().isRequired,
    buttonNewTag: PropTypes.bool.isRequired,
};

AddTag.defaultProps = {
    disabled: false,
};

export default connectFela(Styles)(AddTag);
