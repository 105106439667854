import { sagaEffects } from '../../dependencies';

import fetchReport from './fetchReport';
import createReport from './createReport';
import updateReport from './updateReport';
import deleteReport from './deleteReport';
import createTemporaryReport from './createTemporaryReport';
import fetchReports from './fetchReports';
import tagVisibility from './tagVisibility';
import fetchNewReport from './fetchNewReport';
import reportLifeCycle from './reportLifeCycle';

const { all } = sagaEffects;

export default function*() {
    yield all([
        fetchReport(),
        createReport(),
        updateReport(),
        deleteReport(),
        createTemporaryReport(),
        fetchReports(),
        tagVisibility(),
        fetchNewReport(),
        reportLifeCycle(),
    ]);
}
