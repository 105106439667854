import { sagaEffects } from '../../dependencies';

import removeActiveCompany from './removeActiveCompany';
import setActiveCompany from './setActiveCompany';

const { all } = sagaEffects;

export default function* () {
    yield all([removeActiveCompany(), setActiveCompany()]);
}
