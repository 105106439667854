export const filter = () => ({
    display: 'flex',

    '& .ant-form-item': {
        marginBottom: 0,
    },
    '& .ant-form-item .ant-select': {
        minWidth: '0 !important',
    },
});
