import { React, PropTypes, FelaComponent, FormattedMessage, withRouter } from '../../dependencies';

import Tab from './Tab';

import * as styles from './Tabs.styles';

function Tabs({ items, location: { pathname } }) {
    return (
        <FelaComponent style={styles.tabs} count={items.length}>
            {items.map(({ messageId, path }) => (
                <Tab
                    {...{
                        key: path,
                        path,
                        isActive: path === pathname,
                    }}
                >
                    <FormattedMessage id={messageId} />
                </Tab>
            ))}
        </FelaComponent>
    );
}

Tabs.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            messageId: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(Tabs);
