import { React, PropTypes, FelaComponent, FormattedMessage } from '../../dependencies';

import * as styles from './Title.styles';

function Title({ messageId }) {
    return (
        <FelaComponent style={styles.title}>
            <FormattedMessage id={messageId} />
        </FelaComponent>
    );
}

Title.propTypes = {
    messageId: PropTypes.string.isRequired,
};

export default Title;
