import React from 'react';

const Rotate = props => (
    <svg viewBox="0 0 16 18" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <g transform="translate(-4.000000, -3.000000)">
                <path d="M18,13 C18,16 15.309,19 12,19 C8.691,19 6,16.309 6,13 C6,9.691 8.691,7 12,7 L12,9 L17,6 L12,3 L12,5 C7.582,5 4,8.582 4,13 C4,17.418 7.582,21 12,21 C16.418,21 20,18 20,13 L18,13 Z" />
            </g>
        </g>
    </svg>
);

export default Rotate;
