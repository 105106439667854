import { React, PropTypes } from 'modules/reports/dependencies';
import { Views } from 'modules/reports/constants';

import ChartDoughnutWrapper from '../ChartDoughnutWrapper';
import Tags from '../Tags';
import ToggleView from '../ToggleView';
import Total from '../Total';

const ViewComponents = {
    [Views.DOUGHNUT]: ChartDoughnutWrapper,
    [Views.TAGS]: Tags,
    [Views.TOGGLE_VIEW]: ToggleView,
};

function CardContent({ view, setView }) {
    const ActiveView = ViewComponents[view];

    return (
        <>
            <ActiveView setView={setView} />
            {view !== Views.TAGS && <Total />}
        </>
    );
}

CardContent.propTypes = {
    view: PropTypes.oneOf(Object.values(Views)).isRequired,
    setView: PropTypes.func.isRequired,
};

export default CardContent;
