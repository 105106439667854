export const button = () => ({
    position: 'relative',
    top: '2px',
    marginLeft: '0.5rem',
    display: 'grid',
});

const icon = ({ theme: { colors, transitions }, active }) => ({
    height: 5,
    fill: active ? colors.primary : colors.blueGrey4,
    transition: `fill ${transitions.button}`,
});

export const iconTop = props => ({
    ...icon(props),
    marginBottom: 4,
});

export const iconBottom = props => ({
    ...icon(props),
    transform: 'rotateZ(180deg)',
});
