export const button = ({ theme: { colors } }) => {
    const activeStyle = {
        color: colors.primaryHover,
    };

    return {
        fontSize: '14px',
        lineHeight: 1.21,
        letterSpacing: '0.5px',
        background: 'none',
        border: 'none',
        color: colors.primary,
        textDecoration: 'underline',
        '-webkit-appearance': 'none',

        '&:hover': activeStyle,
        '&:focus': activeStyle,
        '&:active': activeStyle,

        '&:disabled': {
            opacity: 0.75,
            cursor: 'not-allowed',
        },
    };
};
