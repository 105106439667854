export const total = ({ theme: { colors } }) => ({
    fontSize: '1rem',
    lineHeight: 1.56,
    letterSpacing: '0.2px',
    color: colors.text,
});

export const wrapper = () => ({
    display: 'flex',
    marginBottom: 48,
});
