import { sagaEffects, push, Selectors, Utils } from '../../dependencies';

import { types } from '../actions';
import { injectOrderIntoSearch } from '../../utils';

const { select, takeEvery, put } = sagaEffects;

function* setFilterUrlParams(action) {
    const currentLocation = yield select(Selectors.locationSelector);
    const search = injectOrderIntoSearch(currentLocation.search, action.payload);

    const nextLocation = {
        ...currentLocation,
        key: Utils.uniqueNonSecureId(),
        search,
    };

    yield put(push(nextLocation));
}

export default function*() {
    yield takeEvery(types.SET_ORDER_URL_PARAM, setFilterUrlParams);
}
