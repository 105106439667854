import {
    compose,
    reduxForm,
    withProps,
    config,
    connect,
    isEmpty,
    withErrorBoundary,
    Filter,
    commonFiltersActions,
    DataLoader,
    loadable,
} from '../dependencies';

import { tagsOptionsSelectors, reportFiltersValuesSelector, filtersShowLoaderSelector } from '../services/selectors';
import { transformFormValuesToUrlParams } from '../utils';

const mapStateToProps = state => {
    return {
        initialValues: reportFiltersValuesSelector(state),
        showLoader: filtersShowLoaderSelector(state),
    };
};

const mapDispatchToProps = {
    setFilterUrlParams: commonFiltersActions.setFilterUrlParams,
};

const formConfig = {
    form: config.forms.report,
    enableReinitialize: true,
    onChange(values, dispatch, { pristine, setFilterUrlParams }, prevValues) {
        // Check if onChange is called on initialValues (there are no previousValues)
        if (pristine || isEmpty(prevValues)) {
            return;
        }

        const formValuesAsUrlParams = transformFormValuesToUrlParams(values);

        setFilterUrlParams(formValuesAsUrlParams);
    },
};

export default compose(
    withErrorBoundary,
    withProps({
        optionsSelector: tagsOptionsSelectors,
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    loadable(DataLoader),
    reduxForm(formConfig),
)(Filter);
