import { React, PropTypes, TextField, injectIntl, InputWrapper } from '../../../dependencies';

import * as styles from './VariableSymbol.styles';

function VariableSymbol({ intl, disabled }) {
    return (
        <InputWrapper style={styles.variableSymbol}>
            <TextField
                {...{
                    name: 'variableSymbol',
                    label: intl.formatMessage({
                        id: 'expense.form.variableSymbol.label',
                    }),
                    placeholder: intl.formatMessage({
                        id: 'expense.form.variableSymbol.label',
                    }),
                    disabled,
                    parse: VariableSymbol.parse,
                }}
            />
        </InputWrapper>
    );
}

VariableSymbol.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

const isDigit = char => Number.isInteger(Number.parseInt(char, 10));

VariableSymbol.parse = (value = '') => {
    const VARIABLE_SYMBOL_LENGTH = 10;

    return String(value).slice(0, VARIABLE_SYMBOL_LENGTH).split('').filter(isDigit).join('');
};

export default injectIntl(VariableSymbol);
