import types from '../actionTypes';

export { types };

export const openModal = (id, data) => ({
    type: types.OPEN_MODAL,
    payload: { id, data },
});

export const closeModal = id => ({
    type: types.CLOSE_MODAL,
    payload: { id },
});
