import { React, PropTypes, DatePickerField, injectIntl, config, InputWrapper, Utils } from '../../../dependencies';

function ReceivedOn({ intl, disabled }) {
    return (
        <InputWrapper>
            <DatePickerField
                {...{
                    name: 'receivedOn',
                    label: intl.formatMessage({
                        id: 'expense.form.receivedOn.label',
                    }),
                    displayFormat: config.date,
                    disabled,
                    allowClear: false,
                    parse: Utils.parseMomentDateInput,
                }}
            />
        </InputWrapper>
    );
}

ReceivedOn.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default injectIntl(ReceivedOn);
