import React from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from '@ackee/lucas';
import { FelaComponent } from 'react-fela';
import { noop } from 'lodash';

import * as styles from './Icon.styles';

function Icon({ size, children, onClick }) {
    return (
        <FelaComponent {...{ size, style: styles.button }}>
            {({ className }) => (
                <button type="button" {...{ className }} onClick={onClick}>
                    <FelaComponent {...{ size, style: styles.icon }}>{children}</FelaComponent>
                </button>
            )}
        </FelaComponent>
    );
}

Icon.propTypes = {
    children: PropTypes.oneOfType([childrenPropType, PropTypes.func]).isRequired,
    size: PropTypes.string,
    onClick: PropTypes.func,
};

Icon.defaultProps = {
    size: undefined,
    onClick: noop,
};

export default Icon;
