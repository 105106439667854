import { React, PropTypes, FelaComponent } from '../../dependencies';

import * as styles from './Space.styles';

function Space({ top, right, bottom, left }) {
    return <FelaComponent style={styles.space} {...{ top, right, bottom, left }} />;
}

const valuePropType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

Space.propTypes = {
    top: valuePropType,
    right: valuePropType,
    bottom: valuePropType,
    left: valuePropType,
};

Space.defaultProps = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
};

export default Space;
