import { config, authApi, takeLatestRequest, Log, sagaEffects, Normalizers, ErrorsUtils } from '../../dependencies';

import { types, updateServiceKeySuccess, updateServiceKeyFailure, updateServiceKeyInvalidate } from '../actions';

const { put } = sagaEffects;

function* updateServiceKey(action, cancelToken) {
    const { id } = action.meta;

    try {
        const { data } = yield* authApi.put(config.api.serviceKey, action.payload, {
            uriParams: { id },
            cancelToken,
        });

        const { entities } = Normalizers.serviceKey(data);

        yield put(updateServiceKeySuccess(id, entities.serviceKeys[id]));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e);

        yield put(updateServiceKeyFailure(id, errorMessage));
    }
}

export default function* updateServiceKeyWatcher() {
    const actionTypes = {
        REQUEST: types.UPDATE_SERVICE_KEY_REQUEST,
        cancelTask: updateServiceKeyInvalidate,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, updateServiceKey);
}
