import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from './types';

export const fetchNewReportRequest = () => ({
    type: types.FETCH_NEW_REPORT_REQUEST,
});

export const fetchNewReportSuccess = report => {
    return {
        type: types.FETCH_NEW_REPORT_SUCCESS,
        payload: formatEntity({
            entity: report,
            template: entitiesTemplates.temporaryReport,
        }),
    };
};

export const fetchNewReportFailure = error => ({
    type: types.FETCH_NEW_REPORT_FAILURE,
    error,
});

export const fetchNewReportInvalidate = () => ({
    type: types.FETCH_NEW_REPORT_CANCEL,
});

export const fetchNewReportReset = () => ({
    type: types.FETCH_NEW_REPORT_RESET,
});
