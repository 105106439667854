import { React, PropTypes, TextField, UI, useIntl, isSubmittingSelector, useSelector } from '../../../../dependencies';

const { InputWrapper } = UI;

const AccountSlug = ({ formId }) => {
    const intl = useIntl();
    const isSubmitting = useSelector(isSubmittingSelector(formId));

    return (
        <InputWrapper gridArea="accountSlug">
            <TextField
                name="accountSlug"
                label={intl.formatMessage({
                    id: 'export.recurring.form.accountSlug.label',
                })}
                placeholder={intl.formatMessage({
                    id: 'export.recurring.form.accountSlug.placeholder',
                })}
                disabled={isSubmitting}
            />
        </InputWrapper>
    );
};

AccountSlug.propTypes = {
    formId: PropTypes.string.isRequired,
};

export default AccountSlug;
