import types from './types';

export const fetchNextExpenseNumberRequest = type => ({
    type: types.FETCH_NEXT_EXPENSE_NUMBER_REQUEST,
    meta: {
        type,
    },
});

export const fetchNextExpenseNumberSuccess = (type, payload) => ({
    type: types.FETCH_NEXT_EXPENSE_NUMBER_SUCCESS,
    meta: { type },
    payload,
});

export const fetchNextExpenseNumberFailure = (type, error) => ({
    type: types.FETCH_NEXT_EXPENSE_NUMBER_FAILURE,
    meta: { type },
    error,
});

export const fetchNextExpenseNumberInvalidate = type => ({
    type: types.FETCH_NEXT_EXPENSE_NUMBER_CANCEL,
    meta: { type },
});

export const fetchNextExpenseNumberReset = type => ({
    type: types.FETCH_NEXT_EXPENSE_NUMBER_RESET,
    meta: { type },
});
