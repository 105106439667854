import { config, authApi, Log, sagaEffects, ErrorsUtils, ActiveCompanySelectors } from '../../dependencies';

import { types, createMembershipSuccess, createMembershipFailure } from '../actions';

const { put, takeLeading, select } = sagaEffects;

function* createMembership(action) {
    const { role, email } = action.payload;

    try {
        const activeCompanyId = yield select(ActiveCompanySelectors.activeCompanyIdSelector);

        const { data } = yield* authApi.post(config.api.memberships, {
            email,
            role,
            companyId: activeCompanyId,
        });

        const id = String(data.id);

        yield put(createMembershipSuccess(id, data));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            status: {
                404: {
                    id: 'error.memberships.api.create.status.404',
                    values: { email },
                },
            },
            fallback: {
                id: 'error.memberships.api.create.fallback',
            },
        });

        yield put(createMembershipFailure(errorMessage));
    }
}

export default function* createMembershipWatcher() {
    yield takeLeading(types.CREATE_MEMBERSHIP_REQUEST, createMembership);
}
