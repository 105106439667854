import {
    React,
    PropTypes,
    FelaComponent,
    FormattedMessage,
    Icons,
    childrenPropType,
    errorPropType,
} from '../../dependencies';

import { useModal } from '../../hooks';

import Modal from '../Modal';

import * as Styles from './DeleteModal.styles';

const DeleteModal = ({ id, handleDelete, deleting, children, error }) => {
    const modal = useModal(id);
    return (
        <>
            <FelaComponent as="span" style={Styles.button}>
                <button type="button" onClick={() => modal.open()}>
                    <Icons.Remove with="20" height="20" />
                </button>
            </FelaComponent>
            <Modal
                visible={modal.isOpen}
                onCancel={modal.close}
                onOk={handleDelete}
                okText={<FormattedMessage id="modals.delete.ok" />}
                confirmLoading={deleting}
                error={error}
            >
                <FelaComponent style={Styles.modal}>
                    <Icons.NeutralFace width="20" height="20" />
                    {children}
                </FelaComponent>
            </Modal>
        </>
    );
};

DeleteModal.propTypes = {
    id: PropTypes.string.isRequired,
    handleDelete: PropTypes.func.isRequired,
    deleting: PropTypes.bool.isRequired,
    children: childrenPropType.isRequired,
    error: errorPropType,
};

DeleteModal.defaultProps = {
    error: null,
};

export default DeleteModal;
