import { React, FelaComponent, PropTypes, Icons } from '../../../dependencies';

import style from './UpdateTagButton.style';

const UpdateTagButton = ({ onClick }) => (
    <FelaComponent as="span" style={style}>
        <button type="button" onClick={onClick}>
            <Icons.Update width="20" height="21" />
        </button>
    </FelaComponent>
);

UpdateTagButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default UpdateTagButton;
