const safeFnCallFactory = fn => (...args) => {
    try {
        return fn(...args);
    } catch (e) {
        return null;
    }
};

export const createDateFormatter = locale => {
    const { format: formatDate } = new Intl.DateTimeFormat(locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    });

    return safeFnCallFactory(formatDate);
};

export const isValueFormatInvalid = value => {
    const dateValue = Date.parse(value);

    if (Number.isNaN(dateValue)) {
        return true;
    }

    const date = new Date(dateValue);

    return date.getFullYear() < 1000;
};

export const isDateRangeValid = range => range.startDate <= range.endDate;
