import { React, connectFela, injectIntl, compose, useSelector, PropTypes } from 'modules/reports/dependencies';

import { barChartDateRangeSelector } from 'modules/reports/services/selectors';

import * as Styles from './DateRange.styles';

const maybeRemoveYear = (date, shouldBeRemoved) => (shouldBeRemoved ? date.split(' ').slice(0, 2).join(' ') : date);

const equalYears = (dateA, dateB) => new Date(dateA).getYear() === new Date(dateB).getYear();

function DateRange({ intl: { formatDate }, styles }) {
    const { from, to } = useSelector(barChartDateRangeSelector);

    if (!from || !to) {
        return null;
    }

    return (
        <div className={styles.range}>
            {maybeRemoveYear(formatDate(from), equalYears(from, to))}
            <div className={styles.divider}>–</div>
            {formatDate(to)}
        </div>
    );
}

DateRange.propTypes = {
    intl: PropTypes.shape().isRequired,
    styles: PropTypes.shape().isRequired,
};

export default compose(React.memo, connectFela(Styles), injectIntl)(DateRange);
