import { createSelector, Tags, difference, union, CompaniesSelectors } from '../../../dependencies';
import { DoughnutChart } from '../../../config';

import { currentReportSelector } from '../entities';
import { barChartViewportSelector } from '../barChart';
import { calcSegmentsOpacity } from '../utils';

function createOtherSegment(otherSegments, totalWithoutVat) {
    const withoutVatTotal = otherSegments.reduce((sum, segment) => segment.withoutVat + sum, 0);
    const withVatTotal = otherSegments.reduce((sum, segment) => segment.withVat + sum, 0);

    const value = withoutVatTotal / totalWithoutVat;

    return {
        tagNames: [],
        value,
        withoutVat: withoutVatTotal,
        withVat: withVatTotal,
        combinedSegment: true,
    };
}

function createMapDataToSegments(tags, totalWithoutVat) {
    return function mapDataToSegments({ tagIds, withoutVat, withVat }) {
        const value = withoutVat / totalWithoutVat;

        return {
            tagNames: tagIds.map(tagId => tags.byId[tagId]).filter(Boolean),
            value,
            withoutVat,
            withVat,
            combinedSegment: false,
        };
    };
}

const compareFieldInDesc = fieldId => (a, b) => b[fieldId] - a[fieldId];

function tagsGroupsUnion(...tagsGroups) {
    return tagsGroups.reduce((acc, group) => {
        const { withVat, withoutVat, tagIds } = group;

        acc.withVat += withVat;
        acc.withoutVat += withoutVat;
        union(acc.tagIds, tagIds);

        return acc;
    }, {});
}

function tagsDistributionUnion(tagsDistribution = []) {
    const tagsDistributionTable = new Map();

    for (const tagsGroup of tagsDistribution) {
        const sortedTagIds = tagsGroup.tagIds.sort();
        const tagIdsHash = sortedTagIds.join(',');

        const updatedTagsGroup = {
            ...tagsGroup,
            tagIds: sortedTagIds,
        };

        const storedTagsGroup = tagsDistributionTable.get(tagIdsHash);

        if (storedTagsGroup) {
            tagsDistributionTable.set(tagIdsHash, tagsGroupsUnion(updatedTagsGroup, storedTagsGroup));
        } else {
            tagsDistributionTable.set(tagIdsHash, updatedTagsGroup);
        }
    }

    return [...tagsDistributionTable.values()];
}

export const reportDoughnutChartSelector = createSelector(
    [CompaniesSelectors.activeCompanyCurrencySelector, Tags.selectors.selectTags, currentReportSelector],
    (currency, tags, report) => {
        const defaults = {
            currency,
            chartData: [],
            tableData: [],
        };

        if (!report || !report.buckets) {
            return defaults;
        }

        const { buckets, hiddenTags } = report;
        const { total } = buckets;

        const tagsDistributionWithDuplications = total.tagsDistribution.map(tagsGroup => {
            return {
                ...tagsGroup,
                tagIds: difference(
                    tagsGroup.tagIds,
                    hiddenTags.map(tagId => Number.parseInt(tagId, 10)),
                ),
            };
        });

        const tagsDistribution = tagsDistributionUnion(tagsDistributionWithDuplications);

        // The 1 is reserved for the 'Others' segment.
        const VISIBLE_SEGMENTS_COUNT = DoughnutChart.MAX_SEGMENTS_COUNT - 1;

        const sortedSegments = tagsDistribution.sort(compareFieldInDesc('withoutVat'));

        const visibleSegments = sortedSegments.slice(0, VISIBLE_SEGMENTS_COUNT).filter(segment => {
            const value = segment.withoutVat / total.withoutVat;

            return value > DoughnutChart.MIN_SEGMENT_VALUE;
        });

        const tooSmallVisibleSegments = sortedSegments.slice(0, VISIBLE_SEGMENTS_COUNT).filter(segment => {
            const value = segment.withoutVat / total.withoutVat;

            return value <= DoughnutChart.MIN_SEGMENT_VALUE;
        });

        const otherSegments = sortedSegments.slice(VISIBLE_SEGMENTS_COUNT).concat(tooSmallVisibleSegments);

        const otherSegment = createOtherSegment(otherSegments, total.withoutVat);

        const mapDataToSegments = createMapDataToSegments(tags, total.withoutVat);

        const visibleData = visibleSegments.map(mapDataToSegments);

        if (otherSegments.length > 0) {
            visibleData.push(otherSegment);
        }

        const visibleDataWithOpacities = calcSegmentsOpacity(visibleData);

        return {
            currency,
            chartData: visibleDataWithOpacities.sort(compareFieldInDesc('value')),
            tableData: sortedSegments.map(mapDataToSegments),
        };
    },
);

export const reportTagsTotalSelector = createSelector(
    [CompaniesSelectors.activeCompanyCurrencySelector, barChartViewportSelector],
    (currency, report) => {
        if (!report) {
            return {
                withVat: 0,
                withoutVat: 0,
                currency,
            };
        }

        const { withVat, withoutVat } = report.total;

        return {
            currency,
            withVat,
            withoutVat,
        };
    },
);
