import { React, PropTypes, UI, FormSection } from '../../dependencies';

import Name from './Name';
import Period from './Period';
import Service from './Service';
import ServicesCredentials from './ServicesCredentials';

const { Space } = UI;

const ExportForm = ({ styles, formId }) => {
    return (
        <div className={styles.form}>
            <Name formId={formId} />

            <Space bottom={40} />

            <Period formId={formId} />

            <Space bottom={38} />

            <Service formId={formId} />

            <FormSection name="credentials">
                <ServicesCredentials formId={formId} />
            </FormSection>
        </div>
    );
};

ExportForm.propTypes = {
    styles: PropTypes.shape().isRequired,
    formId: PropTypes.string.isRequired,
};

export default ExportForm;
