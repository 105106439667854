import { ReduxUtils } from 'modules/expenses/dependencies';
import { types } from 'modules/expenses/services/actions';

const initialState = {
    totalPriceWithVatDefaultCurrency: null,
    totalPriceWithoutVatDefaultCurrency: null,

    totalCompanyPriceWithVatDefaultCurrency: null,
    totalCompanyPriceWithoutVatDefaultCurrency: null,
};

function totalPriceReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_EXPENSES_SUCCESS: {
            const { payload } = action;
            const entries = Object.keys(initialState).map(key => {
                const value = payload[key];
                const nextValue = value === undefined ? initialState[key] : value;
                return [key, nextValue];
            });

            return Object.fromEntries(entries);
        }

        case types.FETCH_EXPENSES_RESET:
            return initialState;

        default:
            return state;
    }
}

export default ReduxUtils.containerReducer({
    actionTypes: [types.FETCH_EXPENSES_SUCCESS, types.FETCH_EXPENSES_RESET],
    childReducer: totalPriceReducer,
    selectors: {
        itemId: action => action.meta.groupId,
    },
});
