import { createSelector, Tags, union, filterSelectors, Utils } from '../../dependencies';

import { selectReports, currentReportSelector } from './entities';

export const selectPresetOptionsSelector = createSelector(
    selectReports,
    ({ byId, ids }) => {
        return ids
            .map(id => byId[id])
            .map(({ id, name }) => ({
                label: name,
                value: String(id),
            }));
    },
);

const reportExpensesTagsSelector = createSelector(
    [Utils.passParam, currentReportSelector, Tags.selectors.selectTags],
    ({ fieldId }, report, { ids }) => {
        if (!report || !report.buckets) {
            return [];
        }

        if (fieldId === 'excludeTags') {
            return report.buckets.total.tagsDistribution
                .reduce((tags, group) => union(tags, group.tagIds), [])
                .map(String);
        }

        return ids;
    },
);

export const tagsOptionsSelectors = filterSelectors.nonSelectedTagsFactory(reportExpensesTagsSelector);
