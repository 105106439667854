import { React, PropTypes, Tabs, TabsWrapper, connectFela } from '../../dependencies';

import MonthsChart from '../../containers/Charts/MonthsChart';
import QuartersChart from '../../containers/Charts/QuartersChart';

import * as Styles from './Charts.styles';

const { TabPane } = Tabs;

function Charts({ intl, styles }) {
    return (
        <TabsWrapper style={Styles.page}>
            <Tabs defaultActiveKey="1">
                <TabPane
                    tab={intl.formatMessage({
                        id: 'dashboard.tabs.monthly',
                    })}
                    key="1"
                >
                    <div className={styles.chart}>
                        <MonthsChart />
                    </div>
                </TabPane>

                <TabPane
                    tab={intl.formatMessage({
                        id: 'dashboard.tabs.quarterly',
                    })}
                    key="2"
                >
                    <div className={styles.chart}>
                        <QuartersChart />
                    </div>
                </TabPane>
            </Tabs>
        </TabsWrapper>
    );
}

Charts.propTypes = {
    intl: PropTypes.shape().isRequired,
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(Charts);
