import d from '../defaultValues';
import { convertValue } from '../helpers';

const user = {
    firstName: d.string,
    lastName: d.string,
    id: convertValue(String),
    updatedAt: d.string,
    createdAt: d.string,
    email: d.string,
};

export default user;
