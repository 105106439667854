import { createSelector, Tags, Utils } from '../../../dependencies';

import { selectReports } from './general';
import { currentReportSelector } from './report';

export const initialTagsVisibilityValuesSelector = createSelector(
    [currentReportSelector, Tags.selectors.selectTags],
    (report, tags) => {
        if (!report) {
            return [];
        }

        const { tagIds, hiddenTags } = report;
        const hiddenTagsSet = new Set(hiddenTags);

        return tagIds
            .map(tagId => ({
                id: tagId,
                name: tags.byId[tagId] || '',
                hidden: hiddenTagsSet.has(tagId),
            }))
            .sort((tagA, tagB) => Utils.alphaLocaleCompare(tagA.name, tagB.name));
    },
);

export const currentTagsVisibilityValuesSelector = createSelector(
    selectReports,
    reports => {
        const { ids, byId } = reports.tags;

        return ids.map(id => byId[id]);
    },
);

export const hiddenTagsSelector = createSelector(
    currentTagsVisibilityValuesSelector,
    currentTags => {
        return currentTags.filter(tag => tag.hidden).map(tag => Number.parseInt(tag.id, 10));
    },
);
