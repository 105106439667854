import { sagaEffects, push, qs, Selectors, Utils } from '../../dependencies';

import { types } from '../actions';

const { takeEvery, put, select } = sagaEffects;

function* setPageSearchParam(action) {
    const { page } = action.payload;

    const location = yield select(Selectors.locationSelector);

    const search = qs.parse(location.search);
    const nextSearch = {
        ...search,
        page,
    };
    const nextLocation = {
        ...location,
        key: Utils.uniqueNonSecureId(),
        search: qs.stringify(nextSearch),
    };

    yield put(push(nextLocation));
}

export default function*() {
    yield takeEvery(types.SET_PAGE, setPageSearchParam);
}
