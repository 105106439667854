/**
 * Define list of action types which won't affect provided reducer (works even if passed state is undefined).
 * @param {function} reducer
 * @param {array<string>} ignoredActionTypes
 */
export function reducerWithIgnoredActions(reducer, ignoredActionTypes = []) {
    let prevState;
    const actionsBlacklist = new Set(ignoredActionTypes);

    return (state, action) => {
        if (actionsBlacklist.has(action.type)) {
            return prevState;
        }

        prevState = state;

        return reducer(state, action);
    };
}
