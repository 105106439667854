import { sagaEffects, ServiceKeysActions, messageActions, push, routePaths } from '../../../dependencies';

import { createExportRequest, types } from '../../actions';
import { pickCredentials } from './utils';

const { put, takeLeading, take } = sagaEffects;

function* createServiceKey({ name, service, credentials }) {
    yield put(
        ServiceKeysActions.createServiceKeyRequest({
            name,
            service,
            credentials: pickCredentials(service, credentials),
        }),
    );

    const { CREATE_SERVICE_KEY_SUCCESS, CREATE_SERVICE_KEY_FAILURE } = ServiceKeysActions.types;

    const result = yield take([CREATE_SERVICE_KEY_SUCCESS, CREATE_SERVICE_KEY_FAILURE]);

    switch (result.type) {
        case CREATE_SERVICE_KEY_SUCCESS:
            return result.payload.id;

        case CREATE_SERVICE_KEY_FAILURE:
            yield put(messageActions.displayErrorMessage(result.error));
            return null;

        default:
    }
}

function* submitCreateExportForm({ startSubmit, stopSubmit, reset, data }) {
    yield startSubmit();

    const { name, service, credentials, period } = data;

    const serviceKeyId = yield createServiceKey({
        name,
        service,
        credentials,
    });

    if (!serviceKeyId) {
        yield stopSubmit();
        return;
    }

    yield put(
        createExportRequest({
            name,
            period,
            serviceKeyId,
        }),
    );

    const { CREATE_EXPORT_SUCCESS, CREATE_EXPORT_FAILURE } = types;

    const result = yield take([CREATE_EXPORT_SUCCESS, CREATE_EXPORT_FAILURE]);

    switch (result.type) {
        case CREATE_EXPORT_SUCCESS:
            yield put(
                messageActions.displaySuccessMessage({
                    id: 'success.export.api.create',
                }),
            );
            const { id, serviceKeyId } = result.payload;
            yield put(
                push(routePaths.EXPORT_RECURRING.replace(':exportId', id).replace(':serviceKeyId', serviceKeyId)),
            );
            yield reset();
            break;

        case CREATE_EXPORT_FAILURE:
            yield put(messageActions.displayErrorMessage(result.error));
            break;

        default:
    }

    yield stopSubmit();
}

export default function* () {
    yield takeLeading(types.SUBMIT_CREATE_EXPORT_FORM, submitCreateExportForm);
}
