import React from 'react';
import { PropTypes } from 'prop-types';
import { FelaComponent } from 'react-fela';

import * as styles from './Label.styles';

const Label = ({ children }) => <FelaComponent style={styles.basic}>{children}</FelaComponent>;

Label.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Label;
