export const grid = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const label = ({ theme: { colors } }) => ({
    color: colors.text,
    lineHeight: 1.56,
    letterSpacing: '0.2px',
    fontSize: '16px',
    marginRight: 16,
});
