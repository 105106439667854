import { React, PropTypes, FelaComponent, NoData, Measure } from '../../dependencies';

import Bar from './Bar';
import AverageLine from './AverageLine';

import * as styles from './ChartBar.styles';

function ChartBar({ data, calcBarWidth, height, gap, startWithIndex, average, topLabelHeight, bottomLabelHeight }) {
    const [viewportWidth, setViewportWidth] = React.useState(null);
    const [barWidthInPx, setBarWidth] = React.useState(null);

    const onResizeHandler = React.useCallback(
        contentRect => {
            setViewportWidth(contentRect.client.width);
        },
        [setViewportWidth],
    );

    React.useEffect(() => {
        const barWidth = calcBarWidth(viewportWidth, data.length);
        setBarWidth(barWidth);
        // eslint-disable-next-line
    }, [viewportWidth, setBarWidth, data]);

    if (data.length === 0) {
        return <NoData padding="56px 0 96px" />;
    }

    const barHeight = height - (topLabelHeight + bottomLabelHeight);

    return (
        <FelaComponent style={styles.container} width={viewportWidth}>
            <Measure client onResize={onResizeHandler}>
                {measure => (
                    <FelaComponent
                        {...{
                            style: styles.row,
                            barsCount: data.length,
                            height,
                            gap,
                            translateX: `${-1 * (startWithIndex * barWidthInPx + startWithIndex * gap)}px`,
                            opacity: viewportWidth === null ? 0 : 1,
                            barWidth: barWidthInPx,
                        }}
                    >
                        {row => (
                            <div ref={measure.measureRef} className={row.className}>
                                {average && (
                                    <AverageLine
                                        value={average.value}
                                        bottomOffset={ChartBar.calcAverageLineBottomOffset(
                                            barHeight,
                                            bottomLabelHeight,
                                            average.bottomRelativeOffset,
                                        )}
                                    />
                                )}
                                {data.map(bar => (
                                    <Bar key={bar.id} width={barWidthInPx} {...bar} maxHeight={barHeight} />
                                ))}
                            </div>
                        )}
                    </FelaComponent>
                )}
            </Measure>
        </FelaComponent>
    );
}

export const dataShape = PropTypes.shape({
    id: PropTypes.string.isRequired,
    bottomLabel: PropTypes.node,
    topLabel: PropTypes.node,
    height: PropTypes.number.isRequired,
    title: PropTypes.node,
});

ChartBar.propTypes = {
    data: PropTypes.arrayOf(dataShape.isRequired).isRequired,
    calcBarWidth: PropTypes.func.isRequired,
    height: PropTypes.number.isRequired,
    gap: PropTypes.number,
    startWithIndex: PropTypes.number,
    average: PropTypes.shape({
        bottomRelativeOffset: PropTypes.number.isRequired,
        value: PropTypes.node.isRequired,
    }),
    topLabelHeight: PropTypes.number.isRequired,
    bottomLabelHeight: PropTypes.number.isRequired,
};

ChartBar.calcAverageLineBottomOffset = (barMaxHeight, bottomLabelHeight, normalizedAverage) => {
    return barMaxHeight * normalizedAverage + bottomLabelHeight;
};

ChartBar.defaultProps = {
    gap: 17,
    startWithIndex: 0,
    average: null,
};

export default ChartBar;
