import { Expenses, Exports, Lists, Reports } from '../dependencies';

export const navItems = [
    {
        messageId: 'navbar.item.reports',
        to: Reports.consts.routePaths.REPORT_NEW,
    },
    {
        messageId: 'navbar.item.expenses',
        to: Expenses.consts.routePaths.EXPENSES,
    },
    {
        messageId: 'navbar.item.exports',
        to: Exports.consts.routePaths.EXPORTS,
    },
    {
        messageId: 'navbar.item.lists',
        to: Lists.consts.routePaths.LISTS,
    },
];
