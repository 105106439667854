import {
    config,
    authApi,
    takeLatestRequest,
    Log,
    sagaEffects,
    ErrorsUtils,
    messageActions,
    Normalizers,
    push,
} from '../../dependencies';

import { routePaths } from '../../constants';
import {
    updateReportSuccess,
    updateReportFailure,
    updateReportInvalidate,
    fetchReportSuccess,
    types,
} from '../actions';
import { formatResponse } from './utils';
import { selectReport, updateReportDataSelector } from '../selectors';

const { put, select } = sagaEffects;

function* updateReport(action, cancelToken) {
    const { id } = action.meta;

    try {
        const { name } = yield select(selectReport, id);
        const { filters, hiddenTags, companyId } = yield select(updateReportDataSelector, id);
        const payload = {
            name,
            filters,
            hiddenTags,
            companyId,
        };

        const { data } = yield* authApi.put(config.api.report, payload, {
            uriParams: { id },
            cancelToken,
        });

        const { entities } = Normalizers.report(data);
        const report = formatResponse(entities.reports[id]);

        // NOTE: to prevent another fetch and to be able check only REPORT FETCH api reducer,
        // dispatch also fetchReportSuccess to set the api.report[id].success to true.
        yield put(fetchReportSuccess(id, report));

        yield put(updateReportSuccess(id, report));

        // TODO: store company entity
        // yield put(fetchCompanySuccess(id, entities.companies[companyId]))

        yield put(push(routePaths.REPORT.replace(':id', data.id)));

        yield put(
            messageActions.displaySuccessMessage({
                id: 'success.report.update',
                values: { id },
            }),
        );
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.report.api.update',
                values: { id },
            },
        });

        yield put(updateReportFailure(id, errorMessage));

        yield put(messageActions.displayErrorMessage(errorMessage));
    }
}

export default function* updateReportWatcher() {
    const actionTypes = {
        REQUEST: types.UPDATE_REPORT_REQUEST,
        cancelTask: updateReportInvalidate,
    };

    yield takeLatestRequest(actionTypes, updateReport);
}
