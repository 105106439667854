import { connect, compose, reduxForm, submitForm, injectIntl } from '../../dependencies';

import { SupplierFormType } from '../../constants';
import { forms } from '../../config';

import CreateSupplierModal from '../../components/SupplierFormModal';
import { createSupplierRequestFactory } from '../../services/actions';
import { createExpenseFormSelector } from '../../services/selectors';

import validate from './SupplierFormModal.validate';

const formId = forms.createSupplier;

const mapStateToProps = state => {
    return {
        initialValues: {
            /**
             * The container is used in expense-create-form and in add-supplier
             * - expense-create-form: the 'new-supplier' value from expense-create-form is used
             * - add-supplier: the selector returns undefined but the form is still working correctly
             **/
            name: createExpenseFormSelector(state, 'new-supplier'),
            country: 'CZ',
        },
        type: SupplierFormType.CREATE,
    };
};

const mapDispatchToProps = (dispatch, { fillSupplierField }) => ({
    onSubmit(data) {
        const createSupplierRequest = createSupplierRequestFactory({
            fillSupplierField,
        });
        const submitAction = submitForm(formId, createSupplierRequest);

        dispatch(submitAction(data));
    },
});

const formConfig = {
    form: formId,
    enableReinitialize: true,
    validate,
};

export default compose(
    injectIntl,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    reduxForm(formConfig),
)(CreateSupplierModal);
