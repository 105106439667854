import React from 'react';

const EyeOn = props => (
    <svg viewBox="0 0 22 22" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <path d="M10.7109,14.6358 C9.9489,14.6358 9.1989,14.5378 8.4739,14.3548 L10.1329,12.6958 C10.3169,12.7528 10.5089,12.7928 10.7109,12.7928 C11.8149,12.7928 12.7109,11.8658 12.7109,10.7218 C12.7109,10.5368 12.6799,10.3628 12.6369,10.1918 L14.9709,7.8578 C16.2679,8.5308 17.4169,9.5058 18.3119,10.7218 C16.5159,13.1618 13.6999,14.6358 10.7109,14.6358 M3.1099,10.7218 C4.9059,8.2818 7.7219,6.8078 10.7109,6.8078 C11.4629,6.8078 12.2029,6.9028 12.9199,7.0798 L11.2599,8.7398 C11.0849,8.6878 10.9019,8.6508 10.7109,8.6508 C9.6069,8.6508 8.7109,9.5778 8.7109,10.7218 C8.7109,10.8958 8.7369,11.0618 8.7759,11.2238 L6.4269,13.5738 C5.1409,12.9008 3.9999,11.9308 3.1099,10.7218 M16.4819,6.3458 L21.4139,1.4138 L19.9999,-0.0002 L14.5559,5.4448 C13.3509,5.0018 12.0639,4.7378 10.7109,4.7378 C6.4349,4.7378 2.7099,7.1478 0.7109,10.7218 C1.7219,12.5288 3.1749,14.0338 4.9149,15.0848 L-0.0001,19.9998 L1.4139,21.4138 L6.8389,15.9898 C8.0519,16.4388 9.3479,16.7068 10.7109,16.7068 C14.9869,16.7068 18.7119,14.2958 20.7109,10.7218 C19.6949,8.9068 18.2329,7.3978 16.4819,6.3458" />
        </g>
    </svg>
);

export default EyeOn;
