export const background = ({ theme: { colors } }) => ({
    borderRadius: 6,
    backgroundColor: colors.white,
    position: 'relative',
});

export const header = ({ theme: { colors } }) => ({
    display: 'flex',
    alignItems: 'center',
    height: 64,
    borderBottom: `1px solid ${colors.blueGrey1}`,
});

export const cardContent = ({ theme: { colors } }) => ({
    borderRadius: 6,
    backgroundColor: colors.white,
    position: 'relative',
    padding: '1.5rem 1.875rem 3rem',
});
