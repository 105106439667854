import { React, PropTypes, FormattedMessage } from '../../../dependencies';

const Empty = ({ styles }) => (
    <p className={styles.empty}>
        <FormattedMessage id="memberships.table.empty" />
    </p>
);

Empty.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default Empty;
