import {
    React,
    PropTypes,
    UI,
    CustomPropTypes,
    SelectInput,
    useRoleOptions,
    updateMembershipApiSelector,
    useSelector,
} from '../../dependencies';

import { useSelectMembershipRole } from '../../hooks';

const { InputWrapper } = UI;

const SelectMembershipRole = ({ styles, membershipId }) => {
    const options = useRoleOptions();
    const { value, onChange } = useSelectMembershipRole(membershipId);
    const api = useSelector(state => updateMembershipApiSelector(state, membershipId));

    return (
        <InputWrapper>
            <SelectInput
                className={styles.selectInput}
                options={options}
                size="small"
                input={{
                    value,
                    onChange,
                }}
                loading={api.inProgress}
                disabled={api.inProgress}
            />
        </InputWrapper>
    );
};

SelectMembershipRole.propTypes = {
    styles: PropTypes.shape().isRequired,
    membershipId: CustomPropTypes.id.isRequired,
};

export default SelectMembershipRole;
