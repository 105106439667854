import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';

import * as styles from './Link.styles';

const Link = ({ children, ...props }) => (
    <FelaComponent style={styles.link}>
        {({ className }) => <a {...{ className, ...props }}>{children}</a>}
    </FelaComponent>
);

Link.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Link;
