import { React, Container, withErrorBoundary, useSelector, ErrorCard } from '../../dependencies';

import { useReflectReportLifeCycle } from '../../hooks';
import { reportApiSelector } from '../../services/selectors';

import ReportDetail from '../ReportDetail';

function ReportPage() {
    const retry = useReflectReportLifeCycle();
    const { error } = useSelector(reportApiSelector);

    return (
        <ErrorCard message={error} retry={retry}>
            <Container>
                <ReportDetail />
            </Container>
        </ErrorCard>
    );
}

export default withErrorBoundary(ReportPage);
