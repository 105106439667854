import { React, useDispatch, useSelector, ReduxUtils, Consts } from '../dependencies';

import { fetchReportsRequest, fetchReportsInvalidate } from '../services/actions';

const { FETCH } = Consts.apiTypes;
const { REPORTS } = Consts.entityKeys;

export function useFetchReports() {
    const dispatch = useDispatch();
    const { inProgress, success, error } = useSelector(state => ReduxUtils.apiSelector(state, REPORTS, FETCH));

    React.useEffect(() => {
        if (!inProgress && !success) {
            dispatch(fetchReportsRequest());
        }

        return () => {
            if (inProgress) {
                dispatch(fetchReportsInvalidate());
            }
        };
        // eslint-disable-next-line
    }, []);

    return {
        error: {
            message: error,
            retry: () => dispatch(fetchReportsRequest()),
        },
        inProgress,
        success,
    };
}
