import {
    connect,
    compose,
    reduxForm,
    submitForm,
    injectIntl,
    Modals,
    Consts,
    withErrorBoundary,
} from '../../dependencies';

import { Forms } from '../../config';
import CreateReportModal from '../../components/CreateReportModal';
import { createReportRequest } from '../../services/actions';
import validate from './CreateReportModal.validate';

const formId = Forms.CREATE_REPORT;

const mapDispatchToProps = {
    onSubmit: submitForm(formId, createReportRequest),
};

const formConfig = {
    form: formId,
    validate,
};

const modalId = Consts.modals.CREATE_REPORT;

export default compose(
    withErrorBoundary,
    injectIntl,
    Modals.HOC.withModal(modalId),
    connect(
        null,
        mapDispatchToProps,
    ),
    reduxForm(formConfig),
)(CreateReportModal);
