import { endOfToday } from '../../../dependencies';
import { bucketTotalTagsReducer } from '../../../utils';

// BE returns 'to' field equal to end of this year when the the relativePeriod is 'thisYear'.
// But we want a 'to' to be maximally equal to today.
function formatToField(to) {
    if (!to) {
        return to;
    }

    const endOfTodayDate = new Date(endOfToday().toISOString());
    const minTo = Math.min(new Date(to), endOfTodayDate);
    const minToISOString = new Date(minTo).toISOString();

    return minToISOString;
}

export default function formatReportResponse(data) {
    return {
        ...data,
        tagIds: data.buckets ? bucketTotalTagsReducer(data.buckets.total) : [],
        filters: {
            ...data.filters,
            to: formatToField(data.filters.to),
        },
    };
}
