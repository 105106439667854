import React from 'react';

const IconCross = props => (
    <svg viewBox="0 0 12 12" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <g transform="translate(-6.000000, -6.000000)">
                <polygon points="16 6 11.707 10.293 7.414 6 6 7.414 10.293 11.707 6 16 7.414 17.414 11.707 13.121 16 17.414 17.414 16 13.121 11.707 17.414 7.414" />
            </g>
        </g>
    </svg>
);

export default IconCross;
