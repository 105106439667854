import { React, PropTypes, FormattedMessage, Label, noop, FelaComponent } from '../dependencies';

import AddTag from '../containers/AddTag';
import SelectedTags from '../containers/SelectedTags';

import * as styles from './Tags.styles';

function Tags({
    disabled,
    formId,
    onAdd,
    onRemove,
    labelId,
    small,
    fieldId,
    color,
    optionsSelector,
    processSelectedOptions,
    buttonNewTag,
}) {
    const props = { disabled, formId, small, fieldId };

    return (
        <div>
            <Label>
                <FormattedMessage id={labelId} />
            </Label>

            <FelaComponent style={styles.chips}>
                <SelectedTags {...{ ...props, onRemove, color, processOptions: processSelectedOptions }} />
                <AddTag {...{ ...props, onAdd, optionsSelector, buttonNewTag }} />
            </FelaComponent>
        </div>
    );
}

Tags.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formId: PropTypes.string.isRequired,
    onRemove: PropTypes.func,
    onAdd: PropTypes.func,
    labelId: PropTypes.string,
    color: PropTypes.string,
    fieldId: PropTypes.string,
    small: PropTypes.bool,
    optionsSelector: PropTypes.func,
    processSelectedOptions: PropTypes.func,
    buttonNewTag: PropTypes.bool,
};

Tags.defaultProps = {
    onRemove: noop,
    onAdd: noop,
    labelId: 'tags.add',
    fieldId: 'tags',
    small: false,
    color: undefined,
    optionsSelector: undefined,
    buttonNewTag: false,
};

export default Tags;
