import {
    React,
    PropTypes,
    FormattedNumber,
    Config,
    TooltipWrapper,
    FormattedMessage,
    PriceTooltip,
    connectFela,
} from '../../dependencies';

import Label from '../Label';

import * as Styles from './Total.styles';

function Total({
    totalPrice: {
        totalPriceWithVatDefaultCurrency,
        totalPriceWithoutVatDefaultCurrency,
        totalCompanyPriceWithVatDefaultCurrency,
        totalCompanyPriceWithoutVatDefaultCurrency,
    },
    currency,
    intl: { formatNumber },
    styles,
}) {
    const totalPriceNormalized =
        totalPriceWithVatDefaultCurrency === null || totalCompanyPriceWithVatDefaultCurrency === null
            ? '-'
            : formatNumber(totalPriceWithVatDefaultCurrency / totalCompanyPriceWithVatDefaultCurrency, {
                  style: 'percent',
              });

    return (
        <div className={styles.card}>
            <Label messageId="expenses.filters.totalPrice.label" />

            <div>
                <TooltipWrapper
                    title={<PriceTooltip priceWithVat={totalPriceWithVatDefaultCurrency} currency={currency} />}
                >
                    <h3 className={styles.amountTotal}>
                        {totalPriceWithoutVatDefaultCurrency === null ? (
                            '-'
                        ) : (
                            <FormattedNumber
                                {...{
                                    ...Total.number,
                                    value: totalPriceWithoutVatDefaultCurrency,
                                    currency,
                                    ...Config.currency,
                                }}
                            />
                        )}
                    </h3>
                </TooltipWrapper>

                <div className={styles.textRow}>
                    <div className={styles.textInfo}>
                        <FormattedMessage
                            {...{
                                id: 'expenses.filters.totalPrice.info',
                                values: {
                                    number: totalPriceNormalized,
                                },
                            }}
                        />
                    </div>

                    <TooltipWrapper
                        title={
                            <PriceTooltip priceWithVat={totalCompanyPriceWithVatDefaultCurrency} currency={currency} />
                        }
                    >
                        <h3 className={styles.totalWithoutFilters}>
                            {totalCompanyPriceWithoutVatDefaultCurrency === null ? (
                                '-'
                            ) : (
                                <FormattedNumber
                                    {...{
                                        ...Total.number,
                                        value: totalCompanyPriceWithoutVatDefaultCurrency,
                                        currency,
                                        ...Config.currency,
                                    }}
                                />
                            )}
                        </h3>
                    </TooltipWrapper>
                </div>
            </div>
        </div>
    );
}

Total.number = {
    style: 'currency',
    maximumFractionDigits: Config.currency.maximumFractionDigits,
};

Total.propTypes = {
    totalPrice: PropTypes.shape({
        totalPriceWithVatDefaultCurrency: PropTypes.number,
        totalPriceWithoutVatDefaultCurrency: PropTypes.number,
        totalCompanyPriceWithoutVatDefaultCurrency: PropTypes.number,
        totalCompanyPriceWithVatDefaultCurrency: PropTypes.number,
    }).isRequired,
    currency: PropTypes.string.isRequired,
    intl: PropTypes.shape().isRequired,
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(Total);
