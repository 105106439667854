import { strictObjectAccess } from '@ackee/redux-utils';

export const routes = strictObjectAccess({
    LISTS_EXPENSES_RECURRING: '/lists/recurring-expenses',

    SEARCH: '/search',

    REPORTS: '/reports',
    REPORT: '/reports/:id',

    DASHBOARD: '/',

    SETTINGS: '/settings',
});
