import { useSelector, React, useDispatch, useIntl } from '../dependencies';

import { membershipSelector, authUserMembershipSelector } from '../../../services/selectors';
import { updateMembershipRole } from '../../../services/actions';

export function useSelectMembershipRole(membershipId) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const membership = useSelector(state => membershipSelector(state, membershipId));
    const authUserMembership = useSelector(state => authUserMembershipSelector(state, membershipId));
    const [value, setValue] = React.useState(null);

    React.useEffect(() => {
        setValue(membership.role);
    }, [membership.role]);

    return {
        value,
        onChange(nextValue) {
            if (
                authUserMembership &&
                !window.confirm(
                    intl.formatMessage({
                        id: 'memberships.changeAuthRole',
                    }),
                )
            ) {
                return;
            }

            dispatch(updateMembershipRole(membershipId, nextValue));
            setValue(nextValue);
        },
    };
}
