import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from '@ackee/lucas';
import { FelaComponent } from 'react-fela';

import * as Icons from '../../../Icons';

import sizes from '../Chip.sizes';

import * as styles from './ShapeOutline.styles';

const tagBorderSizeEnum = {
    [sizes.S]: Icons.TagBorderSmall,
    [sizes.L]: Icons.TagBorder,
};

function ShapeOutline({ children, size, isLast }) {
    return (
        <FelaComponent
            {...{
                style: styles.shape,
                isLast,
                size,
            }}
        >
            <FelaComponent {...{ style: styles.borderIcon, size }}>
                {({ className }) => {
                    const Icon = tagBorderSizeEnum[size];

                    return (
                        <Suspense fallback={null}>
                            <Icon {...{ className }} />
                        </Suspense>
                    );
                }}
            </FelaComponent>
            <FelaComponent
                {...{
                    style: styles.body,
                    size,
                }}
            >
                <FelaComponent {...{ style: styles.icon, size }}>
                    {({ className }) => <Icons.Plus {...{ className }} />}
                </FelaComponent>
                <span>{children}</span>
            </FelaComponent>
        </FelaComponent>
    );
}

ShapeOutline.propTypes = {
    children: childrenPropType.isRequired,
    size: PropTypes.oneOf(Object.values(sizes)).isRequired,
    isLast: PropTypes.bool.isRequired,
};

export default ShapeOutline;
