import { connect, arrayRemove, noop } from '../dependencies';

import { selectedTagOptionsSelector } from '../services/selectors';

import SelectedTags from '../components/SelectedTags';

const mapStateToProps = (state, { formId, fieldId, processOptions }) => {
    return {
        selectedTags: selectedTagOptionsSelector(state, { formId, fieldId, processOptions }),
    };
};

const mapDispatchToProps = (dispatch, { formId, fieldId, onRemove = noop }) => ({
    removeTag({ index, value }) {
        dispatch(arrayRemove(formId, fieldId, index));
        onRemove({ formId, value });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedTags);
