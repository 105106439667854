import { config, authApi, Log, sagaEffects, takeLatestRequest, ErrorsUtils, messageActions } from '../../dependencies';

import { runExportManuallySuccess, runExportManuallyFailure, runExportManuallyInvalidate, types } from '../actions';

const { put } = sagaEffects;

function* runExportManually(action, cancelToken) {
    const { exportId } = action.meta;

    try {
        const requestConfig = {
            uriParams: {
                id: exportId,
            },
            cancelToken,
        };

        yield* authApi.post(config.api.export, null, requestConfig);

        yield put(runExportManuallySuccess(exportId));

        yield put(
            messageActions.displaySuccessMessage({
                id: 'success.singleExport.api.get',
            }),
        );
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.singleExport.api.get',
            },
        });

        yield put(runExportManuallyFailure(exportId, errorMessage));

        yield put(
            messageActions.displayErrorMessage(errorMessage, {
                duration: 4000,
            }),
        );
    }
}

export default function* fetchSuppliersWatcher() {
    const actionTypes = {
        REQUEST: types.RUN_EXPORT_MANUALLY_REQUEST,
        cancelTask: runExportManuallyInvalidate,
        requestIdSelector: action => action.meta.exportId,
    };

    yield takeLatestRequest(actionTypes, runExportManually);
}
