import { React, PropTypes, Consts, FormattedMessage, FelaComponent } from '../../dependencies';

import Label from '../Label';
import Value from '../Value';

import * as styles from './Header.styles';

const vatModeEnum = {
    [true]: 'expense.form.vatMode.option.base',
    [false]: 'expense.form.vatMode.option.final',
};

const { INVOICE, BILL, OTHER, DEPOSIT } = Consts.documentType;

const documentTypeEnum = {
    [INVOICE]: 'expense.form.documentType.option.invoice',
    [BILL]: 'expense.form.documentType.option.bill',
    [DEPOSIT]: 'expense.form.documentType.option.deposit',
    [OTHER]: 'expense.form.documentType.option.other',
};

const taxDeductibleEnum = {
    [true]: 'expense.detail.taxDeductible.yes',
    [false]: 'expense.detail.taxDeductible.no',
};

const { CASH, BANK, CARD } = Consts.paymentMethod;

const paymentMethodEnum = {
    [CASH]: 'expense.form.paymentMethod.option.cash',
    [BANK]: 'expense.form.paymentMethod.option.bank',
    [CARD]: 'expense.form.paymentMethod.option.card',
};

const supplierItems = ['name', 'address', 'registrationNumber', 'vatNumber'];

const details = [
    {
        id: 'issuedOn',
    },
    {
        id: 'receivedOn',
    },
    {
        id: 'taxableFulfillmentDue',
    },
    {
        id: 'originalNumber',
    },
    {
        id: 'vatMode',
        getValueId: expense => vatModeEnum[expense.vatFromBase],
    },
    {
        id: 'documentType',
        getValueId: expense => documentTypeEnum[expense.documentType],
    },
    {
        id: 'taxDeductible',
        getValueId: expense => taxDeductibleEnum[expense.taxDeductible],
    },
    {
        id: 'paymentMethod',
        getValueId: expense => paymentMethodEnum[expense.paymentMethod],
    },
];

function Header(props) {
    return (
        <FelaComponent style={styles.grid}>
            {supplierItems.map(id => (
                <div key={id}>
                    <Label messageId={`expense.form.supplier.${id}.label`} />
                    <Value>{props.supplier[id]}</Value>
                </div>
            ))}

            {details.map(({ id, getValueId }) => {
                const value = getValueId ? <FormattedMessage id={getValueId(props)} /> : props[id];
                return (
                    <div key={id}>
                        <Label messageId={`expense.form.${id}.label`} />
                        <Value>{value || '-'}</Value>
                    </div>
                );
            })}
        </FelaComponent>
    );
}

Header.propTypes = {
    supplier: PropTypes.shape({
        name: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        registrationNumber: PropTypes.string.isRequired,
        vatNumber: PropTypes.string.isRequired,
    }),
    issuedOn: PropTypes.string.isRequired,
    receivedOn: PropTypes.string.isRequired,
    taxableFulfillmentDue: PropTypes.string.isRequired,
    originalNumber: PropTypes.string.isRequired,
    taxDeductible: PropTypes.bool.isRequired,
    documentType: PropTypes.oneOf(Object.values(Consts.documentType)).isRequired,
    vatFromBase: PropTypes.bool.isRequired,
};

Header.defaultProps = {
    supplier: {
        name: '-',
        address: '-',
        registrationNumber: '-',
        vatNumber: '-',
    },
};

export default React.memo(Header);
