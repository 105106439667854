import { React, Container, Pagination, Consts } from '../dependencies';

import Header from './Header';
import ListWrapper from './ListWrapper';
import ListHeader from './ListHeader';

import Filter from '../containers/Filter';
import Total from '../containers/Total';

import List from './List';

const { entityKeys, expensesGroups } = Consts;

function ExpensesList() {
    return (
        <Container>
            <Header>
                <Filter paymentMethodField={true} />
                <Total />
            </Header>

            <ListWrapper>
                <ListHeader />
                <List groupId={expensesGroups.EXPENSES} />
                <Pagination entityKey={entityKeys.EXPENSES} itemId={expensesGroups.EXPENSES} />
            </ListWrapper>
        </Container>
    );
}

export default ExpensesList;
