import { React, FormattedMessage, UI, useModal, Consts } from '../../dependencies';

const { ButtonDefault } = UI;

const CreateCompanyButton = () => {
    const modal = useModal(Consts.modals.CREATE_COMPANY);

    return (
        <ButtonDefault onClick={() => modal.open()}>
            <FormattedMessage id="settings.createCompany.button" />
        </ButtonDefault>
    );
};

export default CreateCompanyButton;
