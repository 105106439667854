const suffixSearchParamName = name => `${name}[]`;

const getEntries = params => (params instanceof URLSearchParams ? [...params.entries()] : Object.entries(params));

const getValidEntries = params => {
    return params.filter(([, value]) => value !== '');
};

export function encodeSearchParams(params) {
    const searchParams = new URLSearchParams();

    for (const [name, value] of getValidEntries(getEntries(params))) {
        if (Array.isArray(value)) {
            const arrayMemberName = suffixSearchParamName(name);
            value.forEach(item => searchParams.append(arrayMemberName, String(item)));
        } else {
            searchParams.append(name, String(value));
        }
    }

    return searchParams;
}
