import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const actionTypes = {
    REQUEST: types.FETCH_MEMBERSHIPS_REQUEST,
    SUCCESS: types.FETCH_MEMBERSHIPS_SUCCESS,
    FAILURE: types.FETCH_MEMBERSHIPS_FAILURE,
    CANCEL: types.FETCH_MEMBERSHIPS_CANCEL,
};

export default ReduxUtils.basicApiReducer({
    actionTypes,
});
