import {
    React,
    PropTypes,
    UI,
    ExportsActions,
    useSelector,
    useDispatch,
    FormattedMessage,
    ExportsSelectors,
    ServiceKeysSelectors,
} from '../../dependencies';

const { ButtonDanger } = UI;

const DeleteExportButton = ({ serviceKeyId, exportId }) => {
    const dispatch = useDispatch();
    const exportApi = useSelector(state => ExportsSelectors.deleteExportApiSelector(state, exportId));
    const serviceKeyApi = useSelector(state => ServiceKeysSelectors.deleteServiceKeyApiSelector(state, serviceKeyId));

    return (
        <ButtonDanger
            onClick={() => dispatch(ExportsActions.deleteExportWithServiceKey(exportId, serviceKeyId))}
            outline
            loading={exportApi.inProgress || serviceKeyApi.inProgress}
        >
            <FormattedMessage id="export.button.delete" />
        </ButtonDanger>
    );
};

DeleteExportButton.propTypes = {
    serviceKeyId: PropTypes.string.isRequired,
    exportId: PropTypes.string.isRequired,
};

export default DeleteExportButton;
