export const tabWrapper = ({ theme, customStyle }) => {
    const { colors } = theme;

    return {
        '& > .ant-tabs': {
            color: colors.text,

            '> .ant-tabs-bar': {
                borderColor: colors.blueGrey1,
                display: 'flex',
                justifyContent: 'center',
            },
        },

        '& > .ant-tabs .ant-tabs-nav-container': {
            width: '100%',
            '> .ant-tabs-nav-wrap': {
                width: '100%',
                '& .ant-tabs-nav': {
                    width: '100%',
                    '> div': {
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        textAlign: 'center',
                    },
                },
            },
        },
        ...customStyle({ theme }),
    };
};
