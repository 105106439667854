import { React, PropTypes, ReduxForm } from '../../dependencies';

import ExportHeader from '../ExportHeader';
import ExportForm from '../ExportForm';
import ExportFooter from '../ExportFooter';

const ExportAdd = ({ handleSubmit, form }) => {
    return (
        <ReduxForm onSubmit={handleSubmit}>
            <ExportHeader
                title={{
                    id: 'export.recurring.new.title',
                }}
            />

            <ExportForm formId={form} />

            <ExportFooter formId={form} submitButtonMessageId="export.recurring.submit" />
        </ReduxForm>
    );
};

ExportAdd.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
};

export default ExportAdd;
