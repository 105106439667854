import { React, FelaComponent, PropTypes, Files } from '../../dependencies';

import Label from '../Label';
import ExternalLink from '../ExternalLink';

import * as styles from './Attachments.styles';

function Attachments({ files, externalLink }) {
    return (
        <FelaComponent style={styles.attachments}>
            {files.length > 0 && (
                <>
                    <Label
                        {...{
                            marginBottom: 12,
                            messageId: 'expense.form.attachment.label',
                        }}
                    />
                    <Files
                        {...{
                            files,
                            fileIsRemovable: false,
                        }}
                    />
                </>
            )}

            {externalLink && (
                <>
                    <Label
                        {...{
                            marginTop: 32,
                            marginBottom: 12,
                            messageId: 'expense.form.externalLink.label',
                        }}
                    />
                    <ExternalLink externalLink={externalLink} />
                </>
            )}
        </FelaComponent>
    );
}

Attachments.propTypes = {
    externalLink: PropTypes.string.isRequired,
    files: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            localUrl: PropTypes.bool.isRequired,
        }),
    ).isRequired,
};

export default Attachments;
