import { connect, arrayPush, injectIntl, compose } from '../dependencies';

import { selectNonSelectedTags } from '../services/selectors';

import AddTag from '../components/AddTag';

const mapStateToProps = (state, { formId, fieldId, optionsSelector = selectNonSelectedTags, intl }) => ({
    options: optionsSelector(state, { formId, fieldId, intl }),
});

const mapDispatchToProps = (dispatch, { formId, fieldId, onAdd }) => ({
    onChange(value) {
        dispatch(arrayPush(formId, fieldId, value));
        onAdd({ formId, value });
    },
});

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(AddTag);
