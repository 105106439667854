import { React, PropTypes, connectFela } from '../../../dependencies';

import Cell from '../Cell';

import * as Styles from './Overview.styles';

function Overview({ currency, total, currentPeriod, currentPeriodLabelId, styles }) {
    return (
        <div className={styles.overview}>
            <Cell
                label={{
                    id: 'dashboard.totalInYear',
                }}
                currency={currency}
                value={total}
            />

            {currentPeriod && <div className={styles.divider} />}

            {currentPeriod && (
                <Cell
                    label={{
                        id: currentPeriodLabelId,
                    }}
                    currency={currency}
                    value={currentPeriod}
                />
            )}
        </div>
    );
}

Overview.propTypes = {
    currency: PropTypes.string.isRequired,
    total: PropTypes.shape({
        yearFrom: PropTypes.string.isRequired,
        yearTo: PropTypes.string.isRequired,
        withVat: PropTypes.number.isRequired,
        withoutVat: PropTypes.number.isRequired,
    }).isRequired,
    currentPeriod: PropTypes.shape({
        withVat: PropTypes.number.isRequired,
        withoutVat: PropTypes.number.isRequired,
    }),
    styles: PropTypes.shape({}).isRequired,
    currentPeriodLabelId: PropTypes.string.isRequired,
};

Overview.defaultProps = {
    currentPeriod: null,
};

export default connectFela(Styles)(Overview);
