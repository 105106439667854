import { Consts, combineReducers, Actions, ReduxUtils } from '../../../dependencies';

import fetchReport from './fetchReport';
import createReport from './createReport';
import updateReport from './updateReport';
import deleteReport from './deleteReport';
import createTemporaryReport from './createTemporaryReport';
import fetchReports from './fetchReports';
import fetchNewReport from './fetchNewReport';

const { FETCH, CREATE, UPDATE, DELETE } = Consts.apiTypes;
const { basicResetReducer } = ReduxUtils;
const { RESET_ALL_REPORTS } = Actions.types;

const reportReducer = combineReducers({
    [FETCH]: fetchReport,
    [CREATE]: createReport,
    [UPDATE]: updateReport,
    [DELETE]: deleteReport,
});

const temporaryReportReducer = combineReducers({
    [CREATE]: createTemporaryReport,
});

const reportsReducer = combineReducers({
    [FETCH]: fetchReports,
});

const newReportReducer = combineReducers({
    [FETCH]: fetchNewReport,
});

export const report = basicResetReducer(reportReducer, RESET_ALL_REPORTS);

export const temporaryReport = basicResetReducer(temporaryReportReducer, RESET_ALL_REPORTS);

export const reports = basicResetReducer(reportsReducer, RESET_ALL_REPORTS);

export const newReport = basicResetReducer(newReportReducer, RESET_ALL_REPORTS);
