export const danger = ({ theme: { colors }, outline }) => {
    const activeOutlineStyle = {
        borderColor: colors.dangerHover,
        color: colors.dangerHover,
        backgroundColor: 'transparent',
    };

    const activeStyle = {
        backgroundColor: colors.dangerHover,
        borderColor: colors.dangerHover,
    };

    const outlineEnum = {
        [true]: {
            backgroundColor: 'transparent',
            color: colors.danger,
            '&:hover': activeOutlineStyle,
            '&:focus': activeOutlineStyle,
            '&:active': activeOutlineStyle,
        },
        [false]: {
            backgroundColor: colors.danger,
            color: colors.white,
            '&:hover': activeStyle,
            '&:focus': activeStyle,
            '&:active': activeStyle,
        },
    };

    return {
        borderColor: colors.danger,
        ...outlineEnum[outline],
    };
};
