import { Utils, ServiceKeysConsts } from '../../dependencies';

const { validatorsWithMessage, translate, getFieldsErrors } = Utils.InputValidation;

const { isRequired, isAlphaNumeric, isSafePassword } = validatorsWithMessage;

const fieldsValidators = {
    name: [isRequired, isAlphaNumeric],
    period: isRequired,
    service: isRequired,
};

const { Service } = ServiceKeysConsts;

const credentialsFieldsValidators = {
    [Service.FAKTUROID]: {
        email: isRequired,
        accountSlug: [isRequired, isAlphaNumeric],
        apiToken: isRequired,
    },
    [Service.FLEXIBEE]: {
        accountSlug: [isRequired, isAlphaNumeric],
        username: [isRequired, isAlphaNumeric],
        password: [isRequired, isSafePassword],
        company: [isRequired, isAlphaNumeric],
    },
};

export default function validate(values, { intl }) {
    return {
        ...translate(intl.formatMessage, getFieldsErrors(fieldsValidators, values)),
        credentials: translate(
            intl.formatMessage,
            getFieldsErrors(credentialsFieldsValidators[values.service], values.credentials),
        ),
    };
}
