import React from 'react';

const IconSearch = props => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fillRule="evenodd" transform="translate(2 2)">
            <path d="M20,18.5859 L18.586,19.9999 L14.343,15.7569 L15.757,14.3429 L17.2966547,15.8825547 L20,18.5859 Z M8,13.9999 C4.691,13.9999 2,11.3089 2,7.9999 C2,4.6909 4.691,1.9999 8,1.9999 C11.309,1.9999 14,4.6909 14,7.9999 C14,11.3089 11.309,13.9999 8,13.9999 L8,13.9999 Z M8,-0.0001 C3.582,-0.0001 0,3.5819 0,7.9999 C0,12.4189 3.582,15.9999 8,15.9999 C12.418,15.9999 16,12.4189 16,7.9999 C16,3.5819 12.418,-0.0001 8,-0.0001 L8,-0.0001 Z" />
        </g>
    </svg>
);

export default IconSearch;
