import { createSelector, ReduxUtils, Consts } from '../../dependencies';

const { EXPENSE } = Consts.entityKeys;
const { FETCH } = Consts.apiTypes;

export const fetchExpenseApiSelector = (state, expenseId) => ReduxUtils.apiSelector(state, EXPENSE, FETCH, expenseId);

export const isExpenseDetailReadySelector = createSelector(
    fetchExpenseApiSelector,
    (...apis) => {
        return apis.every(({ inProgress, success }) => !inProgress && success);
    },
);
