import { Actions } from '../../dependencies';
import types from './types';

export const { fetchExpenseSuccess } = Actions;

export const fetchExpenseRequest = id => ({
    type: types.FETCH_EXPENSE_REQUEST,
    meta: { id },
});

export const fetchExpenseFailure = (id, error) => ({
    type: types.FETCH_EXPENSE_FAILURE,
    meta: { id },
    error,
});

export const fetchExpenseInvalidate = id => ({
    type: types.FETCH_EXPENSE_CANCEL,
    meta: { id },
});
