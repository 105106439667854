import {
    React,
    PropTypes,
    Modal,
    Form,
    FelaComponent,
    FormattedMessage,
    ButtonPrimary,
    errorPropType,
    ErrorMessage,
    CountriesSelectField,
    ZipCodeField,
    CityField,
    StreetField,
    OrganizationNameField,
    VatNumberField,
} from '../../dependencies';

import { SupplierFormType } from '../../constants';

import RegistrationNumberFieldGroup from '../RegistrationNumberFieldGroup';

import * as styles from './SupplierFormModal.styles';

function SupplierFormModal({
    type,
    form,
    isOpen,
    closeModal,
    handleSubmit,
    reset,
    submitting,
    submitSucceeded,
    error,
}) {
    const title = (
        <FormattedMessage
            id={type === SupplierFormType.CREATE ? 'supplier.create.form.title' : 'supplier.update.form.title'}
        />
    );
    const okText = (
        <FormattedMessage
            id={type === SupplierFormType.CREATE ? 'supplier.create.form.submit' : 'supplier.update.form.submit'}
        />
    );
    const cancelText = <FormattedMessage id="supplier.form.cancel" />;
    const modalProps = {
        visible: isOpen,
        title,
        cancelText,
        onCancel: () => {
            closeModal();
            reset();
        },
        okText,
        onOk: handleSubmit,
        confirmLoading: submitting,
        destroyOnClose: submitSucceeded,
        footer: null,
        closable: false,
    };

    return (
        <Modal {...modalProps}>
            <Form onSubmit={handleSubmit}>
                <FelaComponent style={styles.form}>
                    {error && (
                        <ErrorMessage>
                            <FormattedMessage {...error} />
                        </ErrorMessage>
                    )}

                    <OrganizationNameField formId={form} tabIndex={1} autoFocus={true} />

                    <RegistrationNumberFieldGroup formId={form} tabIndex={2} />

                    <VatNumberField formId={form} tabIndex={3} />

                    <StreetField formId={form} tabIndex={4} />

                    <CityField formId={form} tabIndex={5} />

                    <ZipCodeField formId={form} tabIndex={6} />

                    <CountriesSelectField formId={form} tabIndex={7} />

                    {/* The button is here to make submit by ENTER key possible, visual submit is done by the modal itself */}
                    <ButtonPrimary
                        {...{
                            htmlType: 'submit',
                            onClick: handleSubmit,
                            loading: submitting,
                            disabled: submitting,
                        }}
                    >
                        {okText}
                    </ButtonPrimary>
                </FelaComponent>
            </Form>
        </Modal>
    );
}

SupplierFormModal.propTypes = {
    type: PropTypes.oneOf(Object.values(SupplierFormType)).isRequired,
    form: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitSucceeded: PropTypes.bool.isRequired,
    error: errorPropType,
};

SupplierFormModal.defaultProps = {
    error: null,
};

export default SupplierFormModal;
