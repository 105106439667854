import { React, useSelector, useDispatch, useCurrentObjectRef } from '../dependencies';

import { fetchMembershipsRequest, fetchMembershipsCancel } from '../../../services/actions';
import { fetchMembershipsApiSelector } from '../../../services/selectors';

export default function useFetchMemberships() {
    const api = useSelector(fetchMembershipsApiSelector);
    const apiRef = useCurrentObjectRef(api);
    const dispatch = useDispatch();
    const request = () => dispatch(fetchMembershipsRequest());

    React.useEffect(() => {
        const { inProgress, success } = apiRef.current;

        if (!inProgress && !success) {
            request();
        }

        return () => {
            if (inProgress) {
                dispatch(fetchMembershipsCancel());
            }
        };
        // eslint-disable-next-line
    }, []);

    const { inProgress, success, error } = api;

    return {
        inProgress,
        success,
        error: {
            retry: request,
            message: error,
        },
    };
}
