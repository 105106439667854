import { Sentry, Config, Consts } from '../dependencies';

export const initializeSentry = () => {
    const init = () =>
        Sentry.init({
            dsn: Config.sentry.dsn,
            debug: false,
            environment: process.env.NODE_ENV || process.env.REACT_APP_BUILD_ENV,
            release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
            normalizeDepth: 5,
        });

    !Consts.isServerEnv && window.requestIdleCallback ? window.requestIdleCallback(init) : init();
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer();
