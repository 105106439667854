import { sagaEffects } from '../../dependencies';

import createSupplier from './createSupplier';
import updateSupplier from './updateSupplier';
import deleteSupplier from './deleteSupplier';
import fetchSupplier from './fetchSupplier';
import fetchSuppliers from './fetchSuppliers';
import fetchAresData from './fetchAresData';

const { all } = sagaEffects;

export default function* suppliersSaga() {
    yield all([
        fetchSuppliers(),
        fetchSupplier(),
        createSupplier(),
        updateSupplier(),
        deleteSupplier(),
        fetchAresData(),
    ]);
}
