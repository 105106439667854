import React from 'react';

const Link = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <defs>
            <polygon points="0 0.0003 19.954 0.0003 19.954 19.954 0 19.954" />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-2.000000, -2.000000)">
                <g transform="translate(0.000000, 0.000000)">
                    <g transform="translate(2.000000, 2.000000)">
                        <mask fill="white">
                            <use xlinkHref="#path-1" />
                        </mask>
                        <g />
                        <path
                            d="M18.1972,1.757 C15.8542,-0.586 12.0552,-0.586 9.7122,1.757 L7.1492,4.32 L8.5632,5.735 L11.1262,3.172 C12.6892,1.608 15.2192,1.608 16.7822,3.172 C18.3422,4.731 18.3422,7.269 16.7822,8.828 L14.2192,11.391 L15.6342,12.805 L18.1972,10.242 C20.5402,7.9 20.5402,4.1 18.1972,1.757 L18.1972,1.757 Z M8.8282,16.783 C8.0722,17.538 7.0682,17.954 6.0002,17.954 C4.9312,17.954 3.9272,17.538 3.1712,16.783 C1.6122,15.223 1.6122,12.685 3.1712,11.126 L5.7342,8.563 L4.3202,7.149 L1.7572,9.712 C-0.5858,12.055 -0.5858,15.854 1.7572,18.197 C2.9282,19.368 4.4642,19.954 6.0002,19.954 C7.5352,19.954 9.0712,19.368 10.2422,18.197 L12.8052,15.634 L11.3912,14.22 L8.8282,16.783 Z M12.2352,6.305 L13.6492,7.719 L7.7042,13.665 L6.2892,12.25 L12.2352,6.305 Z"
                            fill="#10092D"
                            mask="url(#mask-2)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Link;
