export const list = () => ({
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    height: 367,
    padding: '0 30px',
});

export const row = ({ theme: { colors }, isLast }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '1rem',
    width: '100%',
    borderBottom: isLast ? '' : `1px solid ${colors.blueGrey1}`,
    padding: '20px 0',
});

export const label = ({ theme: { colors } }) => ({
    color: colors.text,
    fontSize: '1rem',
    lineHeight: 1.56,
    letterSpacing: '0.15px',
});

export const value = ({ theme: { colors } }) => ({
    textAlign: 'right',
    color: colors.text,
    fontSize: '1rem',
    lineHeight: 1.56,
    letterSpacing: '0.15px',
    whiteSpace: 'nowrap',
});

export const extendCardLoader = {
    background: () => ({
        height: '74%',
    }),
};

export const extendNoData = {
    noData: () => ({
        height: '100%',
    }),
};
