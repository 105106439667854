import types from './types';

export const storeGroupValues = (id, values) => ({
    type: types.STORE_GROUP_VALUES,
    meta: { id },
    payload: values,
});

export const deleteGroupValues = id => ({
    type: types.DELETE_GROUP_VALUES,
    meta: { id },
});
