import { React, PropTypes, config, UI } from '../../../../dependencies';

import AccountSlug from '../AccountSlug';
import ServiceNote from '../ServiceNote';

import ApiToken from './ApiToken';
import Email from './Email';

const { Space } = UI;

const FakturoidService = ({ formId, styles }) => {
    return (
        <>
            <Space bottom="1rem" />
            <ServiceNote
                messages={{
                    note: 'export.recurring.form.fakturoid.note',
                    link: 'export.recurring.form.fakturoid.link',
                }}
                url={config.links.serviceKey.fakturoid}
            />
            <Space bottom="2.5rem" />
            <div className={styles.grid}>
                <Email formId={formId} />
                <AccountSlug formId={formId} />
                <ApiToken formId={formId} />
            </div>
        </>
    );
};

FakturoidService.propTypes = {
    formId: PropTypes.string.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default FakturoidService;
