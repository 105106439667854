export const container = ({ theme: { zIndex } }) => ({
    position: 'relative',
    zIndex: zIndex.dateRangePickerDropdown,

    '& .rdrDefinedRangesWrapper': {
        width: 240,
    },
});

export const selectedRange = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const row = () => ({
    display: 'flex',
    alignItems: 'center',
});
