import { React, PropTypes, FormattedMessage, connectFela } from 'modules/date-range-picker/dependencies';

import Input from './Input';

import * as Styles from './RangeInput.styles';

import { Errors } from './RangeInput.consts';
import { createDateFormatter, isDateRangeValid } from './RangeInput.utils';

const RangeInput = ({ value: initialValue, onChange, styles, locale, formatMessage }) => {
    const formatDate = React.useMemo(() => createDateFormatter(locale), [locale]);

    const [value, setValue] = React.useState(initialValue);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const validateRange = React.useCallback(
        nextRange => {
            const result = isDateRangeValid(nextRange);

            if (!result) {
                setError(Errors.RANGE_ERROR);
            } else if (result && error) {
                setError(null);
            }
        },
        [error, setError],
    );

    const onFromFieldSubmitHandler = React.useCallback(
        date => {
            const nextRange = {
                startDate: date,
                endDate: value.endDate,
            };

            validateRange(nextRange);

            onChange(nextRange);
        },
        [value.endDate, validateRange, onChange],
    );

    const onToFieldSubmitHandler = React.useCallback(
        date => {
            const nextRange = {
                startDate: value.startDate,
                endDate: date,
            };

            validateRange(nextRange);

            onChange(nextRange);
        },
        [value.startDate, validateRange, onChange],
    );

    return (
        <div className={styles.container}>
            <div className={styles.label}>
                <FormattedMessage id="dateRangePicker.button.choose" />
            </div>

            <div className={styles.input}>
                <Input
                    {...{
                        locale,
                        formatDate,
                        initialValue: value.startDate,
                        onValidationError: setError,
                        hasValidationError: Boolean(error),
                        onSubmit: onFromFieldSubmitHandler,
                        placeholderId: 'dateRangePicker.input.from',
                        formatMessage,
                    }}
                />
                <Input
                    {...{
                        locale,
                        formatDate,
                        initialValue: value.endDate,
                        onValidationError: setError,
                        hasValidationError: Boolean(error),
                        onSubmit: onToFieldSubmitHandler,
                        placeholderId: 'dateRangePicker.input.to',
                        formatMessage,
                    }}
                />
            </div>

            <div className={styles.error}>
                {error === Errors.FORMAT_ERROR && <FormattedMessage id="dateRangePicker.input.error.format" />}
                {error === Errors.RANGE_ERROR && <FormattedMessage id="dateRangePicker.input.error.range" />}
            </div>
        </div>
    );
};

const ValuePropType = PropTypes.shape({
    relativePeriod: PropTypes.string,
    startDate: PropTypes.shape(),
    endDate: PropTypes.shape(),
});

RangeInput.propTypes = {
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,

    value: ValuePropType.isRequired,
    onChange: PropTypes.func.isRequired,

    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(RangeInput);
