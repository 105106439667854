import { connect, compose, reduxForm, submitForm, injectIntl } from '../../dependencies';

import { updateFormIdFactory } from '../../utils';
import { SupplierFormType } from '../../constants';

import CreateSupplierModal from '../../components/SupplierFormModal';

import { updateSupplierRequest } from '../../services/actions';
import { supplierSelector } from '../../services/selectors';

import validate from './SupplierFormModal.validate';

const mapStateToProps = (state, { id }) => {
    return {
        initialValues: supplierSelector(state, id),
        form: updateFormIdFactory(id),
        type: SupplierFormType.UPDATE,
    };
};

export default compose(
    injectIntl,
    connect(mapStateToProps),
    reduxForm({
        enableReinitialize: true,
        validate,
        onSubmit: (values, dispatch, { id }) => {
            const submit = submitForm(updateFormIdFactory(id), () => updateSupplierRequest(id));
            dispatch(submit(values));
        },
    }),
)(CreateSupplierModal);
