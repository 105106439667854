import { sagaEffects, ExpenseTriggers } from '../../dependencies';

import createExpense from './createExpense';
import updateExpense from './updateExpense';
import deleteExpense from './deleteExpense';
import fetchExpense from './fetchExpense';
import fetchExpenses from './fetchExpenses';
import fetchNextExpenseNumber from './fetchNextExpenseNumber';
import duplicateExpense from './duplicateExpense';

import { saga as expenseDetail } from '../../modules/detail';
import { saga as expensesList } from '../../modules/list';
import { saga as expensesEdit } from '../../modules/edit';

const { all } = sagaEffects;

export default function* expensesSaga() {
    yield all([
        fetchExpenses(),
        fetchExpense(),
        createExpense(),
        updateExpense(),
        deleteExpense(),
        fetchNextExpenseNumber(),
        expenseDetail(),
        expensesList(),
        ExpenseTriggers.saga(),
        duplicateExpense(),
        expensesEdit(),
    ]);
}
