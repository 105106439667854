import { createSelector } from 'reselect';
import { parse } from 'query-string';

const routerSelector = state => state.router;

export const locationSelector = createSelector(
    routerSelector,
    router => router.location,
);

export const pageUrlParamSelector = createSelector(
    locationSelector,
    location => {
        const { page } = parse(location.search);

        return Number.parseInt(page, 10) || 1;
    },
);
