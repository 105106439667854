import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { combineRules } from 'fela';

import * as styles from './Text.styles';

function Text({ children, customStyle, ...props }) {
    const superStyle = combineRules(styles.button, customStyle);

    return (
        <FelaComponent style={superStyle}>
            {button => (
                <button type="button" className={button.className} {...props}>
                    {children}
                </button>
            )}
        </FelaComponent>
    );
}

Text.propTypes = {
    children: PropTypes.node.isRequired,
    customStyle: PropTypes.func,
};

Text.defaultProps = {
    customStyle: () => ({}),
};

export default Text;
