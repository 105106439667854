import React from 'react';

const LogoExpenses = props => (
    <svg viewBox="0 0 189 28" {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon
                fill="#10092D"
                points="0 0.0001 0 22.1471 17.811 22.1471 17.811 17.8431 5.222 17.8431 5.222 12.8771 16.042 12.8771 16.042 8.7971 5.222 8.7971 5.222 4.3031 17.497 4.3031 17.497 0.0001"
            />
            <polygon
                fill="#10092D"
                points="32.0497 6.0748 37.5857 6.0748 31.8897 13.8258 37.8687 22.1468 32.3327 22.1468 29.0737 16.8958 25.8157 22.1468 20.2787 22.1468 26.2897 13.8258 20.5657 6.0748 26.1327 6.0748 29.0737 11.0738"
            />
            <g transform="translate(40.000000, 0.000000)">
                <path
                    d="M9.1786,19.5206 C11.9636,19.5206 12.9136,16.8636 12.9136,14.0796 C12.9136,9.5866 10.6026,8.7006 9.1786,8.7006 C7.7226,8.7006 5.4146,9.5866 5.4146,14.0796 C5.4146,16.8636 6.3626,19.5206 9.1786,19.5206 L9.1786,19.5206 Z M5.3496,6.0746 L5.3196,8.0056 L5.4146,8.0056 C7.2826,5.6946 9.3996,5.4746 10.6026,5.4746 C15.1266,5.4746 17.9116,8.8276 17.9116,14.1436 C17.9116,19.2056 15.0956,22.7486 10.5076,22.7486 C9.8766,22.7486 7.2186,22.5896 5.6366,20.5016 L5.6366,27.9996 L0.8266,27.9996 L0.8266,6.0746 L5.3496,6.0746 Z"
                    fill="#10092D"
                />
                <path
                    d="M33.456,12.5923 C33.361,9.1443 31.241,8.7003 30.071,8.7003 C27.224,8.7003 26.212,10.9473 26.117,12.5923 L33.456,12.5923 Z M38.106,17.4953 C36.841,22.0513 32.696,22.7483 29.754,22.7483 C27.634,22.7483 21.116,22.3053 21.116,14.0793 C21.116,8.5423 24.883,5.4743 30.135,5.4743 C38.013,5.4743 38.296,12.4333 38.423,14.3953 L38.456,15.2813 L26.117,15.2813 C26.117,16.8313 26.876,19.5203 29.976,19.5203 C31.369,19.5203 32.822,18.9203 33.234,17.4953 L38.106,17.4953 Z"
                    fill="#10092D"
                />
                <path
                    d="M47.0243,8.0054 C48.3223,6.2334 50.4423,5.4744 52.5943,5.4744 C54.7453,5.4744 57.9733,6.0744 57.9733,12.0554 L57.9733,22.1474 L53.1633,22.1474 L53.1633,12.8454 C53.1633,11.2644 53.0033,8.7004 50.4423,8.7004 C47.2763,8.7004 47.2153,12.4024 47.2153,13.0984 L47.2153,22.1474 L42.4053,22.1474 L42.4053,6.0744 L47.0243,6.0744 L47.0243,8.0054 Z"
                    fill="#10092D"
                />
                <path
                    d="M66.662,17.085 C66.758,18.856 67.961,19.521 69.89,19.521 C70.998,19.521 72.42,19.046 72.42,17.813 C72.42,15.946 70.207,15.725 67.801,15.377 C65.428,15.028 62.108,14.301 62.108,10.409 C62.108,8.985 62.708,5.474 69.512,5.474 C75.331,5.474 76.629,8.353 76.725,10.504 L72.358,10.504 C72.201,8.638 70.459,8.701 69.826,8.701 C69.004,8.701 67.105,8.796 67.105,10.093 C67.105,11.991 69.385,12.118 71.124,12.371 C73.875,12.781 77.42,13.289 77.42,17.274 C77.42,19.711 76.186,22.749 69.733,22.749 C64.702,22.749 61.855,20.502 61.726,17.085 L66.662,17.085 Z"
                    fill="#10092D"
                />
                <path
                    d="M92.787,12.5923 C92.691,9.1443 90.571,8.7003 89.402,8.7003 C86.555,8.7003 85.543,10.9473 85.447,12.5923 L92.787,12.5923 Z M97.437,17.4953 C96.172,22.0513 92.027,22.7483 89.085,22.7483 C86.965,22.7483 80.447,22.3053 80.447,14.0793 C80.447,8.5423 84.213,5.4743 89.466,5.4743 C97.344,5.4743 97.627,12.4333 97.754,14.3953 L97.787,15.2813 L85.447,15.2813 C85.447,16.8313 86.207,19.5203 89.307,19.5203 C90.7,19.5203 92.153,18.9203 92.565,17.4953 L97.437,17.4953 Z"
                    fill="#10092D"
                />
                <path
                    d="M105.6728,17.085 C105.7678,18.856 106.9708,19.521 108.9008,19.521 C110.0078,19.521 111.4328,19.046 111.4328,17.813 C111.4328,15.946 109.2168,15.725 106.8118,15.377 C104.4388,15.028 101.1178,14.301 101.1178,10.409 C101.1178,8.985 101.7178,5.474 108.5218,5.474 C114.3408,5.474 115.6398,8.353 115.7348,10.504 L111.3688,10.504 C111.2118,8.638 109.4698,8.701 108.8368,8.701 C108.0148,8.701 106.1158,8.796 106.1158,10.093 C106.1158,11.991 108.3928,12.118 110.1348,12.371 C112.8858,12.781 116.4308,13.289 116.4308,17.274 C116.4308,19.711 115.1968,22.749 108.7438,22.749 C103.7128,22.749 100.8658,20.502 100.7368,17.085 L105.6728,17.085 Z"
                    fill="#10092D"
                />
                <path
                    d="M135.1025,6.6065 C135.1025,10.2485 132.1495,13.2015 128.5075,13.2015 C124.8655,13.2015 121.9125,10.2485 121.9125,6.6065 C121.9125,2.9645 124.8655,0.0115 128.5075,0.0115 C132.1495,0.0115 135.1025,2.9645 135.1025,6.6065"
                    fill="#FF2106"
                />
                <g transform="translate(135.000000, 0.000000)">
                    <path
                        d="M13.2924,6.6065 C13.2924,10.2485 10.3394,13.2015 6.6974,13.2015 C3.0554,13.2015 0.1024,10.2485 0.1024,6.6065 C0.1024,2.9645 3.0554,0.0115 6.6974,0.0115 C10.3394,0.0115 13.2924,2.9645 13.2924,6.6065"
                        fill="#33D1BE"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default LogoExpenses;
