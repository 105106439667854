export const wrapper = ({ type, minHeight }) => {
    if (type === 'inline') {
        return {};
    }

    return {
        height: '100%',
        minHeight,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
};

export const spinner = ({ theme: { colors }, size, animationName, color }) => {
    const duration = '700ms';
    const easing = 'linear';

    return {
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        border: `2px solid ${colors.blueGrey1}`,
        borderTopColor: color,
        borderBottomColor: color,
        animation: `${duration} ${animationName} ${easing} infinite`,
    };
};

export const spin = () => ({
    '0%': {
        transform: 'rotate(0deg)',
    },
    '100%': {
        transform: 'rotate(360deg)',
    },
});
