import { types } from '../../../actions';

const initialState = {};

export default function expenseTriggersReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_EXPENSE_TRIGGERS_SUCCESS:
            return {
                ...state,
                ...action.payload.byId,
            };

        case types.DELETE_EXPENSE_TRIGGER_SUCCESS: {
            const { id } = action.meta;

            if (state[id]) {
                const nextState = { ...state };

                delete nextState[id];

                return nextState;
            }

            return state;
        }

        case types.UPDATE_EXPENSE_TRIGGER_SUCCESS:
        case types.CREATE_EXPENSE_TRIGGER_SUCCESS:
        case types.FETCH_EXPENSE_TRIGGER_SUCCESS:
            return {
                ...state,
                [action.meta.id]: action.payload,
            };

        default:
            return state;
    }
}
