export default ({ theme }) => ({
    padding: '1rem 0',

    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,

    color: theme.colors.text,

    borderTop: `1px solid ${theme.colors.blueGrey1}`,

    ':last-of-type': {
        borderBottom: `1px solid ${theme.colors.blueGrey1}`,
    },
});
