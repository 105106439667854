export const chart = ({ data, height, theme: { colors } }) => {
    const styles = data
        .map(item => item.opacity)
        .reduce(
            (acc, opacity, index) => ({
                ...acc,
                [`& > svg > path:nth-child(${index + 1})`]: {
                    stroke: colors.secondary,
                    opacity,
                },
            }),
            {},
        );

    return {
        height: height || 303,
        ...styles,
    };
};

export const wrapper = () => ({
    position: 'relative',
});
