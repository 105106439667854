import { React, PropTypes, connectFela, NoData } from 'modules/reports/dependencies';

import { useTagVisibility } from 'modules/reports/hooks';

import TagVisibility from './TagVisibility';

import * as Styles from './TagsVisibility.styles';

function Tags({ styles }) {
    const { values, setTagVisibility } = useTagVisibility();

    return (
        <div className={styles.wrapper}>
            {values.length === 0 && <NoData extend={Styles.extendNoData} />}
            {values.map(({ id, hidden, name }, index) => (
                <TagVisibility
                    key={id}
                    id={id}
                    checked={!hidden}
                    onChange={setTagVisibility}
                    label={name}
                    isLast={index >= values.length - 1}
                />
            ))}
        </div>
    );
}

Tags.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(Tags);
