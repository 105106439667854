import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const actionTypes = {
    REQUEST: types.FETCH_USER_REQUEST,
    SUCCESS: types.FETCH_USER_SUCCESS,
    FAILURE: types.FETCH_USER_FAILURE,
    CANCEL: types.FETCH_USER_CANCEL,
};

const apiReducer = ReduxUtils.basicApiReducer({
    actionTypes,
});

export default ReduxUtils.containerReducer({
    actionTypes: Object.values(actionTypes),
    childReducer: apiReducer,
    selectors: {
        itemId: action => action.meta.id,
    },
});
