import { React, PropTypes, ButtonPrimary, FormattedMessage } from 'modules/reports/dependencies';

import { useUpdateReport } from 'modules/reports/hooks/ReportControl';

const UpdateReport = ({ reportId }) => {
    const { inProgress, updateReport } = useUpdateReport(reportId);

    return (
        <ButtonPrimary onClick={updateReport} loading={inProgress}>
            <FormattedMessage id="report.button.saveChanges" />
        </ButtonPrimary>
    );
};

UpdateReport.propTypes = {
    reportId: PropTypes.string.isRequired,
};

export default UpdateReport;
