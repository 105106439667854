import { React, FormattedMessage, UI, useModal, Consts } from '../../dependencies';

const { ButtonDefault } = UI;

const CreateMembershipButton = () => {
    const modal = useModal(Consts.modals.CREATE_MEMBERSHIP);

    return (
        <ButtonDefault onClick={() => modal.open()}>
            <FormattedMessage id="memberships.button.add" />
        </ButtonDefault>
    );
};

export default CreateMembershipButton;
