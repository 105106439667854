import { MODULE_PREFIX } from '../../constants';

export const types = {
    SET_AUTH_USER: `${MODULE_PREFIX}/SET_AUTH_USER`,
    UPDATE_AUTH_USER: `${MODULE_PREFIX}/UPDATE_AUTH_USER`,

    DELETE_MEMBERSHIP: `${MODULE_PREFIX}/DELETE_MEMBERSHIP`,
    APPEND_MEMBERSHIP: `${MODULE_PREFIX}/APPEND_MEMBERSHIP`,
};

export const setAuthUser = user => ({
    type: types.SET_AUTH_USER,
    payload: user,
});

export const updateAuthUser = user => ({
    type: types.UPDATE_AUTH_USER,
    payload: user,
});

export const deleteMembership = membershipId => ({
    type: types.DELETE_MEMBERSHIP,
    payload: membershipId,
});

export const appendMembership = membershipId => ({
    type: types.APPEND_MEMBERSHIP,
    payload: membershipId,
});
