import { combineReducers, Consts } from '../../../dependencies';

import fetchSuppliers from './fetchSuppliers';

import fetchSupplier from './fetchSupplier';
import deleteSupplier from './deleteSupplier';
import createSupplier from './createSupplier';
import updateSupplier from './updateSupplier';

import fetchAresData from './fetchAresData';

const { CREATE, UPDATE, FETCH, DELETE } = Consts.apiTypes;

export const supplier = combineReducers({
    [CREATE]: createSupplier,
    [UPDATE]: updateSupplier,
    [FETCH]: fetchSupplier,
    [DELETE]: deleteSupplier,
});

export const suppliers = combineReducers({
    [FETCH]: fetchSuppliers,
});

export const ares = combineReducers({
    [FETCH]: fetchAresData,
});
