import types from './types';

export { types };

export const expenseTagAdd = (expenseId, { value, formId }) => ({
    type: types.EXPENSE_TAG_ADD,
    meta: {
        expenseId,
    },
    payload: {
        value,
        formId,
    },
});

export const expenseTagRemove = (expenseId, { value, formId }) => ({
    type: types.EXPENSE_TAG_REMOVE,
    meta: {
        expenseId,
    },
    payload: {
        value,
        formId,
    },
});
