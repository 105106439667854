import { ReduxUtils } from 'modules/expenses/dependencies';

import paginationReducer, { actionTypes } from './paginationReducer';

export default ReduxUtils.containerReducer({
    actionTypes: Object.values(actionTypes),
    childReducer: paginationReducer,
    selectors: {
        itemId: action => {
            const { groupId, itemId } = action.meta;
            return groupId || itemId;
        },
    },
});
