import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from '../actionTypes';

export const fetchSupplierRequest = id => ({
    type: types.FETCH_SUPPLIER_REQUEST,
    meta: { id },
});

export const fetchSupplierSuccess = (id, payload) => ({
    type: types.FETCH_SUPPLIER_SUCCESS,
    meta: { id },
    payload: formatEntity({
        entity: payload,
        template: entitiesTemplates.supplier,
    }),
});

export const fetchSupplierFailure = (id, error) => ({
    type: types.FETCH_SUPPLIER_FAILURE,
    meta: { id },
    error,
});

export const fetchSupplierInvalidate = id => ({
    type: types.FETCH_SUPPLIER_CANCEL,
    meta: { id },
});
