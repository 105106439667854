import { api, config } from 'modules/auth/dependencies';
import { transformCredentials } from '../utils';

export default function* refreshTokens({ refreshToken }) {
    const response = yield* api.post(config.api.refresh, null, {
        params: {
            token: refreshToken.token,
        },
    });

    const tokens = transformCredentials(response.data);

    return tokens;
}
