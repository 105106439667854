import React from 'react';

const IconTagShape = props => (
    <svg viewBox="0 0 20 40" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <path d="M13,24 C10.791,24 9,22.209 9,20 C9,17.791 10.791,16 13,16 C15.209,16 17,17.791 17,20 C17,22.209 15.209,24 13,24 L13,24 Z M16,0 L15.384,0 C14.487,0 13.617,0.301 12.912,0.854 L1.528,9.799 C0.563,10.558 0,11.717 0,12.944 L0,27.056 C0,28.283 0.563,29.442 1.528,30.201 L12.912,39.146 C13.617,39.699 14.487,40 15.384,40 L16,40 L20,40 L20,0 L16,0 Z" />
        </g>
    </svg>
);

export default IconTagShape;
