export const overview = () => ({
    display: 'flex',
    marginBottom: 34,
});

export const divider = ({ theme: { colors } }) => ({
    width: 1,
    height: 50,
    backgroundColor: colors.blueGrey2,
    margin: '0 16px',
});
