export const dropdownButton = () => ({
    display: 'grid',
    gridTemplateColumns: '2.5rem 1fr 0.81rem',
    alignItems: 'center',
    gridColumnGap: 10,
});

export const iconUserWrapper = ({ theme: { colors } }) => ({
    backgroundColor: colors.white,
    borderRadius: '50%',
    height: '2.5rem',
    width: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const iconUser = ({ theme: { colors } }) => ({
    fill: colors.text,
    height: '1.25rem',
});

export const iconChevronDown = ({ theme: { colors } }) => ({
    fill: colors.text,
    height: '0.5rem',
});

export const dropdown = ({ theme: { colors, boxShadows } }) => ({
    background: colors.white,
    boxShadow: boxShadows.dropdown,
    borderRadius: '6px',
    width: 225,
});

export const companyName = ({ theme: { colors } }) => ({
    color: colors.text,
    fontSize: '15px',
    letterSpacing: '0.15px',
    lineHeight: 1.56,
    whiteSpace: 'nowrap',
});
