import { difference, Actions } from '../../../dependencies';
import { types } from '../../actions';

const initialState = {
    byId: {},
    ids: [],
};

export default function membershipsReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.types.SET_MEMBERSHIPS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...action.payload.byId,
                },
            };

        case types.FETCH_MEMBERSHIPS_SUCCESS: {
            const { ids, byId } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...byId,
                },
                ids,
            };
        }

        case types.CREATE_MEMBERSHIP_SUCCESS: {
            const { id } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [id]: action.payload,
                },
                ids: state.ids.concat(String(id)),
            };
        }

        case types.UPDATE_MEMBERSHIP_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.id]: action.payload,
                },
            };

        case types.DELETE_MEMBERSHIP_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.id]: undefined,
                },
                ids: difference(state.ids, [String(action.meta.id)]),
            };

        default:
            return state;
    }
}
