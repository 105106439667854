import { formatEntities, entitiesTemplates } from '../../dependencies';
import types from '../actionTypes';

export const fetchTagsRequest = () => ({
    type: types.FETCH_TAGS_REQUEST,
});

export const fetchTagsSuccess = payload => ({
    type: types.FETCH_TAGS_SUCCESS,
    payload: {
        ...payload,
        byName: formatEntities({
            entities: payload.byName,
            template: entitiesTemplates.tag,
        }),
    },
});

export const fetchTagsFailure = error => ({
    type: types.FETCH_TAGS_FAILURE,
    error,
});
