import { Modals, compose, submitForm, ExpensesActions, config, connect, goBack } from '../dependencies';
import * as Config from '../config';

import ChangeExportedExpenseModal from '../components/ChangeExportedExpenseModal';

const formId = config.forms.updateExpense;

const mapDispatchToProps = (dispatch, { expenseId }) => ({
    onSubmit() {
        const submit = submitForm(formId, () => ExpensesActions.updateExpenseRequest(expenseId));

        dispatch(submit());
    },
    goBack() {
        dispatch(goBack());
    },
});

export default compose(
    Modals.HOC.withModal(Config.Modals.CHANGE_EXPORTED_EXPENSE),
    connect(
        null,
        mapDispatchToProps,
    ),
)(ChangeExportedExpenseModal);
