import { React, CardContent, CardBackground } from '../../dependencies';

import { Views } from '../../constants';

import TagsCardHeader from './CardHeader';
import TagsCardContent from './CardContent';

import * as Styles from './ReportTags.styles';

function ReportTags() {
    const [view, setView] = React.useState(Views.DOUGHNUT);
    const props = { view, setView };
    const header = <TagsCardHeader {...props} />;

    return (
        <CardBackground>
            <CardContent
                {...{
                    header,
                    extend: Styles.cardExtend,
                }}
            >
                <TagsCardContent {...props} />
            </CardContent>
        </CardBackground>
    );
}

export default ReportTags;
