import React from 'react';

const IconLineDotted = props => (
    <svg width="100%" height="2" {...props}>
        <defs>
            <pattern id="line-dotted" x="0" y="0" width="18" height="2" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" height="2" width="10" />
                <rect x="10" y="0" height="2" width="8" fill="none" />
            </pattern>
        </defs>

        <rect x="0" y="0" width="100%" height="100%" fill="url(#line-dotted)" />
    </svg>
);

export default IconLineDotted;
