import { formatEntities, entitiesTemplates } from '../../dependencies';
import types from './types';

export const fetchExportsRequest = () => ({
    type: types.FETCH_EXPORTS_REQUEST,
});

export const fetchExportsSuccess = ({ ids = [], byId = {} }) => ({
    type: types.FETCH_EXPORTS_SUCCESS,
    payload: {
        ids,
        byId: formatEntities({
            template: entitiesTemplates.export,
            entities: byId,
        }),
    },
});

export const fetchExportsFailure = error => ({
    type: types.FETCH_EXPORTS_FAILURE,
    error,
});

export const fetchExportsInvalidate = () => ({
    type: types.FETCH_EXPORTS_CANCEL,
});
