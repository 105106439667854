import { React, PropTypes, FelaComponent, childrenPropType } from '../../../../dependencies';

import * as styles from './Cell.styles';

function Cell({ children, textAlign }) {
    return (
        <FelaComponent style={styles.cell} textAlign={textAlign}>
            {children}
        </FelaComponent>
    );
}

Cell.propTypes = {
    children: childrenPropType.isRequired,
    textAlign: PropTypes.string,
};

Cell.defaultProps = {
    textAlign: undefined,
};

export default Cell;
