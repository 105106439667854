import { isDateInvalid } from '../../format';
import d from '../defaultValues';
import { convertValue } from '../helpers';

const expenseListItem = {
    authorId: convertValue(String),
    companyId: convertValue(String),
    createdAt: d.string,
    currency: d.string,
    documentType: d.string,
    dueOn: d.string,
    hasFiles: d.false,
    id: convertValue(String),
    issuedOn: d.string,
    monthlySerial: d.null,
    note: d.string,
    number: d.string,
    originalNumber: d.string,
    paymentMethod: d.string,
    receivedOn: d.string,
    supplierId: convertValue(String),
    synced: value => (isDateInvalid(value) ? null : value),
    syncedExports: d.array,
    tags: d.array,
    taxDeductible: d.false,
    taxableFulfillmentDue: d.string,
    totalPriceWithVat: d.zero,
    totalPriceWithVatDefaultCurrency: d.zero,
    totalPriceWithoutVat: d.zero,
    totalPriceWithoutVatDefaultCurrency: d.zero,
    type: d.string,
    updatedAt: d.string,
    variableSymbol: d.string,
    vatFromBase: d.false,
};

export default expenseListItem;
