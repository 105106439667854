export const modal = ({ theme }) => ({
    '& .ant-modal-header': {
        paddingTop: 32,
        paddingBottom: 16,
        paddingLeft: 24,
        paddingRight: 24,
        border: 'none',
    },
    '& .ant-modal-title': {
        color: theme.colors.text,
        lineHeight: 1.31,
        letterSpacing: '0.3px',
        fontSize: '20px',
    },
});
