export const row = ({ theme: { colors } }) => ({
    alignItems: 'center',
    padding: '18px 30px',

    borderBottom: `1px solid ${colors.blueGrey1}`,

    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr) 2fr 2fr 80px 1fr',
    gridColumnGap: 8,
});

export const col = ({ theme: { colors } }) => ({
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.3px',
    lineHeight: '19px',
    color: colors.blueGrey4,
});

export const alignRight = () => ({
    '& .OrderFilter': {
        justifyContent: 'flex-end',
    },
});
