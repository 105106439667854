import { useIntl, React, Roles } from '../dependencies';

export function useRoleOptions() {
    const intl = useIntl();

    return React.useMemo(() => {
        return [Roles.OWNER, Roles.ACCOUNTANT, Roles.CONTRIBUTOR].map(id => ({
            value: id,
            label: intl.formatMessage({
                id: `role.${id}`,
            }),
        }));
    }, [intl]);
}
