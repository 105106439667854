import {
    compose,
    reduxForm,
    submitForm,
    injectIntl,
    connect,
    submitCreateMembershipForm,
    Roles,
} from '../../dependencies';

import { Forms } from '../../constants';

import CreateMembershipForm from '../../components/CreateMembershipModal/CreateMembershipForm';

import validate from './CreateMembershipForm.validate';

const form = Forms.CREATE_MEMBERSHIP;

export default compose(
    injectIntl,
    connect(null, {
        onSubmit: submitForm(form, submitCreateMembershipForm),
    }),
    reduxForm({
        form,
        validate,
        initialValues: {
            role: Roles.OWNER,
        },
    }),
)(CreateMembershipForm);
