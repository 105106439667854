import { encodeSearchParams } from 'services/utilities';
import { authApi, config } from '../../dependencies';

let interceptor = null;

const requestsBlacklistEndpoints = new Set([config.api.companies, config.api.company]);

export default function setRequestInterceptor(companyId) {
    if (interceptor !== null) {
        authApi.interceptors.request.eject(interceptor);
        interceptor = null;
    }

    if (companyId) {
        interceptor = authApi.interceptors.request.use(requestParams => {
            const { url, config = {} } = requestParams;
            // 'companies' EP can't include `companyId` param, otherwise not all companies will be fetched
            const blacklisted = requestsBlacklistEndpoints.has(url);

            if (!blacklisted) {
                if (config.params instanceof URLSearchParams) {
                    config.params.set('companyId', companyId);
                } else {
                    config.params = encodeSearchParams({
                        ...config.params,
                        companyId,
                    });
                }
            }

            return {
                ...requestParams,
                url,
                config,
            };
        });
    }
}
