import {
    React,
    PropTypes,
    Link,
    Icons,
    connectFela,
    compose,
    useSelector,
    useNoActiveCompanyId,
    Consts,
} from '../../dependencies';

import Dropdown from '../Dropdown';

import NavItem from '../NavItem';
import ButtonCreate from '../ButtonCreate';

import { navItemsSelector } from '../../services/selectors';

import * as Styles from './Navbar.styles';
import { isNavItemActive } from './Navbar.utils';

const Navbar = ({ pathname, styles, onSearchClick }) => {
    const navItems = useSelector(navItemsSelector);
    const hasNoActiveCompany = useNoActiveCompanyId();

    return (
        <div className={styles.navbar}>
            <Link to={hasNoActiveCompany ? Consts.routes.SETTINGS : Consts.routes.DASHBOARD}>
                <Icons.LogoExpenses className={styles.logo} />
            </Link>

            <nav className={styles.nav}>
                {navItems.map(navItem => (
                    <NavItem
                        {...{
                            ...navItem,
                            key: navItem.to,
                            isActive: isNavItemActive(pathname, navItem.to),
                        }}
                    />
                ))}
            </nav>

            {hasNoActiveCompany ? (
                <div />
            ) : (
                <button type="button" className={styles.search} onClick={onSearchClick}>
                    <Icons.Search className={styles.iconSearch} />
                </button>
            )}

            {hasNoActiveCompany ? <div /> : <ButtonCreate />}

            <Dropdown />
        </div>
    );
};

Navbar.propTypes = {
    pathname: PropTypes.string.isRequired,
    styles: PropTypes.shape().isRequired,
    onSearchClick: PropTypes.func.isRequired,
};

export default compose(React.memo, connectFela(Styles))(Navbar);
