import { React, useSelector, DataLoader } from 'modules/reports/dependencies';

import { reportApiSelector } from 'modules/reports/services/selectors';

import TagVisibilityList from './TagVisibilityList';

import { extendDataLoader } from './Tags.styles';

function Tags() {
    const { inProgress, success } = useSelector(reportApiSelector);

    return (
        <DataLoader show={inProgress || !success} extend={extendDataLoader}>
            <TagVisibilityList />
        </DataLoader>
    );
}

export default Tags;
