export const footer = ({ theme: { colors } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${colors.blueGrey1}`,
    height: 63,
    padding: '16px 30px',
});

export const label = ({ theme: { colors } }) => ({
    letterSpacing: '0.3px',
    lineHeight: 1.31,
    color: colors.blueGrey4,
    fontSize: '1rem',
});

export const value = ({ theme: { colors } }) => ({
    color: colors.text,
    letterSpacing: '0.3px',
    lineHeight: 1.29,
    fontSize: '1.5rem',
});
