import { React, connect, PropTypes, DataLoader, CardBackground } from '../dependencies';

import { reportApiSelector, updateReportApiSelector } from '../services/selectors';

export default function withCardLoader(cardBackgroundProps = {}) {
    return WrappedComponent => {
        function CardLoader({ showLoader, ...props }) {
            return (
                <CardBackground extend={cardBackgroundProps.extend}>
                    {showLoader ? <DataLoader show /> : <WrappedComponent {...props} />}
                </CardBackground>
            );
        }

        CardLoader.propTypes = {
            showLoader: PropTypes.bool.isRequired,
        };

        const mapStateToProps = (state, { showLoader }) => {
            const { inProgress, success } = reportApiSelector(state);
            const update = updateReportApiSelector(state);

            return {
                showLoader:
                    showLoader !== undefined ? showLoader : Boolean(inProgress || !success || update.inProgress),
            };
        };

        return connect(mapStateToProps)(CardLoader);
    };
}
