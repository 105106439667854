import { combineReducers, Consts } from '../../../dependencies';

import createCompany from './createCompany';
import deleteCompany from './deleteCompany';
import updateCompany from './updateCompany';

const { CREATE, UPDATE, DELETE } = Consts.apiTypes;

export const company = combineReducers({
    [CREATE]: createCompany,
    [DELETE]: deleteCompany,
    [UPDATE]: updateCompany,
});
