import { ReduxUtils } from '../dependencies';

const { strictObjectAccess } = ReduxUtils;

export const Fields = strictObjectAccess({
    RANGE: 'range',
    SUPPLIER_ID: 'supplierId',
    INCLUDE_TAGS: 'includeTags',
    EXCLUDE_TAGS: 'excludeTags',
    HAS_FILES: 'hasFiles',
    CURRENCY_TAGS: 'currency',
    PAYMENT_METHOD_TAGS: 'paymentMethod',
});

export const HAS_FILES_OPTION = Fields.HAS_FILES;

export const ORDER_TYPE = strictObjectAccess({
    not: null,
    asc: 'asc',
    desc: 'desc',
});

export const OrderSigns = strictObjectAccess({
    [ORDER_TYPE.asc]: '+',
    [ORDER_TYPE.desc]: '-',
});

export const ParamsKeys = strictObjectAccess({
    EXCLUDE_TAGS: 'excludeTags',
    INCLUDE_TAGS: 'includeTags',
    SUPPLIER_ID: 'supplierId',
    FROM: 'from',
    TO: 'to',
    QUERY: 'query',
    RELATIVE_PERIOD: 'relativePeriod',
    MARK_AS_SYNCED: 'markAsSynced',
    CURRENCY_TAGS: 'currency',
    PAYMENT_METHOD_TAGS: 'paymentMethod',
});
