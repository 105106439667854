export const footer = ({ theme: { colors } }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gridColumnGap: '1rem',

    padding: '1.5rem 2rem',
    borderTop: `1px solid ${colors.blueGrey1}`,
});
