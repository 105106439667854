import types from './types';

export const deleteMembershipRequest = id => ({
    type: types.DELETE_MEMBERSHIP_REQUEST,
    meta: { id: String(id) },
});

export const deleteMembershipSuccess = id => ({
    type: types.DELETE_MEMBERSHIP_SUCCESS,
    meta: { id: String(id) },
});

export const deleteMembershipFailure = (id, error) => ({
    type: types.DELETE_MEMBERSHIP_FAILURE,
    meta: { id: String(id) },
    error,
});

export const deleteMembershipInvalidate = id => ({
    type: types.DELETE_MEMBERSHIP_CANCEL,
    meta: { id: String(id) },
});
