import { React, FelaComponent, PropTypes } from '../../dependencies';

import SupplierListItem, { supplierShape } from '../SupplierListItem';

import style from './SupplierList.style';

const SupplierList = ({ suppliers, isContributor }) => (
    <FelaComponent style={style}>
        {suppliers.map(supplier => (
            <SupplierListItem key={supplier.id} isContributor={isContributor} {...supplier} />
        ))}
    </FelaComponent>
);

SupplierList.propTypes = {
    suppliers: PropTypes.arrayOf(supplierShape),
    isContributor: PropTypes.bool.isRequired,
};

SupplierList.defaultProps = {
    suppliers: [],
};

export default SupplierList;
