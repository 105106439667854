import { React, PropTypes, connectFela, FormattedMessage } from '../../../dependencies';

import * as Styles from './TooltipAverage.styles';

function TooltipAverage({ value, styles }) {
    return (
        <div className={styles.tooltip}>
            <div className={styles.title}>
                <FormattedMessage id="report.chart.average" />
            </div>
            <div className={styles.value}>{value}</div>
        </div>
    );
}

TooltipAverage.propTypes = {
    value: PropTypes.node.isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default connectFela(Styles)(TooltipAverage);
