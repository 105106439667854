export const expandedContent = ({ theme: { colors } }) => ({
    borderTop: `1px dashed ${colors.blueGrey2}`,
    padding: '40px 0 0',
    marginTop: 39,
    willChange: 'content',
});

export const button = ({ theme: { colors, transitions }, isActive }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    color: isActive ? colors.primary : colors.text,
    borderColor: isActive ? colors.primary : colors.text,
    '& > svg': {
        transition: `fill ${transitions.button}`,
        fill: isActive ? colors.primary : colors.text,
    },
    '&:hover > svg': {
        fill: colors.primary,
    },
    '&:active > svg': {
        fill: colors.primary,
    },
    '&:focus > svg': {
        fill: colors.primary,
    },
});

export const icon = ({ isExpanded, theme: { colors } }) => ({
    width: 13,
    marginLeft: 12,
    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
    fill: colors.text,
    transition: 'transform 250ms',
});

export const buttonWrapper = () => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: 64,
    position: 'relative',
});

export const divider = ({ theme: { colors } }) => ({
    height: '1px',
    width: '100%',
    backgroundColor: colors.blueGrey1,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 0,
});
