import { React, PropTypes, Nav, useSelector } from '../../dependencies';

import ButtonRemove from '../../containers/ButtonRemove';
import { canDeleteExpenseSelector } from '../../services/selectors';

function NavWrapper({ expenseId, goBack }) {
    const canDeleteExpense = useSelector(canDeleteExpenseSelector);
    const navProps = {
        goBack,
        titleProps: {
            messageId: 'expense.edit.title',
            style: {
                marginBottom: 0,
            },
        },
    };

    return <Nav {...navProps}>{canDeleteExpense && <ButtonRemove {...{ expenseId }} />}</Nav>;
}

NavWrapper.propTypes = {
    expenseId: PropTypes.string.isRequired,
    goBack: PropTypes.func.isRequired,
};

export default NavWrapper;
