export const container = ({ width }) => ({
    overflow: 'hidden',
    position: 'relative',
    maxWidth: width <= 0 ? '100%' : width,
    width: '100%',
});

export const row = ({ barsCount, barWidth, height, translateX = 0, gap, opacity }) => ({
    display: 'grid',
    alignItems: 'flex-end',
    gridTemplateColumns: `repeat(${barsCount}, ${barWidth > 0 ? `${barWidth}px` : `${100 / barsCount}fr`})`,
    gridColumnGap: gap,
    position: 'relative',
    transform: `translateX(${translateX})`,
    transition: 'transform 250ms ease-out',
    opacity,
    willChange: 'transform',
    width: '100%',
    minHeight: '100%',
    height,
});
