import { React, FelaComponent, childrenPropType, PropTypes } from '../../dependencies';

import * as styles from './Grid.styles';

function Grid({ children, gridColumnGap }) {
    return (
        <FelaComponent style={styles.grid} {...{ gridColumnGap }}>
            {children}
        </FelaComponent>
    );
}

Grid.propTypes = {
    children: childrenPropType.isRequired,
    gridColumnGap: PropTypes.number,
};

Grid.defaultProps = {
    gridColumnGap: 16,
};

export default Grid;
