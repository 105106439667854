export const container = () => ({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: '1rem',
    alignItems: 'center',
    marginTop: '4rem',
    marginBottom: '1.5rem',
    justifyContent: 'space-between',
});

export const title = ({ theme: { colors } }) => ({
    fontSize: '1.5rem',
    letterSpacing: '0.3px',
    lineHeight: 1.29,
    color: colors.text,
    fontWeight: 700,
});
