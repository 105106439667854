import { React, PropTypes, FormattedNumber, injectIntl, Config, connectFela, compose } from '../../dependencies';

import Label from '../Label';
import Value from '../Value';

import * as Styles from './Items.styles';

function Items({ items, currency, intl: { formatMessage, formatNumber }, styles }) {
    return (
        <div className={styles.items}>
            <div className={styles.item}>
                {Items.labels.map((messageId, i) => (
                    <Label
                        {...{
                            key: messageId,
                            big: true,
                            messageId,
                            marginBottom: 4,
                            textAlign: i > 0 ? 'right' : undefined,
                        }}
                    />
                ))}
            </div>

            {items.map(({ name, vatRate, priceWithVatTotal, priceWithoutVatTotal }, i) => {
                return (
                    <div key={i} className={styles.item}>
                        <Value>{name}</Value>
                        <Value textAlign="right">
                            <FormattedNumber
                                {...{
                                    value: vatRate,
                                    style: 'percent',
                                }}
                            />
                        </Value>
                        <Value
                            textAlign="right"
                            title={formatMessage(
                                {
                                    id: 'expenses.detail.item.totalPriceWithVat',
                                },
                                {
                                    price: formatNumber(priceWithVatTotal, {
                                        style: 'currency',
                                        currency,
                                        ...Config.currency,
                                    }),
                                },
                            )}
                        >
                            <FormattedNumber
                                {...{
                                    style: 'currency',
                                    value: priceWithoutVatTotal,
                                    currency,
                                    ...Config.currency,
                                }}
                            />
                        </Value>
                    </div>
                );
            })}
        </div>
    );
}

Items.labels = [
    'expense.form.item.name.label',
    'expense.form.item.vatRate.label',
    'expense.detail.item.priceWithoutVatTotal',
];

Items.itemShape = PropTypes.shape({
    name: PropTypes.string,
    vatRate: PropTypes.number.isRequired,
    priceWithVatTotal: PropTypes.number.isRequired,
    priceWithoutVatTotal: PropTypes.number.isRequired,
});

Items.propTypes = {
    items: PropTypes.arrayOf(Items.itemShape.isRequired).isRequired,
    currency: PropTypes.string.isRequired,
    intl: PropTypes.shape().isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default compose(injectIntl, connectFela(Styles))(Items);
