import {
    React,
    Charts,
    FormattedNumber,
    config,
    PriceTooltip,
    useSelector,
    injectIntl,
    PropTypes,
} from 'modules/reports/dependencies';

import { shortenedNumber } from 'modules/reports/services/utils';
import { barChartSelector } from 'modules/reports/services/selectors';

const { ChartBar } = Charts;

const GAP = 9;
const HEIGHT = 229;
const chartBarProps = {
    gap: GAP,
    height: HEIGHT,
    bottomLabelHeight: 22,
    topLabelHeight: 22,
};
const NUM_OF_MONTHS = 12;

function mapBars(bars = [], { currency, intl }) {
    return bars.map(({ withVat, withoutVat, year, monthIndex, ...bar }) => ({
        ...bar,
        title: <PriceTooltip currency={currency} priceWithVat={withVat} />,
        topLabel: shortenedNumber(withoutVat, intl),
        bottomLabel: `${monthIndex + 1}/${year}`,
    }));
}

function BarChart({ intl }) {
    const { currency, data, viewportLeftOffset, viewportSize, average } = useSelector(barChartSelector);

    return (
        <ChartBar
            {...{
                ...chartBarProps,
                calcBarWidth: (viewportWidth, dataSize) => {
                    const size = dataSize <= NUM_OF_MONTHS ? dataSize : Math.min(viewportSize, dataSize);
                    const gaps = size - 1;
                    const barWidth = Math.floor((viewportWidth - gaps * GAP) / size);

                    return barWidth === viewportWidth ? Math.floor(barWidth / 4) : barWidth;
                },
                data: mapBars(data, { intl, currency }),
                startWithIndex: viewportLeftOffset,
                average: {
                    ...average,
                    value: average ? (
                        <FormattedNumber
                            {...config.currency}
                            // eslint-disable-next-line
                            style="currency"
                            value={average.value}
                            currency={currency}
                        />
                    ) : (
                        '-'
                    ),
                },
            }}
        />
    );
}

BarChart.propTypes = {
    intl: PropTypes.shape().isRequired,
};

export default injectIntl(BarChart);
