import { Utils } from 'modules/reports/dependencies';

import { NewReportFields } from 'modules/reports/config';

const { InputValidation } = Utils;
const { getFieldsErrors, validatorsWithMessage, translate } = InputValidation;
const { isRequired } = validatorsWithMessage;

const validators = {
    [NewReportFields.NAME]: [isRequired],
};

export default function validate(values, { intl }) {
    const errors = getFieldsErrors(validators, values);

    return translate(intl.formatMessage, errors);
}
