export const tooltipWrapper = ({ theme: { colors, boxShadows } }) => ({
    minWidth: 134,
    '> .ant-tooltip-content > .ant-tooltip-inner': {
        padding: 0,

        color: colors.text,
        backgroundColor: colors.white,
        borderRadius: '4px',
        border: `1px solid ${colors.blueGrey1}`,
        boxShadow: boxShadows.dropdown,

        fontSize: '1rem',
        lineHeight: 1.56,
        letterSpacing: '0.2px',
    },
    '> .ant-tooltip-content > .ant-tooltip-arrow': {
        borderTopColor: colors.white,
        borderWidth: '10px 10px 0',
        bottom: '2px',
    },
});
