import { createSelector, config, ActiveCompanySelectors } from '../../../dependencies';
import { trasformReportFiltersToApiFilters } from '../../../utils';

import { hiddenTagsSelector } from '../entities';

const newReportFiltersSelector = () => {
    const { from, to, relativePeriod } = config.filters.defaultInitials.reports;

    return {
        from,
        to,
        relativePeriod,
        supplierIds: null,
        excludeTags: null,
        includeTags: null,
    };
};

export const newReportDataSelector = createSelector(
    [ActiveCompanySelectors.activeCompanyIdSelector, newReportFiltersSelector, hiddenTagsSelector],
    (companyId, filters, hiddenTags) => {
        return {
            filters: trasformReportFiltersToApiFilters(filters),
            hiddenTags,
            companyId,
        };
    },
);
