import { config, authApi, takeLatestRequest, Log, sagaEffects, ErrorsUtils } from '../../dependencies';

import {
    updateExpenseTriggerSuccess,
    updateExpenseTriggerFailure,
    updateExpenseTriggerInvalidate,
    types,
} from '../actions';
import { transformPayload } from './utils';

const { put } = sagaEffects;

function* updateExpenseTrigger(action, cancelToken) {
    const { id } = action.meta;

    try {
        const { data } = yield* authApi.put(config.api.expenseTrigger, transformPayload(action.payload), {
            uriParams: {
                id,
            },
            cancelToken,
        });

        yield put(updateExpenseTriggerSuccess(id, data));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.expenseTrigger.api.update',
            },
        });

        yield put(updateExpenseTriggerFailure(id, errorMessage));
    }
}

export default function* updateExpenseTriggerWatcher() {
    const actionTypes = {
        REQUEST: types.UPDATE_EXPENSE_TRIGGER_REQUEST,
        cancelTask: updateExpenseTriggerInvalidate,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, updateExpenseTrigger);
}
