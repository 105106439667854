import { connect } from '../dependencies';

import SearchHeader from '../components/SearchHeader';

import { selectSearchValue } from '../services/selectors';

const mapStateToProps = state => ({
    search: selectSearchValue(state),
});

export default connect(mapStateToProps)(SearchHeader);
