import { sagaEffects } from '../../../dependencies';

import updateMembershipRole from './updateMembershipRole';
import submitCreateMembershipForm from './submitCreateMembershipForm';

const { all } = sagaEffects;

export default function* expensesSaga() {
    yield all([updateMembershipRole(), submitCreateMembershipForm()]);
}
