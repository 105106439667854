import { React, Space, CardBackground } from 'modules/reports/dependencies';

import CreateReportModal from 'modules/reports/containers/CreateReportModal';
import Filter from 'modules/reports/containers/Filter';
import ReportTags from 'modules/reports/containers/ReportTags';
import ReportBarChart from 'modules/reports/containers/ReportBarChart';

import ReportControl from '../ReportControl';
import ReportGrid from '../ReportGrid';
import ReportExpenses from '../ReportExpenses';

function ReportDetail() {
    return (
        <>
            <ReportGrid>
                <CardBackground>
                    <Filter />
                </CardBackground>
                <ReportControl />

                <ReportBarChart />
                <ReportTags />
            </ReportGrid>

            <ReportExpenses />

            <Space top="5rem" />

            <CreateReportModal />
        </>
    );
}

export default ReportDetail;
