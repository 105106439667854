import { ReduxUtils } from '../../../dependencies';
import types from '../../actions/types';

const actionTypes = {
    REQUEST: types.FETCH_REPORT_REQUEST,
    SUCCESS: types.FETCH_REPORT_SUCCESS,
    FAILURE: types.FETCH_REPORT_FAILURE,
    CANCEL: types.FETCH_REPORT_CANCEL,
    RESET: types.FETCH_REPORT_RESET,
};

const basicApiReducer = ReduxUtils.basicApiReducer({
    actionTypes,
});

export default ReduxUtils.containerReducer({
    childReducer: basicApiReducer,
    actionTypes: Object.values(actionTypes),
    selectors: {
        itemId: action => action.meta.id,
    },
});
