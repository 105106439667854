export const buttonDefault = ({ theme: { colors } }) => {
    const activeStyle = {
        color: colors.textHover,
        borderColor: colors.textHover,
        backgroundColor: 'transparent',
    };

    return {
        color: colors.text,
        borderColor: colors.text,
        backgroundColor: 'transparent',

        '&:hover': activeStyle,
        '&:active': activeStyle,
        '&:focus': activeStyle,
    };
};
