import { React, PropTypes, childrenPropType } from '../../dependencies';

import ErrorWithAction from '../ErrorWithAction';
import { errorPropType } from '../utils';

function ErrorCard({ message, retry, children }) {
    if (!message) {
        return <>{children}</>;
    }
    return <ErrorWithAction error={message} onErrorMessageClick={retry} />;
}

ErrorCard.propTypes = {
    message: errorPropType,
    retry: PropTypes.func.isRequired,
    children: childrenPropType.isRequired,
};

ErrorCard.defaultProps = {
    message: null,
};

export default ErrorCard;
