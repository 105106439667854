import { connect } from '../dependencies';
import AlertSuccess from '../components/AlertSuccess';
import { syncedExportsSelector } from '../services/selectors';

const mapStateToProps = (state, { expenseId }) => {
    return {
        timestamp: syncedExportsSelector(state, expenseId),
    };
};

export default connect(mapStateToProps)(AlertSuccess);
