import { combineReducers, ReduxUtils, Actions } from 'modules/expenses/dependencies';

import details from './details';
import lists from './lists';
import nextExpenseNumber from './nextExpenseNumber';
import duplicateExpense from './duplicateExpense';

const expensesReducer = combineReducers({
    details,
    lists,
    nextExpenseNumber,
    duplicateExpense,
});

export default ReduxUtils.basicResetReducer(expensesReducer, Actions.types.RESET_ALL_EXPENSES);
