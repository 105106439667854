import { InputValidation } from '../../../../dependencies';

const { getFieldsErrors, validatorsWithMessage, translate } = InputValidation;
const { isRequired, isAlphaNumeric } = validatorsWithMessage;

// TODO: use isNowOrFutureDay only for create expense, not for edit!!!
const validators = {
    name: isRequired,

    startAt: isRequired,

    period: isRequired,

    supplier: isRequired,

    vatFromBase: isRequired,
    currency: isRequired,

    item: {
        vatRate: isRequired,
        units: isAlphaNumeric,
        pricePerUnit: isRequired,
    },
};

export default function validate({ items = [], ...values }, { intl }) {
    const errors = {
        ...getFieldsErrors(validators, values),
        items: items.map(item => getFieldsErrors(validators.item, item)),
    };

    return translate(intl.formatMessage, errors);
}
