export const navbar = () => ({
    display: 'grid',
    alignItems: 'center',
    width: '100%',
    gridTemplateColumns: '189px 3fr 1fr repeat(2, auto)',
    gridColumnGap: 42,
});

export const nav = () => ({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridColumnGap: 32,
});

export const logo = () => ({
    height: '1.75rem',
    marginRight: 16,
});

export const search = ({ theme }) => ({
    marginLeft: 'auto',
    color: theme.colors.text,
    height: '1.5rem',
    width: '1.5rem',
});
