import {
    React,
    PropTypes,
    ButtonPrimary,
    FormattedMessage,
    InputWrapper,
    TextField,
    Icons,
    connectFela,
} from '../../dependencies';

import * as Styles from './SearchBarForm.style';

const SearchBarForm = ({ handleSubmit, intl, styles }) => (
    <form onSubmit={handleSubmit} className={styles.searchBarForm}>
        <InputWrapper>
            <TextField
                autoFocus
                prefix={<Icons.Search className={styles.iconSearch} />}
                name="search"
                placeholder={intl.formatMessage({ id: 'search.bar.placeholder' })}
            />
        </InputWrapper>
        <ButtonPrimary htmlType="submit">
            <FormattedMessage id="search.bar.submit" />
        </ButtonPrimary>
    </form>
);

SearchBarForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.shape().isRequired,
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(SearchBarForm);
