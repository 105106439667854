export const card = ({ theme: { colors } }) => ({
    backgroundColor: colors.grey1,
    padding: '1.5rem',

    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: '1rem',
});

export const name = () => ({
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: 1.35,
    letterSpacing: '0.3px',

    marginBottom: 16,
});

export const details = () => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    gridTemplateRows: 'repeat(3, auto)',
    gridColumnGap: '5rem',
    gridRowGap: 4,
    justifyContent: 'flex-start',
});

export const text = ({ theme: { colors } }) => ({
    fontSize: '1rem',
    lineHeight: 1.56,
    letterSpacing: '0.15px',
    color: colors.text,
});

export const roleRow = () => ({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: '0.5rem',
    marginBottom: 22,
    justifyContent: 'flex-start',
    alignItems: 'center',
});

export const roleLabel = ({ theme: { colors } }) => ({
    fontSize: '1rem',
    lineHeight: 1.56,
    letterSpacing: '0.15px',
    color: colors.blueGrey4,
});

export const roleValue = ({ theme: { colors } }) => ({
    color: colors.white,
    fontSize: '0.75rem',
    lineHeight: 1,
    letterSpacing: '0.1px',
});

export const noCompany = ({ theme: { colors } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingTop: '3rem',
    paddingBottom: '3rem',

    fontSize: '1rem',
    lineHeight: 1.56,
    letterSpacing: '0.15px',
    color: colors.blueGrey4,
});
