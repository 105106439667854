import { React, PropTypes, TooltipWrapper, connectFela } from '../../../dependencies';

import * as Styles from './Bar.styles';

function Bar({ topLabel, bottomLabel, title, styles }) {
    return (
        <TooltipWrapper title={title}>
            <div className={styles.bar}>
                {topLabel && <span className={styles.topLabel}>{topLabel}</span>}
                <div className={styles.column} />
                {bottomLabel && <span className={styles.bottomLabel}>{bottomLabel}</span>}
            </div>
        </TooltipWrapper>
    );
}

Bar.propTypes = {
    // eslint-disable-next-line
    width: PropTypes.number,
    // eslint-disable-next-line
    height: PropTypes.number.isRequired,
    topLabel: PropTypes.node.isRequired,
    bottomLabel: PropTypes.node.isRequired,
    title: PropTypes.node,
    styles: PropTypes.shape({}).isRequired,
};

Bar.defaultProps = {
    width: null,
    title: null,
};

export default connectFela(Styles)(Bar);
