import {
    React,
    PropTypes,
    connectFela,
    FormattedDate,
    CustomPropTypes,
    DeleteMembershipButton,
    SelectMembershipRole,
    FormattedMessage,
} from '../../../dependencies';

import * as Styles from './Item.styles';

const Item = ({ styles, membership }) => {
    const { createdAt, id, user, deletable, roleUpdatable, role } = membership;

    return (
        <div className={styles.item}>
            <span>{user.email}</span>
            {roleUpdatable ? (
                <SelectMembershipRole membershipId={id} />
            ) : (
                <div>
                    <FormattedMessage id={`role.${role}`} />
                </div>
            )}
            {createdAt ? <FormattedDate value={createdAt} /> : '-'}
            {deletable ? <DeleteMembershipButton id={id} email={user.email} /> : <div />}
        </div>
    );
};

Item.propTypes = {
    styles: PropTypes.shape().isRequired,
    membership: PropTypes.shape({
        id: CustomPropTypes.id.isRequired,
        user: PropTypes.shape({
            email: PropTypes.string.isRequired,
        }).isRequired,
        createdAt: PropTypes.string,
        role: PropTypes.string.isRequired,
        deletable: PropTypes.bool.isRequired,
        roleUpdatable: PropTypes.bool.isRequired,
    }).isRequired,
};

export default connectFela(Styles)(Item);
