import { types } from 'modules/reports/services/actions';

const initialState = {};

export default function temporaryReports(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_TEMPORARY_REPORT_SUCCESS: {
            return {
                [action.meta.id]: action.payload,
            };
        }

        case types.CREATE_TEMPORARY_REPORT_RESET:
            return initialState;

        default:
            return state;
    }
}
