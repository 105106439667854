import { isPlainObject, set, isFunction, isEmpty } from 'lodash';
import * as Log from 'config/loglevel';

function createSubItems(fieldName, value) {
    const subEntries = Object.entries(value);

    const subItems = subEntries.map(subEntry => {
        const [subFieldName, subFieldDefault] = subEntry;
        const fieldNames = Array.isArray(fieldName) ? fieldName.concat(subFieldName) : [fieldName, subFieldName];

        return [fieldNames, subFieldDefault];
    });

    return subItems;
}

export function formatEntityValue(valueFormatter, value) {
    return isFunction(valueFormatter) ? valueFormatter(value) : valueFormatter;
}

const incompleteTemplates = new WeakSet();

/*
    - iterate through template
    - get value for each field
        if value from API response is undefined or null,
        then use predefined default value,
        else use value from API
 */
export default function formatEntity({ entity: _entity, template }) {
    if (_entity === null) {
        return {};
    }

    if (!template) {
        const message = {
            title: 'utils/formatEntity',
            message: `Parameter 'template' is undefined, received entity`,
            payload: _entity,
        };
        Log.warn(message);
    }

    const templateEntries = Object.entries(template);
    const result = {};
    const stack = [];
    const entity = { ..._entity };

    // initialize stack
    stack.push(templateEntries.pop());

    while (stack.length) {
        const [fieldName, valueFormatter] = stack.pop();

        const entityValue = entity[fieldName];
        // delete used field
        // (we need to log out missing fields = those that weren't deleted)
        delete entity[fieldName];

        const value = formatEntityValue(valueFormatter, entityValue);

        if (isPlainObject(value)) {
            const subItems = createSubItems(fieldName, value);

            stack.push(...subItems);
        } else {
            set(result, fieldName, value);

            if (!stack.length && templateEntries.length) {
                stack.push(templateEntries.pop());
            }
        }
    }

    // some fields aren't included in a template,
    // log out warning with those missing fields
    //
    // use WeakSet to prevent multiple log messages
    // for the same template and for those same missing fields)
    if (!isEmpty(entity) && !incompleteTemplates.has(template)) {
        incompleteTemplates.add(template);

        const message = {
            title: 'utils/formatEntity',
            message: 'Some fields are missing in template.',
            payload: {
                missingFields: entity,
                templateKeys: Object.keys(template),
                sourceEntity: _entity,
            },
        };

        Log.warn(message);
    }

    return result;
}
