export const row = () => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    justifyContent: 'flex-end',
    gridColumnGap: '4px',
    marginTop: '16px',
});

export const text = ({ theme: { colors } }) => ({
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: 1.64,
    color: colors.text,
});
