import { React, PropTypes, UI, Consts, useModal } from '../../../dependencies';

const { Icons } = UI;

const OpenModalButton = ({ styles }) => {
    const modal = useModal(Consts.modals.CHANGE_PASSWORD);

    return (
        <button type="button" onClick={() => modal.open()}>
            <Icons.Update className={styles.pencilIcon} />
        </button>
    );
};

OpenModalButton.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default OpenModalButton;
