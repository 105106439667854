import { React, UI, childrenPropType, ErrorCard, withErrorBoundary } from '../../dependencies';

import { useFetchMemberships } from '../../hooks';

const { DataLoader } = UI;

const MembershipsFetcher = ({ children }) => {
    const { inProgress, success, error } = useFetchMemberships();

    return (
        <ErrorCard {...error}>
            <DataLoader show={inProgress || !success} minHeight={200}>
                {children}
            </DataLoader>
        </ErrorCard>
    );
};

MembershipsFetcher.propTypes = {
    children: childrenPropType.isRequired,
};

export default withErrorBoundary(MembershipsFetcher);
