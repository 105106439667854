import { React, PropTypes, connectFela, ButtonDefault, FormattedMessage, Icons } from '../../../dependencies';

import { Views } from '../../../constants';

import * as Styles from './CardHeader.styles';

function CardHeader({ view, setView, styles }) {
    if (view === Views.DOUGHNUT) {
        return (
            <div className={styles.buttons}>
                <ButtonDefault onClick={() => setView(Views.TAGS)}>
                    <FormattedMessage id="report.button.tags" />
                </ButtonDefault>

                <ButtonDefault onClick={() => setView(Views.TOGGLE_VIEW)}>
                    <FormattedMessage id="report.button.toggleView" />
                </ButtonDefault>
            </div>
        );
    }

    return (
        <div className={styles.nav}>
            <button type="button" onClick={() => setView(Views.DOUGHNUT)} className={styles.buttonIcon}>
                <Icons.Chevron2Left className={styles.iconArrowLeft} />
            </button>
            <span className={styles.viewName}>
                <FormattedMessage id="report.button.backToChart" />
            </span>
        </div>
    );
}

CardHeader.propTypes = {
    setView: PropTypes.func.isRequired,
    view: PropTypes.string.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(CardHeader);
