import { React, FelaComponent, ExpensesConsts, FormattedMessage, ButtonPrimary, Link } from '../../dependencies';

import * as styles from './Header.styles';

function Header() {
    return (
        <FelaComponent style={styles.header}>
            <ButtonPrimary>
                <Link to={ExpensesConsts.routePaths.EXPENSES_RECURRING_NEW}>
                    <FormattedMessage id="expenses.recurring.button.create" />
                </Link>
            </ButtonPrimary>
        </FelaComponent>
    );
}

export default Header;
