import { types } from '../actions';

export default function authUserMembershipsReducer(state = [], action) {
    switch (action.type) {
        case types.DELETE_MEMBERSHIP:
            return state.filter(id => id !== action.payload);

        case types.APPEND_MEMBERSHIP:
            return state.concat(action.payload);

        default:
            return state;
    }
}
