import {
    React,
    PropTypes,
    connectFela,
    FormattedMessage,
    FormattedNumber,
    config,
    TooltipWrapper,
    PriceTooltip,
    FelaComponent,
    useSelector,
    compose,
    NoData,
} from 'modules/reports/dependencies';

import { reportDoughnutChartSelector } from 'modules/reports/services/selectors';
import withCardLoader from 'modules/reports/HOC/withCardLoader';

import * as Styles from './ToggleView.styles';

function ToggleView({ styles }) {
    const { tableData: data, currency } = useSelector(reportDoughnutChartSelector);

    return (
        <div className={styles.list}>
            {data.length === 0 && <NoData extend={Styles.extendNoData} />}
            {data.map(({ value, tagNames, withVat, withoutVat }, index) => (
                <FelaComponent key={index} style={Styles.row} isLast={index >= data.length - 1}>
                    <div className={styles.label}>
                        {tagNames.length === 0 ? <FormattedMessage id="report.noTags" /> : tagNames.join(', ')}
                    </div>

                    <TooltipWrapper title={<PriceTooltip currency={currency} priceWithVat={withVat} />}>
                        <div className={styles.value}>
                            <FormattedNumber
                                {...{
                                    value: withoutVat,
                                    style: 'currency',
                                    currency,
                                    ...config.currency,
                                }}
                            />
                            {' ('}
                            <FormattedNumber
                                {...{
                                    value,
                                    style: 'percent',
                                }}
                            />
                            {')'}
                        </div>
                    </TooltipWrapper>
                </FelaComponent>
            ))}
        </div>
    );
}

ToggleView.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default compose(
    connectFela(Styles),
    withCardLoader({
        extend: Styles.extendCardLoader,
    }),
)(ToggleView);
