import { React, PropTypes, childrenPropType } from '../../dependencies';

const ReportGrid = ({ styles, children }) => <div className={styles.grid}>{children}</div>;

ReportGrid.propTypes = {
    styles: PropTypes.shape().isRequired,
    children: childrenPropType.isRequired,
};

export default ReportGrid;
