import types from './types';
import { VISIBILITY_DURATION } from '../../config';
import { Level } from '../../constants';

export const addMessageRequest = (id, payload = {}, meta = {}) => ({
    type: types.ADD_MESSAGE_REQUEST,
    payload,
    meta: {
        duration: VISIBILITY_DURATION,
        ...meta,
        id,
    },
});

export const addMessageResolve = (payload, meta) => ({
    type: types.ADD_MESSAGE_RESOLVE,
    payload,
    meta,
});

export const hideMessage = id => ({
    type: types.HIDE_MESSAGE,
    meta: { id },
});

export const removeMessage = id => ({
    type: types.REMOVE_MESSAGE,
    meta: { id },
});

export const clearMessages = () => ({
    type: types.CLEAR_MESSAGES,
});

export const displaySuccessMessage = (message, options) => ({
    type: types.DISPLAY_SUCCESS_MESSAGE,
    payload: {
        message,
        level: Level.SUCCESS,
    },
    meta: options,
});

export const displayErrorMessage = (message, options) => ({
    type: types.DISPLAY_ERROR_MESSAGE,
    payload: {
        message,
        level: Level.ERROR,
    },
    meta: options,
});
