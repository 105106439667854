export const listItemWrapper = () => ({
    width: '100%',
});

export const listItem = ({ theme: { colors } }) => ({
    display: 'grid',
    gridTemplateColumns: '1.5rem 1fr',
    gridColumnGap: 18,
    alignItems: 'center',
    padding: '16px 22px',
    borderBottom: `1px solid ${colors.blueGrey1}`,
    color: colors.text,
    textAlign: 'left',
    fontSize: '15px',
    letterSpacing: '0.15px',
    lineHeight: 1.56,
    whiteSpace: 'nowrap',

    onHover: {
        color: colors.textHover,
        fill: colors.textHover,
    },
});

export const icon = () => ({
    fill: 'currentColor',
    height: '1.25rem',
    margin: '0 auto',
});
