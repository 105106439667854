import { React, useLocation, useDispatch, pagingActions, qs, Consts } from '../dependencies';

export function useSetInitPageParam(groupId) {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = qs.parse(location.search);
    const page = Number.parseInt(searchParams.page, 10);

    React.useEffect(() => {
        if (!Number.isNaN(page)) {
            const payload = {
                entityKey: Consts.entityKeys.EXPENSES,
                itemId: groupId,
                page,
            };

            dispatch(pagingActions.setPage(payload));
        }
        // eslint-disable-next-line
    }, []);
}
