import { connect, ExpensesSelectors, ExpensesActions, goBack, ExpenseTriggers, Users } from '../dependencies';

import Nav from '../components/Nav';

const mapStateToProps = (state, { expenseId, expenseTriggerId }) => {
    const { number, authorId } = ExpensesSelectors.expenseSelector(state, expenseId);
    const expenseTriggerName = ExpenseTriggers.selectors.expenseTriggerNameSelector(state, expenseTriggerId);
    const authorFullName = Users.selectors.userFullNameSelector(state, authorId);

    return {
        expenseId,
        expenseNumber: number,
        expenseTriggerName,
        authorFullName,
        canEditExpense: true,
    };
};

const mapDispatchToProps = (dispatch, { expenseId }) => ({
    onExpenseDuplicate() {
        dispatch(ExpensesActions.duplicateExpenseRequest(expenseId));
    },
    goBack() {
        dispatch(goBack());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
