import { ReduxUtils, Consts } from '../../dependencies';

const { apiTypes, entityKeys } = Consts;

const { apiSelector } = ReduxUtils;

export const fetchServiceKeysApiSelector = state => apiSelector(state, entityKeys.SERVICE_KEYS, apiTypes.FETCH);

export const fetchServiceKeyApiSelector = (state, id) => apiSelector(state, entityKeys.SERVICE_KEY, apiTypes.FETCH, id);

export const updateServiceKeyApiSelector = (state, id) =>
    apiSelector(state, entityKeys.SERVICE_KEY, apiTypes.UPDATE, id);

export const deleteServiceKeyApiSelector = (state, id) =>
    apiSelector(state, entityKeys.SERVICE_KEY, apiTypes.DELETE, id);

export const createServiceKeyApiSelector = state => apiSelector(state, entityKeys.SERVICE_KEY, apiTypes.CREATE);
