import { React, PropTypes, TextField, UI, isSubmittingSelector, useSelector } from '../../dependencies';

const { InputWrapper, Icons } = UI;

const { EyeOff, EyeOn } = Icons;

const PasswordField = ({ styles, theme, rules, name, autoComplete, formId, ...rest }) => {
    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const submitting = useSelector(isSubmittingSelector(formId));

    return (
        <InputWrapper style={rules.inputWrapper}>
            <TextField
                {...rest}
                name={name}
                type={passwordVisible ? 'text' : 'password'}
                autoComplete={autoComplete}
                disabled={submitting}
            />
            <button
                type="button"
                onClick={() => setPasswordVisible(!passwordVisible)}
                className={passwordVisible ? styles.buttonOn : styles.buttonOff}
            >
                {passwordVisible ? <EyeOn className={styles.icon} /> : <EyeOff className={styles.icon} />}
            </button>
        </InputWrapper>
    );
};

PasswordField.propTypes = {
    styles: PropTypes.shape().isRequired,
    name: PropTypes.string.isRequired,
    autoComplete: PropTypes.string.isRequired,
    formId: PropTypes.string.isRequired,
};

export default PasswordField;
