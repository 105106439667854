import { React, PropTypes, childrenPropType } from '../../../dependencies';

const Buttons = ({ styles, children }) => <div className={styles.buttonsGroup}>{children}</div>;

Buttons.propTypes = {
    styles: PropTypes.shape().isRequired,
    // eslint-disable-next-line
    buttonsCount: PropTypes.number.isRequired,
    children: childrenPropType.isRequired,
};

export default Buttons;
