import { React, FelaComponent, PropTypes, FormattedMessage } from '../../dependencies';

import { updateModalIdFactory, deleteModalIdFactory } from '../../utils';

import UpdateSupplier from '../../containers/UpdateSupplier';
import DeleteSupplier from '../../containers/DeleteSupplier';

import style from './SupplierListItem.style';

const propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export const supplierShape = PropTypes.shape(propTypes);

const SupplierListItem = ({ name, id, isContributor }) => (
    <FelaComponent style={style} as="ul">
        <div>{name}</div>
        <div>
            <UpdateSupplier modalId={updateModalIdFactory(id)} id={id} />
            {!isContributor && (
                <DeleteSupplier modalId={deleteModalIdFactory(id)} id={id}>
                    <FormattedMessage
                        id="suppliers.delete.modal.title"
                        values={{
                            name,
                        }}
                    />
                </DeleteSupplier>
            )}
        </div>
    </FelaComponent>
);

SupplierListItem.propTypes = {
    ...propTypes,
    isContributor: PropTypes.bool.isRequired,
};

export default SupplierListItem;
