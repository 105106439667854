import { React, PropTypes, ErrorCard, useDispatch, push } from '../../dependencies';

import { useFetchExpenses } from '../../hooks';

import Loader from '../Loader';

import ListContent from './ListContent';

function List({ groupId }) {
    const dispatch = useDispatch();
    const { inProgress, success, error } = useFetchExpenses(groupId);
    const dispatchPush = location => dispatch(push(location), []);

    return (
        <ErrorCard {...error}>
            <Loader show={inProgress || !success}>
                <ListContent groupId={groupId} push={dispatchPush} />
            </Loader>
        </ErrorCard>
    );
}

List.propTypes = {
    groupId: PropTypes.string.isRequired,
};

export default List;
