import { difference } from '../../../dependencies';
import { types } from '../../actions';

const initialState = {
    byId: {},
    ids: [],
};

export default function serviceKeysReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_SERVICE_KEYS_SUCCESS: {
            const { ids, byId } = action.payload;
            return {
                ...state,
                byId,
                ids,
            };
        }

        case types.CREATE_SERVICE_KEY_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: action.payload,
                },
            };

        case types.UPDATE_SERVICE_KEY_SUCCESS:
        case types.FETCH_SERVICE_KEY_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.id]: action.payload,
                },
            };

        case types.DELETE_SERVICE_KEY_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.id]: undefined,
                },
                ids: difference(state.ids, [action.meta.id]),
            };

        default:
            return state;
    }
}
