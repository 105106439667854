import types from './types';

export const reportDidUpdate = (slug, searchParams, meta) => ({
    type: types.REPORT_DID_UPDATE,
    meta: {
        forceUpdate: false,
        ...meta,
    },
    payload: {
        slug,
        searchParams,
    },
});

export const reportWillUnmount = slug => ({
    type: types.REPORT_WILL_UNMOUNT,
    payload: {
        slug,
    },
});
