import types from '../actionTypes';

export const createTagRequest = payload => ({
    type: types.CREATE_TAG_REQUEST,
    payload,
});

export const createTagSuccess = (name, payload) => ({
    type: types.CREATE_TAG_SUCCESS,
    meta: { name },
    payload,
});

export const createTagFailure = error => ({
    type: types.CREATE_TAG_FAILURE,
    error,
});
