import { React, Pagination, ExpensesList, Consts } from '../../dependencies';

const { EXPENSES } = Consts.entityKeys;
const { SEARCH } = Consts.expensesGroups;

function SearchResults() {
    return (
        <>
            <ExpensesList groupId={Consts.expensesGroups.SEARCH} />
            <Pagination entityKey={EXPENSES} itemId={SEARCH} />
        </>
    );
}

export default SearchResults;
