import { connect, ReduxUtils, Consts, DeleteModal } from '../dependencies';

import { deleteTagRequest } from '../services/actions';

const { TAG } = Consts.entityKeys;
const { DELETE } = Consts.apiTypes;

export default connect(
    (state, { id }) => {
        const { inProgress: deleting, error } = ReduxUtils.apiSelector(state, TAG, DELETE, id);
        return {
            deleting,
            error,
        };
    },
    (dispatch, { id }) => ({
        handleDelete: () => {
            dispatch(deleteTagRequest(id));
        },
    }),
)(DeleteModal);
