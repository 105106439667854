import { ExpensesSelectors, createSelector, Suppliers, Utils, Selectors, Tags } from '../../dependencies';

export const supplierSelector = createSelector(
    ExpensesSelectors.expenseSelector,
    Suppliers.selectors.selectSuppliers,
    (expense, suppliers) => {
        return suppliers.byId[expense.supplierId];
    },
);

export const expenseDetailHeaderSelector = createSelector(
    [ExpensesSelectors.expenseSelector, supplierSelector, Selectors.localeSelector],
    (expense, supplier, locale) => {
        const {
            issuedOn,
            receivedOn,
            taxableFulfillmentDue,
            originalNumber,
            taxDeductible,
            documentType,
            vatFromBase,
            paymentMethod,
        } = expense;

        const { name, street, city, zipCode, registrationNumber, vatNumber } = supplier ?? {};

        return {
            supplier: supplier
                ? {
                      name,
                      address: `${street}\n${city}, ${zipCode}`,
                      registrationNumber,
                      vatNumber,
                  }
                : undefined,
            issuedOn: Utils.formatDate(locale, issuedOn),
            receivedOn: Utils.formatDate(locale, receivedOn),
            taxableFulfillmentDue: Utils.formatDate(locale, taxableFulfillmentDue),
            originalNumber,
            taxDeductible: Boolean(taxDeductible),
            documentType,
            vatFromBase: Boolean(vatFromBase),
            paymentMethod,
        };
    },
);

export const expenseDetailBodySelector = createSelector([ExpensesSelectors.expenseSelector], expense => {
    const { id, items, currency, files, hasFiles } = expense;

    return {
        id,
        currency,
        items: items.map(({ name, quantity, unitPriceWithVat, unitPriceWithoutVat, vatRate }) => ({
            name,
            vatRate,
            priceWithoutVatTotal: unitPriceWithoutVat * quantity,
            priceWithVatTotal: unitPriceWithVat * quantity,
        })),
        hasAttachment: hasFiles || (files && files.length > 0),
    };
});

export const expenseFilesSelector = createSelector(ExpensesSelectors.expenseSelector, expense => {
    const { files } = expense;

    // filter out external links
    // (file is external link if: file.file === null)
    return files
        .filter(file => Boolean(file.file))
        .map(({ id, file, publicUrl }) => ({
            id: String(id),
            name: file.split('/').pop() || '',
            url: publicUrl,
            localUrl: false,
        }));
});

export const externalLinkSelector = createSelector(ExpensesSelectors.expenseSelector, expense => {
    const { files } = expense;

    return (
        files
            .filter(fileItem => fileItem.file === null)
            .map(fileItem => fileItem.publicUrl)
            .pop() || ''
    );
});

export const expenseTagsSelector = createSelector(
    [ExpensesSelectors.expenseSelector, Tags.selectors.selectTags],
    (expense, tags) => {
        return expense.tags.map(tagId => tags.byId[tagId]);
    },
);
