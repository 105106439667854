import {
    takeLatestRequest,
    sagaEffects,
    authApi,
    config,
    Log,
    ExpensesActions,
    Normalizers,
    ErrorsUtils,
    Consts,
    Tags,
} from '../../dependencies';

import { types, fetchDashboardSuccess, fetchDashboardFailure, fetchDashboardInvalidate } from '../actions';

const { DASHBOARD } = Consts.expensesGroups;
const { put } = sagaEffects;

function* fetchDashboard(action, cancelToken) {
    try {
        const { data } = yield* authApi.get(config.api.dashboard, {
            cancelToken,
        });

        const { result, entities } = Normalizers.dashboard(data);
        const { expenses, dashboard, tags } = entities;
        const { sample, ...report } = dashboard[result];

        yield put(Tags.actions.addTags(Tags.utils.formatPayload(tags)));

        const expensesIds = sample.map(id => String(id));

        yield put(
            ExpensesActions.fetchExpensesSuccess(DASHBOARD, {
                byId: expenses,
                ids: expensesIds,
            }),
        );

        yield put(fetchDashboardSuccess(report));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e);

        yield put(fetchDashboardFailure(errorMessage));
    }
}

export default function* () {
    const actionTypes = {
        REQUEST: types.FETCH_DASHBOARD_REQUEST,
        cancelTask: fetchDashboardInvalidate,
    };

    yield takeLatestRequest(actionTypes, fetchDashboard);
}
