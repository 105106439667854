import { ReduxUtils } from '../../dependencies';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: 'cache',
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        types: ['STORE_GROUP_VALUES', 'DELETE_GROUP_VALUES'],
    }),
});
