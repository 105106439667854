import { React, PropTypes, CardContent, CardBackground } from '../../dependencies';

import { useReportId } from '../../hooks/ReportControl';

import Header from './Header';
import Buttons, { RemoveReport, CreatePreset, UpdateReport } from './Buttons';
import SelectPresetInput from './SelectPresetInput';

function ReportControl({ styles }) {
    const [reportId, setReportId] = useReportId();
    const reportIsSelected = Boolean(reportId);

    return (
        <CardBackground>
            <CardContent header={<Header />}>
                <div className={styles.wrapper}>
                    <SelectPresetInput presetId={reportId} onPresetChange={setReportId} />

                    <Buttons buttonsCount={reportId ? 2 : 1}>
                        {reportIsSelected ? <UpdateReport reportId={reportId} /> : <CreatePreset />}
                        {reportIsSelected && <RemoveReport reportId={reportId} />}
                    </Buttons>
                </div>
            </CardContent>
        </CardBackground>
    );
}

ReportControl.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default ReportControl;
