import { ReduxUtils } from 'modules/expenses/dependencies';
import { types } from 'modules/expenses/services/actions';

const actionTypes = {
    REQUEST: types.FETCH_EXPENSE_REQUEST,
    SUCCESS: types.FETCH_EXPENSE_SUCCESS,
    FAILURE: types.FETCH_EXPENSE_FAILURE,
    CANCEL: types.FETCH_EXPENSE_CANCEL,
    RESET: types.DELETE_EXPENSE_SUCCESS,
};

const basicReducer = ReduxUtils.basicApiReducer({
    actionTypes,
});

export default ReduxUtils.containerReducer({
    childReducer: basicReducer,
    actionTypes: Object.values(actionTypes),
    selectors: {
        itemId: action => action.meta.id,
    },
});
