import types from './types';

export const createServiceKeyRequest = requestData => ({
    type: types.CREATE_SERVICE_KEY_REQUEST,
    payload: requestData,
});

export const createServiceKeySuccess = (id, responseData) => ({
    type: types.CREATE_SERVICE_KEY_SUCCESS,
    meta: { id },
    payload: responseData,
});

export const createServiceKeyFailure = error => ({
    type: types.CREATE_SERVICE_KEY_FAILURE,
    error,
});

export const createServiceKeyInvalidate = () => ({
    type: types.CREATE_SERVICE_KEY_CANCEL,
});
