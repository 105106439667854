import { React, PropTypes, childrenPropType, connectFela } from '../../dependencies';

import * as Styles from './Card.styles';

function CardContent({ header, children, styles }) {
    return (
        <div className={styles.card}>
            {header && <div className={styles.header}>{header}</div>}
            {children}
        </div>
    );
}

CardContent.propTypes = {
    header: childrenPropType,
    children: childrenPropType.isRequired,
    styles: PropTypes.shape().isRequired,
};

CardContent.defaultProps = {
    header: null,
};

export default connectFela(Styles)(CardContent);
