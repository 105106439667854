import { React, Modal, useModal, Consts } from '../../dependencies';

import ChangePasswordForm from '../../containers/ChangePasswordForm';

const ChangePasswordModal = () => {
    const modal = useModal(Consts.modals.CHANGE_PASSWORD);

    return (
        <Modal visible={modal.isOpen} onCancel={modal.close}>
            <ChangePasswordForm onCancel={modal.close} />
        </Modal>
    );
};

export default ChangePasswordModal;
