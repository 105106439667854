import { sagaEffects, AUTH_SESSION_START, ReactGA } from '../../../dependencies';

import { Events } from '../../../config';
import { trackAuthSessionStart } from '../../actions';
import { dataAnalysisPermission } from '../../selectors';
import { UserId } from '../utils';

const { takeEvery, put, select } = sagaEffects;

export default function* () {
    yield takeEvery(AUTH_SESSION_START, function* () {
        yield put(trackAuthSessionStart());

        const hasPermission = yield select(dataAnalysisPermission);

        if (hasPermission) {
            const userId = yield UserId.get();
            ReactGA.set({
                userId,
            });
        } else {
            yield UserId.remove();
        }

        Events.login();
    });
}
