import { React, PropTypes } from '../../../../dependencies';

import File from '../File';

function Files({ files, removeFile, fileIsRemovable }) {
    return (
        <>
            {files.map((file, index) => (
                <File
                    {...{
                        ...file,
                        key: file.id || file.url || index,
                        onRemove: fileIsRemovable ? () => removeFile({ index, ...file }) : undefined,
                    }}
                />
            ))}
        </>
    );
}

Files.propTypes = {
    files: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            localUrl: PropTypes.bool.isRequired,
        }),
    ).isRequired,
    removeFile: PropTypes.func,
    fileIsRemovable: PropTypes.bool,
};

Files.defaultProps = {
    removeFile: undefined,
    fileIsRemovable: true,
};

export default Files;
