import { config, authApi, Log, sagaEffects, ErrorsUtils } from '../../dependencies';

import { types, deleteServiceKeySuccess, deleteServiceKeyFailure } from '../actions';

const { put, takeEvery } = sagaEffects;

function* deleteServiceKey(action) {
    const { id } = action.meta;

    try {
        yield* authApi.delete(config.api.serviceKey, {
            uriParams: { id },
        });

        yield put(deleteServiceKeySuccess(id));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e);

        yield put(deleteServiceKeyFailure(id, errorMessage));
    }
}

export default function* deleteServiceKeyWatcher() {
    yield takeEvery(types.DELETE_SERVICE_KEY_REQUEST, deleteServiceKey);
}
