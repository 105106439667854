export const title = () => ({
    marginBottom: '2.5rem',
});

export const footer = () => ({
    marginTop: '2.5rem',
    display: 'grid',
    justifyContent: 'flex-end',
    gridColumnGap: '1rem',
    gridTemplateColumns: 'auto auto',
});

export const hint = ({ theme: { colors } }) => ({
    letterSpacing: '0.1px',
    lineHeight: 1.25,
    fontSize: '0.75rem',
    color: colors.blueGrey4,
    marginTop: 4,
    marginLeft: 12,
});
