import { strictObjectAccess } from '@ackee/redux-utils';
import { setDefaultValue } from './helpers';

const defaults = strictObjectAccess({
    string: setDefaultValue(''),
    zero: setDefaultValue(0),
    true: setDefaultValue(true),
    false: setDefaultValue(false),
    array: setDefaultValue([]),
    null: setDefaultValue(null),
    date: setDefaultValue(new Date().toISOString()),

    reports: {
        hiddenTags: (value = []) => value.map(String),
        filters: (filters = {}) => {
            const { from, to, relativePeriod, supplierIds, excludeTags, includeTags } = filters;

            return {
                relativePeriod,
                from: relativePeriod ? null : from,
                to: relativePeriod ? null : to,
                supplierId: Array.isArray(supplierIds) && supplierIds.length > 0 ? supplierIds.map(String)[0] : '',
                excludeTags: Array.isArray(excludeTags) ? excludeTags.map(String) : [],
                includeTags: Array.isArray(includeTags) ? includeTags.map(String) : [],
            };
        },
    },
});

export default defaults;
