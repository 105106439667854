export const row = () => ({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '260px 1fr',
    gridColumnGap: 16,
    '& .ant-form-item': {
        width: 260,
        maxWidth: '100%',
    },
});

export const button = ({ theme: { colors } }) => ({
    color: colors.text,
});
