import { React, PropTypes, Page, Container } from '../../dependencies';

import Form from '../../containers/Form';
import Summary from '../../containers/Summary';
import RoundingTotal from '../../containers/RoundingTotal';
import Nav from '../../containers/Nav';

function RecurringExpenseEdit({ expenseId, expenseTriggerId }) {
    return (
        <Container>
            <Page>
                <Nav {...{ expenseId }} />
                <Form
                    {...{
                        expenseId,
                        expenseTriggerId,
                        Summary,
                        RoundingTotal,
                    }}
                />
            </Page>
        </Container>
    );
}

RecurringExpenseEdit.propTypes = {
    expenseId: PropTypes.string.isRequired,
    expenseTriggerId: PropTypes.string.isRequired,
};

const arePropsEqual = (prevProps, { expenseId, expenseTriggerId }) =>
    prevProps.expenseId === expenseId && prevProps.expenseTriggerId === expenseTriggerId;

export default React.memo(RecurringExpenseEdit, arePropsEqual);
