import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { noop } from 'lodash';
import { mapProps } from 'recompose';

const defaultConfig = {
    routeDidMount: noop,
    shouldRouteUpdate: () => true,
    routeDidUpdate: noop,
    routeWillUnmount: noop,
};

export default function routeHooks(customConfig) {
    const config = {
        ...defaultConfig,
        ...customConfig,
    };

    const mapOwnProps = ownProps => {
        const { history, ...rest } = ownProps;

        return rest;
    };

    return WrappedComponent => {
        class RouteHooks extends React.Component {
            componentDidMount() {
                config.routeDidMount(this.props);
            }

            componentDidUpdate(prevProps) {
                if (config.shouldRouteUpdate(prevProps, this.props)) {
                    config.routeDidUpdate(this.props);
                }
            }

            componentWillUnmount() {
                config.routeWillUnmount(this.props);
            }

            render() {
                return <WrappedComponent {...this.props} />;
            }
        }

        return compose(
            withRouter,
            mapProps(mapOwnProps),
        )(RouteHooks);
    };
}
