import { React, PropTypes, FormFields, UI, FormattedMessage, Alert } from '../../../dependencies';

import RoleField from './RoleField';

const { EmailField } = FormFields;
const { ButtonDefault, ButtonPrimary } = UI;

const CreateMembershipForm = ({ styles, form, handleSubmit, error, onCancel, submitting }) => {
    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.header}>
                <h2 className={styles.title}>
                    <FormattedMessage id="memberships.create.title" />
                </h2>
            </div>

            {error && (
                <Alert className={styles.error} showIcon type="error" message={<FormattedMessage {...error} />} />
            )}

            <div className={styles.fields}>
                <RoleField formId={form} />
                <EmailField formId={form} />
            </div>

            <div className={styles.footer}>
                <ButtonDefault onClick={onCancel} disabled={submitting}>
                    <FormattedMessage id="memberships.create.cancel" />
                </ButtonDefault>

                <ButtonPrimary htmlType="submit" loading={submitting}>
                    <FormattedMessage id="memberships.create.submit" />
                </ButtonPrimary>
            </div>
        </form>
    );
};

CreateMembershipForm.propTypes = {
    styles: PropTypes.shape().isRequired,
    handleSubmit: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    error: PropTypes.shape(),
    onCancel: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

CreateMembershipForm.defaultProps = {
    error: null,
};

export default CreateMembershipForm;
