import { formatEntities, entitiesTemplates } from '../../dependencies';

import types from './types';

export const fetchReportsRequest = () => ({
    type: types.FETCH_REPORTS_REQUEST,
});

export const fetchReportsSuccess = data => {
    return {
        type: types.FETCH_REPORTS_SUCCESS,
        payload: {
            ...data,
            byId: formatEntities({
                entities: data.byId,
                template: entitiesTemplates.reportListItem,
            }),
        },
    };
};

export const fetchReportsFailure = error => ({
    type: types.FETCH_REPORTS_FAILURE,
    error,
});

export const fetchReportsInvalidate = () => ({
    type: types.FETCH_REPORTS_CANCEL,
});

export const fetchReportsReset = () => ({
    type: types.FETCH_REPORTS_RESET,
});
