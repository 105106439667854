export const item = ({ theme: { colors } }) => ({
    display: 'grid',
    gridTemplateColumns: '6fr 1fr 1fr',
    borderBottom: `1px solid ${colors.blueGrey1}`,
    gridColumnGap: '80px',
    padding: '15px 0 13px',
});

export const items = () => ({
    marginBottom: 64,
});
