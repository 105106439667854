import { compose, injectIntl, pure, connect, Suppliers, config, change } from '../../dependencies';
import SupplierField from '../../components/Fields/Supplier/SupplierField';

const formId = config.forms.createExpense;

const mapStateToProps = state => {
    return {
        options: Suppliers.selectors.supplierFormOptionsSelector(state),
    };
};

const mapDispatchToProps = dispatch => ({
    onSearch(value) {
        dispatch(change(formId, 'new-supplier', value));
    },
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    pure(),
    injectIntl,
)(SupplierField);
