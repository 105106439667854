import { config, authApi, takeLatestRequest, Log, sagaEffects, ErrorsUtils } from '../../dependencies';

import {
    deleteExpenseTriggerSuccess,
    deleteExpenseTriggerFailure,
    deleteExpenseTriggerInvalidate,
    types,
} from '../actions';

const { put } = sagaEffects;

function* deleteExpenseTrigger(action, cancelToken) {
    const { id } = action.meta;

    try {
        const requestConfig = {
            uriParams: { id },
            cancelToken,
        };

        yield* authApi.delete(config.api.expenseTrigger, requestConfig);

        yield put(deleteExpenseTriggerSuccess(id));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.expenseTrigger.api.delete',
                values: { id },
            },
        });

        yield put(deleteExpenseTriggerFailure(id, errorMessage));
    }
}

export default function* deleteExpenseTriggerWatcher() {
    const actionTypes = {
        REQUEST: types.DELETE_EXPENSE_TRIGGER_REQUEST,
        cancelTask: deleteExpenseTriggerInvalidate,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, deleteExpenseTrigger);
}
