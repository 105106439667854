import { Petrus } from '../../dependencies';
import { types } from '../actions';

import memberships from './memberships';

export default function authUserReducer(state = null, action) {
    switch (action.type) {
        case types.SET_AUTH_USER:
            return action.payload;

        case types.UPDATE_AUTH_USER:
            return {
                ...state,
                ...action.payload,
            };

        case Petrus.AUTH_SESSION_END:
            return null;

        case types.DELETE_MEMBERSHIP:
        case types.APPEND_MEMBERSHIP:
            return {
                ...state,
                memberships: memberships(state.memberships, action),
            };

        default:
            return state;
    }
}
