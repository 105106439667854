export default function transformCredentials({ user, credentials }) {
    const { accessToken, expiresIn, refreshToken } = credentials;
    const expirationNumericDate = Date.now() + expiresIn * 1000;
    const expiration = Number.isNaN(expirationNumericDate) ? null : new Date(expirationNumericDate).toISOString();

    return {
        accessToken: {
            userId: user.id,
            token: accessToken,
            expiration,
        },
        refreshToken: {
            token: refreshToken,
        },
    };
}
