import React from 'react';

const ArrowLeft = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-2.000000, -2.000000)" fill="#10092D">
                <path d="M12,22 L13.435,20.606 L5.829,13 L22,13 L22,11 L5.829,11 L13.414,3.414 L12,2 C8.339,5.661 5.504,8.496 2,12 C5.966,15.966 3.167,13.166 12,22" />
            </g>
        </g>
    </svg>
);

export default ArrowLeft;
