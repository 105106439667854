import { React, PropTypes, RadioField, injectIntl, Consts, InputWrapper } from '../../../dependencies';

import * as styles from './VatMode.styles';

function VatMode({ intl, disabled }) {
    const options = VatMode.translateOptions(intl);

    return (
        <InputWrapper style={styles.vatMode}>
            <RadioField
                {...{
                    name: 'vatMode',
                    label: intl.formatMessage({
                        id: 'expense.form.vatMode.label',
                    }),
                    options,
                    disabled,
                }}
            />
        </InputWrapper>
    );
}

VatMode.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

VatMode.translateOptions = ({ formatMessage }) => {
    const prefix = 'expense.form.vatMode.option';
    const ids = Object.values(Consts.vatMode);

    return ids.map(id => ({
        value: id,
        label: formatMessage({ id: `${prefix}.${id}` }),
    }));
};

export default injectIntl(VatMode);
