export const container = () => ({
    position: 'absolute',
    top: 260,
    left: 0,
    padding: '0 16px',
});

export const label = ({ theme: { colors } }) => ({
    fontSize: '0.875rem',
    lineHeight: 1.64,
    letterSpacing: '0.1px',
    color: colors.primary,
});

export const input = () => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 96px)',
    gridColumnGap: 8,
    marginTop: 4,

    '& > .ant-input': {
        height: 32,
    },
});

export const error = ({ theme: { colors } }) => ({
    fontSize: '0.75rem',
    color: colors.primary,
    marginTop: 4,
});
