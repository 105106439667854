import { React, PropTypes, FelaComponent, injectIntl, ExpensesConfig } from '../../../../dependencies';

import * as styles from './ItemFields.styles';

import Row from '../Row';
import RowPlaceholder from '../RowPlaceholder';

const { expenseItemInitialValues } = ExpensesConfig;

function ItemFields({ fields, intl, mayAddNewRow, quantityDataAreVisible }) {
    return (
        <>
            <FelaComponent {...{ style: styles.grid, quantityDataAreVisible }}>
                {fields.map((member, index) => (
                    <Row
                        {...{
                            member,
                            key: index,
                            removable: fields.length > 1,
                            onRemove() {
                                fields.remove(index);
                            },
                            intl,
                            showLabels: true,
                            quantityDataAreVisible,
                        }}
                    />
                ))}
            </FelaComponent>
            {mayAddNewRow && (
                <RowPlaceholder
                    style={styles.grid}
                    {...{
                        onClick() {
                            fields.push(expenseItemInitialValues);
                        },
                        quantityDataAreVisible,
                    }}
                />
            )}
        </>
    );
}

ItemFields.propTypes = {
    intl: PropTypes.shape().isRequired,
    fields: PropTypes.shape().isRequired,
    mayAddNewRow: PropTypes.bool.isRequired,
    quantityDataAreVisible: PropTypes.bool.isRequired,
};

export default injectIntl(ItemFields);
