import { React, useDispatch, useSelector, filterSelectors } from '../dependencies';
import { reportDidUpdate, reportWillUnmount } from '../services/actions';

import { useCurrentReportSlug } from './useCurrentReportId';

function useReportParams() {
    const reportParams = useSelector(filterSelectors.decodeReportFilterParamsSelector);

    return [reportParams, JSON.stringify(reportParams)];
}

export function useReflectReportLifeCycle() {
    const dispatch = useDispatch();
    const slug = useCurrentReportSlug();
    const [reportParams, stringifiedParams] = useReportParams();

    const updateDep = React.useMemo(() => {
        return [slug, reportParams];
        // eslint-disable-next-line
    }, [slug, stringifiedParams]);

    React.useEffect(() => {
        dispatch(reportDidUpdate(...updateDep));
        // eslint-disable-next-line
    }, updateDep);

    React.useEffect(() => {
        return () => {
            dispatch(reportWillUnmount(slug));
        };
        // eslint-disable-next-line
    }, []);

    return () => {
        dispatch(
            reportDidUpdate(slug, reportParams, {
                // This action is called on retry,
                // so we need to skip isEqual(prev, next)
                // check in reportLifeCycle saga.
                forceUpdate: true,
            }),
        );
    };
}
