import { React, FelaComponent, FormattedMessage } from '../../../dependencies';

import style from './Empty.style';

const Empty = () => (
    <FelaComponent style={style}>
        <FormattedMessage id="exports.recurring.empty.title" tagName="p" />
        <FormattedMessage id="exports.recurring.empty.desc" tagName="p" />
    </FelaComponent>
);

export default Empty;
