// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"boxShadows.less"}]);
// Exports
exports.locals = {
	"dropdown": "0 2px 8px 0 rgba(0, 0, 0, 0.15)",
	"page": "0 -2px 5px 0 rgba(0, 0, 0, 0.1)",
	"alertSuccess": "0 2px 8px 0 rgba(174, 224, 198, 0.7)",
	"alertFailure": "0 2px 8px 0 rgba(210, 126, 126, 0.4)"
};
module.exports = exports;
