import Loglevel from 'loglevel';
import * as Sentry from '@sentry/react';
import { isEnvProduction } from 'constants/index';

isEnvProduction ? Loglevel.disableAll() : Loglevel.enableAll();

export const { trace, debug } = Loglevel;

export const info = message => {
    Loglevel.info(message);
};

export const warn = message => {
    Loglevel.warn(message);
};

export const error = message => {
    Loglevel.error(message);
    Sentry.captureException(message);
};
