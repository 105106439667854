import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from './types';

export const fetchReportRequest = id => ({
    type: types.FETCH_REPORT_REQUEST,
    meta: { id },
});

export const fetchReportSuccess = (id, report) => {
    return {
        type: types.FETCH_REPORT_SUCCESS,
        meta: { id },
        payload: formatEntity({
            entity: report,
            template: entitiesTemplates.report,
        }),
    };
};

export const fetchReportFailure = (id, error) => ({
    type: types.FETCH_REPORT_FAILURE,
    meta: { id },
    error,
});

export const fetchReportInvalidate = id => ({
    type: types.FETCH_REPORT_CANCEL,
    meta: { id },
});

export const fetchReportReset = id => ({
    type: types.FETCH_REPORT_RESET,
    meta: { id },
});
