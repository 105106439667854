import { sagaEffects } from '../../../dependencies';

import submitCreateExportForm from './submitCreateExportForm';
import submitUpdateExportForm from './submitUpdateExportForm';
import deleteExportWithServiceKey from './deleteExportWithServiceKey';

const { all } = sagaEffects;

export default function* () {
    yield all([submitCreateExportForm(), submitUpdateExportForm(), deleteExportWithServiceKey()]);
}
