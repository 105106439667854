import { formatEntities, entitiesTemplates } from '../../dependencies';
import types from './types';

export const fetchExpenseTriggersRequest = ({ params = {} } = {}) => ({
    type: types.FETCH_EXPENSE_TRIGGERS_REQUEST,
    payload: {
        params,
    },
});

export const fetchExpenseTriggersSuccess = ({ ids, byId, ...payloadRest }, meta = {}) => ({
    type: types.FETCH_EXPENSE_TRIGGERS_SUCCESS,
    meta,
    payload: {
        ...payloadRest,
        ids,
        byId: formatEntities({
            template: entitiesTemplates.expenseTrigger,
            entities: byId,
        }),
    },
});

export const fetchExpenseTriggersFailure = error => ({
    type: types.FETCH_EXPENSE_TRIGGERS_FAILURE,
    error,
});

export const fetchExpenseTriggersInvalidate = () => ({
    type: types.FETCH_EXPENSE_TRIGGERS_CANCEL,
});

export const fetchExpenseTriggersReset = () => ({
    type: types.FETCH_EXPENSE_TRIGGERS_RESET,
});
