import { React, PropTypes, injectIntl, TextAreaField, InputWrapper } from '../../../dependencies';

import * as styles from './Note.styles';

function Note({ intl: { formatMessage }, disabled }) {
    return (
        <InputWrapper style={styles.note}>
            <TextAreaField
                {...{
                    name: 'note',
                    placeholder: formatMessage({
                        id: 'expense.form.note.placeholder',
                    }),
                    disabled,
                }}
            />
        </InputWrapper>
    );
}

Note.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default injectIntl(Note);
