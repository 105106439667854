import { React, PropTypes } from '../../dependencies';

import Divider from '../Divider';
import Summary from '../../containers/Summary';
import Items from '../Items';
import Attachments from '../../containers/Attachments';

function Body({ expense: { id, items, currency, hasAttachment } }) {
    return (
        <>
            <Items {...{ items, currency }} />

            <Summary expenseId={id} />

            {hasAttachment && <Divider />}

            <Attachments expenseId={id} />
        </>
    );
}

Body.propTypes = {
    expense: PropTypes.shape({
        id: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(Items.itemShape.isRequired).isRequired,
        currency: PropTypes.string.isRequired,
        hasAttachment: PropTypes.bool.isRequired,
    }).isRequired,
};

export default Body;
