import { React, PropTypes, NoData, useSelector, ExpensesSelectors } from '../../dependencies';

import ListItem from './ListItem';

function ListContent({ groupId, push }) {
    const expenses = useSelector(state => ExpensesSelectors.expensesSelector(state, groupId));

    return (
        <>
            {expenses.length === 0 && <NoData />}
            {expenses.map(expense => (
                <ListItem
                    {...{
                        key: expense.id,
                        expense,
                        push,
                    }}
                />
            ))}
        </>
    );
}

ListContent.propTypes = {
    groupId: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
};

export default ListContent;
