import {
    sagaEffects,
    authApi,
    config,
    Normalizers,
    Log,
    Suppliers,
    ExpensesActions,
    difference,
    union,
    formValueSelector,
    Tags,
    ErrorsUtils,
    messageActions,
} from '../../dependencies';
import { types } from '../actions';

const { takeEvery, put, select } = sagaEffects;
const { updateExpenseSuccess, updateExpenseFailure } = ExpensesActions;

function* updateExpense(id, payload) {
    try {
        const requestConfig = {
            uriParams: { id },
        };

        const { data } = yield* authApi.put(config.api.expense, payload, requestConfig);

        const { entities } = Normalizers.expense(data);

        for (const [supplierId, supplier] of Object.entries(entities.suppliers)) {
            yield put(Suppliers.actions.fetchSupplierSuccess(supplierId, supplier));
        }

        yield put(Tags.actions.addTags(Tags.utils.formatPayload(entities.tags)));

        yield put(updateExpenseSuccess(id, entities.expenses[id]));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.expense.api.update',
            },
        });

        yield put(updateExpenseFailure(id, errorMessage));

        yield put(messageActions.displayErrorMessage(errorMessage));
    }
}

function* updateTag(action) {
    const { expenseId } = action.meta;
    const { value, formId } = action.payload;

    const tags = yield select(state => formValueSelector(formId)(state, 'tags'));
    let nextTags;

    switch (action.type) {
        case types.EXPENSE_TAG_REMOVE: {
            nextTags = difference(tags, [value]);
            break;
        }

        case types.EXPENSE_TAG_ADD: {
            nextTags = union(tags, [value]);
            break;
        }
        default:
    }

    yield updateExpense(expenseId, {
        tags: yield select(Tags.selectors.mapTagNamesToTagIds, nextTags),
    });
}

export default function* () {
    yield takeEvery([types.EXPENSE_TAG_ADD, types.EXPENSE_TAG_REMOVE], updateTag);
}
