import { React, PropTypes, compose, connectFela, CardContent } from '../../dependencies';

import withCardLoader from '../../HOC/withCardLoader';

import Header from './Header';

import TotalSum from './TotalSum';
import DateRange from './DateRange';
import BarChart from './BarChart';

import * as Styles from './ReportBarChart.styles';

function ReportBarChart({ styles }) {
    return (
        <CardContent header={<Header />} extend={Styles.cardExtend}>
            <div className={styles.content}>
                <DateRange />
                <TotalSum />
                <BarChart />
            </div>
        </CardContent>
    );
}

ReportBarChart.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default compose(
    withCardLoader({
        extend: Styles.cardLoaderExtend,
    }),
    connectFela(Styles),
)(ReportBarChart);
