import { ReduxUtils } from '../dependencies';

export const routePaths = ReduxUtils.strictObjectAccess({
    EXPENSES: '/expenses',

    EXPENSES_NEW: '/expenses/new',

    EXPENSES_ITEM: '/expenses/:expenseId',
    EXPENSES_ITEM_EDIT: '/expenses/:expenseId/edit',

    EXPENSES_RECURRING: '/recurring-expenses',
    EXPENSES_RECURRING_NEW: '/recurring-expenses/new',
    EXPENSES_RECURRING_ITEM: '/recurring-expenses/:expenseTriggerId',
    EXPENSES_RECURRING_ITEM_EDIT: '/recurring-expenses/:expenseTriggerId/edit',
});
