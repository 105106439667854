import {
    React,
    PropTypes,
    TextField,
    UI,
    useIntl,
    isSubmittingSelector,
    useSelector,
} from '../../../../../dependencies';

const { InputWrapper } = UI;

const Email = ({ formId }) => {
    const intl = useIntl();
    const isSubmitting = useSelector(isSubmittingSelector(formId));

    return (
        <InputWrapper gridArea="email">
            <TextField
                name="email"
                label={intl.formatMessage({
                    id: 'export.recurring.form.email.label',
                })}
                placeholder={intl.formatMessage({
                    id: 'export.recurring.form.email.placeholder',
                })}
                disabled={isSubmitting}
                type="email"
            />
        </InputWrapper>
    );
};

Email.propTypes = {
    formId: PropTypes.string.isRequired,
};

export default Email;
