import { React, PropTypes, FelaComponent } from '../../dependencies';

import * as styles from './Divider.styles';

function Divider({ top, bottom }) {
    return <FelaComponent style={styles.divider} {...{ top, bottom }} />;
}

Divider.propTypes = {
    top: PropTypes.string,
    bottom: PropTypes.string,
};

Divider.defaultProps = {
    top: '32px',
    bottom: '32px',
};

export default Divider;
