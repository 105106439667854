import { isPlainObject } from 'lodash';

const isValueValid = value => value !== undefined && value !== '' && value !== null && !Number.isNaN(value);

/**
 * Prepare payload object for backend -> ignore any undefined or empty string values.
 * @param {Object} src
 * @param {Number?} depth
 */
export default function preparePayload(src = {}, depth = 3) {
    if (depth < 0) {
        return src;
    }

    const dest = {};

    for (const [key, value] of Object.entries(src)) {
        if (isPlainObject(value)) {
            dest[key] = preparePayload(value, depth - 1);
        } else if (isValueValid(value)) {
            dest[key] = value;
        }
    }

    return dest;
}
