import { React, Switch, Route, Redirect, Loader, ErrorCard } from '../dependencies';

import { routePaths } from '../constants';
import { useFetchReports } from '../hooks';

import ReportPage from './ReportPage';

const pages = [
    {
        path: routePaths.REPORT,
        render: () => <ReportPage />,
        exact: true,
    },
    {
        render: () => <Redirect to={routePaths.REPORT_NEW} />,
    },
];

function Reports() {
    // NOTE: Reports must fetch before report detail because:
    // If 1st temp. report should be created (URL is for example /reports/1?supplierId=2),
    // to create a temp. report we need to know filters of the perm. report.
    // Otherwise we can't create valid request, since we only know incomplete of report filters set (from URL).
    const { inProgress, success, error } = useFetchReports();

    return (
        <ErrorCard {...error}>
            <Loader show={inProgress || !success}>
                <Switch>
                    {pages.map((page, index) => (
                        <Route key={index} {...page} />
                    ))}
                </Switch>
            </Loader>
        </ErrorCard>
    );
}

export default Reports;
