import {
    compose,
    reduxForm,
    submitForm,
    injectIntl,
    CompaniesActions,
    CompaniesSelectors,
    connect,
    Consts,
} from '../../dependencies';

import { Forms } from '../../constants';
import { InitialValues } from '../../config';

import CompanyForm from '../../components/CompanyForm';

import validate from './EditCompanyForm.validate';

const form = Forms.EDIT_COMPANY;

export default compose(
    injectIntl,
    connect(
        state => ({
            modalId: Consts.modals.EDIT_COMPANY,
            initialValues: {
                ...InitialValues,
                ...CompaniesSelectors.activeCompanySelector(state),
            },
        }),
        {
            onSubmit: submitForm(form, CompaniesActions.submitEditCompanyForm),
        },
    ),
    reduxForm({
        form,
        validate,
        enableReinitialize: true,
    }),
)(CompanyForm);
