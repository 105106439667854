export const label = ({ theme: { colors } }) => ({
    marginTop: 2,
    color: colors.blueGrey4,
    fontSize: '1rem',
    lineHeight: 1.31,
    letterSpacing: '0.3px',
});

export const value = ({ theme: { colors } }) => ({
    color: colors.text,
    marginTop: 4,
    lineHeight: 1.56,
    letterSpacing: '0.2px',
    fontSize: '1rem',
});
