import { React, ButtonDefault, FormattedMessage, useDispatch, Consts, Modals } from '../../../../dependencies';

const CreatePreset = () => {
    const dispatch = useDispatch();

    return (
        <ButtonDefault onClick={() => dispatch(Modals.actions.openModal(Consts.modals.CREATE_REPORT))}>
            <FormattedMessage id="report.button.savePreset" />
        </ButtonDefault>
    );
};

export default CreatePreset;
