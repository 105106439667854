import React from 'react';

const Triangel = props => (
    <svg viewBox="0 0 8 5" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <polygon
                transform="translate(4.000000, 2.500000) scale(1, -1) translate(-4.000000, -2.500000) "
                points="0 0 4 5 8 0"
            />
        </g>
    </svg>
);

export default Triangel;
