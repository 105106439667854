export const card = ({ theme: { colors } }) => ({
    borderRadius: '6px',
    backgroundColor: colors.white,
    padding: '32px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: 400,
});

export const row = () => ({
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: '54px',
});

export const amountTotal = ({ theme: { colors } }) => ({
    color: colors.text,
    fontSize: 42,
    fontWeight: 500,
    lineHeight: 0.95,
    letterSpacing: '0.5px',
    margin: '0 0 8px',
});

export const textRow = () => ({
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px',
    lineHeight: 1.64,
    fontSize: '14px',
});

export const textInfo = ({ theme: { colors } }) => ({
    color: colors.blueGrey4,
    marginRight: 8,
    whiteSpace: 'nowrap',
});

export const totalWithoutFilters = ({ theme: { colors } }) => ({
    color: colors.info,
});
