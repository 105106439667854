import { ReduxUtils } from '../../dependencies';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: 'expenses',
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        types: ['EDIT_EXPENSE_FORM_SUBMIT_REQUEST'],
    }),
});
