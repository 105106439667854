import { config, authApi, takeLatestRequest, Log, sagaEffects, Modals, ErrorsUtils } from '../../dependencies';

import { updateModalIdFactory } from '../../utils';

import types from '../actionTypes';
import { updateSupplierSuccess, updateSupplierFailure, updateSupplierInvalidate } from '../actions';

const { put } = sagaEffects;

function* updateSupplier(action, cancelToken) {
    const { data, startSubmit, stopSubmit } = action;

    try {
        yield startSubmit();

        const requestConfig = {
            uriParams: { id: data.id },
            cancelToken,
        };

        const response = yield* authApi.put(config.api.supplier, action.data, requestConfig);

        yield put(updateSupplierSuccess(data.id, response.data));

        yield put(Modals.actions.closeModal(updateModalIdFactory(data.id)));

        yield stopSubmit();
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.supplier.api.update',
            },
        });

        yield put(updateSupplierFailure(data.id, errorMessage));

        yield stopSubmit({
            _error: errorMessage,
        });
    }
}

export default function* updateSupplierWatcher() {
    const actionTypes = {
        REQUEST: types.UPDATE_SUPPLIER_REQUEST,
        cancelTask: updateSupplierInvalidate,
        requestIdSelector: action => action.meta.userId,
    };

    yield takeLatestRequest(actionTypes, updateSupplier);
}
