export const row = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const icon = ({ theme: { colors } }) => ({
    marginRight: 10,
    fill: colors.text,
    height: 20,
    width: 20,
});

export const link = ({ theme: { colors } }) => ({
    fontSize: '14px',
    lineHeight: 1.21,
    letterSpacing: '0.5px',
    color: colors.text,
    textDecoration: 'underline',
});
