export const attachment = () => ({
    margin: '38px 0 43px',
});

export const label = ({ theme: { colors } }) => ({
    fontSize: 12,
    lineHeight: 1.25,
    letterSpacing: 0.1,
    marginBottom: 12,
    color: colors.blueGrey4,
});
