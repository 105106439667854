import d from '../defaultValues';
import { convertValue } from '../helpers';

const tag = {
    color: d.null,
    companyId: convertValue(String),
    id: convertValue(String),
    name: d.string,
};

export default tag;
