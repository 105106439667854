import { React, FelaComponent, PropTypes } from '../../dependencies';

import CreateTagForm from '../../containers/CreateTagForm';

import style from './CreateTag.style';

const CreateTag = ({ isOpen, closeModal }) =>
    isOpen && (
        <FelaComponent style={style}>
            <CreateTagForm closeModal={closeModal} />
        </FelaComponent>
    );

CreateTag.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default CreateTag;
