import {
    config,
    authApi,
    takeLatestRequest,
    Log,
    sagaEffects,
    Normalizers,
    Suppliers,
    Utils,
    push,
    Tags,
    ExpenseTriggers,
    ErrorsUtils,
    Actions,
} from '../../dependencies';

import { types, updateExpenseSuccess, updateExpenseFailure, updateExpenseInvalidate } from '../actions';
import { routePaths } from '../../constants';

import { transformExpenseFormData } from './transformFormData';

const { put, take, select } = sagaEffects;

function* updateExpenseTrigger(expenseId, { id, period, startAt, endAt, name }) {
    yield put(
        ExpenseTriggers.actions.updateExpenseTriggerRequest(id, {
            expenseId,
            period,
            startAt,
            endAt,
            name,
        }),
    );

    const { types: expenseTriggerTypes } = ExpenseTriggers.actions;

    const action = yield take([
        expenseTriggerTypes.UPDATE_EXPENSE_TRIGGER_SUCCESS,
        expenseTriggerTypes.UPDATE_EXPENSE_TRIGGER_FAILURE,
        expenseTriggerTypes.UPDATE_EXPENSE_TRIGGER_CANCEL,
    ]);

    switch (action.type) {
        case expenseTriggerTypes.UPDATE_EXPENSE_TRIGGER_FAILURE:
            throw action.error;
        case expenseTriggerTypes.UPDATE_EXPENSE_TRIGGER_SUCCESS:
            return action.meta.id;
        default:
            return null;
    }
}

function* updateExpense(action, cancelToken) {
    const { data: formData, startSubmit, stopSubmit, meta } = action;
    const { id } = meta;
    let error = null;

    try {
        yield startSubmit();

        const { payload, urlsToBeRevoked } = yield transformExpenseFormData(formData);

        const requestConfig = {
            uriParams: { id },
            cancelToken,
        };

        delete payload.type;

        const { data } = yield* authApi.put(config.api.expense, payload, requestConfig);

        const recurring = yield select(ExpenseTriggers.selectors.isRecurringExpense, id);

        let expenseTriggerId = null;
        if (recurring) {
            expenseTriggerId = yield updateExpenseTrigger(id, formData);
        }

        // revoke object urls only when the request successfully finished
        Utils.revokeUrls(urlsToBeRevoked);

        yield put(Actions.resetAllExpenses());
        yield put(Actions.resetAllReports());

        const { entities } = Normalizers.expense(data);

        for (const [supplierId, supplier] of Object.entries(entities.suppliers || {})) {
            yield put(Suppliers.actions.fetchSupplierSuccess(supplierId, supplier));
        }

        yield put(Tags.actions.addTags(Tags.utils.formatPayload(entities.tags)));

        yield put(updateExpenseSuccess(id, entities.expenses[id]));

        if (recurring) {
            yield put(push(routePaths.EXPENSES_RECURRING_ITEM.replace(':expenseTriggerId', expenseTriggerId)));
        } else {
            yield put(push(routePaths.EXPENSES_ITEM.replace(':expenseId', id)));
        }
    } catch (e) {
        Log.error(e);
        error = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.expense.api.update',
                values: { id },
            },
        });
        yield put(updateExpenseFailure(id, error));
    } finally {
        yield stopSubmit({
            _error: error,
        });
    }
}

export default function* updateExpenseWatcher() {
    const actionTypes = {
        REQUEST: types.UPDATE_EXPENSE_REQUEST,
        cancelTask: updateExpenseInvalidate,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, updateExpense);
}
