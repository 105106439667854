import { React, useSelector, useDispatch } from '../dependencies';

import { fetchExportsRequest, fetchExportsInvalidate } from '../services/actions';
import { fetchExportsApiSelector } from '../services/selectors';

export function useFetchExports() {
    const api = useSelector(fetchExportsApiSelector);
    const dispatch = useDispatch();
    const request = () => dispatch(fetchExportsRequest());

    React.useEffect(() => {
        if (!api.inProgress && !api.success) {
            request();
        }

        return () => {
            if (api.inProgress) {
                dispatch(fetchExportsInvalidate());
            }
        };
        // eslint-disable-next-line
    }, []);

    const { inProgress, success, error } = api;

    return {
        inProgress,
        success,
        error: {
            retry: request,
            message: error,
        },
    };
}
