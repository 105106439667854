import d from '../defaultValues';
import { convertValue, any } from '../helpers';

const temporaryReport = {
    companyId: convertValue(String),
    hiddenTags: d.reports.hiddenTags,
    buckets: any,
    temp: d.true,
    filters: d.reports.filters,
    tagIds: d.array,
};

export default temporaryReport;
