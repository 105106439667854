import types from './types';

export const deleteCompanyRequest = id => ({
    type: types.DELETE_COMPANY_REQUEST,
    meta: { id },
});

export const deleteCompanySuccess = id => ({
    type: types.DELETE_COMPANY_SUCCESS,
    meta: { id },
});

export const deleteCompanyFailure = (id, error) => ({
    type: types.DELETE_COMPANY_FAILURE,
    meta: { id },
    error,
});

export const deleteCompanyInvalidate = id => ({
    type: types.DELETE_COMPANY_CANCEL,
    meta: { id },
});
