import types from './types';

export const selectBucket = bucket => ({
    type: types.SELECT_BUCKET,
    payload: {
        bucket,
    },
});

export const setViewportSize = viewportSize => ({
    type: types.SET_VIEWPORT_SIZE,
    payload: {
        viewportSize,
    },
});

export const setViewportLeftOffset = viewportLeftOffset => ({
    type: types.SET_VIEWPORT_LEFT_OFFSET,
    payload: {
        viewportLeftOffset,
    },
});
