import {
    React,
    PropTypes,
    Form,
    TextField,
    CheckboxField,
    UI,
    FormattedMessage,
    PasswordField,
    EmailField,
    FormSection,
    CountriesSelectField,
    Currency,
} from '../../dependencies';

const { Space, Container, ButtonPrimary, InputWrapper } = UI;

const SignUpForm = ({ handleSubmit, intl, submitting, error, styles, form }) => {
    return (
        <Container>
            <div className={styles.form}>
                <Form onSubmit={handleSubmit}>
                    <div className={styles.nameRow}>
                        <InputWrapper>
                            <TextField
                                name="firstName"
                                label={intl.formatMessage({ id: 'login.firstName' })}
                                disabled={submitting}
                                autoComplete="given-name"
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <TextField
                                name="lastName"
                                label={intl.formatMessage({ id: 'login.lastName' })}
                                disabled={submitting}
                                autoComplete="family-name"
                            />
                        </InputWrapper>
                    </div>

                    <Space bottom="1rem" />

                    <EmailField formId={form} />

                    <Space bottom="1rem" />

                    <PasswordField
                        name="password"
                        label={intl.formatMessage({ id: 'login.password' })}
                        autoComplete="new-password"
                        disabled={submitting}
                    />

                    <Space bottom="2.5rem" />

                    <FormSection name="company">
                        <InputWrapper>
                            <TextField
                                name="name"
                                label={intl.formatMessage({ id: 'login.companyName' })}
                                disabled={submitting}
                                autoComplete="organization"
                            />
                        </InputWrapper>
                        <Space bottom="1rem" />
                        <Currency formId={form} />
                        <Space bottom="1rem" />
                        <CountriesSelectField
                            formId={form}
                            label={intl.formatMessage({
                                id: `supplier.form.country.placeholder`,
                            })}
                        />
                    </FormSection>

                    <Space bottom="2.5rem" />

                    <div className={styles.checkbox}>
                        <CheckboxField
                            name="dataAnalysis"
                            label={
                                <FormattedMessage
                                    id="login.dataAnalysis.text"
                                    values={{
                                        link: (
                                            <a
                                                href="https://support.google.com/analytics/answer/3123662"
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                <FormattedMessage id="login.dataAnalysis.link" />
                                            </a>
                                        ),
                                    }}
                                />
                            }
                            disabled={submitting}
                        />
                    </div>

                    <div className={styles.footer}>
                        <ButtonPrimary htmlType="submit" loading={submitting} id="submitButton">
                            {intl.formatMessage({ id: 'signUp.submit' })}
                        </ButtonPrimary>

                        {error && <p>{error}</p>}
                    </div>
                </Form>
            </div>
        </Container>
    );
};

SignUpForm.propTypes = {
    styles: PropTypes.shape().isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
        formatHTMLMessage: PropTypes.func.isRequired,
    }).isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
    form: PropTypes.string.isRequired,
};

SignUpForm.defaultProps = {
    error: '',
};

export default SignUpForm;
