import {
    config,
    formValueSelector,
    Log,
    createSelector,
    getFormValues,
    ExpensesSelectors,
    ExpensesConfig,
    ExpensesConsts,
    CompaniesSelectors,
} from '../../dependencies';

const {
    expenseRegularInitialValues,
    expenseStatisticalInitialValues,
    expenseRecurringStatisticalInitialValues,
    expenseRecurringRegularInitialValues,
} = ExpensesConfig;

const { forms } = config;

export const createExpenseFormSelector = formValueSelector(forms.createExpense);
export const createExpenseFormValuesSelector = getFormValues(forms.createExpense);

export const updateExpenseFormSelector = formValueSelector(forms.updateExpense);

const createExpenseFilesSelector = createSelector(
    state => createExpenseFormSelector(state, 'files'),
    (files = []) => files,
);

const updateExpenseFilesSelector = createSelector(
    state => updateExpenseFormSelector(state, 'files'),
    (files = []) => {
        return files.map(file => file);
    },
);

export const filesSelector = (state, formId) => {
    switch (formId) {
        case forms.createExpense:
            return createExpenseFilesSelector(state);

        case forms.updateExpense:
            return updateExpenseFilesSelector(state);

        default:
            Log.error(`Inlivad 'formId': '${formId}'. Valid values: ${[forms.createExpense, forms.updateExpense]}`);
            return [];
    }
};

const { types } = ExpensesConsts;

const regularFormInitialValuesSelector = createSelector(
    [
        createExpenseFormValuesSelector,
        ExpensesSelectors.nextExpenseNumberSelector,
        CompaniesSelectors.activeCompanyCurrencySelector,
    ],
    (values, number, currency) => ({
        ...expenseRegularInitialValues,
        currency,
        ...values,
        type: types.DEFAULT,
        number,
    }),
);

const statisticalFormInitialValuesSelector = createSelector(
    [
        createExpenseFormValuesSelector,
        ExpensesSelectors.nextExpenseNumberSelector,
        CompaniesSelectors.activeCompanyCurrencySelector,
    ],
    (values, number, currency) => ({
        ...expenseStatisticalInitialValues,
        currency,
        ...values,
        type: types.STATISTIC,
        number,
    }),
);

const recurringRegularFormInitialValuesSelector = createSelector(
    [createExpenseFormValuesSelector, CompaniesSelectors.activeCompanyCurrencySelector],
    (values, currency) => ({
        ...expenseRecurringRegularInitialValues,
        currency,
        ...values,
        type: types.TEMPLATE,
    }),
);

const recurringStatisticalFormInitialValuesSelector = createSelector(
    [createExpenseFormValuesSelector, CompaniesSelectors.activeCompanyCurrencySelector],
    (values, currency) => ({
        ...expenseRecurringStatisticalInitialValues,
        currency,
        ...values,
        type: types.TEMPLATE,
    }),
);

export const createExpenseInitialValuesSelector = state => {
    const { recurring, statistic } = ExpensesSelectors.toolboxFormValuesSelector(state);

    if (recurring) {
        return statistic
            ? recurringStatisticalFormInitialValuesSelector(state)
            : recurringRegularFormInitialValuesSelector(state);
    }

    const { DEFAULT, STATISTIC } = ExpensesConsts.types;

    return statistic
        ? statisticalFormInitialValuesSelector(state, STATISTIC)
        : regularFormInitialValuesSelector(state, DEFAULT);
};
