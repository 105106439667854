import { React, PropTypes, Form, FelaComponent, Space, CheckboxField } from '../../../dependencies';

import InfoBlock from '../../InfoBlock';

import * as styles from './ToolboxForm.styles';

function ToolboxForm({ handleSubmit, submitting, error, intl: { formatMessage } }) {
    return (
        <FelaComponent style={styles.wrapper}>
            <Form onSubmit={handleSubmit}>
                <CheckboxField
                    {...{
                        name: 'statistic',
                        label: formatMessage({
                            id: 'expense.type.statistic.title',
                        }),
                    }}
                />

                <Space bottom={22} />

                <InfoBlock messageId="expense.type.statistic.description" />

                <Space bottom={32} />

                <CheckboxField
                    {...{
                        name: 'recurring',
                        label: formatMessage({
                            id: 'expense.type.recurring.title',
                        }),
                    }}
                />

                <Space bottom={22} />

                <InfoBlock messageId="expense.type.recurring.description" />
            </Form>
        </FelaComponent>
    );
}

ToolboxForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.shape().isRequired,
    error: PropTypes.string,
    submitting: PropTypes.bool.isRequired,
};

ToolboxForm.defaultProps = {
    error: '',
};

export default ToolboxForm;
