export const buttons = () => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    gridColumnGap: '1rem',
    justifyContent: 'flex-end',
    padding: '12px 30px',
    alignItems: 'center',
    width: '100%',
});

export const nav = () => ({
    display: 'grid',
    gridTemplateColumns: '1.5rem auto',
    gridColumnGap: '1.625rem',
    alignItems: 'center',
    padding: '20px 30px',
});

export const viewName = ({ theme: { colors } }) => ({
    letterSpacing: '0.3px',
    lineHeight: 1.31,
    fontSize: '1rem',
    color: colors.text,
});

export const iconArrowLeft = ({ theme: { colors } }) => ({
    height: '1.25rem',
    fill: colors.text,
});

export const buttonIcon = () => ({
    height: '1.5rem',
    width: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
