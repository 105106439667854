import { React, PropTypes, FelaComponent, childrenPropType } from '../../../../dependencies';

import * as styles from './Button.styles';

function Button({ onClick, children }) {
    return (
        <FelaComponent style={styles.button}>
            {({ className }) => (
                <button type="button" {...{ onClick, className }}>
                    {children}
                </button>
            )}
        </FelaComponent>
    );
}

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: childrenPropType.isRequired,
};

export default Button;
