import { React, PropTypes, FelaComponent, childrenPropType, noop } from '../../dependencies';

import * as styles from './TabsWrapper.styles';

function TabsWrapper({ children, style }) {
    return (
        <FelaComponent style={styles.tabWrapper} customStyle={style}>
            {children}
        </FelaComponent>
    );
}

TabsWrapper.propTypes = {
    children: childrenPropType.isRequired,
    style: PropTypes.func,
};

TabsWrapper.defaultProps = {
    style: noop,
};

export default TabsWrapper;
