import { union, Utils } from '../../../dependencies';
import types from '../../actionTypes';

const initialState = {
    byName: {},
    byId: {},
    names: [],
    ids: [],
};

export default function tagsReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_TAGS:
        case types.FETCH_TAGS_SUCCESS: {
            const { byName, names, byId } = action.payload;
            const sortedNames = union(state.names, names).sort(Utils.alphaLocaleCompare);
            const mergedByName = {
                ...state.byName,
                ...byName,
            };

            return {
                ...state,
                byName: mergedByName,
                byId: {
                    ...state.byId,
                    ...byId,
                },
                // NOTE: this could be done more efficiently
                names: sortedNames,
                ids: sortedNames.map(name => mergedByName[name].id),
            };
        }

        case types.CREATE_TAG_SUCCESS: {
            const { name: newTagName } = action.meta;
            const tag = action.payload;
            const sortedNames = union(state.names, [newTagName]).sort(Utils.alphaLocaleCompare);
            const mergedByName = {
                ...state.byName,
                [newTagName]: tag,
            };

            return {
                ...state,
                byName: mergedByName,
                byId: {
                    ...state.byId,
                    [tag.id]: newTagName,
                },
                // NOTE: this could be done more efficiently
                names: sortedNames,
                ids: sortedNames.map(name => mergedByName[name].id),
            };
        }
        case types.UPDATE_TAG_SUCCESS: {
            const { payload, meta } = action;
            const oldName = state.byId[meta.id];
            const namesWithoutOldOne = state.names.filter(name => name !== oldName);

            return {
                ...state,
                byName: {
                    ...state.byName,
                    [meta.name]: payload,
                },
                byId: {
                    ...state.byId,
                    [meta.id]: meta.name,
                },
                // NOTE: this could be done more efficiently
                names: union(namesWithoutOldOne, [meta.name]).sort(Utils.alphaLocaleCompare),
            };
        }
        case types.DELETE_TAG_SUCCESS: {
            const { meta } = action;
            const nameToBeDeleted = state.byId[meta.id];

            if (nameToBeDeleted) {
                const nextById = { ...state.byId };
                const nextByName = { ...state.byName };

                delete nextById[meta.id];
                delete nextByName[meta.id];

                const nextNames = state.names.filter(name => name !== nameToBeDeleted);

                return {
                    ...state,
                    byName: nextByName,
                    byId: nextById,
                    names: nextNames,
                    ids: state.ids.filter(id => id !== meta.id),
                };
            }
            return state;
        }

        default:
            return state;
    }
}
