import React from 'react';

const Plus = props => (
    <svg viewBox="0 0 12 12" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <g transform="translate(-2.000000, -2.000000)">
                <path d="M8.66666667,7.33333333 L14,7.33333333 L14,8.66666667 L8.66666667,8.66666667 L8.66666667,14 L7.33333333,14 L7.33333333,8.66666667 L2,8.66666667 L2,7.33333333 L7.33333333,7.33333333 L7.33333333,2 L8.66666667,2 L8.66666667,7.33333333 Z" />
            </g>
        </g>
    </svg>
);

export default Plus;
