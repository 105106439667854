export const shortenedNumber = (number, { formatNumber, formatMessage }) => {
    if (number === 0) return '';

    let scale = 0;
    const suffix = ['suffix.hundreds', 'suffix.thousands', 'suffix.millions', 'suffix.billions'];
    while (number > 1000 && scale < 3) {
        number /= 1000;
        scale++;
    }

    const price = formatNumber(number, {
        maximumFractionDigits: scale < 2 ? 0 : 1,
    });

    return `${price} ${formatMessage({ id: suffix[scale] })}`;
};

export const getQuarterFromMonthIndex = monthIndex => {
    return Math.floor(monthIndex / 3) + 1;
};
