import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from './types';

export const fetchDashboardRequest = () => ({
    type: types.FETCH_DASHBOARD_REQUEST,
});

export const fetchDashboardSuccess = dashboard => {
    return {
        type: types.FETCH_DASHBOARD_SUCCESS,
        payload: formatEntity({
            entity: dashboard,
            template: entitiesTemplates.dashboard,
        }),
    };
};

export const fetchDashboardFailure = error => ({
    type: types.FETCH_DASHBOARD_FAILURE,
    error,
});

export const fetchDashboardInvalidate = () => ({
    type: types.FETCH_DASHBOARD_CANCEL,
});

export const fetchDashboardReset = () => ({
    type: types.FETCH_DASHBOARD_RESET,
});
