import {
    React,
    PropTypes,
    ChartBar,
    PriceTooltip,
    monthsIndex,
    ReportUtils,
    config,
    FormattedNumber,
} from '../../dependencies';

import Overview from './Overview';

import { calcBarWidth, barShape } from './utils';

const BARS_COUNT = 12;
const GAP = 16;

function MonthsChart({ intl, total, currentPeriod, currency, buckets, average }) {
    return (
        <>
            <Overview
                {...{
                    total,
                    currentPeriod,
                    currency,
                    currentPeriodLabelId: 'dashboard.currentMonth',
                }}
            />
            <ChartBar
                {...{
                    bottomLabelHeight: 29,
                    topLabelHeight: 29,
                    height: 245,
                    gap: GAP,
                    barsCount: BARS_COUNT,
                    data: MonthsChart.mapBars(buckets, currency, intl),
                    calcBarWidth: MonthsChart.calcBarWidth,
                    average: {
                        ...average,
                        value: (
                            <FormattedNumber
                                {...config.currency}
                                // eslint-disable-next-line
                                style="currency"
                                value={average.value}
                                currency={currency}
                            />
                        ),
                    },
                }}
            />
        </>
    );
}

MonthsChart.calcBarWidth = viewportWidth => {
    return calcBarWidth(BARS_COUNT, GAP, viewportWidth);
};

MonthsChart.mapBars = (bars = [], currency, intl) => {
    return bars.map(({ withVat, withoutVat, monthIndex, ...bar }) => ({
        ...bar,
        title: <PriceTooltip currency={currency} priceWithVat={withVat} />,
        topLabel: ReportUtils.shortenedNumber(withoutVat, intl),
        bottomLabel: intl.formatMessage({
            id: monthsIndex[monthIndex],
        }),
    }));
};

MonthsChart.propTypes = {
    intl: PropTypes.shape().isRequired,
    currency: PropTypes.string.isRequired,
    buckets: PropTypes.arrayOf(barShape.isRequired).isRequired,

    total: PropTypes.shape({
        yearFrom: PropTypes.string.isRequired,
        yearTo: PropTypes.string.isRequired,
        withVat: PropTypes.number.isRequired,
        withoutVat: PropTypes.number.isRequired,
    }).isRequired,

    currentPeriod: PropTypes.shape({
        withVat: PropTypes.number.isRequired,
        withoutVat: PropTypes.number.isRequired,
    }),

    average: PropTypes.shape({
        bottomRelativeOffset: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    }).isRequired,
};

MonthsChart.defaultProps = {
    currentPeriod: null,
};

export default MonthsChart;
