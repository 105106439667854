import types from './types';

export const initializeTagVisibility = initialTags => ({
    type: types.INITIALIZE_TAGS_VISIBILITY,
    payload: initialTags,
});

export const setTagVisibility = (id, hidden) => ({
    type: types.SET_TAG_VISIBILITY,
    payload: {
        id,
        hidden,
    },
});
