import { useDispatch, useSelector, ReduxUtils, Consts } from '../../dependencies';

import { updateReportRequest } from '../../services/actions';

const { UPDATE } = Consts.apiTypes;
const { REPORT } = Consts.entityKeys;

export function useUpdateReport(reportId) {
    const dispatch = useDispatch();
    const { inProgress } = useSelector(state => ReduxUtils.apiSelector(state, REPORT, UPDATE, reportId));

    return {
        inProgress,
        updateReport() {
            dispatch(updateReportRequest(reportId));
        },
    };
}
