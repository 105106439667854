import { createSelector, difference, formValueSelector, Utils } from '../../dependencies';

import { selectTags, tagsSelector } from './entities';
import { selectTagListFilter } from './filter';

const selectedTagIdsSelector = createSelector(
    (state, { formId, fieldId = 'tags' }) => formValueSelector(formId)(state, fieldId),
    tags => tags || [],
);

export const selectedTagOptionsSelector = createSelector(
    [selectedTagIdsSelector, selectTags, Utils.passParam],
    (selectedTagIds = [], tags, { processOptions }) => {
        const options = Utils.createOptions(selectedTagIds, tags.byId);
        return processOptions ? processOptions(options) : options;
    },
);

export const selectNonSelectedTags = createSelector(
    [selectedTagIdsSelector, selectTags],
    (selectedTagIds = [], tags) => {
        const nonSelectedIds = difference(tags.ids, selectedTagIds);

        return Utils.createOptions(nonSelectedIds, tags.byId);
    },
);

export const filteredTagsSelector = createSelector([tagsSelector, selectTagListFilter], (tags, filter) => {
    const normalizedFilter = Utils.normalizeInput(filter.trim());
    return tags.filter(({ name }) => Utils.normalizeInput(name).includes(normalizedFilter));
});

export const mapTagNamesToTagIds = createSelector([Utils.passParam, selectTags], (tagIds, tags) => {
    return tagIds.map(id => tags.byId[id] || id);
});
