import { React, SignUp, Switch, Route, Background, AuthConsts, Messages, Consts } from '../dependencies';

import AuthApp from './AuthApp';

const routes = [
    {
        path: AuthConsts.routePaths.SIGN_UP,
        render: () => <SignUp />,
        exact: true,
    },
    {
        path: Consts.routes.DASHBOARD,
        render: () => <AuthApp />,
        exact: false,
    },
];

const App = () => {
    return (
        <>
            <Background>
                <Switch>
                    {routes.map(route => (
                        <Route key={route.path} {...route} />
                    ))}
                </Switch>
            </Background>
            <Messages />
        </>
    );
};

export default App;
