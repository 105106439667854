import { config, authApi, takeLatestRequest, Log, sagaEffects, ErrorsUtils } from '../../dependencies';

import { types, updateMembershipSuccess, updateMembershipFailure, updateMembershipCancel } from '../actions';

const { put } = sagaEffects;

function* updateMembership(action, cancelToken) {
    const { id } = action.meta;

    try {
        const { data } = yield* authApi.put(config.api.membership, action.payload, {
            uriParams: { id },
            cancelToken,
        });

        yield put(updateMembershipSuccess(id, data));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.memberships.api.update',
            },
        });

        yield put(updateMembershipFailure(id, errorMessage));
    }
}

export default function* updateMembershipWatcher() {
    const actionTypes = {
        REQUEST: types.UPDATE_MEMBERSHIP_REQUEST,
        cancelTask: updateMembershipCancel,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, updateMembership);
}
