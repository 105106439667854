import { React, PropTypes, capitalize, SelectField, InputWrapper, Utils } from '../../../dependencies';

import { Fields } from '../../../constants';

import * as styles from './SupplierField.styles';

function Supplier({ intl, options, disabled }) {
    return (
        <InputWrapper style={styles.input}>
            <SelectField
                {...{
                    name: Fields.SUPPLIER_ID,
                    label: intl.formatMessage({
                        id: 'expense.form.supplier.name.label',
                    }),
                    placeholder: intl.formatMessage({
                        id: 'expense.form.supplier.placeholder',
                    }),
                    parse: Supplier.capitalize,
                    autoComplete: 'organization',
                    disabled,
                    options,
                    optionFilterProp: 'children',
                    showSearch: true,
                    filterOption: Utils.filterOption,
                    allowClear: true,
                    notFoundContent: null,
                }}
            />
        </InputWrapper>
    );
}

Supplier.propTypes = {
    intl: PropTypes.shape().isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ).isRequired,
    disabled: PropTypes.bool.isRequired,
};

Supplier.capitalize = (value = '') => capitalize(value);

export default Supplier;
