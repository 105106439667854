import { Utils } from '../dependencies';

const { InputValidation } = Utils;
const { getFieldsErrors, validatorsWithMessage } = InputValidation;
const { isRequired } = validatorsWithMessage;

const validators = {
    name: [isRequired],
};

export default function validate(values) {
    const errors = getFieldsErrors(validators, values);

    // TODO: change the validation when we have graphics for it
    return errors.name
        ? {
              _error: {
                  id: errors.name,
              },
          }
        : {};
}
