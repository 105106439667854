import { React, PropTypes, connectFela, isEqual } from '../dependencies';

import * as Styles from './DateRangePicker.styles';

import { DefaultValue } from '../config';

import PickerDropdown from './PickerDropdown';
import Label from './Label';
import ButtonWithRange from './ButtonWithRange';
import ButtonReset from './ButtonReset';
import { RangePropType } from './propTypes';

const DateRangePicker = ({ input: { value, onChange }, meta: { initial: initialValue, form }, styles }) => {
    const defaultValue = DefaultValue[form];

    const [isDropdownOpen, setDropdownOpen] = React.useState(false);

    const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

    const onCloseHandler = () => setDropdownOpen(false);

    const submitSelection = nextValue => {
        onCloseHandler();
        onChange(nextValue);
    };

    return (
        <div className={styles.container}>
            <Label />

            <div className={styles.row}>
                <ButtonWithRange value={value} toggleDropdown={toggleDropdown} />
                {!isEqual(value, defaultValue) && <ButtonReset onClick={() => onChange(defaultValue)} />}
            </div>

            {isDropdownOpen && (
                <PickerDropdown
                    {...{
                        initialValue,
                        onClose: onCloseHandler,
                        onSubmit: submitSelection,
                    }}
                />
            )}
        </div>
    );
};

DateRangePicker.propTypes = {
    input: PropTypes.shape({
        value: RangePropType,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
        initial: RangePropType.isRequired,
        form: PropTypes.string.isRequired,
    }).isRequired,
    styles: PropTypes.shape().isRequired,
};
export default connectFela(Styles)(DateRangePicker);
