import { Selectors, createSelector, matchPath, Consts } from '../../dependencies';
import { getReportId, isReportIdNewReport } from '../../utils';
import { NEW_REPORT } from '../../constants';

const reportRouteMatchSelector = createSelector(
    Selectors.locationSelector,
    location => {
        return matchPath(location.pathname, {
            path: Consts.routes.REPORT,
        });
    },
);

export const currentReportIdSelector = createSelector(
    reportRouteMatchSelector,
    getReportId,
);

export const isNewReportSelector = createSelector(
    reportRouteMatchSelector,
    isReportIdNewReport,
);

export const currentReportSlugSelector = createSelector(
    [currentReportIdSelector, isNewReportSelector],
    (reportId, isNewReport) => (isNewReport ? NEW_REPORT : reportId),
);
