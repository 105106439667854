import { connect, compose, ExpensesSelectors, injectIntl, Consts, CompaniesSelectors } from '../dependencies';

import Total from '../components/Total';

const { EXPENSES } = Consts.expensesGroups;

const mapStateToProps = state => {
    return {
        totalPrice: ExpensesSelectors.totalPriceSelector(state, EXPENSES),
        currency: CompaniesSelectors.activeCompanyCurrencySelector(state),
    };
};

const mergeProps = (stateProps, dispatchProps, { intl }) => ({
    ...stateProps,
    intl,
});

export default compose(injectIntl, connect(mapStateToProps, null, mergeProps))(Total);
