import { useRouteMatch, Consts } from '../dependencies';
import { getReportId, isReportIdNewReport } from '../utils';
import { NEW_REPORT } from '../constants';

export function useCurrentReportId() {
    const match = useRouteMatch({
        path: Consts.routes.REPORT,
    });

    return getReportId(match);
}

export function useCurrentReportSlug() {
    const match = useRouteMatch({
        path: Consts.routes.REPORT,
    });

    return isReportIdNewReport(match) ? NEW_REPORT : getReportId(match);
}
