import {
    React,
    PropTypes,
    TextField,
    UI,
    useIntl,
    isSubmittingSelector,
    useSelector,
} from '../../../../../dependencies';

const { InputWrapper } = UI;

const ApiToken = ({ formId }) => {
    const intl = useIntl();
    const isSubmitting = useSelector(isSubmittingSelector(formId));

    return (
        <InputWrapper gridArea="apiToken">
            <TextField
                name="apiToken"
                label={intl.formatMessage({
                    id: 'export.recurring.form.apiToken.label',
                })}
                placeholder={intl.formatMessage({
                    id: 'export.recurring.form.apiToken.placeholder',
                })}
                disabled={isSubmitting}
            />
        </InputWrapper>
    );
};

ApiToken.propTypes = {
    formId: PropTypes.string.isRequired,
};

export default ApiToken;
