import {
    compose,
    withErrorBoundary,
    connect,
    fetchDependencies,
    loadable,
    Loader,
    Consts,
    ReduxUtils,
    withErrorMessage,
    mapProps,
    MembershipsSelectors,
} from '../dependencies';

import SupplierList from '../components/SupplierList/SupplierList';

import { fetchSuppliersRequest } from '../services/actions';
import { filteredSuppliersSelector } from '../services/selectors';

const { SUPPLIERS } = Consts.entityKeys;
const { FETCH } = Consts.apiTypes;

const mapPropsToErrorMessage = ({ fetch, ...rest }) => {
    return {
        ...rest,
        onErrorMessageClick: fetch,
    };
};

export default compose(
    withErrorBoundary,
    connect(
        state => {
            const { inProgress, success, error } = ReduxUtils.apiSelector(state, SUPPLIERS, FETCH);
            const suppliers = filteredSuppliersSelector(state);
            const isContributor = MembershipsSelectors.isContributorSelector(state);

            return {
                showLoader: inProgress || !success,
                suppliers,
                error,
                isContributor,
            };
        },
        {
            fetch: fetchSuppliersRequest,
        },
    ),
    fetchDependencies(),
    mapProps(mapPropsToErrorMessage),
    withErrorMessage,
    loadable(Loader),
)(SupplierList);
