export default ({ theme }) => ({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 6rem',
    gridColumnGap: '0.5rem',

    padding: '1rem 0',

    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,

    color: theme.colors.text,

    borderTop: `1px solid ${theme.colors.blueGrey1}`,

    ':last-of-type': {
        borderBottom: `1px solid ${theme.colors.blueGrey1}`,
    },

    '& > div:last-of-type': {
        justifySelf: 'end',
    },
    '& > div:last-of-type button': {
        marginLeft: '1.75rem',
    },
});
