import { createAsyncType } from '@ackee/redux-utils';

const createTypes = createAsyncType({
    modulePrefix: 'global/expenses',
});

export default {
    ...createTypes({
        types: ['FILTER_EXPENSES_REQUEST', 'FETCH_EXPENSE_SUCCESS', 'RESET_ALL_EXPENSES', 'RESET_ALL_REPORTS'],
    }),
};
