import types from './types';

export const deleteExpenseTriggerRequest = id => ({
    type: types.DELETE_EXPENSE_TRIGGER_REQUEST,
    meta: { id },
});

export const deleteExpenseTriggerSuccess = id => ({
    type: types.DELETE_EXPENSE_TRIGGER_SUCCESS,
    meta: { id },
});

export const deleteExpenseTriggerFailure = (id, error) => ({
    type: types.DELETE_EXPENSE_TRIGGER_FAILURE,
    meta: { id },
    error,
});

export const deleteExpenseTriggerInvalidate = id => ({
    type: types.DELETE_EXPENSE_TRIGGER_CANCEL,
    meta: { id },
});
