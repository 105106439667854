import { sagaEffects, eventChannel, messageActions } from '../../dependencies';

import { Status } from '../../constants';

const { takeEvery, put } = sagaEffects;

function createConnectionChannel() {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    if (!connection) {
        return eventChannel(emit => {
            function onlineHandler() {
                emit(Status.ONLINE);
            }

            function offlineHandler() {
                emit(Status.OFFLINE);
            }

            window.addEventListener('online', onlineHandler);
            window.addEventListener('offline', offlineHandler);

            return () => {
                window.removeEventListener('online', onlineHandler);
                window.removeEventListener('offline', offlineHandler);
            };
        });
    }

    return eventChannel(emit => {
        let prevStatus = Status.ONLINE;

        function updateConncetionStatus() {
            const status = connection.downlink === 0 ? Status.OFFLINE : Status.ONLINE;

            if (prevStatus !== status) {
                emit(status);
                prevStatus = status;
            }
        }

        connection.addEventListener('change', updateConncetionStatus);

        return () => connection.removeEventListener('change', updateConncetionStatus);
    });
}

const { displaySuccessMessage, displayErrorMessage } = messageActions;

function* handleConnectionChange(status) {
    if (status === Status.OFFLINE) {
        const message = {
            id: 'error.network.offline',
        };
        const options = {
            duration: 3500,
        };
        yield put(displayErrorMessage(message, options));
    } else {
        yield put(
            displaySuccessMessage({
                id: 'success.network.backOnline',
            }),
        );
    }
}

export default function* networkConnection() {
    const connectionChannel = yield createConnectionChannel();

    yield takeEvery(connectionChannel, handleConnectionChange);
}
