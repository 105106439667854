import { ReduxUtils } from '../../dependencies';

import types from '../actionTypes';

import modalReducer from './modal';

export default ReduxUtils.containerReducer({
    childReducer: modalReducer,
    actionTypes: Object.values(types),
    selectors: {
        itemId: action => action.payload.id,
    },
});
