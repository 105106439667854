import {
    React,
    connectFela,
    compose,
    FormattedMessage,
    PropTypes,
    Link,
    Consts,
    Icons,
    PickCompany,
    useNoActiveCompanyId,
} from '../../../dependencies';

import * as Styles from './DropdownMenu.styles';

function DropdownMenu({ logout, styles }) {
    const hasNoActiveCompany = useNoActiveCompanyId();
    return (
        <>
            {hasNoActiveCompany ? null : <PickCompany />}

            <Link to={Consts.routes.SETTINGS} className={styles.listItemWrapper}>
                <div className={styles.listItem}>
                    <Icons.HappyFace className={styles.icon} />
                    <span>
                        <FormattedMessage id="menu.user.settings" />
                    </span>
                </div>
            </Link>

            <button onClick={logout} type="button" className={styles.listItemWrapper}>
                <div className={styles.listItem}>
                    <Icons.Logout className={styles.icon} />
                    <span>
                        <FormattedMessage id="menu.user.logout" />
                    </span>
                </div>
            </button>
        </>
    );
}

DropdownMenu.propTypes = {
    logout: PropTypes.func.isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default compose(connectFela(Styles), React.memo)(DropdownMenu);
