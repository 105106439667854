export default ({ theme }) => ({
    '> form': {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 6rem',
        gridColumnGap: '0.5rem',
    },
    '> form > div:first-of-type': {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 3fr',
        gridColumnGap: '0.5rem',
    },
    '> form > div:last-of-type': {
        justifySelf: 'end',
    },
    '> form .Error': {
        color: theme.colors.primary,
    },
    '& button': {
        marginLeft: '1.5rem',
    },
});
