import { openDB } from '../dependencies';

import { DATABASE_NAME, DATABASE_VERSION, DATABASE_STORE_NAME } from '../constants';

let db = null;

function initDatabase() {
    return openDB(DATABASE_NAME, DATABASE_VERSION, {
        upgrade(nextDb) {
            nextDb.createObjectStore(DATABASE_STORE_NAME);
        },
    });
}

async function accessDatabase() {
    if (!db) {
        db = await initDatabase();
    }

    return db;
}

/**
 * @param {string} key
 */
export async function get(key) {
    const db = await accessDatabase();
    return db.get(DATABASE_STORE_NAME, key);
}

/**
 * @param {string} key
 * @param {any} value
 */
export async function set(key, value) {
    const db = await accessDatabase();
    return db.put(DATABASE_STORE_NAME, value, key);
}

/**
 * @param {string} key
 */
export async function remove(key) {
    const db = await accessDatabase();
    return db.delete(DATABASE_STORE_NAME, key);
}
