import {
    React,
    PropTypes,
    UI,
    childrenPropType,
    ErrorCard,
    useFetchExport,
    useFetchServiceKey,
} from '../../dependencies';

const { DataLoader } = UI;

const ExportFetcher = ({ exportId, serviceKeyId, children }) => {
    const { inProgress, success, error } = useFetchExport(exportId);
    const api = useFetchServiceKey(serviceKeyId);

    return (
        <ErrorCard {...error} {...api.error}>
            <DataLoader show={inProgress || !success || api.inProgress || !api.success} minHeight={480}>
                {children}
            </DataLoader>
        </ErrorCard>
    );
};

ExportFetcher.propTypes = {
    exportId: PropTypes.string.isRequired,
    serviceKeyId: PropTypes.string.isRequired,
    children: childrenPropType.isRequired,
};

export default ExportFetcher;
