import sizes from './InputWrapper.sizes';

export const input = ({ theme, labelMarginLeft, size, gridArea }) => {
    const { colors } = theme;

    const fontStyleEnum = {
        [sizes.S]: {
            lineHeight: 1.64,
            letterSpacing: '0.1px',
            fontSize: '14px',
            padding: '5px 12px 4px',
        },
        [sizes.L]: {
            lineHeight: 1.56,
            letterSpacing: '0.2px',
            fontSize: '16px',
            padding: '8px 12px 7px',
        },
    };

    const fontStyle = {
        ...fontStyleEnum[size],
        color: colors.text,
    };

    return {
        gridArea,
        '> .ant-form-item': {
            marginBottom: 0,

            '> .ant-form-item-control-wrapper > .ant-form-item-control': {
                lineHeight: 'unset',

                '> .ant-form-explain': {
                    marginLeft: 12,
                    marginTop: 4,
                    fontSize: 12,
                    lineHeight: 1.25,
                    letterSpacing: '0.1px',
                    color: colors.primary,
                    position: 'absolute',
                    whiteSpace: 'nowrap',
                },
            },

            '> .ant-form-item-control-wrapper > .ant-form-item-control > .ant-form-item-children': {
                position: 'unset',

                '> .ant-input-affix-wrapper': {
                    '> .ant-input': {
                        paddingLeft: 46,
                    },
                },

                '> .ant-form-item-children-icon': {
                    display: 'none',
                },

                '> .ant-switch:(..ant-switch-checked)': {
                    backgroundColor: colors.blueGrey3,
                },

                '> .ant-calendar-picker': {
                    width: '100%',

                    '&:hover .ant-calendar-picker-input:not(.ant-input-disabled)': {
                        borderColor: colors.text,
                    },

                    '&:focus .ant-calendar-picker-input:not(.ant-input-disabled)': {
                        borderColor: colors.text,
                        boxShadow: 'none',
                    },

                    '&:active .ant-calendar-picker-input:not(.ant-input-disabled)': {
                        borderColor: colors.text,
                        boxShadow: 'none',
                    },
                },

                '& textarea.ant-input': {
                    minHeight: 96,
                },

                '& .ant-input': fontStyle,
                '& .ant-select': {
                    ...fontStyle,
                    padding: 0,

                    '&.ant-select-open > .ant-select-selection': {
                        boxShadow: 'unset',
                        borderColor: 'unset',
                    },
                },

                '& .ant-radio-group': {
                    marginTop: 8,

                    '> .ant-radio-wrapper': {
                        ...fontStyle,
                        margin: '0 20px 0 0',
                        padding: 0,

                        '> span:nth-child(2)': {
                            padding: '0 0 0 8px',
                        },
                    },
                },

                '& .ant-checkbox-group': {
                    marginTop: 8,

                    '> .ant-checkbox-wrapper': {
                        ...fontStyle,
                        margin: '0 20px 0 0',
                        padding: 0,

                        '> span:nth-child(2)': {
                            padding: '0 0 0 8px',
                        },
                    },
                },

                '> .ant-input-number': {
                    ...fontStyle,
                    padding: 0,
                    width: '100%',
                },
            },

            '> .ant-form-item-label': {
                lineHeight: 1,
                marginBottom: 4,
                marginLeft: labelMarginLeft,

                '> label': {
                    lineHeight: 1.2,
                    letterSpacing: '0.1px',
                    fontSize: 12,
                },

                '> label:after': {
                    content: 'none',
                },
            },
        },
    };
};
