import { ReduxUtils, MODULE_NAME } from '../../dependencies';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: MODULE_NAME,
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        typePrefix: 'FETCH_EXPORTS_',
    }),
    ...createTypes({
        typePrefix: 'CREATE_EXPORT_',
    }),
    ...createTypes({
        typePrefix: 'UPDATE_EXPORT_',
    }),
    ...createTypes({
        typePrefix: 'FETCH_EXPORT_',
    }),
    ...createTypes({
        typePrefix: 'DELETE_EXPORT_',
    }),
    ...createTypes({
        typePrefix: 'RUN_EXPORT_MANUALLY_',
    }),
    ...createTypes({
        types: ['SUBMIT_CREATE_EXPORT_FORM', 'SUBMIT_UPDATE_EXPORT_FORM', 'DELETE_EXPORT_WITH_SERVICE_KEY'],
    }),
});
