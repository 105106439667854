import {
    React,
    FelaComponent,
    useState,
    childrenPropType,
    ButtonDefault,
    FormattedMessage,
    Icons,
} from '../../dependencies';

import * as styles from './ShowMore.styles';

function ShowMore({ children }) {
    const [isExpanded, setExpandState] = useState(false);

    return (
        <>
            {isExpanded && <FelaComponent style={styles.expandedContent}>{children}</FelaComponent>}

            <FelaComponent style={styles.buttonWrapper}>
                <FelaComponent style={styles.button} isActive={isExpanded}>
                    {({ className: buttonClassName }) => (
                        <ButtonDefault
                            size="small"
                            onClick={() => setExpandState(!isExpanded)}
                            className={buttonClassName}
                        >
                            <FormattedMessage id="expense.create.form.showMore" />
                            <FelaComponent style={styles.icon} isExpanded={isExpanded}>
                                {({ className }) => <Icons.ChevronDown {...{ className }} />}
                            </FelaComponent>
                        </ButtonDefault>
                    )}
                </FelaComponent>
                <FelaComponent style={styles.divider} />
            </FelaComponent>
        </>
    );
}

ShowMore.propTypes = {
    children: childrenPropType.isRequired,
};

export default ShowMore;
