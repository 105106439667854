import { React, PropTypes, FelaComponent, Icons } from '../../dependencies';

import { ORDER_TYPE } from '../../constants';

import * as styles from './Switcher.styles';

function Switcher({ onClick, order }) {
    return (
        <FelaComponent style={styles.button}>
            {button => (
                <button className={button.className} onClick={onClick} type="button">
                    <FelaComponent style={styles.iconTop} active={order === ORDER_TYPE.asc}>
                        {icon => <Icons.Triangel className={icon.className} />}
                    </FelaComponent>
                    <FelaComponent style={styles.iconBottom} active={order === ORDER_TYPE.desc}>
                        {icon => <Icons.Triangel className={icon.className} />}
                    </FelaComponent>
                </button>
            )}
        </FelaComponent>
    );
}

Switcher.propTypes = {
    onClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(Object.values(ORDER_TYPE)),
};

Switcher.defaultProps = {
    order: null,
};

export default Switcher;
