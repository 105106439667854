import { ReduxUtils } from '../../dependencies';

const asyncType = ReduxUtils.createAsyncType({
    modulePrefix: 'ui/message',
});

export default ReduxUtils.strictObjectAccess({
    ...asyncType({
        types: ['ADD_MESSAGE_REQUEST', 'ADD_MESSAGE_RESOLVE', 'REMOVE_MESSAGE', 'HIDE_MESSAGE', 'CLEAR_MESSAGES'],
    }),
    ...asyncType({
        types: ['DISPLAY_SUCCESS_MESSAGE', 'DISPLAY_ERROR_MESSAGE'],
    }),
});
