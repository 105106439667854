import {
    config,
    authApi,
    Log,
    sagaEffects,
    ErrorsUtils,
    ModalsActions,
    Consts,
    MessagesActions,
    AuthUserActions,
} from '../../dependencies';

import { types, deleteMembershipSuccess, deleteMembershipFailure } from '../actions';
import { membershipSelector } from '../selectors';

const { put, takeEvery, select } = sagaEffects;

function* deleteMembership(action) {
    const { id } = action.meta;

    try {
        const membership = yield select(membershipSelector, id);
        yield* authApi.delete(config.api.membership, {
            uriParams: { id },
        });

        yield put(deleteMembershipSuccess(id));

        yield put(AuthUserActions.deleteMembership(id));

        yield put(ModalsActions.closeModal(Consts.modals.DELETE_MEMBERSHIP));

        yield put(
            MessagesActions.displaySuccessMessage({
                id: 'success.membership.delete',
                values: {
                    email: membership.user.email,
                },
            }),
        );
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.memberships.api.delete',
            },
        });

        yield put(deleteMembershipFailure(id, errorMessage));
    }
}

export default function* deleteMembershipWatcher() {
    yield takeEvery(types.DELETE_MEMBERSHIP_REQUEST, deleteMembership);
}
