import types from './types';

export const createCompanyRequest = requestData => ({
    type: types.CREATE_COMPANY_REQUEST,
    payload: requestData,
});

export const createCompanySuccess = (id, responseData) => ({
    type: types.CREATE_COMPANY_SUCCESS,
    meta: { id },
    payload: responseData,
});

export const createCompanyFailure = error => ({
    type: types.CREATE_COMPANY_FAILURE,
    error,
});

export const createCompanyInvalidate = () => ({
    type: types.CREATE_COMPANY_CANCEL,
});
