import React from 'react';

const Calendar = props => (
    <svg viewBox="0 0 15 14" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <path d="M12.6,5.6 L1.4,5.6 L1.4,3.4797 C1.4,3.1045 1.7045,2.8 2.0797,2.8 L2.8,2.8 L2.8,4.2 L4.2,4.2 L4.2,2.8 L9.8,2.8 L9.8,4.2 L11.2,4.2 L11.2,2.8 L11.9,2.8 C12.2864,2.8 12.6,3.1129 12.6,3.5 L12.6,5.6 Z M12.6,11.9 C12.6,12.285 12.285,12.6 11.9,12.6 L2.1,12.6 C1.7136,12.6 1.4,12.2871 1.4,11.9 L1.4,7 L12.6,7 L12.6,11.9 Z M0.0441,12.4327 C0.0441,13.2055 0.7154,14 1.4889,14 L12.6889,14 C13.4624,14 14.0224,13.2853 14.0224,12.4327 C14.0224,12.1597 14,3.7513 14,3.2956 C14,1.8382 13.8033,1.4 11.2,1.4 L11.2,0 L9.8,0 L9.8,1.4 L4.2,1.4 L4.2,0 L2.8,0 L2.8,1.4 L1.4,1.4 C0.63,1.4 0,2.03 0,2.8 L0.0441,12.4327 Z" />
        </g>
    </svg>
);

export default Calendar;
