import { ExpensesSelectors, createSelector, ExpensesUtils, Consts } from '../../dependencies';

const vatModeEnum = {
    [true]: Consts.vatMode.BASE,
    [false]: Consts.vatMode.FINAL,
};

export const expenseSummarySelector = createSelector(
    [ExpensesSelectors.expenseSelector],
    expense => {
        const { items, vatFromBase, currency } = expense;

        const pricesPerUnits = items.map(({ quantity, vatRate, unitPriceWithoutVat, unitPriceWithVat }) => {
            const pricePerUnit = vatFromBase ? unitPriceWithoutVat : unitPriceWithVat;

            return {
                pricePerUnits: pricePerUnit * quantity,
                vatRate,
            };
        });

        const byVatRate = ExpensesUtils.groupByVatRate(pricesPerUnits);
        const flattenByVatRated = ExpensesUtils.flatByVatRated(byVatRate, vatModeEnum[vatFromBase]);
        const priceWithVatTotal = ExpensesUtils.calcPriceWithVatTotal(flattenByVatRated);
        const priceWithoutVatTotal = ExpensesUtils.calcPriceWithoutVatTotal(flattenByVatRated);

        return {
            currency,
            priceWithVatTotal,
            priceWithoutVatTotal,
            byVatRate: flattenByVatRated,
        };
    },
);
