export const summary = () => ({
    width: 558,
    marginLeft: 'auto',
    marginRight: 0,
    maxWidth: '100%',
    marginTop: 64,
});

export const label = ({ theme: { colors } }) => ({
    color: colors.blueGrey4,
    letterSpacing: '0.3px',
    lineHeight: 1.36,
    fontSize: '14px',
});

export const value = ({ theme: { colors } }) => ({
    color: colors.text,
    letterSpacing: '0.2px',
    lineHeight: 1.56,
    fontSize: '16px',
});
