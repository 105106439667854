import { React, PropTypes, TextField, injectIntl, InputWrapper } from '../../../dependencies';

import * as styles from './ExpenseOriginalNumber.styles';

function ExpenseOriginalNumber({ intl: { formatMessage }, disabled }) {
    return (
        <InputWrapper style={styles.expenseNumberOriginal}>
            <TextField
                {...{
                    name: 'originalNumber',
                    placeholder: formatMessage({
                        id: 'expense.form.originalNumber.placeholder',
                    }),
                    label: formatMessage({
                        id: 'expense.form.originalNumber.label',
                    }),
                    parse: ExpenseOriginalNumber.parseValue,
                    disabled,
                }}
            />
        </InputWrapper>
    );
}

ExpenseOriginalNumber.parseValue = (value = '') => value.toUpperCase();

ExpenseOriginalNumber.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default injectIntl(ExpenseOriginalNumber);
