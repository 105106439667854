import { sagaEffects, push, Selectors, Utils, qs } from '../../dependencies';
import { encodeFilterParams } from '../../utils';

import { types } from '../actions';

const { select, takeEvery, put } = sagaEffects;

function* setFilterUrlParams(action) {
    const nextSearch = encodeFilterParams(action.payload);
    const location = yield select(Selectors.locationSelector);
    const search = {
        ...qs.parse(location.search),
        ...nextSearch,
    };

    delete search.page;

    const nextLocation = {
        ...location,
        key: Utils.uniqueNonSecureId(),
        search: qs.stringify(search),
    };

    yield put(push(nextLocation));
}

export default function*() {
    yield takeEvery(types.SET_FILTER_URL_PARAMS, setFilterUrlParams);
}
