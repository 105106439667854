import { React, PropTypes, RegistrationNumberField, useDispatch } from '../../dependencies';

import { fetchAresDataRequest } from '../../services/actions';

const RegistrationNumberFieldGroup = ({ formId, tabIndex }) => {
    const dispatch = useDispatch();

    return (
        <RegistrationNumberField
            formId={formId}
            onSubmit={() => dispatch(fetchAresDataRequest(formId))}
            tabIndex={tabIndex}
        />
    );
};

RegistrationNumberFieldGroup.propTypes = {
    formId: PropTypes.string.isRequired,
    tabIndex: PropTypes.number.isRequired,
};

export default React.memo(RegistrationNumberFieldGroup);
