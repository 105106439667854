import { ReduxUtils, MODULE_NAME } from '../../dependencies';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: MODULE_NAME,
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        typePrefix: 'FETCH_DISPOSABLE_EXPORT_',
    }),
});
