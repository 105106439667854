import { React, PropTypes, TextField, injectIntl, InputWrapper } from '../../../dependencies';

import * as styles from './ExpenseNumber.styles';

function ExpenseNumber({ intl: { formatMessage }, disabled }) {
    return (
        <InputWrapper style={styles.expenseNumber}>
            <TextField
                {...{
                    name: 'number',
                    placeholder: formatMessage({
                        id: 'expense.form.number.label',
                    }),
                    label: formatMessage({
                        id: 'expense.form.number.label',
                    }),
                    parse: ExpenseNumber.parseValue,
                    disabled,
                }}
            />
        </InputWrapper>
    );
}

ExpenseNumber.parseValue = (value = '') => value.toUpperCase();

ExpenseNumber.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default injectIntl(ExpenseNumber);
