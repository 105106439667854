import { reduxForm, config, Filter, submitForm, compose, connect, isEmpty, injectIntl } from '../dependencies';

import DisposableExport from '../components/DisposableExport';

import { fetchDisposableExportRequest } from '../services/actions';
import { filterFormInitialValuesSelector } from '../services/selectors';
import { transformFormValuesToUrlParams } from '../utils';

const formId = config.forms.disposableExport;

const mapStateToProps = state => {
    return {
        initialValues: filterFormInitialValuesSelector(state),
    };
};

const formParams = {
    form: formId,
    enableReinitialize: true,
    onSubmit(values, dispatch) {
        const submit = submitForm(formId, fetchDisposableExportRequest);
        dispatch(submit(values));
    },
    onChange(values, dispatch, { pristine }, previousValues) {
        // Check if onChange is called on initialValues (there are no previousValues)
        if (pristine || isEmpty(previousValues)) {
            return;
        }

        const formValuesAsUrlParams = transformFormValuesToUrlParams(values);

        dispatch(Filter.actions.setFilterUrlParams(formValuesAsUrlParams));
    },
};

export default compose(
    injectIntl,
    connect(mapStateToProps),
    reduxForm(formParams),
)(DisposableExport);
