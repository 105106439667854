import { createSelector, isEqual } from '../../dependencies';

const selectGroup = (state, groupId) => state.cache[groupId];

const passNextValues = (state, groupId, nextValues) => nextValues;

export const areGroupValuesEqualSelector = createSelector(
    [passNextValues, selectGroup],
    isEqual,
);
