import {
    React,
    PropTypes,
    ServiceKeyName,
    connectFela,
    routePaths,
    UI,
    FormattedMessage,
    Link,
} from '../../../dependencies';

import ButtonRunExport from '../../../containers/ButtonRunExport';

import * as Styles from './Item.styles';

const { ButtonDefault } = UI;

const Item = ({ id, name, period, serviceKeyId, styles }) => {
    return (
        <div className={styles.item}>
            <span>{name}</span>
            <ServiceKeyName id={serviceKeyId} />
            <span>{period}</span>
            <div className={styles.buttons}>
                <ButtonRunExport exportId={id} />
                <Link
                    to={routePaths.EXPORT_RECURRING_EDIT.replace(':exportId', id).replace(
                        ':serviceKeyId',
                        serviceKeyId,
                    )}
                >
                    <ButtonDefault size="small">
                        <FormattedMessage id="export.button.update" />
                    </ButtonDefault>
                </Link>
            </div>
        </div>
    );
};

Item.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    serviceKeyId: PropTypes.string.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(Item);
