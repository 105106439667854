import { ReduxUtils } from '../dependencies';

export const routePaths = ReduxUtils.strictObjectAccess({
    EXPORTS: '/exports',
    EXPORTS_DISPOSABLE: '/exports/disposable',
    EXPORTS_RECURRING: '/exports/recurring',
    EXPORT_RECURRING_NEW: '/exports/recurring/new',
    EXPORT_RECURRING: '/exports/recurring/:exportId/:serviceKeyId',
    EXPORT_RECURRING_EDIT: '/exports/recurring/:exportId/:serviceKeyId/edit',
});
