import { sagaEffects } from '../../dependencies';

import fetchExports from './fetchExports';
import runExportManually from './runExportManually';
import createExport from './createExport';
import updateExport from './updateExport';
import fetchExport from './fetchExport';
import deleteExport from './deleteExport';
import singles from './singles';

const { all } = sagaEffects;

export default function* () {
    yield all([
        fetchExports(),
        runExportManually(),
        createExport(),
        fetchExport(),
        updateExport(),
        deleteExport(),
        singles(),
    ]);
}
