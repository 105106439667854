export const pagination = ({ theme: { text, colors, transitions } }) => {
    const prevAndNext = {
        height: 22,
        minWidth: 22,
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > button': {
            display: 'flex',
            alignItems: 'center',
        },
    };

    return {
        padding: '32px 0',
        display: 'flex',
        justifyContent: 'center',

        '& > .ant-pagination': {
            display: 'flex',
            alignItems: 'center',

            '> .ant-pagination-prev': {
                marginRight: 12,
            },
            '> .ant-pagination-next': {
                marginLeft: 4,
            },
        },

        '& .ant-pagination-item a': {
            color: 'unset',
            margin: 'unset',
        },

        '& > .ant-pagination .ant-pagination-item-link': {
            border: 'none',
            lineHeight: 'initial',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },

        '& > .ant-pagination > .ant-pagination-item': {
            border: 'none',
            fontFamily: text.fontFamily,
            minWidth: 22,
            height: 22,

            color: colors.text,
            fontSize: '16px',
            fontWeight: 500,
            letterSpacing: '0.2px',
            lineHeight: 1.56,

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',

            transition: `background-color ${transitions.text}`,

            onHover: {
                backgroundColor: colors.primarySelection,
            },
            onFocus: {
                backgroundColor: colors.primarySelection,
            },
        },

        '& > .ant-pagination > .ant-pagination-next': prevAndNext,

        '& > .ant-pagination > .ant-pagination-prev': prevAndNext,

        '& > .ant-pagination > .ant-pagination-item-active': {
            color: colors.text,
            backgroundColor: colors.primarySelection,
        },

        '& > .ant-pagination > .ant-pagination-item-active > a': {
            color: colors.text,
        },
    };
};

export const icon = ({ theme: { colors, transitions }, rotateZ, disabled }) => ({
    height: 14,
    fill: disabled ? colors.blueGrey2 : colors.text,
    cursor: disabled ? 'not-allowed' : 'pointer',
    transform: rotateZ ? `rotateZ(${rotateZ}deg)` : '',
    transition: `fill ${transitions.text}`,
});
