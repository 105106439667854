import { React, childrenPropType, FelaComponent } from '../../dependencies';

import * as styles from './Wrapper.styles';

function Wrapper({ children }) {
    return <FelaComponent style={styles.wrapper}>{children}</FelaComponent>;
}

Wrapper.propTypes = {
    children: childrenPropType.isRequired,
};

export default Wrapper;
