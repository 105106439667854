import { React, useSelector, useDispatch } from '../dependencies';

import { openModal, closeModal } from '../services/actions';
import { modalSelector } from '../services/selectors';

export function useModal(id) {
    const dispatch = useDispatch();
    const { isOpen, data } = useSelector(state => modalSelector(state, id));

    const open = React.useCallback(
        data => {
            dispatch(openModal(id, data));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id],
    );
    const close = React.useCallback(() => {
        dispatch(closeModal(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return {
        isOpen,
        open,
        close,
        data,
    };
}
