import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const actionTypes = {
    REQUEST: types.DELETE_MEMBERSHIP_REQUEST,
    SUCCESS: types.DELETE_MEMBERSHIP_SUCCESS,
    FAILURE: types.DELETE_MEMBERSHIP_FAILURE,
    CANCEL: types.DELETE_MEMBERSHIP_CANCEL,
};

const basicReducer = ReduxUtils.basicApiReducer({
    actionTypes,
});

export default ReduxUtils.containerReducer({
    childReducer: basicReducer,
    actionTypes: Object.values(actionTypes),
    selectors: {
        itemId: action => action.meta.id,
    },
});
