import { React, PropTypes, UI, useDispatch, CustomPropTypes, FormattedMessage } from '../../dependencies';

import { Level } from '../../constants';

const { Icons } = UI;

const IconEnum = {
    [Level.SUCCESS]: Icons.HappyFace,
    [Level.ERROR]: Icons.SadFace,
};

const Content = ({ styles, level, message, button }) => {
    const dispatch = useDispatch();
    const Icon = IconEnum[level];

    return (
        <div className={styles.content}>
            <Icon className={styles.icon} />
            <div className={styles.text}>
                <FormattedMessage {...message} />
            </div>
            {button && (
                <button className={styles.button} type="button" onClick={() => dispatch(button.onClick)}>
                    <FormattedMessage {...button.message} />
                </button>
            )}
        </div>
    );
};

export const MessageContentPropTypes = {
    level: PropTypes.oneOf(Object.values(Level)).isRequired,
    message: CustomPropTypes.message.isRequired,
    button: PropTypes.shape({
        message: CustomPropTypes.message.isRequired,
        onClick: PropTypes.shape({
            type: PropTypes.string.isRequired,
        }).isRequired,
    }),
};

Content.propTypes = {
    styles: PropTypes.shape().isRequired,
    ...MessageContentPropTypes,
};

Content.defaultProps = {
    button: null,
};

export default Content;
