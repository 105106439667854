import { React, PropTypes, useState, capitalize, SelectField, InputWrapper, Utils } from '../../../../dependencies';

import CreateSupplierButton from '../CreateSupplierButton';

import * as styles from './SupplierField.styles';

function SupplierField({ intl, disabled, openModal, options, onSearch }) {
    const [buttonIsVisible, setButtonVisibility] = useState(false);
    const [persistButtonVisibility, setVisiblityPersistance] = useState(false);

    return (
        <InputWrapper style={styles.input}>
            <SelectField
                {...{
                    name: 'supplier',
                    label: intl.formatMessage({
                        id: 'expense.form.supplier.name.label',
                    }),
                    placeholder: intl.formatMessage({
                        id: 'expense.form.supplier.name.placeholder',
                    }),
                    parse: SupplierField.capitalize,
                    autoComplete: 'organization',
                    disabled,
                    options,
                    showSearch: true,
                    filterOption: Utils.filterOption,
                    allowClear: true,
                    notFoundContent: null,
                    onDropdownVisibleChange(isOpen) {
                        if (isOpen) {
                            setButtonVisibility(isOpen);
                        } else if (!persistButtonVisibility) {
                            setButtonVisibility(false);
                        }
                    },
                    onSearch,
                }}
            />

            {buttonIsVisible && (
                <CreateSupplierButton
                    onMouseEnter={() => {
                        setVisiblityPersistance(true);
                    }}
                    onMouseLeave={() => {
                        setVisiblityPersistance(false);
                    }}
                    onClick={() => {
                        openModal();
                        setVisiblityPersistance(false);
                        setButtonVisibility(false);
                    }}
                />
            )}
        </InputWrapper>
    );
}

SupplierField.propTypes = {
    intl: PropTypes.shape().isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ).isRequired,
    disabled: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
};

SupplierField.capitalize = (value = '') => capitalize(value);

export default SupplierField;
