import { Consts, Utils } from '../dependencies';

import { VAT_MODE, VAT_RATE, DOCUMENT_TYPE, PAYMENT_METHOD, TAX_DEDUCTIBLE, roundingMode } from '../constants';

const { startOfDayInUtcFormat } = Utils;

export const expenseItemInitialValues = Object.freeze({
    name: '',
    units: '',
    quantity: 1,
    pricePerUnit: undefined,
    vatRate: VAT_RATE,
});

const DAYS_14 = 14 * 24 * 60 * 60 * 1000;
const dateNow = new Date();

export const expenseStatisticalInitialValues = {
    vatMode: VAT_MODE,
    paymentMethod: PAYMENT_METHOD,
    issuedOn: startOfDayInUtcFormat(dateNow),
    items: [expenseItemInitialValues],
    files: [],
    tags: [],
    fileUrl: '',
    taxDeductible: TAX_DEDUCTIBLE,
    roundingMode: roundingMode.NONE,
};

export const expenseRegularInitialValues = {
    ...expenseStatisticalInitialValues,
    documentType: Consts.documentType.INVOICE,
    receivedOn: startOfDayInUtcFormat(dateNow),
    taxableFulfillmentDue: startOfDayInUtcFormat(dateNow),
    dueOn: startOfDayInUtcFormat(dateNow.valueOf() + DAYS_14),
    originalNumber: '',
    variableSymbol: '',
};

const recurringExpense = {
    name: '',
    startAt: startOfDayInUtcFormat(dateNow),
    endAt: null,
    period: Consts.period.MONTHLY,
    tags: [],
    supplier: '',
    vatMode: VAT_MODE,
    items: [expenseItemInitialValues],
};

export const expenseRecurringStatisticalInitialValues = recurringExpense;

export const expenseRecurringRegularInitialValues = {
    ...recurringExpense,
    originalNumber: '',
    documentType: DOCUMENT_TYPE,
    paymentMethod: PAYMENT_METHOD,
    variableSymbol: '',
    taxDeductible: TAX_DEDUCTIBLE,
    fileUrl: '',
    files: [],
};

export const toolboxInitialValues = {
    statistic: false,
    recurring: false,
};
