import { React, PropTypes } from '../../dependencies';

import Header from './Header';
import ExportsFetcher from '../ExportsFetcher';
import RecurringExportList from '../RecurringExportList';
import RecurringExportListHeader from '../RecurringExportList/Header';

const RecurringExports = ({ styles }) => (
    <>
        <Header />

        <div className={styles.exports}>
            <RecurringExportListHeader />
            <ExportsFetcher>
                <RecurringExportList />
            </ExportsFetcher>
        </div>
    </>
);

RecurringExports.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default RecurringExports;
