export const types = {
    INIT_ACTIVE_COMPANY: 'INIT_ACTIVE_COMPANY',
    SET_ACTIVE_COMPANY_REQUEST: 'SET_ACTIVE_COMPANY_REQUEST',
    SET_ACTIVE_COMPANY_RESOLVE: 'SET_ACTIVE_COMPANY_RESOLVE',
    REMOVE_ACTIVE_COMPANY: 'REMOVE_ACTIVE_COMPANY',
};

export const initActiveCompany = companyId => ({
    type: types.INIT_ACTIVE_COMPANY,
    companyId,
});

export const setActiveCompanyRequest = (companyId, options) => ({
    type: types.SET_ACTIVE_COMPANY_REQUEST,
    companyId,
    options: {
        hardReload: false,
        newCompany: false,
        ...options,
    },
});

export const setActiveCompanyResolve = (companyId, options = {}) => ({
    type: types.SET_ACTIVE_COMPANY_RESOLVE,
    companyId,
    options,
});

export const removeActiveCompany = () => ({
    type: types.REMOVE_ACTIVE_COMPANY,
});
