import { Utils } from 'modules/reports/dependencies';
import { types } from 'modules/reports/services/actions';

const initialState = {};

export default function reportsReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_REPORTS_SUCCESS: {
            const { byId, ids } = action.payload;
            const nextState = { ...state };

            ids.forEach(id => {
                nextState[id] = {
                    ...nextState[id],
                    ...byId[id],
                };
            });

            return nextState;
        }

        case types.CREATE_REPORT_SUCCESS:
        case types.FETCH_REPORT_SUCCESS:
        case types.UPDATE_REPORT_SUCCESS:
            return {
                ...state,
                [action.meta.id]: action.payload,
            };

        case types.DELETE_REPORT_SUCCESS:
            return Utils.immutablePropDelete(state, action.meta.id);

        default:
            return state;
    }
}
