import { Consts, Config, round } from '../../dependencies';

const { maximumFractionDigits } = Config.currency;

export const groupByVatRate = (items = []) => {
    const byVatRate = {};

    items.forEach(item => {
        const { vatRate, pricePerUnits } = item;
        const groupValue = byVatRate[vatRate] || 0;

        byVatRate[vatRate] = groupValue + pricePerUnits;
    });

    return Object.entries(byVatRate).map(([vatRate, group]) => [Number.parseFloat(vatRate), group]);
};

export const flatByVatRated = (byVatRate, vatMode) =>
    byVatRate.map(([vatRate, groupPriceTotal]) => {
        const priceWithoutVatTotal =
            vatMode === Consts.vatMode.BASE ? groupPriceTotal : groupPriceTotal / (1 + vatRate);

        return {
            vatRate,
            priceWithoutVatTotal: round(priceWithoutVatTotal, maximumFractionDigits + 2),
            vat: round(priceWithoutVatTotal * vatRate, maximumFractionDigits + 2),
        };
    });

export const calcPriceWithoutVatTotal = flattenByVatRated => {
    const withoutVatTotal = flattenByVatRated.reduce((total, { priceWithoutVatTotal }) => {
        return total + priceWithoutVatTotal;
    }, 0);

    return round(withoutVatTotal, maximumFractionDigits + 2);
};

export const calcPriceWithVatTotal = flattenByVatRated => {
    const withVatTotal = flattenByVatRated.reduce((total, { priceWithoutVatTotal, vat }) => {
        return total + priceWithoutVatTotal + vat;
    }, 0);

    return round(withVatTotal, maximumFractionDigits + 2);
};
