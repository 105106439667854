import { React, Switch, Route, Redirect, Tabs, Container, Page } from '../../dependencies';

import { DisposableExport } from '../../modules/disposable-export';
import { RecurringExports } from '../../modules/recurring-exports';
import { ExportAdd, ExportEdit } from '../../modules/recurring-export';

import { routePaths } from '../../constants';

const routes = [
    {
        path: routePaths.EXPORTS_RECURRING,
        render: () => <RecurringExports />,
    },
    {
        path: routePaths.EXPORTS_DISPOSABLE,
        render: () => <DisposableExport />,
    },
    {
        path: routePaths.EXPORT_RECURRING_NEW,
        render: () => <ExportAdd />,
    },
    {
        path: routePaths.EXPORT_RECURRING_EDIT,
        render: ({ match }) => <ExportEdit {...match?.params} />,
    },
    {
        render: () => <Redirect to={routePaths.EXPORTS_RECURRING} />,
    },
];

const tabItems = [
    {
        path: routePaths.EXPORTS_RECURRING,
        messageId: 'exports.tab.recurring',
    },
    {
        path: routePaths.EXPORTS_DISPOSABLE,
        messageId: 'exports.tab.disposable',
    },
];

const tabsRoute = {
    path: tabItems.map(item => item.path),
    render: () => <Tabs items={tabItems} />,
    exact: true,
};

export default function Lists() {
    return (
        <Container>
            <Page>
                <Route {...tabsRoute} />

                <Switch>
                    {routes.map((route, index) => (
                        <Route key={index} {...route} exact />
                    ))}
                </Switch>
            </Page>
        </Container>
    );
}
