import { React, PropTypes, Input as AntdInput, config, moment } from 'modules/date-range-picker/dependencies';

import { Errors } from '../RangeInput.consts';
import { isValueFormatInvalid } from '../RangeInput.utils';

const Input = ({
    initialValue,
    onSubmit,
    hasValidationError,
    onValidationError,
    placeholderId,
    formatDate,
    formatMessage,
}) => {
    const [value, setValue] = React.useState(formatDate(initialValue));

    const changeHandler = e => {
        const nextValue = e.target.value;

        if (hasValidationError && !isValueFormatInvalid(nextValue)) {
            onValidationError(null);
        }

        setValue(nextValue);
    };

    const submitHandler = () => {
        const date = moment(value, config.date).toDate();

        if (isValueFormatInvalid(date)) {
            onValidationError(Errors.FORMAT_ERROR);
            return;
        }

        setValue(formatDate(date));
        onSubmit(date);
    };

    React.useEffect(() => {
        setValue(formatDate(initialValue));
        // eslint-disable-next-line
    }, [initialValue]);

    return (
        <AntdInput
            value={value}
            onChange={changeHandler}
            onPressEnter={submitHandler}
            onBlur={submitHandler}
            placeholder={formatMessage({
                id: placeholderId,
            })}
        />
    );
};

Input.propTypes = {
    formatDate: PropTypes.func.isRequired,
    formatMessage: PropTypes.func.isRequired,

    initialValue: PropTypes.instanceOf(Date).isRequired,
    hasValidationError: PropTypes.bool.isRequired,
    placeholderId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValidationError: PropTypes.func.isRequired,
};

export default React.memo(Input);
