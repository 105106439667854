import { createSelector, ActiveCompanySelectors } from '../../dependencies';

export const selectCompanies = state => state.entities.companies;

export const companiesSelector = createSelector(selectCompanies, ({ byId, ids }) => ids.map(id => byId[id]));

export const activeCompanySelector = createSelector(
    [selectCompanies, ActiveCompanySelectors.activeCompanyIdSelector],
    (companies, activeCompanyId) => companies.byId[activeCompanyId],
);

export const activeCompanyNameSelector = createSelector(activeCompanySelector, company => company?.name || '-');

export const activeCompanyCurrencySelector = createSelector(activeCompanySelector, company => company?.currency || '');
