import {
    React,
    PropTypes,
    VendorDateRangePicker,
    ButtonPrimary,
    ButtonDefault,
    FormattedMessage,
    pickerLocalization,
    Log,
    theme,
    connectFela,
    startOfDay,
    endOfDay,
    injectIntl,
    compose,
    Utils,
} from 'modules/date-range-picker/dependencies';

import { StaticDateRangesCreators } from '../../config';
import { getRelativePeriod } from '../../utils';

import RangeInput from './RangeInput';
import staticRanges from './staticRanges';
import { RangePropType } from '../propTypes';

import * as Styles from './PickerDropdown.styles';

function createInitialValue({ from, to, relativePeriod }) {
    if (!relativePeriod) {
        return {
            startDate: startOfDay(new Date(from)),
            endDate: endOfDay(new Date(to)),
            relativePeriod: null,
        };
    }

    const range = StaticDateRangesCreators[relativePeriod]();

    return {
        relativePeriod,
        startDate: startOfDay(range.from),
        endDate: endOfDay(range.to),
    };
}

const PickerDropdown = ({ initialValue, onClose, onSubmit, styles, intl: { formatMessage, locale } }) => {
    const [value, setValue] = React.useState(() => createInitialValue(initialValue));

    const setRangeValue = React.useCallback(
        ({ startDate, endDate }) => {
            const from = startOfDay(startDate);
            const to = endOfDay(endDate);

            setValue({
                startDate: from,
                endDate: to,
                relativePeriod: getRelativePeriod({
                    startDate: Utils.getISODateWithoutTimezone(from),
                    endDate: Utils.getISODateWithoutTimezone(to),
                }),
            });
        },
        [setValue],
    );

    const onChangeHandler = ranges => {
        setRangeValue(ranges[PickerDropdown.rangeKey]);
    };

    const onSubmitHandler = () => {
        const { startDate, endDate, relativePeriod } = value;

        onSubmit({
            from: relativePeriod ? null : startDate,
            to: relativePeriod ? null : endDate,
            relativePeriod,
        });
    };

    if (!pickerLocalization[locale]) {
        Log.error(`DateRangePicker: Missing localization file for ${locale} locale.`);
    }

    return (
        <div className={styles.wrapper}>
            <VendorDateRangePicker
                {...{
                    ...PickerDropdown.dateRangePicker,
                    ranges: [
                        {
                            key: PickerDropdown.rangeKey,
                            ...value,
                        },
                    ],
                    locale: pickerLocalization[locale],
                    className: styles.dateRangePicker,
                    onChange: onChangeHandler,
                }}
            />

            <RangeInput {...{ onChange: setRangeValue, value, locale, formatMessage }} />

            <div className={styles.footer}>
                <ButtonDefault type="button" onClick={onClose} size="small">
                    <FormattedMessage id="dateRangePicker.button.cancel" />
                </ButtonDefault>
                <ButtonPrimary type="button" id="submitSelection" onClick={onSubmitHandler} size="small">
                    <FormattedMessage id="dateRangePicker.button.submit" />
                </ButtonPrimary>
            </div>
        </div>
    );
};

PickerDropdown.rangeKey = 'selection';

PickerDropdown.dateRangePicker = {
    showDateDisplay: false,
    showPreview: false,
    staticRanges,
    inputRanges: [],
    rangeColors: [theme.colors.primarySelection],
};

PickerDropdown.propTypes = {
    initialValue: RangePropType.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    styles: PropTypes.shape().isRequired,
    intl: PropTypes.shape().isRequired,
};

export default compose(injectIntl, connectFela(Styles))(PickerDropdown);
