export const card = ({ theme: { colors } }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridRowGap: 4,
    gridColumnGap: 16,
    alignItems: 'center',
    padding: 24,
    backgroundColor: colors.blueGrey1,
    marginTop: 40,
});

export const labelPrimary = ({ theme: { colors } }) => ({
    color: colors.blueGrey4,
    lineHeight: 1.36,
    fontSize: '14px',
    letterSpacing: '0.3px',
});

export const valuePrimary = ({ theme: { colors } }) => ({
    color: colors.text,
    fontSize: '24px',
    lineHeight: 1.29,
    letterSpacing: '0.3px',
    textAlign: 'right',
});

export const labelSecondary = ({ theme: { colors } }) => ({
    color: colors.blueGrey3,
    lineHeight: 1.36,
    fontSize: '14px',
    letterSpacing: '0.3px',
});

export const valueSecondary = ({ theme: { colors } }) => ({
    color: colors.blueGrey3,
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: 1.36,
    textAlign: 'right',
});
