import { sagaEffects, ExpensesSelectors, submitForm, ExpensesActions, Modals } from '../../dependencies';
import * as Config from '../../config';

import { types } from '../actions';

const { takeEvery, put, select } = sagaEffects;

// NOTE: open confirmation modal before submitting if the expense is synced
function* handleSubmitRequest(action) {
    const { expenseId, formId } = action.payload;

    const expenseIsSynced = yield select(ExpensesSelectors.expenseIsSyncedSelector, expenseId);

    if (expenseIsSynced) {
        yield put(Modals.actions.openModal(Config.Modals.CHANGE_EXPORTED_EXPENSE));
        return;
    }

    const submit = submitForm(formId, () => ExpensesActions.updateExpenseRequest(expenseId));

    yield put(submit());
}

export default function* submitFormHandler() {
    yield takeEvery(types.EDIT_EXPENSE_FORM_SUBMIT_REQUEST, handleSubmitRequest);
}
