import { Utils } from '../../dependencies';
import { Fields } from '../../constants';

const { InputValidation } = Utils;
const { getFieldsErrors, validatorsWithMessage, translate } = InputValidation;
const { isRequired, isSafePassword } = validatorsWithMessage;

const validators = {
    [Fields.OLD_PASSWORD]: [isRequired],
    [Fields.NEW_PASSWORD]: [isRequired, isSafePassword],
};

export default function validate(values, { intl }) {
    const errors = getFieldsErrors(validators, values);

    return translate(intl.formatMessage, errors);
}
