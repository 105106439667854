import { React, FelaComponent, PropTypes, ButtonDefault, ButtonPrimary } from '../../dependencies';

import style from './ModalFooter.style';

const ModalFooter = ({ okText, onOk, okButtonProps, cancelText, onCancel, cancelButtonProps, confirmLoading }) => (
    <FelaComponent style={style}>
        {cancelText && (
            <ButtonDefault
                {...{
                    ...cancelButtonProps,
                    onClick: onCancel,
                    disabled: confirmLoading,
                }}
            >
                {cancelText}
            </ButtonDefault>
        )}
        {okText && (
            <ButtonPrimary
                {...{
                    ...okButtonProps,
                    onClick: onOk,
                    loading: confirmLoading,
                    disabled: confirmLoading,
                }}
            >
                {okText}
            </ButtonPrimary>
        )}
    </FelaComponent>
);

ModalFooter.propTypes = {
    cancelText: PropTypes.node.isRequired,
    onCancel: PropTypes.func.isRequired,
    cancelButtonProps: PropTypes.shape({}),
    okText: PropTypes.node.isRequired,
    onOk: PropTypes.func.isRequired,
    okButtonProps: PropTypes.shape({}),
    confirmLoading: PropTypes.bool.isRequired,
};

ModalFooter.defaultProps = {
    cancelButtonProps: {},
    okButtonProps: {},
};

export default ModalFooter;
