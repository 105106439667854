import { Selectors, createSelector, qs, config } from '../../dependencies';

export const decodeOrderParamsSelector = createSelector(
    Selectors.locationSelector,
    location => {
        const { order = null, orderBy = null } = qs.parse(location.search);

        return {
            order,
            orderBy,
        };
    },
);

export const activeOrderParams = createSelector(
    decodeOrderParamsSelector,
    ({ order, orderBy }) => ({
        activeOrderBy: orderBy,
        activeOrderType: order,
    }),
);

const { dateFieldOptions, defaultInitials } = config.filters;

export const orderSelector = createSelector(
    decodeOrderParamsSelector,
    ({ order }) => order || defaultInitials.order,
);

export const dateFieldSelector = createSelector(
    decodeOrderParamsSelector,
    ({ orderBy }) => {
        return dateFieldOptions[orderBy] ? orderBy : defaultInitials.dateRangeField;
    },
);
