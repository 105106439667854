import { startOfDay, endOfDay } from 'date-fns';
import moment from 'moment';

export const formatDate = (locale, value) => {
    if (!value) {
        return value;
    }

    const formatter = new Intl.DateTimeFormat(locale, {
        timeZone: 'UTC',
    });

    return formatter.format(new Date(value));
};

export const isDateInvalid = date => Number.isNaN(Date.parse(date));

const getTimezoneOffset = (date = Date.now()) => new Date(date).getTimezoneOffset() * 60 * 1000 * -1;

/*
    converts the input date to ISO date string format without changing the date value

    1. get current timezone offset in ms
    2. add the offset to the input date
    3. convert the result date to ISO string 
*/
export const getISODateWithoutTimezone = inputDate => {
    if (!inputDate) {
        return inputDate;
    }

    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);

    if (isDateInvalid(date)) {
        throw new TypeError(
            `Received an invalid date parameter: '${inputDate}'. 'inputDate' must be date object or date string.`,
        );
    }

    const timezoneOffset = getTimezoneOffset(inputDate);

    return new Date(Date.parse(inputDate) + timezoneOffset).toISOString();
};

/*
    start of the day in user's timezone in UTC format
*/

const applyDateToUtcFormat = (date, formatter) => {
    return date ? getISODateWithoutTimezone(formatter(date)) : date;
};

export const startOfDayInUtcFormat = date => applyDateToUtcFormat(new Date(date), startOfDay);

export const endOfDayInUtcFormat = date => applyDateToUtcFormat(new Date(date), endOfDay);

export const parseMomentDateInput = momentDate => {
    if (isDateInvalid(momentDate)) {
        return momentDate;
    }

    const date = moment.isMoment(momentDate) ? momentDate.toString() : momentDate;

    return startOfDayInUtcFormat(date);
};

export const normalizeInput = input =>
    String(input)
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

export const parseStringIds = ids => {
    if (!ids) {
        return null;
    }

    const result = ids.map(value => Number.parseInt(value, 10)).filter(Number.isInteger);

    return result.length > 0 ? result : null;
};

export const toUpperCase = (value = '') => (value ? value.toUpperCase() : value);
