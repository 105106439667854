import {
    React,
    useSelector,
    ExpensesActions,
    Actions,
    useDispatch,
    ExpensesSelectors,
    Consts,
    Selectors,
} from '../dependencies';

import { expensesParamsSelector } from '../services/selectors';

import { useSetInitPageParam } from './useSetInitPageParam';

const { fetchExpensesInvalidate } = ExpensesActions;
const { filterExpensesRequest } = Actions;
const { fetchExpensesApiSelector } = ExpensesSelectors;

function useFilterParams(apiParamsSelector = expensesParamsSelector) {
    const expensesParams = useSelector(apiParamsSelector);

    return [expensesParams, JSON.stringify(expensesParams)];
}

export function useFetchExpenses(groupId, apiParamsSelector) {
    const dispatch = useDispatch();
    const { inProgress, success, error } = useSelector(state => fetchExpensesApiSelector(state, groupId));
    const [expensesParams, stringifiedParams] = useFilterParams(apiParamsSelector);
    const page = useSelector(Selectors.pageUrlParamSelector);

    useSetInitPageParam(groupId);

    React.useEffect(() => {
        // FIXME: groupId !== Consts.expensesGroups.DASHBOARD is hacky solution to prevent redundant request
        if (!inProgress && groupId !== Consts.expensesGroups.DASHBOARD) {
            dispatch(
                filterExpensesRequest(groupId, {
                    ...expensesParams,
                    page,
                }),
            );
        }
        // eslint-disable-next-line
    }, [groupId, stringifiedParams, page]);

    React.useEffect(() => {
        return () => {
            if (inProgress) {
                dispatch(fetchExpensesInvalidate(groupId));
            }
        };
        // eslint-disable-next-line
    }, [groupId]);

    return {
        inProgress,
        success,
        error: {
            message: error,
            retry() {
                dispatch(
                    filterExpensesRequest(groupId, expensesParams, {
                        forceUpdate: true,
                    }),
                );
            },
        },
    };
}
