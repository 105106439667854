import { configure } from '@ackee/redux-utils';
import * as Log from '../loglevel';

configure({
    logger: Log,

    basicApiReducer: ({ initialState, ...rest }) => {
        return {
            ...rest,
            initialState: {
                ...initialState,
                error: null,
            },
        };
    },
});
