import { combineReducers, Consts } from '../../../dependencies';

import fetchTriggers from './fetchTriggers';
import fetchTrigger from './fetchTrigger';
import deleteTrigger from './deleteTrigger';
import createTrigger from './createTrigger';
import updateTrigger from './updateTrigger';

const { CREATE, UPDATE, FETCH, DELETE } = Consts.apiTypes;

export const expenseTrigger = combineReducers({
    [CREATE]: createTrigger,
    [UPDATE]: updateTrigger,
    [FETCH]: fetchTrigger,
    [DELETE]: deleteTrigger,
});

export const expenseTriggers = combineReducers({
    [FETCH]: fetchTriggers,
});
