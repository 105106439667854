import { sagaEffects, ModalsActions, Consts, MessagesActions } from '../../../dependencies';

import { types, updateCompanyRequest } from '../../actions';

const { put, takeEvery, take } = sagaEffects;

function* submitEditCompanyForm({ startSubmit, stopSubmit, reset, data }) {
    yield startSubmit();

    yield put(updateCompanyRequest(data.id, data));

    const { UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAILURE } = types;

    const result = yield take([UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAILURE]);

    switch (result.type) {
        case UPDATE_COMPANY_SUCCESS:
            yield stopSubmit();
            yield reset();
            yield put(ModalsActions.closeModal(Consts.modals.EDIT_COMPANY));
            yield put(
                MessagesActions.displaySuccessMessage({
                    id: 'success.company.update',
                }),
            );
            break;

        case UPDATE_COMPANY_FAILURE:
            yield stopSubmit({
                _error: result.error,
            });
            break;

        default:
    }
}

export default function* createCompanyWatcher() {
    yield takeEvery(types.SUBMIT_EDIT_COMPANY_FORM, submitEditCompanyForm);
}
