import { React, PropTypes, Icons, connectFela } from 'modules/reports/dependencies';

import * as Styles from './ButtonIcon.styles';

function ButtonIcon({ styles, onClick, disabled }) {
    return (
        <button type="button" onClick={onClick} className={styles.button} disabled={disabled}>
            <Icons.Chevron2Left className={styles.icon} />
        </button>
    );
}

ButtonIcon.propTypes = {
    styles: PropTypes.shape().isRequired,
    onClick: PropTypes.func.isRequired,
    // eslint-disable-next-line
    direction: PropTypes.oneOf(['left', 'right']).isRequired,
    // eslint-disable-next-line
    disabled: PropTypes.bool.isRequired,
};

export default connectFela(Styles)(ButtonIcon);
