import { React, PropTypes, UI, TextField, useIntl, useSelector, isSubmittingSelector, Utils } from '../../dependencies';

const { InputWrapper } = UI;

const ZipCodeField = ({ formId, rules, ...rest }) => {
    const intl = useIntl();
    const submitting = useSelector(isSubmittingSelector(formId));

    return (
        <InputWrapper style={rules.inputSmall}>
            <TextField
                name="zipCode"
                placeholder={intl.formatMessage({
                    id: `supplier.form.zipCode.placeholder`,
                })}
                parse={Utils.toUpperCase}
                disabled={submitting}
                autoComplete="postal-code"
                {...rest}
            />
        </InputWrapper>
    );
};

ZipCodeField.propTypes = {
    formId: PropTypes.string.isRequired,
    tabIndex: PropTypes.number.isRequired,
};

export default React.memo(ZipCodeField);
