import { React, FormattedMessage, PropTypes, connectFela } from '../../dependencies';

import * as Styles from './SearchInfo.styles';

const SearchInfo = ({ search, styles }) => (
    <div className={styles.searchInfo}>
        <div className={styles.label}>
            <FormattedMessage id="search.title" />
        </div>
        <div className={styles.value}>{`"${search}"`}</div>
    </div>
);

SearchInfo.propTypes = {
    search: PropTypes.string.isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default connectFela(Styles)(SearchInfo);
