import { connect, PropTypes } from '../dependencies';

import { openModal, closeModal } from '../services/actions';
import { modalSelector } from '../services/selectors';

export const withModalPropTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default function withModal(id) {
    const mapStateToProps = (state, props) => modalSelector(state, id || props.modalId);

    const mapDispatchToProps = (dispatch, props) => ({
        openModal() {
            dispatch(openModal(id || props.modalId));
        },
        closeModal() {
            dispatch(closeModal(id || props.modalId));
        },
    });

    return WrappedComponent =>
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(WrappedComponent);
}
