import { React, UI, childrenPropType, ErrorCard, useFetchServiceKeys } from '../../dependencies';

import { useFetchExports } from '../../hooks';

const { DataLoader } = UI;

const ExportsFetcher = ({ children }) => {
    const { inProgress, success, error } = useFetchExports();
    const serviceKeysApi = useFetchServiceKeys();

    return (
        <ErrorCard {...error} {...serviceKeysApi.error}>
            <DataLoader
                show={inProgress || !success || serviceKeysApi.inProgress || !serviceKeysApi.success}
                minHeight={120}
            >
                {children}
            </DataLoader>
        </ErrorCard>
    );
};

ExportsFetcher.propTypes = {
    children: childrenPropType.isRequired,
};

export default ExportsFetcher;
