import { createSelector, ReportSelectorUtils, ReportSelectors, CompaniesSelectors } from '../../dependencies';

export const dashboardSelector = state => state.entities.dashboard;

const getFullYear = date => new Date(date).getFullYear().toString();

const dashboardChartTotalOverviewSelector = createSelector(dashboardSelector, ({ from, to, buckets: { total } }) => {
    return {
        yearFrom: getFullYear(from),
        yearTo: getFullYear(to),
        withVat: total.withVat,
        withoutVat: total.withoutVat,
    };
});

export const dashboardMonthsChartSelector = createSelector(
    [dashboardSelector, CompaniesSelectors.activeCompanyCurrencySelector, dashboardChartTotalOverviewSelector],
    (dashboard, currency, total) => {
        const { months } = dashboard.buckets;

        return {
            currency,
            total,
            currentPeriod: ReportSelectorUtils.findCurrentMonth(months),
            buckets: ReportSelectors.mapBucketItemsToBars(months),
            average: ReportSelectorUtils.calcAverage(months),
        };
    },
);

export const dashboardQuartersChartSelector = createSelector(
    [dashboardSelector, CompaniesSelectors.activeCompanyCurrencySelector, dashboardChartTotalOverviewSelector],
    (dashboard, currency, total) => {
        const { quarters } = dashboard.buckets;

        return {
            currency,
            total,
            currentPeriod: ReportSelectorUtils.findCurrentQuarter(quarters),
            buckets: ReportSelectors.mapBucketItemsToBars(quarters),
            average: ReportSelectorUtils.calcAverage(quarters),
        };
    },
);
