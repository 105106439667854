import {
    compose,
    connect,
    fetchDependencies,
    withErrorBoundary,
    loadable,
    Loader,
    Consts,
    ReduxUtils,
    withErrorMessage,
    mapProps,
} from '../dependencies';

import { FILTER_DEFAULT_VALUE } from '../constants';

import { filteredTagsSelector } from '../services/selectors';
import { setFilter, fetchTagsRequest } from '../services/actions';

import TagList from '../components/TagList';

const { TAGS } = Consts.entityKeys;
const { FETCH } = Consts.apiTypes;

const mapPropsToErrorMessage = ({ fetch, ...rest }) => {
    return {
        ...rest,
        onErrorMessageClick: fetch,
    };
};

export default compose(
    withErrorBoundary,
    connect(
        state => {
            const { inProgress, success, error } = ReduxUtils.apiSelector(state, TAGS, FETCH);
            const tags = filteredTagsSelector(state);
            return {
                showLoader: inProgress || !success,
                tags,
                error,
            };
        },
        dispatch => ({
            fetch: () => {
                dispatch(setFilter(FILTER_DEFAULT_VALUE));
                dispatch(fetchTagsRequest());
            },
        }),
    ),
    fetchDependencies(),
    mapProps(mapPropsToErrorMessage),
    withErrorMessage,
    loadable(Loader),
)(TagList);
