import { config, authApi, takeLatestRequest, Log, sagaEffects, Utils, ErrorsUtils } from '../../dependencies';

import {
    fetchExpenseTriggersSuccess,
    fetchExpenseTriggersFailure,
    fetchExpenseTriggersInvalidate,
    types,
} from '../actions';
import { expenseTriggersApiSelector } from '../selectors';

const { put, select } = sagaEffects;

function* fetchExpenseTriggers(action, cancelToken) {
    try {
        const { offset } = yield select(expenseTriggersApiSelector);
        const { params } = action.payload;

        // TODO: check if the request is needed (maybe all data are already fetched)

        const requestConfig = {
            params: {
                offset,
                limit: Number.MAX_SAFE_INTEGER,
                ...params,
            },
            cancelToken,
        };

        const { data, headers } = yield* authApi.get(config.api.expenseTriggers, requestConfig);

        const payload = Utils.transformResponse(data);
        const byExpenseId = {};

        for (const triggerId of payload.ids) {
            const { expenseId } = payload.byId[triggerId];
            byExpenseId[expenseId] = triggerId;
        }

        const totalCount = Number(headers['x-total-count']);

        yield put(
            fetchExpenseTriggersSuccess(
                {
                    ...payload,
                    byExpenseId,
                },
                {
                    totalCount,
                    offset,
                },
            ),
        );
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.expenseTriggers.api.get',
            },
        });

        yield put(fetchExpenseTriggersFailure(errorMessage));
    }
}

export default function* fetchExpensesWatcher() {
    const actionTypes = {
        REQUEST: types.FETCH_EXPENSE_TRIGGERS_REQUEST,
        cancelTask: fetchExpenseTriggersInvalidate,
    };

    yield takeLatestRequest(actionTypes, fetchExpenseTriggers);
}
