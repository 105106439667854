import { ActiveCompanySelectors, createSelector, Utils } from '../../../dependencies';
import { trasformReportFiltersToApiFilters, removeTimezoneFromDates } from '../../../utils';

import { hiddenTagsSelector } from '../entities';

export const createTempReportDataSelector = createSelector(
    [Utils.passParam, ActiveCompanySelectors.activeCompanyIdSelector, hiddenTagsSelector],
    (urlFilters, companyId, hiddenTags) => {
        return {
            companyId,
            filters: removeTimezoneFromDates(trasformReportFiltersToApiFilters(urlFilters)),
            hiddenTags,
        };
    },
);
