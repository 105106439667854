import { takeLatestRequest, sagaEffects, authApi, config, Log, ErrorsUtils, isEqual } from '../../dependencies';

import {
    types,
    createTemporaryReportSuccess,
    createTemporaryReportFailure,
    createTemporaryReportInvalidate,
} from '../actions';
import { createTempReportDataSelector, selectTempReport } from '../selectors';

import { formatResponse } from './utils';

const { put, select } = sagaEffects;

function* isEqualWithCurrentTempReport(id, reportData) {
    const tempReport = yield select(selectTempReport, id);

    if (!tempReport) {
        return false;
    }

    const { buckets, temp, ...currentReportData } = tempReport;
    const equal = isEqual(reportData, currentReportData);

    return equal;
}

function* createTemporaryReport(action, cancelToken) {
    const { id } = action.meta;

    try {
        const reportData = yield select(createTempReportDataSelector, action.payload);
        const payload = {
            ...reportData,
            temp: true,
        };

        if (yield isEqualWithCurrentTempReport(id, reportData)) {
            const tempReport = yield select(selectTempReport, id);
            yield put(createTemporaryReportSuccess(id, tempReport));
            return;
        }

        const { data } = yield* authApi.post(config.api.reports, payload, {
            cancelToken,
        });

        yield put(createTemporaryReportSuccess(id, formatResponse(data)));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e);

        yield put(createTemporaryReportFailure(id, errorMessage));
    }
}

export default function* () {
    const actionTypes = {
        REQUEST: types.CREATE_TEMPORARY_REPORT_REQUEST,
        cancelTask: createTemporaryReportInvalidate,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, createTemporaryReport);
}
