import * as IDB from './idb';

export function createNamespace(key) {
    return {
        get() {
            return IDB.get(key);
        },
        set(value) {
            return IDB.set(key, value);
        },
        remove() {
            return IDB.remove(key);
        },
    };
}
