import {
    connect,
    compose,
    Loader,
    loadable,
    withErrorBoundary,
    ReduxUtils,
    Consts,
    withErrorMessage,
} from '../dependencies';
import ExpenseDetail from '../components/ExpenseDetail';
import { fetchExpense } from '../HOC';

const { EXPENSE } = Consts.entityKeys;
const { FETCH } = Consts.apiTypes;

const mapStateToProps = (state, ownProps) => {
    const { expenseId } = ownProps.match.params;
    const { inProgress, success, error } = ReduxUtils.apiSelector(state, EXPENSE, FETCH, expenseId);

    return {
        expenseId,
        showLoader: inProgress || !success,
        error,
    };
};

const mergeProps = (stateProps, dispatchProps, { fetchExpenseRequest }) => ({
    ...stateProps,
    onErrorMessageClick: fetchExpenseRequest,
});

export default compose(
    withErrorBoundary,
    fetchExpense,
    connect(
        mapStateToProps,
        null,
        mergeProps,
    ),
    withErrorMessage,
    loadable(Loader),
)(ExpenseDetail);
