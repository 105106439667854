export const header = () => ({
    display: 'grid',
    justifyContent: 'space-between',
    alignItems: 'center',
    gridColumnGap: '1rem',
    gridTemplateColumns: 'auto auto',
});

export const title = () => ({
    marginBottom: 0,
});

export const footer = () => ({
    marginTop: '2.5rem',
    display: 'grid',
    justifyContent: 'flex-end',
    gridColumnGap: '1rem',
    gridTemplateColumns: 'auto auto',
});

export const fields = () => ({
    margin: '1.5rem 0',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: '1.5rem',
});

export const error = () => ({
    marginTop: '1rem',
});
