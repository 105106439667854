import types from './types';

export const deleteReportRequest = id => ({
    type: types.DELETE_REPORT_REQUEST,
    meta: { id },
});

export const deleteReportSuccess = id => ({
    type: types.DELETE_REPORT_SUCCESS,
    meta: { id },
});

export const deleteReportFailure = (id, error) => ({
    type: types.DELETE_REPORT_FAILURE,
    meta: { id },
    error,
});

export const deleteReportInvalidate = id => ({
    type: types.DELETE_REPORT_CANCEL,
    meta: { id },
});
