import { all } from 'redux-saga/effects';

import { withAuthSession } from '@ackee/petrus';

import { saga as tags } from 'modules/tags';
import { saga as expenses } from 'modules/expenses';
import { saga as suppliers } from 'modules/suppliers';
import { saga as reports } from 'modules/reports';
import { saga as exports } from 'modules/exports';
import { saga as users } from 'modules/users';
import { saga as commonFilter } from 'modules/common-filter';
import { saga as dashboard } from 'modules/dashboard';
import { saga as serviceKeys } from 'modules/service-keys';
import { saga as companies } from 'modules/companies';
import { saga as memberships } from 'modules/memberships';

// NOTE:
// this saga will be running only if user is authorized,
// and canceled on logout
function* authSaga() {
    yield all([
        tags(),
        expenses(),
        suppliers(),
        reports(),
        exports(),
        users(),
        commonFilter(),
        dashboard(),
        serviceKeys(),
        companies(),
        memberships(),
    ]);
}

export default function* () {
    yield withAuthSession(authSaga);
}
