import { React, PropTypes, FelaComponent, FormattedMessage } from '../../../../dependencies';

import Cell from './Cell';

import * as styles from './RowPlaceholder.styles';

function RowPlaceholder({ onClick, quantityDataAreVisible, style }) {
    return (
        <FelaComponent style={styles.button}>
            {({ className: btnClassName }) => (
                <button type="button" {...{ onClick, className: btnClassName }}>
                    <FelaComponent
                        style={styles.row}
                        customStyle={style}
                        quantityDataAreVisible={quantityDataAreVisible}
                    >
                        {({ className }) => (
                            <div className={className}>
                                {RowPlaceholder.placeholders
                                    .filter(placeholder => {
                                        if (quantityDataAreVisible) {
                                            return true;
                                        }

                                        return !RowPlaceholder.hiddenFields.has(placeholder.id);
                                    })
                                    .map(placeholder => (
                                        <Cell key={placeholder.id}>{placeholder.element}</Cell>
                                    ))}
                                <div />
                            </div>
                        )}
                    </FelaComponent>
                </button>
            )}
        </FelaComponent>
    );
}

RowPlaceholder.propTypes = {
    onClick: PropTypes.func.isRequired,
    quantityDataAreVisible: PropTypes.bool.isRequired,
    style: PropTypes.func.isRequired,
};

RowPlaceholder.hiddenFields = new Set(['quantity', 'units']);

RowPlaceholder.placeholders = ['name', 'quantity', 'units', 'pricePerUnit', 'vatRate'].map(id => ({
    id,
    element: <FormattedMessage id={`expense.form.item.${id}.label`} />,
}));

export default RowPlaceholder;
