import { ReactGA } from '../dependencies';
import { Categories } from '../constants';

export const finishAuthSessionDurationMeasurement = startedAt =>
    ReactGA.timing({
        category: Categories.AUTH_VISIT,
        variable: 'duration',
        value: performance.now() - startedAt,
        label: 'User visit duration',
    });
