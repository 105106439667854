import { sagaEffects } from '../../dependencies';

import createTrigger from './createTrigger';
import updateTrigger from './updateTrigger';
import deleteTrigger from './deleteTrigger';
import fetchTrigger from './fetchTrigger';
import fetchTriggers from './fetchTriggers';
import fetchTriggersPaging from './fetchTriggersPaging';

const { all } = sagaEffects;

export default function* expensesSaga() {
    yield all([
        createTrigger(),
        updateTrigger(),
        deleteTrigger(),
        fetchTrigger(),
        fetchTriggers(),
        fetchTriggersPaging(),
    ]);
}
