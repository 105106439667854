export const tab = ({ theme: { colors }, isActive }) => ({
    color: isActive ? colors.primary : colors.text,
    lineHeight: 1.56,
    letterSpacing: '0.2px',
    fontSize: '16px',
    padding: '9px 24px',
    width: '100%',
    textAlign: 'center',
    borderBottom: `2px solid ${isActive ? colors.primary : 'transparent'}`,
});
