import { React, PropTypes, connectFela, Charts, useSelector, compose } from 'modules/reports/dependencies';

import { reportDoughnutChartSelector } from 'modules/reports/services/selectors';

import withCardLoader from 'modules/reports/HOC/withCardLoader';

import * as Styles from './ChartDoughnutWrapper.styles';

const { ChartDoughnut } = Charts;

function ChartDoughnutWrapper({ styles }) {
    const { chartData, currency } = useSelector(reportDoughnutChartSelector);

    return (
        <div className={styles.chart}>
            <ChartDoughnut data={chartData} currency={currency} />
        </div>
    );
}

ChartDoughnutWrapper.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default compose(
    connectFela(Styles),
    withCardLoader({
        extend: Styles.extendCardLoader,
    }),
)(ChartDoughnutWrapper);
