import { connect, arrayPush } from '../../dependencies';

import Attachment from '../../components/Fields/Attachment';

const mapDispatchToProps = (dispatch, { formId }) => ({
    // TODO: create saga that inserts all files at once
    onDrop(files) {
        for (const file of files) {
            const url = URL.createObjectURL(file);
            const { name, type } = file;

            dispatch(
                arrayPush(formId, 'files', {
                    uploaded: false,
                    localUrl: true,
                    url,
                    name,
                    type,
                }),
            );
        }
    },
});

export default connect(
    null,
    mapDispatchToProps,
)(Attachment);
