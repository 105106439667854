import { sagaEffects, AuthSelectors, ModalsActions, Consts, MessagesActions } from '../../../dependencies';

import { types, updateUserRequest } from '../../actions';

const { put, takeEvery, select, take } = sagaEffects;

function* updateAuthUserPassword(action) {
    const { data, startSubmit, stopSubmit, reset } = action;

    yield startSubmit();

    const authUser = yield select(AuthSelectors.authUserSelector);

    yield put(updateUserRequest(authUser.id, data));

    const result = yield take([types.UPDATE_USER_SUCCESS, types.UPDATE_USER_FAILURE]);

    switch (result.type) {
        case types.UPDATE_USER_SUCCESS:
            yield stopSubmit();
            yield reset();

            yield put(ModalsActions.closeModal(Consts.modals.CHANGE_PASSWORD));

            yield put(
                MessagesActions.displaySuccessMessage({
                    id: 'success.auth.changePassword',
                }),
            );
            break;

        case types.UPDATE_USER_FAILURE:
            yield stopSubmit({
                _error: result.error,
            });
            break;

        default:
    }
}

export default function* () {
    yield takeEvery(types.UPDATE_AUTH_USER_PASSWORD, updateAuthUserPassword);
}
