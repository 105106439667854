import { Antonio } from '@ackee/antonio-core';
import { requestAuthHeaderInterceptor } from '@ackee/antonio-auth';
import { encodeSearchParams } from 'services/utilities';

import config from 'config/config';

function encodeParamsInterceptor(requestParams) {
    const { config } = requestParams;

    if (config?.params) {
        const params = encodeSearchParams(config.params);

        return {
            ...requestParams,
            config: {
                ...config,
                params,
            },
        };
    }

    return requestParams;
}

const api = new Antonio({
    baseURL: config.api.base,
});
api.interceptors.request.use(encodeParamsInterceptor);

const authApi = new Antonio({
    baseURL: config.api.base,
});

authApi.interceptors.request.use(encodeParamsInterceptor, requestAuthHeaderInterceptor);

const aresApi = new Antonio({ baseURL: config.ares.base });

aresApi.interceptors.request.use(encodeParamsInterceptor);

export { api, authApi, aresApi };
