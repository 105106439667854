export const button = () => ({
    width: '100%',
});

export const row = ({ customStyle, theme, quantityDataAreVisible }) => ({
    ...customStyle({
        theme,
        quantityDataAreVisible,
    }),
    marginTop: 40,
});
