export const card = ({ theme: { colors } }) => ({
    padding: '24px',
    display: 'grid',
    gridTemplateColumns: '1.5rem 1fr',
    gridColumnGap: 16,
    maxWidth: 508,
    backgroundColor: colors.positive,
    borderRadius: 2,
    marginBottom: 40,
});

export const text = ({ theme: { colors } }) => ({
    color: colors.text,
    fontSize: '1rem',
    letterSpacing: '0.15px',
    lineHeight: 1.5625,
});

export const icon = ({ theme: { colors } }) => ({
    width: '1.25rem',
    margin: '0.25rem auto 0',
    fill: colors.text,
});
