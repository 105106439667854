import { all } from 'redux-saga/effects';

import { submitFormSaga } from '@ackee/mateus';

import modules from './modules';
import authSaga from './authSaga';

/**
 * Root generator for all application sagas
 */
export default function* () {
    yield all([modules(), submitFormSaga(), authSaga()]);
}
