import { useDispatch, useSelector, ReduxUtils, Consts } from '../../dependencies';

import { deleteReportRequest } from '../../services/actions';

const { DELETE } = Consts.apiTypes;
const { REPORT } = Consts.entityKeys;

export function useRemoveReport(reportId) {
    const dispatch = useDispatch();
    const { inProgress } = useSelector(state => ReduxUtils.apiSelector(state, REPORT, DELETE, reportId));

    return {
        inProgress,
        removeReport() {
            dispatch(deleteReportRequest(reportId));
        },
    };
}
