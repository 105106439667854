import { React, PropTypes, FelaComponent, FormattedNumber, Config, FormattedMessage } from '../../../dependencies';

import * as styles from './TotalPrice.styles';

function TotalPrice({ currency, priceWithVatTotal, priceWithoutVatTotal }) {
    return (
        <FelaComponent style={styles.card}>
            <FelaComponent style={styles.labelPrimary}>
                <FormattedMessage id="expense.form.summary.priceWithVatTotal" />
            </FelaComponent>
            <FelaComponent style={styles.valuePrimary}>
                <FormattedNumber
                    {...{
                        ...Config.currency,
                        minimumFractionDigits: 2,
                        style: 'currency',
                        value: priceWithVatTotal,
                        currency,
                    }}
                />
            </FelaComponent>

            <FelaComponent style={styles.labelSecondary}>
                <FormattedMessage id="expense.form.summary.base" />
            </FelaComponent>
            <FelaComponent style={styles.valueSecondary}>
                <FormattedNumber
                    {...{
                        ...Config.currency,
                        minimumFractionDigits: 2,
                        style: 'currency',
                        value: priceWithoutVatTotal,
                        currency,
                    }}
                />
            </FelaComponent>
        </FelaComponent>
    );
}

TotalPrice.propTypes = {
    currency: PropTypes.string.isRequired,
    priceWithVatTotal: PropTypes.number.isRequired,
    priceWithoutVatTotal: PropTypes.number.isRequired,
};

export default TotalPrice;
