import { types } from 'modules/expenses/services/actions';

const initialState = {};

export default function expensesReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_EXPENSES_SUCCESS:
        case types.SAVE_EXPENSES:
            return action.payload.byId;

        case types.FETCH_EXPENSES_RESET:
            return initialState;

        default:
            return state;
    }
}
