import { compose, connect, reduxForm, injectIntl, push, reset, config, Consts } from '../dependencies';

import SearchBarForm from '../components/SearchBarForm';
import { selectSearchValue } from '../services/selectors';

const form = config.forms.searchBar;

const mapStateToProps = state => {
    return {
        initialValues: {
            search: selectSearchValue(state),
        },
    };
};

export default compose(
    connect(mapStateToProps),
    reduxForm({
        form,
        validate: values => {
            const errors = {};
            if (!values.search) {
                errors.search = true;
            }
            return errors;
        },
        onSubmit: (values, dispatch, { onSearch }) => {
            dispatch(push(`${Consts.routes.SEARCH}?query=${values.search}`));
            reset(form);
            onSearch();
        },
    }),
    injectIntl,
)(SearchBarForm);
