import { React, PropTypes, Transition } from '../../dependencies';

import { TRANSITION_DURATION } from '../../config';

function Message({ visible, children }) {
    const [mounted, setMountedFlag] = React.useState(false);

    React.useEffect(() => {
        setMountedFlag(true);
        // eslint-disable-next-line
    }, []);

    return (
        <Transition in={mounted && visible} timeout={TRANSITION_DURATION}>
            {children}
        </Transition>
    );
}

Message.propTypes = {
    visible: PropTypes.bool.isRequired,
    children: PropTypes.func.isRequired,
};

export default Message;
