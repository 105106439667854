import types from '../actionTypes';

export const deleteSupplierRequest = id => ({
    type: types.DELETE_SUPPLIER_REQUEST,
    meta: { id },
});

export const deleteSupplierSuccess = id => ({
    type: types.DELETE_SUPPLIER_SUCCESS,
    meta: { id },
});

export const deleteSupplierFailure = (id, error) => ({
    type: types.DELETE_SUPPLIER_FAILURE,
    meta: { id },
    error,
});

export const deleteSupplierInvalidate = id => ({
    type: types.DELETE_SUPPLIER_CANCEL,
    meta: { id },
});
