import { React, PropTypes, FelaComponent, childrenPropType, Link } from '../../../dependencies';

import * as styles from './Tab.styles';

function Tab({ path, isActive, children }) {
    return (
        <FelaComponent style={styles.tab} isActive={isActive}>
            {({ className }) => (
                <Link
                    {...{
                        className,
                        to: path,
                    }}
                >
                    {children}
                </Link>
            )}
        </FelaComponent>
    );
}

Tab.propTypes = {
    path: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    children: childrenPropType.isRequired,
};

export default Tab;
