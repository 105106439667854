import { formatEntities, entitiesTemplates } from '../../dependencies';
import types from '../actionTypes';

export const fetchSuppliersRequest = (payload = {}) => ({
    type: types.FETCH_SUPPLIERS_REQUEST,
    payload,
});

export const fetchSuppliersSuccess = ({ byId, ids }, meta = {}) => ({
    type: types.FETCH_SUPPLIERS_SUCCESS,
    payload: {
        ids,
        byId: formatEntities({
            entities: byId,
            template: entitiesTemplates.supplier,
        }),
    },
    meta,
});

export const fetchSuppliersFailure = error => ({
    type: types.FETCH_SUPPLIERS_FAILURE,
    error,
});

export const fetchSuppliersInvalidate = () => ({
    type: types.FETCH_SUPPLIERS_CANCEL,
});
