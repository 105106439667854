export const tags = () => ({
    display: 'flex',
    alignItems: 'flex-end',
});

export const card = ({ theme: { colors } }) => ({
    borderRadius: '6px',
    backgroundColor: colors.white,
    padding: '24px 30px 32px',
});

export const grid = () => ({
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 16,
});

export const tagsGrid = ({ paymentMethodField }) => ({
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateColumns: paymentMethodField ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
    gridColumnGap: 16,
    gridRowGap: 24,
});
