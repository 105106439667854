import { Petrus, sagaEffects, MessagesActions, ErrorsUtils } from 'modules/auth/dependencies';
import { login } from '../actions';
import types from '../actionTypes';

const { put, take, takeEvery } = sagaEffects;

const { LOGIN_SUCCESS, LOGIN_FAILURE } = Petrus;

function* handleLoginForm(action) {
    yield action.startSubmit();

    yield put(login(action.data));

    const result = yield take([LOGIN_SUCCESS, LOGIN_FAILURE]);

    if (result.type === LOGIN_FAILURE) {
        const error = ErrorsUtils.createUIErrorMessage(result.error, {
            fallback: {
                id: 'error.signIn.api',
            },
        });
        yield put(MessagesActions.displayErrorMessage(error));
    }

    yield action.stopSubmit();
}

export default function* () {
    yield takeEvery(types.LOGIN_FORM, handleLoginForm);
}
