export default ({ theme }) => ({
    '> button': {
        padding: '0.375rem',
    },
    '> button[disabled]': {
        cursor: 'default',
    },
    '> button > svg': {
        fill: theme.colors.primary,
        verticalAlign: 'middle',
    },
    '> button[disabled] > svg': {
        fill: theme.colors.blueGrey3,
    },
});
