import types from './types';

export const createMembershipRequest = requestData => ({
    type: types.CREATE_MEMBERSHIP_REQUEST,
    payload: requestData,
});

export const createMembershipSuccess = (id, responseData) => ({
    type: types.CREATE_MEMBERSHIP_SUCCESS,
    meta: { id },
    payload: responseData,
});

export const createMembershipFailure = error => ({
    type: types.CREATE_MEMBERSHIP_FAILURE,
    error,
});

export const createMembershipInvalidate = () => ({
    type: types.CREATE_MEMBERSHIP_CANCEL,
});
