import { React, PropTypes } from '../dependencies';

import ErrorWithAction from '../components/ErrorWithAction';
import { errorPropType } from '../components/utils';

export default function withErrorMessage(WrappedComponent) {
    function ErrorMessageContainer({ error, onErrorMessageClick, ...rest }) {
        return error ? <ErrorWithAction {...{ error, onErrorMessageClick }} /> : <WrappedComponent {...rest} />;
    }

    ErrorMessageContainer.propTypes = {
        error: errorPropType,
        onErrorMessageClick: PropTypes.func,
    };

    ErrorMessageContainer.defaultProps = {
        error: null,
        onErrorMessageClick: null,
    };

    return ErrorMessageContainer;
}
