import { ReduxUtils } from 'modules/expenses/dependencies';
import { types } from 'modules/expenses/services/actions';

import ids from './ids';

export default ReduxUtils.containerReducer({
    actionTypes: [types.FETCH_EXPENSES_SUCCESS, types.FETCH_EXPENSES_RESET, types.SAVE_EXPENSES],
    childReducer: ids,
    selectors: {
        itemId: action => action.meta.groupId,
    },
    options: {
        ignoreWarnings: true,
    },
});
