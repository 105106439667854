import { React, PropTypes, RadioField, useIntl, UI, useSelector, isSubmittingSelector } from '../../../dependencies';

const { InputWrapper } = UI;

function Period({ formId }) {
    const intl = useIntl();
    const submitting = useSelector(isSubmittingSelector(formId));

    return (
        <InputWrapper>
            <RadioField
                {...{
                    name: 'period',
                    label: intl.formatMessage({
                        id: 'expense.form.period.label',
                    }),
                    // NOTE: store as number, display as string
                    parse: value => {
                        return value || Number.parseInt(value, 10) === 0 ? Number(value) : value;
                    },
                    format: value => {
                        return value || value === 0 ? String(value) : value;
                    },
                    options: [
                        {
                            label: intl.formatMessage({
                                id: 'export.recurring.form.period.option.0',
                            }),
                            value: 0,
                        },
                        {
                            label: intl.formatMessage({
                                id: 'export.recurring.form.period.option.1',
                            }),
                            value: 1,
                        },
                    ],
                    disabled: submitting,
                }}
            />
        </InputWrapper>
    );
}

Period.propTypes = {
    formId: PropTypes.string.isRequired,
};

export default Period;
