import { formatEntityValue } from './formatEntity';

const defaultOptions = {
    merge: false,
};

export const setDefaultValue = (defaultValue, customOptions = {}) => value => {
    if (value === null || value === undefined) {
        return defaultValue;
    }

    const options = {
        ...defaultOptions,
        ...customOptions,
    };

    if (options.merge) {
        // TODO: missing fields warning
        const resultValue = {};

        for (const [key, valueFormatter] of Object.entries(defaultValue)) {
            resultValue[key] = formatEntityValue(valueFormatter, value[key]);
        }

        return resultValue;
    }

    return value;
};

export const convertValue = converter => value => converter(value);

export const any = value => value;

export const StringOrNull = value => (value !== null && value !== undefined ? JSON.stringify(value) : null);

export const converters = {
    any,
    StringOrNull,
    String,
    Number,
};
