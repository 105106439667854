import { React, Modal, useModal, Consts } from '../../dependencies';

import CreateCompanyForm from '../../containers/CreateCompanyForm';

const CreateCompanyModal = () => {
    const modal = useModal(Consts.modals.CREATE_COMPANY);

    return (
        <Modal visible={modal.isOpen} onCancel={modal.close}>
            <CreateCompanyForm onCancel={modal.close} />
        </Modal>
    );
};

export default CreateCompanyModal;
