import { React, PropTypes, childrenPropType, connectFela } from '../../dependencies';

import { background } from './Card.styles';

function CardBackground({ children, styles }) {
    return <div className={styles.background}>{children}</div>;
}

CardBackground.propTypes = {
    children: childrenPropType.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default connectFela({
    background,
})(CardBackground);
