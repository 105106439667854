export const form = () => ({
    padding: '24px 30px',
    borderRadius: '6px',
    backgroundColor: '#fff',
    margin: '48px auto',
    maxWidth: '600px',
});

export const checkbox = () => ({
    '& label': {
        fontSize: '12px',
    },
});

export const nameRow = () => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '1rem',
});

export const footer = () => ({
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'flex-end',
});
