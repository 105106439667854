import {
    React,
    Switch,
    Route,
    Redirect,
    Tabs,
    Container,
    Page,
    SupplierList,
    TagList,
    RecurringExpensesList,
} from '../dependencies';

import { routePaths } from '../constants';

const routes = [
    {
        path: routePaths.LISTS_SUPPLIERS,
        render: () => <SupplierList />,
    },
    {
        path: routePaths.LISTS_TAGS,
        render: () => <TagList />,
    },
    {
        path: routePaths.LISTS_EXPENSES_RECURRING,
        render: () => <RecurringExpensesList />,
    },
    {
        render: () => <Redirect to={routePaths.LISTS_SUPPLIERS} />,
    },
];

const tabItems = [
    {
        path: routePaths.LISTS_SUPPLIERS,
        messageId: 'lists.tab.suppliers',
    },
    {
        path: routePaths.LISTS_TAGS,
        messageId: 'lists.tab.tags',
    },
    {
        path: routePaths.LISTS_EXPENSES_RECURRING,
        messageId: 'lists.tab.recurringExpenses',
    },
];

const tabsRoute = {
    // NOTE: 'path' may be array of strings
    path: tabItems.map(item => item.path),
    render: () => <Tabs items={tabItems} />,
    exact: true,
};

export default function Lists() {
    return (
        <Container>
            <Page>
                <Route {...tabsRoute} />

                <Switch>
                    {routes.map((route, index) => (
                        <Route key={index} {...route} />
                    ))}
                </Switch>
            </Page>
        </Container>
    );
}
