import { React, PropTypes, Container, Page } from '../../dependencies';

import Form from '../../containers/Form';
import Summary from '../../containers/Summary';
import RoundingTotal from '../../containers/RoundingTotal';
import Nav from '../../containers/Nav';
import ChangeExportedExpenseModal from '../../containers/ChangeExportedExpenseModal';

function NormalExpenseEdit({ expenseId }) {
    return (
        <Container>
            <Page>
                <Nav expenseId={expenseId} />

                <Form
                    {...{
                        expenseId,
                        Summary,
                        RoundingTotal,
                    }}
                />

                <ChangeExportedExpenseModal expenseId={expenseId} />
            </Page>
        </Container>
    );
}

NormalExpenseEdit.propTypes = {
    expenseId: PropTypes.string.isRequired,
};

const arePropsEqual = (prevProps, nextProps) => prevProps.expenseId === nextProps.expenseId;

export default React.memo(NormalExpenseEdit, arePropsEqual);
