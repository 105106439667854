import { React } from '../../dependencies';

import { useMemberships } from '../../hooks';

import Item from './Item';
import Empty from './Empty';

const MembershipsList = () => {
    const memberships = useMemberships();

    return (
        <>
            {memberships.length === 0 && <Empty />}
            {memberships.map(item => (
                <Item key={item.id} membership={item} />
            ))}
        </>
    );
};

export default MembershipsList;
