import { createSelector, expensesParamsSelector, resolveRelativePeriod, Utils } from '../../dependencies';

import { transformFormValuesToUrlParams } from '../../utils';
import { currentReportFiltersSelector } from './entities';

export const expensesApiParamsSelector = createSelector(
    [currentReportFiltersSelector, expensesParamsSelector],
    (reportFilters, expensesParams) => {
        // NOTE: Since report expenses list loader is visible when report is fetching,
        // we can be sure that reportFilters are always avail.
        // useFetchExpenses hook must rely only on filters from report, not from URL
        //    -> to prevent race cond. and multi. fetches
        //    -> that doesn't apply for other params such as are order or orderBy
        const reportFiltersAsUrlParams = transformFormValuesToUrlParams(reportFilters);
        const { order, orderBy, currency } = expensesParams;
        const { relativePeriod } = reportFilters;
        const { from, to } = resolveRelativePeriod(reportFilters);

        return {
            ...reportFiltersAsUrlParams,
            from: relativePeriod ? Utils.getISODateWithoutTimezone(from) : from,
            to: relativePeriod ? Utils.getISODateWithoutTimezone(to) : to,
            order,
            orderBy,
            currency,
        };
    },
);
