import { matchPath } from '../../dependencies';

export const isNavItemActive = (pathname, path) => {
    if (path === '/') {
        return pathname === '/';
    }

    const match = matchPath(pathname, {
        path,
    });

    return Boolean(match);
};
