import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const initialState = {};

function userReducer(state = initialState, action) {
    switch (action.type) {
        case types.UPDATE_USER_SUCCESS:
        case types.FETCH_USER_SUCCESS:
            return action.payload;

        default:
            return state;
    }
}

export default ReduxUtils.containerReducer({
    childReducer: userReducer,
    actionTypes: [types.UPDATE_USER_SUCCESS, types.FETCH_USER_SUCCESS],
    selectors: {
        itemId: action => action.meta.id,
    },
});
