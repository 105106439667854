import { React, FelaComponent, FormattedMessage } from '../../../dependencies';

import * as styles from './Header.styles';

const columns = [
    {
        messageId: 'expenses.list.header.name',
    },
    {
        messageId: 'expenses.list.header.period',
    },
    {
        messageId: 'expenses.list.header.startAt',
    },
    {
        messageId: 'expenses.list.header.endAt',
    },
];

function Header() {
    return (
        <FelaComponent style={styles.row}>
            {columns.map(col => (
                <FelaComponent key={col.messageId} style={styles.col}>
                    <FormattedMessage id={col.messageId} />
                </FelaComponent>
            ))}
            <div />
        </FelaComponent>
    );
}

export default Header;
