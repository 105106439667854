import { connect, compose, injectIntl } from '../dependencies';

import OrderFilter from '../components/OrderFilter';

import { activeOrderParams } from '../services/selectors';
import { setOrderUrlParam } from '../services/actions';

const mapStateToProps = activeOrderParams;

const mapDispatchToProps = {
    onChange: setOrderUrlParam,
};

export default compose(
    injectIntl,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(OrderFilter);
