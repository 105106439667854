import { React, PropTypes, FelaComponent, FormattedMessage } from '../../dependencies';

import * as styles from './Label.styles';

function Label({ messageId, marginBottom, marginTop, textAlign, big }) {
    return (
        <FelaComponent
            {...{
                style: styles.label,
                marginTop,
                marginBottom,
                textAlign,
                big,
            }}
        >
            <FormattedMessage id={messageId} />
        </FelaComponent>
    );
}

Label.propTypes = {
    messageId: PropTypes.string.isRequired,
    marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    textAlign: PropTypes.string,
    big: PropTypes.bool,
};

Label.defaultProps = {
    marginTop: 0,
    marginBottom: '4px',
    textAlign: 'left',
    big: false,
};

export default Label;
