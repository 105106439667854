import { combineReducers } from 'redux';
import { AUTH_SESSION_END } from '@ackee/petrus';
import * as ReduxUtils from '@ackee/redux-utils';

import { apiReducers as expenses } from 'modules/expenses';
import { apiReducers as tags } from 'modules/tags';
import { apiReducers as suppliers } from 'modules/suppliers';
import { apiReducers as reports } from 'modules/reports';
import { apiReducers as exports } from 'modules/exports';
import { apiReducers as users } from 'modules/users';
import { apiReducers as dashboard } from 'modules/dashboard';
import { apiReducers as serviceKeys } from 'modules/service-keys';
import { apiReducers as companies } from 'modules/companies';
import { apiReducers as memberships } from 'modules/memberships';

const reducer = combineReducers({
    ...expenses,
    ...tags,
    ...suppliers,
    ...reports,
    ...exports,
    ...users,
    ...dashboard,
    ...serviceKeys,
    ...companies,
    ...memberships,
});

export default ReduxUtils.basicResetReducer(reducer, AUTH_SESSION_END);
