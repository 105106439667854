import { isDateInvalid } from '../../format';
import d from '../defaultValues';
import { convertValue, any } from '../helpers';

const expense = {
    supplierId: convertValue(String),
    number: d.string,
    originalNumber: d.string,
    variableSymbol: d.string,
    issuedOn: d.string,
    receivedOn: d.string,
    taxableFulfillmentDue: d.string,
    dueOn: d.string,
    currency: d.string,
    documentType: d.string,
    taxDeductible: d.false,
    items: d.array,
    tags: d.array,
    id: convertValue(String),
    companyId: convertValue(String),
    vatFromBase: d.false,
    createdAt: d.string,
    updatedAt: d.string,
    totalPriceWithVat: d.zero,
    totalPriceWithVatDefaultCurrency: d.zero,
    totalPriceWithoutVat: d.zero,
    totalPriceWithoutVatDefaultCurrency: d.zero,
    hasFiles: d.false,
    files: d.array,
    paymentMethod: d.null,
    authorId: convertValue(String),
    note: d.string,
    supplier: d.null,
    // TODO: add enum
    type: d.string,
    monthlySerial: d.null,
    expenseTrigger: d.null,
    synced: value => (isDateInvalid(value) ? null : value),
    syncedExports: d.array,
    author: any,
};

export default expense;
