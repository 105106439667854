import { Config } from '../dependencies';

import RoundingTotal from '../components/RoundingTotal';
import { decimalDifferenceSelector } from '../services/selectors';
import withRoundingTotal from '../HOC/withRoundingTotal';

export default withRoundingTotal({
    formId: Config.forms.createExpense,
    decimalDifferenceSelector,
})(RoundingTotal);
