import { React, PropTypes, formValueSelector, useSelector, ServiceKeysConsts } from '../../../dependencies';

import FakturoidService from './FakturoidService';
import FlexibeeService from './FlexibeeService';

const { Service } = ServiceKeysConsts;

const ServicesCredentials = ({ formId }) => {
    const service = useSelector(state => formValueSelector(formId)(state, 'service'));

    switch (service) {
        case Service.FAKTUROID:
            return <FakturoidService formId={formId} />;

        case Service.FLEXIBEE:
            return <FlexibeeService formId={formId} />;

        default:
            return null;
    }
};

ServicesCredentials.propTypes = {
    formId: PropTypes.string.isRequired,
};

export default ServicesCredentials;
