export default ({ theme }) => ({
    padding: '112px 16px 80px',

    fontSize: '16px',
    lineHeight: '25px',

    color: theme.colors.blueGrey3,

    textAlign: 'center',

    '& > p': {
        maxWidth: '400px',
        margin: '0 auto 14px auto',
    },
    '& > p:first-of-type': {
        maxWidth: '500px',

        fontSize: '20px',
        lineHeight: '27px',

        color: theme.colors.text,
    },
});
