import {
    sagaEffects,
    ExpensesActions,
    Utils,
    Actions,
    config,
    commonFiltersUtils,
    ExpensesSelectors,
    Cache,
} from '../../dependencies';

const { put, takeLatest, select } = sagaEffects;

const { dateRangeField } = config.filters.defaultInitials;
const { getOrderSign } = commonFiltersUtils;

const CACHE_ID = 'expenses';

function* filterExpenses({ meta, payload }) {
    const { groupId } = meta;

    const { includeTags, excludeTags, order, orderBy, supplierId, query, page, from, to, currency, paymentMethod } =
        payload;

    const { limit } = yield select(ExpensesSelectors.expensesApiSelector, groupId);
    const offset = (page - 1) * limit;

    const params = Utils.preparePayload({
        offset,
        limit,
        order: `${getOrderSign(order)}${orderBy}`,
        supplierId,
        includeTags,
        excludeTags,
        currency,
        paymentMethod,
        q: query,
    });

    const paramsList = [`${dateRangeField}=>=${from}`, `${dateRangeField}=<=${to}`];

    const nextRequest = {
        params,
        paramsList,
    };

    const requestAreEqual = yield select(Cache.areGroupValuesEqualSelector, CACHE_ID, nextRequest);

    if (!meta.forceUpdate && requestAreEqual) {
        return;
    }

    yield put(Cache.storeGroupValues(CACHE_ID, nextRequest));

    yield put(ExpensesActions.fetchExpensesRequest(groupId, nextRequest));
}

export default function* listFilters() {
    yield takeLatest(Actions.types.FILTER_EXPENSES_REQUEST, filterExpenses);
}
