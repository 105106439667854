import { createSelector, ExpensesUtils, CompaniesSelectors } from '../../dependencies';
import { calcDecimalDifferenceWithoutVat } from '../utils';
import { createExpenseFormSelector } from './form';

const itemsSelector = state => createExpenseFormSelector(state, 'items');
const vatModeSelector = state => createExpenseFormSelector(state, 'vatMode');
const currencySelector = state => createExpenseFormSelector(state, 'currency');

export const summarySelectorFactory = selectors =>
    createSelector(selectors, (items = [], vatMode, currency, defaultCurrency) => {
        if (!currency) {
            return {
                currency: defaultCurrency,
                priceWithVatTotal: 0,
                byVatRate: [],
            };
        }

        const pricesPerUnits = items.map(({ pricePerUnit = 0, quantity, vatRate }) => ({
            pricePerUnits: pricePerUnit * quantity,
            vatRate,
        }));

        const byVatRate = ExpensesUtils.groupByVatRate(pricesPerUnits);
        const flattenByVatRated = ExpensesUtils.flatByVatRated(byVatRate, vatMode);
        const priceWithVatTotal = ExpensesUtils.calcPriceWithVatTotal(flattenByVatRated);
        const priceWithoutVatTotal = ExpensesUtils.calcPriceWithoutVatTotal(flattenByVatRated);

        return {
            currency,
            priceWithVatTotal,
            priceWithoutVatTotal,
            byVatRate: flattenByVatRated,
        };
    });

export const summarySelector = summarySelectorFactory([
    itemsSelector,
    vatModeSelector,
    currencySelector,
    CompaniesSelectors.activeCompanyCurrencySelector,
]);

export const decimalDifferenceSelectorFactory = selectors =>
    createSelector(selectors, ({ priceWithVatTotal }, vatMode) =>
        calcDecimalDifferenceWithoutVat(priceWithVatTotal, vatMode),
    );

export const decimalDifferenceSelector = decimalDifferenceSelectorFactory([summarySelector, vatModeSelector]);
