import {
    React,
    PropTypes,
    FelaComponent,
    connectFela,
    Link,
    FormattedNumber,
    Chips,
    Config,
    Icons,
    PriceTooltip,
    TooltipWrapper,
    commonFilterUtils,
} from '../../dependencies';

import * as Styles from './ListItem.styles';

const { Document: IconDocument } = Icons;

const stopPropagation = e => e.stopPropagation();

function ListItem({
    expense: {
        id,
        tags,
        number,
        dateField,
        supplierName,
        totalPriceWithVat,
        totalPriceWithoutVat,
        currency,
        hasAttachment,
        totalPriceWithVatDefaultCurrency,
        totalPriceWithoutVatDefaultCurrency,
        supplierFilterPath,
        defaultCurrency,
    },
    push,
    styles,
}) {
    const handleButtonClick = React.useCallback(() => {
        push(`/expenses/${id}`);
        // eslint-disable-next-line
    }, [push]);

    const handleTagClick = React.useCallback(
        tagId => {
            const tagFilterPath = commonFilterUtils.composeUrl('/expenses', {
                includeTags: [tagId],
            });

            push(tagFilterPath);
        },
        [push],
    );

    return (
        <div
            role="button"
            tabIndex="0"
            onKeyDown={handleButtonClick}
            onClick={handleButtonClick}
            className={styles.listItem}
        >
            <div className={styles.cell}>{number}</div>

            <div className={styles.cell}>{dateField || '-'}</div>

            <div className={styles.cell}>
                <Link onClick={stopPropagation} to={supplierFilterPath} className={styles.link}>
                    {supplierName}
                </Link>
            </div>

            <div className={styles.cell}>
                <div className={styles.tagsLink}>
                    <Chips dotted={false} items={tags} limit={4} size={Chips.sizes.XS} onClick={handleTagClick} />
                </div>
            </div>

            <div className={styles.cell}>{hasAttachment ? <IconDocument className={styles.documentIcon} /> : '-'}</div>

            <FelaComponent style={Styles.cell} textAlign="right">
                {({ className: sumClassName }) => {
                    const tooltipSettings = {
                        priceWithVat: totalPriceWithVat,
                        priceWithoutVatDefaultCurrency: totalPriceWithoutVatDefaultCurrency,
                        priceWithVatDefaultCurrency: totalPriceWithVatDefaultCurrency,
                        currency,
                        defaultCurrency,
                    };
                    return (
                        <TooltipWrapper title={<PriceTooltip {...tooltipSettings} />} placement="topRight">
                            <div className={sumClassName}>
                                <FormattedNumber
                                    {...{
                                        value: totalPriceWithoutVat,
                                        style: 'currency',
                                        currency,
                                        ...Config.currency,
                                    }}
                                />
                            </div>
                        </TooltipWrapper>
                    );
                }}
            </FelaComponent>
        </div>
    );
}

export const listExpenseItemPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        }),
    ).isRequired,
    number: PropTypes.string.isRequired,
    dateField: PropTypes.string.isRequired,
    supplierName: PropTypes.string.isRequired,
    totalPriceWithVat: PropTypes.number.isRequired,
    totalPriceWithoutVat: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    hasAttachment: PropTypes.bool.isRequired,
    totalPriceWithVatDefaultCurrency: PropTypes.number.isRequired,
    totalPriceWithoutVatDefaultCurrency: PropTypes.number.isRequired,
    supplierFilterPath: PropTypes.string.isRequired,
    defaultCurrency: PropTypes.string.isRequired,
});

ListItem.expensePropType = listExpenseItemPropType;

ListItem.propTypes = {
    expense: ListItem.expensePropType.isRequired,
    push: PropTypes.func.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(ListItem);
