import React from 'react';
import { FelaComponent } from 'react-fela';
import { childrenPropType } from '@ackee/lucas';

import * as styles from './Background.styles';

function Background({ children }) {
    return <FelaComponent style={styles.background}>{children}</FelaComponent>;
}

Background.propTypes = {
    children: childrenPropType.isRequired,
};

export default Background;
