import {
    React,
    PropTypes,
    TextField,
    FelaComponent,
    UI,
    useIntl,
    ReduxUtils,
    Consts,
    useSelector,
    isSubmittingSelector,
} from '../../dependencies';

import * as styles from './RegistrationNumberField.styles';

const { InputWrapper, ButtonText } = UI;

const onlyDigits = value => {
    return (value || '')
        .split('')
        .filter(ch => ch.match(/[0-9]/))
        .slice(0, 9)
        .join('');
};

const { ARES } = Consts.entityKeys;
const { FETCH } = Consts.apiTypes;

function RegistrationNumberField({ formId, tabIndex, onSubmit }) {
    const intl = useIntl();
    const api = useSelector(state => ReduxUtils.apiSelector(state, ARES, FETCH));
    const submitting = useSelector(isSubmittingSelector(formId));

    return (
        <FelaComponent style={styles.row}>
            <InputWrapper>
                <TextField
                    {...{
                        name: 'registrationNumber',
                        type: 'phone',
                        placeholder: intl.formatMessage({
                            id: `supplier.form.registrationNumber.placeholder`,
                        }),
                        tabIndex,
                        disabled: submitting || api.inProgress,
                        format: onlyDigits,
                        parse: onlyDigits,
                    }}
                />
            </InputWrapper>
            <div>
                <ButtonText onClick={onSubmit} disabled={submitting || api.inProgress} customStyle={styles.button}>
                    {intl.formatMessage({
                        id: 'supplier.form.registrationNumber.button',
                    })}
                </ButtonText>
            </div>
        </FelaComponent>
    );
}

RegistrationNumberField.propTypes = {
    tabIndex: PropTypes.number.isRequired,
    formId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default RegistrationNumberField;
