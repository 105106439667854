import { ReduxUtils } from 'modules/expenses/dependencies';
import { types } from 'modules/expenses/services/actions';

const actionTypes = {
    REQUEST: types.FETCH_NEXT_EXPENSE_NUMBER_REQUEST,
    SUCCESS: types.FETCH_NEXT_EXPENSE_NUMBER_SUCCESS,
    FAILURE: types.FETCH_NEXT_EXPENSE_NUMBER_FAILURE,
    CANCEL: types.FETCH_NEXT_EXPENSE_NUMBER_CANCEL,
    RESET: types.FETCH_NEXT_EXPENSE_NUMBER_RESET,
};

const apiReducer = ReduxUtils.basicApiReducer({
    actionTypes,
});

export default ReduxUtils.containerReducer({
    childReducer: apiReducer,
    actionTypes: Object.values(actionTypes),
    selectors: {
        itemId: action => action.meta.type,
    },
});
