import { React, PropTypes, SelectInput, FelaComponent } from '../../dependencies';

import OrderFilter from '../../containers/OrderFilter';

import * as styles from './SelectOrderFilter.styles';

const SelectOrderFilter = ({ options, intl, hideSwitcher, orderBy: activeOrderBy, order, onChange }) => {
    const [orderBy, setOrderBy] = React.useState(activeOrderBy);

    const onChangeHandler = React.useCallback(
        nextOrderBy => {
            setOrderBy(nextOrderBy);
            onChange({
                orderBy: nextOrderBy,
                order,
            });
        },
        [onChange, order, setOrderBy],
    );

    return (
        <OrderFilter {...{ hideSwitcher, orderBy }}>
            <FelaComponent style={styles.selectInput}>
                {selectInput => (
                    <SelectInput
                        {...{
                            className: selectInput.className,
                            options: options.map(option => ({
                                label: intl.formatMessage({
                                    id: option.messageId,
                                }),
                                value: option.orderBy,
                            })),
                            size: 'small',
                            input: {
                                value: orderBy,
                                onChange: onChangeHandler,
                            },
                        }}
                    />
                )}
            </FelaComponent>
        </OrderFilter>
    );
};

SelectOrderFilter.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            orderBy: PropTypes.string.isRequired,
            messageId: PropTypes.string.isRequired,
        }),
    ).isRequired,
    orderBy: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
    intl: PropTypes.shape().isRequired,
    hideSwitcher: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

SelectOrderFilter.defaultProps = {
    hideSwitcher: false,
};

export default SelectOrderFilter;
