import { withRouter, compose, mapProps, withErrorBoundary } from '../dependencies';

import Navbar from '../components/Navbar/Navbar';

const mapOwnProps = ({ location: { pathname }, onSearchClick }) => ({
    pathname,
    onSearchClick,
});

export default compose(
    withErrorBoundary,
    withRouter,
    mapProps(mapOwnProps),
)(Navbar);
