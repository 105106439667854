import { createSelector, ActiveCompanySelectors } from '../../dependencies';

import { navItems as NavItems } from '../../config';

export const navItemsSelector = createSelector(
    [ActiveCompanySelectors.noActiveCompanyIdSelector],
    noActiveCompanyId => {
        if (noActiveCompanyId) {
            return [];
        }

        return NavItems;
    },
);
