import { React, PropTypes, ButtonDanger, FormattedMessage } from 'modules/reports/dependencies';

import { useRemoveReport } from 'modules/reports/hooks/ReportControl';

const RemoveReport = ({ reportId }) => {
    const { inProgress, removeReport } = useRemoveReport(reportId);

    return (
        <ButtonDanger loading={inProgress} onClick={removeReport} outline>
            <FormattedMessage id="report.button.removePreset" />
        </ButtonDanger>
    );
};

RemoveReport.propTypes = {
    reportId: PropTypes.string.isRequired,
};

export default RemoveReport;
