import {
    React,
    Dashboard,
    Expenses,
    ExpensesConsts,
    Reports,
    ReportsConsts,
    Lists,
    ListsConsts,
    Exports,
    ExportsConsts,
    Search,
    Consts,
    PageNotFound,
    Redirect,
    Settings,
} from '../dependencies';

const authRoutes = [
    {
        path: Consts.routes.DASHBOARD,
        render: ({ isNotOwner }) => {
            return isNotOwner ? <Redirect to={ExpensesConsts.routePaths.EXPENSES} /> : <Dashboard />;
        },
        exact: true,
    },
    {
        path: ReportsConsts.routePaths.REPORTS,
        render: () => <Reports />,
        exact: false,
    },
    {
        path: [ExpensesConsts.routePaths.EXPENSES, ExpensesConsts.routePaths.EXPENSES_RECURRING],
        render: () => <Expenses />,
        exact: false,
    },
    {
        path: ExportsConsts.routePaths.EXPORTS,
        render: () => {
            return <Exports />;
        },
        exact: false,
    },
    {
        path: ListsConsts.routePaths.LISTS,
        render: () => <Lists />,
        exact: false,
    },
    {
        path: Consts.routes.SETTINGS,
        render: () => <Settings />,
        exact: true,
    },
    {
        path: Consts.routes.SEARCH,
        render: () => <Search />,
        exact: true,
    },
    {
        component: PageNotFound,
    },
];

export default authRoutes;
