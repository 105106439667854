import { React, PropTypes, ReduxForm } from '../../dependencies';

import ExportHeader from '../ExportHeader';
import ExportForm from '../ExportForm';
import ExportFooter from '../ExportFooter';
import ExportFetcher from '../ExportFetcher';
import DeleteExportButton from '../DeleteExportButton';

const ExportEdit = ({ handleSubmit, form, exportId, serviceKeyId }) => {
    return (
        <ReduxForm onSubmit={handleSubmit}>
            <ExportHeader
                title={{
                    id: 'export.recurring.update.title',
                }}
            >
                <DeleteExportButton exportId={exportId} serviceKeyId={serviceKeyId} />
            </ExportHeader>

            <ExportFetcher exportId={exportId} serviceKeyId={serviceKeyId}>
                <ExportForm formId={form} />
            </ExportFetcher>

            <ExportFooter formId={form} submitButtonMessageId="export.recurring.submit" />
        </ReduxForm>
    );
};

ExportEdit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    exportId: PropTypes.string.isRequired,
    serviceKeyId: PropTypes.string.isRequired,
};

export default ExportEdit;
