import { React, Page, Container, Space, FelaComponent, PropTypes, ExpensesConfig } from '../../dependencies';

import { Form, ToolboxForm } from '../../containers/Forms';
import Summary from '../../containers/Summary';
import RoundingTotal from '../../containers/RoundingTotal';
import Nav from '../Nav';

import * as styles from './NewExpense.styles';

function NewExpense({ toolboxFormInitialValues }) {
    return (
        <Container>
            <FelaComponent style={styles.grid}>
                <Page margin={0}>
                    <Nav titleId="expense.create.title" />

                    <Form
                        {...{
                            Summary,
                            RoundingTotal,
                        }}
                    />
                </Page>

                <Page margin={0}>
                    <ToolboxForm initialValues={toolboxFormInitialValues} />
                </Page>
            </FelaComponent>

            <Space bottom={48} />
        </Container>
    );
}

NewExpense.propTypes = {
    toolboxFormInitialValues: PropTypes.shape({
        recurring: PropTypes.bool,
        statistic: PropTypes.bool,
    }),
};

NewExpense.defaultProps = {
    toolboxFormInitialValues: ExpensesConfig.toolboxInitialValues,
};

const arePropsEqual = ({ toolboxFormInitialValues: prev }, { toolboxFormInitialValues: next }) => {
    return prev.recurring === next.recurring && prev.statistic === next.statistic;
};

export default React.memo(NewExpense, arePropsEqual);
