import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from './types';

export const updateExpenseRequest = id => ({
    type: types.UPDATE_EXPENSE_REQUEST,
    meta: { id },
});

export const updateExpenseSuccess = (id, payload) => ({
    type: types.UPDATE_EXPENSE_SUCCESS,
    meta: { id },
    payload: formatEntity({
        entity: payload,
        template: entitiesTemplates.expense,
    }),
});

export const updateExpenseFailure = (id, error) => ({
    type: types.UPDATE_EXPENSE_FAILURE,
    meta: { id },
    error,
});

export const updateExpenseInvalidate = id => ({
    type: types.UPDATE_EXPENSE_CANCEL,
    meta: { id },
});
