export const item = ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 24px',
    gridColumnGap: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'center',

    padding: '15px 0 13px',

    fontSize: '1rem',
    lineHeight: '1.56rem',
    letterSpacing: '0.15px',

    borderBottom: `1px solid ${theme.colors.blueGrey1}`,

    color: theme.colors.text,
});
