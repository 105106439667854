import { React, PropTypes, FelaComponent, ButtonText, FormattedMessage } from '../../dependencies';

import * as styles from './RoundingTotal.styles';

function RoundingTotal({ decimalDifference, addRoundingItem }) {
    const clickHandler = React.useCallback(() => {
        addRoundingItem(decimalDifference);
    }, [addRoundingItem, decimalDifference]);

    if (decimalDifference === 0) {
        return null;
    }

    return (
        <FelaComponent style={styles.row}>
            <ButtonText onClick={clickHandler}>
                <FormattedMessage id="expense.create.round.base" />
            </ButtonText>
        </FelaComponent>
    );
}

RoundingTotal.propTypes = {
    decimalDifference: PropTypes.number.isRequired,
    addRoundingItem: PropTypes.func.isRequired,
};

export default RoundingTotal;
