import {
    fetchDependencies,
    connect,
    compose,
    ReduxUtils,
    ExpensesActions,
    Consts,
    withErrorBoundary,
} from '../dependencies';
import NewExpense from '../components/NewExpense';

const { EXPENSE } = Consts.entityKeys;
const { CREATE } = Consts.apiTypes;

const mapStateToProps = state => {
    return {
        createExpense: ReduxUtils.apiSelector(state, EXPENSE, CREATE),
    };
};

const mapDispatchToProps = dispatch => ({
    createExpenseReset() {
        dispatch(ExpensesActions.createExpenseReset());
    },
});

const fetchConfig = {
    onUnload({ createExpenseReset, createExpense }) {
        if (createExpense.success) {
            createExpenseReset();
        }
    },
};

const mergeProps = (stateProps, dispatchProps, { toolboxFormInitialValues }) => ({
    ...stateProps,
    ...dispatchProps,
    toolboxFormInitialValues,
});

export default compose(
    withErrorBoundary,
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps,
    ),
    fetchDependencies(fetchConfig),
)(NewExpense);
