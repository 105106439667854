import { config, authApi, Log, sagaEffects, Modals, Consts, ErrorsUtils } from '../../dependencies';

import { createTagSuccess, createTagFailure } from '../actions';

import types from '../actionTypes';

const { put, takeEvery } = sagaEffects;

function* createTag(action) {
    const { data: payload, startSubmit, stopSubmit } = action;
    try {
        yield startSubmit();

        const { data: tag } = yield* authApi.post(config.api.tags, payload);

        yield put(createTagSuccess(tag.name, tag));
        yield put(Modals.actions.closeModal(Consts.modals.CREATE_TAG));

        yield stopSubmit();
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.tag.api.create',
            },
        });

        yield put(createTagFailure(errorMessage));

        yield stopSubmit({
            _error: errorMessage,
        });
    }
}

export default function* createTagWatcher() {
    yield takeEvery(types.CREATE_TAG_REQUEST, createTag);
}
