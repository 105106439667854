import { reduxForm, compose, config, injectIntl } from '../../dependencies';

import ToolboxForm from '../../components/Forms/ToolboxForm';

const formConfig = {
    form: config.forms.createExpenseToolbox,
};

export default compose(
    injectIntl,
    reduxForm(formConfig),
)(ToolboxForm);
