import {
    createSelector,
    ReduxUtils,
    Utils,
    Suppliers,
    Consts,
    Selectors,
    commonFilterSelectors,
    commonFilterUtils,
    Tags,
    CompaniesSelectors,
} from '../../dependencies';

export const selectExpenses = state => state.entities.expenses;

const expensesItemsSelector = createSelector(selectExpenses, expenses => expenses.lists.byId);

const selectExpensesIds = (state, groupId) => state.entities.expenses.lists.groups[groupId];
const selectExpensesIdsPlaceholder = state => state.entities.expenses.lists.groups.placeholder;

const expensesIdsSelector = createSelector(
    [selectExpensesIds, selectExpensesIdsPlaceholder],
    (ids, placeholder) => ids || placeholder,
);

export const expensesApiSelector = (state, groupId) =>
    ReduxUtils.paginationApiSelector(state, Consts.entityKeys.EXPENSES, Consts.apiTypes.FETCH, groupId);

export const mapExpenseToListItem = (expense, { suppliers, locale, dateField, defaultCurrency, tags }) => {
    const {
        id,
        number,
        tags: tagIds,
        totalPriceWithVat,
        totalPriceWithoutVat,
        totalPriceWithVatDefaultCurrency,
        totalPriceWithoutVatDefaultCurrency,
        currency,
        supplierId,
        hasFiles,
    } = expense;
    const supplier = suppliers.byId[supplierId] || {};

    return {
        supplierName: supplier.name || '',
        supplierId,
        id,
        dateField: Utils.formatDate(locale, expense[dateField]),
        number,
        tags: tagIds
            .map(tagId => tags.byId[tagId])
            .map(tagName => tags.byName[tagName])
            .filter(Boolean)
            .map(tag => ({
                label: tag.name,
                value: tag.id,
            })),
        totalPriceWithVat,
        totalPriceWithoutVat,
        currency,
        hasAttachment: hasFiles,
        totalPriceWithVatDefaultCurrency,
        totalPriceWithoutVatDefaultCurrency,
        supplierFilterPath: commonFilterUtils.composeUrl('/expenses', {
            supplierId,
        }),
        defaultCurrency,
    };
};

export const expensesSelector = createSelector(
    [
        expensesIdsSelector,
        expensesItemsSelector,
        Suppliers.selectors.selectSuppliers,
        Selectors.localeSelector,
        commonFilterSelectors.dateFieldSelector,
        CompaniesSelectors.activeCompanyCurrencySelector,
        Tags.selectors.selectTags,
    ],
    (ids, byId, suppliers, locale, dateField, defaultCurrency, tags) => {
        return ids
            .map(id => byId[id])
            .filter(Boolean)
            .map(expense => mapExpenseToListItem(expense, { suppliers, locale, dateField, defaultCurrency, tags }));
    },
);

const selectNextExpenseNumber = (state, type) => state.entities.expenses.nextExpenseNumber[type];
const selectNextExpenseNumberPlaceholder = state => state.entities.expenses.nextExpenseNumber.placeholder;

export const nextExpenseNumberSelector = createSelector(
    [selectNextExpenseNumber, selectNextExpenseNumberPlaceholder],
    (number, placeholder) => number || placeholder,
);

const selectTotalPrice = (state, groupId) => state.entities.expenses.lists.totalPrice[groupId];
const selectTotalPricePlaceholder = state => state.entities.expenses.lists.totalPrice.placeholder;

export const totalPriceSelector = createSelector(
    [selectTotalPrice, selectTotalPricePlaceholder],
    (totalPrice, placeholder) => totalPrice || placeholder,
);
