import { qs, Utils } from '../dependencies';
import { ParamsKeys } from '../constants';

const decodeArray = value => {
    const parsedValue = Utils.safeJsonParse(value);
    return Array.isArray(parsedValue) && parsedValue.length > 0 ? parsedValue : null;
};

const encoders = {
    date: JSON.stringify,
    array: JSON.stringify,
    string: JSON.stringify,
    bool: JSON.stringify,
};

const decoders = {
    date: Utils.safeJsonParse,
    array: decodeArray,
    string: Utils.safeJsonParse,
    bool: Utils.safeJsonParse,
};

export function encodeFilterParams({
    from,
    to,
    relativePeriod,
    excludeTags,
    includeTags,
    supplierId,
    query,
    markAsSynced,
    currency,
    paymentMethod,
}) {
    const payload = {
        [ParamsKeys.FROM]: encoders.date(from),
        [ParamsKeys.TO]: encoders.date(to),
        [ParamsKeys.RELATIVE_PERIOD]: encoders.string(relativePeriod),
        [ParamsKeys.EXCLUDE_TAGS]: encoders.array(excludeTags),
        [ParamsKeys.INCLUDE_TAGS]: encoders.array(includeTags),
        [ParamsKeys.SUPPLIER_ID]: encoders.string(supplierId),
        [ParamsKeys.QUERY]: encoders.string(query),
        [ParamsKeys.MARK_AS_SYNCED]: encoders.bool(markAsSynced),
        [ParamsKeys.CURRENCY_TAGS]: encoders.array(currency),
        [ParamsKeys.PAYMENT_METHOD_TAGS]: encoders.array(paymentMethod),
    };

    return payload;
}

export function decodeReportFilterParams(params) {
    const from = params[ParamsKeys.FROM];
    const to = params[ParamsKeys.TO];
    const relativePeriod = params[ParamsKeys.RELATIVE_PERIOD];
    const excludeTags = params[ParamsKeys.EXCLUDE_TAGS];
    const includeTags = params[ParamsKeys.INCLUDE_TAGS];
    const supplierId = params[ParamsKeys.SUPPLIER_ID];
    const currency = params[ParamsKeys.CURRENCY_TAGS];
    const paymentMethod = params[ParamsKeys.PAYMENT_METHOD_TAGS];

    return {
        from: decoders.date(from),
        to: decoders.date(to),
        relativePeriod: decoders.string(relativePeriod),
        excludeTags: decoders.array(excludeTags),
        includeTags: decoders.array(includeTags),
        supplierId: decoders.string(supplierId),
        currency: decoders.array(currency),
        paymentMethod: decoders.array(paymentMethod),
    };
}

export function decodeExpensesFilterParams(params) {
    const query = params[ParamsKeys.QUERY];

    return {
        ...decodeReportFilterParams(params),
        query,
    };
}

export function decodeExportsFilterParams(params) {
    const markAsSynced = params[ParamsKeys.MARK_AS_SYNCED];

    return {
        ...decodeReportFilterParams(params),
        markAsSynced: decoders.bool(markAsSynced),
    };
}

export const composeUrl = (path, params) => {
    const search = qs.stringify(encodeFilterParams(params));

    return `${path}?${search}`;
};
