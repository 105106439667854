import { React, PropTypes, UI, FormattedMessage, Link, routePaths } from '../../../dependencies';

const { ButtonPrimary } = UI;

const Header = ({ styles }) => (
    <div className={styles.container}>
        <Link to={routePaths.EXPORT_RECURRING_NEW}>
            <ButtonPrimary>
                <FormattedMessage id="exports.recurring.new" />
            </ButtonPrimary>
        </Link>
    </div>
);

Header.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
};

export default Header;
