import {
    fetchDependencies,
    connect,
    compose,
    ExpensesActions,
    ReduxUtils,
    Consts,
    withRouter,
    ExpensesConsts,
} from '../dependencies';

const expenseTypes = {
    regular: ExpensesConsts.types.DEFAULT,
    statistical: ExpensesConsts.types.STATISTIC,
};

export default function fetchExpenseNumber(WrappedComponent) {
    const { NEXT_EXPENSE_NUMBER } = Consts.entityKeys;
    const { FETCH } = Consts.apiTypes;

    const mapStateToProps = state => {
        return {
            fetchRegularExpenseNumberApi: ReduxUtils.apiSelector(
                state,
                NEXT_EXPENSE_NUMBER,
                FETCH,
                expenseTypes.regular,
            ),
            fetchStatisticExpenseNumberApi: ReduxUtils.apiSelector(
                state,
                NEXT_EXPENSE_NUMBER,
                FETCH,
                expenseTypes.statistical,
            ),
        };
    };

    const mapDispatchToProps = {
        fetchNextExpenseNumberRequest: ExpensesActions.fetchNextExpenseNumberRequest,
        fetchNextExpenseNumberInvalidate: ExpensesActions.fetchNextExpenseNumberInvalidate,
    };

    const fetchConfig = {
        onLoad({ fetchNextExpenseNumberRequest, fetchRegularExpenseNumberApi, fetchStatisticExpenseNumberApi }) {
            if (!fetchRegularExpenseNumberApi.inProgress && !fetchRegularExpenseNumberApi.success) {
                fetchNextExpenseNumberRequest(expenseTypes.regular);
            }

            if (!fetchStatisticExpenseNumberApi.inProgress && !fetchStatisticExpenseNumberApi.success) {
                fetchNextExpenseNumberRequest(expenseTypes.statistical);
            }
        },
        onUnload({ fetchNextExpenseNumberInvalidate, fetchRegularExpenseNumberApi, fetchStatisticExpenseNumberApi }) {
            if (fetchRegularExpenseNumberApi.inProgress) {
                fetchNextExpenseNumberInvalidate(expenseTypes.regular);
            }

            if (fetchStatisticExpenseNumberApi.inProgress) {
                fetchNextExpenseNumberInvalidate(expenseTypes.statistical);
            }
        },
    };

    return compose(
        withRouter,
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
        fetchDependencies(fetchConfig),
    )(WrappedComponent);
}
