import { createSelector, config, formValueSelector, Consts, ExpenseTriggers, Tags } from '../../dependencies';

import { expenseSelector } from './expense';

export const updateExpenseFormSelector = formValueSelector(config.forms.updateExpense);

export const updateExpenseInitialValuesSelector = createSelector(
    [expenseSelector, Tags.selectors.selectTags],
    (expense, tags) => {
        const {
            type,
            currency,
            documentType,
            dueOn,
            issuedOn,
            originalNumber,
            number,
            supplierId,
            taxDeductible,
            receivedOn,
            items,
            tags: tagIds,
            taxableFulfillmentDue,
            variableSymbol,
            vatFromBase,
            files,
            paymentMethod,
        } = expense;

        return {
            recurring: false,
            type,
            currency,
            documentType,
            receivedOn,
            dueOn,
            issuedOn,
            originalNumber,
            number,
            supplier: supplierId,
            taxableFulfillmentDue,
            variableSymbol,
            taxDeductible: Boolean(taxDeductible),
            items: items.map(({ name, quantity, units, vatRate, unitPriceWithoutVat, unitPriceWithVat }) => ({
                name,
                quantity,
                units,
                vatRate,
                pricePerUnit: vatFromBase ? unitPriceWithoutVat : unitPriceWithVat,
            })),
            tags: tagIds.map(tagId => tags.byId[tagId]),
            vatMode: vatFromBase ? Consts.vatMode.BASE : Consts.vatMode.FINAL,
            files: files
                .filter(fileItem => fileItem.file !== null)
                .map(fileItem => {
                    const { id, publicUrl, file } = fileItem;
                    const name = (file || publicUrl).split('/').pop();

                    return {
                        uploaded: true,
                        localUrl: false,
                        name,
                        url: publicUrl,
                        id: String(id),
                    };
                }),
            fileUrl:
                files
                    .filter(fileItem => fileItem.file === null)
                    .map(fileItem => fileItem.publicUrl)
                    .pop() || '',
            paymentMethod,
        };
    },
);

export const updateRecurringExpenseInitialValuesSelector = (state, { expenseId, expenseTriggerId }) => {
    if (!expenseId || !expenseTriggerId) {
        throw new Error(
            `'expenseId' and 'expenseTriggerId' must be defined: expenseId: '${expenseId}', expenseTriggerId: '${expenseTriggerId}'.`,
        );
    }

    const { name, startAt, endAt, period, id } = ExpenseTriggers.selectors.expenseTriggerSelector(
        state,
        expenseTriggerId,
    );

    return {
        ...updateExpenseInitialValuesSelector(state, expenseId),
        recurring: true,
        id,
        name,
        startAt,
        endAt,
        period,
    };
};

export const initialValuesSelector = (state, { recurring, showLoader, expenseId, expenseTriggerId }) => {
    if (showLoader) {
        return {};
    }

    if (recurring) {
        return updateRecurringExpenseInitialValuesSelector(state, {
            expenseId,
            expenseTriggerId,
        });
    }

    return updateExpenseInitialValuesSelector(state, expenseId);
};
