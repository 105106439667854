import { buttonSizes } from '../../constants';

const buttonSizeEnum = {
    [buttonSizes.MEDIUM]: {
        padding: '9px 16px 7px',
        fontSize: '16px',
        lineHeight: 1.5,
    },
    [buttonSizes.SMALL]: {
        padding: '6px 16px 5px',
        fontSize: '14px',
        lineHeight: 1.57,
    },
};

export const button = ({ size, customStyle, theme, ...props }) => ({
    ...buttonSizeEnum[size],
    borderRadius: '4px',
    height: 'unset',
    fontWeight: 500,
    ...customStyle({
        ...props,
        size,
        theme,
    }),
});
