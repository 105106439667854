export const infoBlock = () => ({
    display: 'grid',
    gridTemplateColumns: '20px 1fr',
    gridColumnGap: 14,
});

export const icon = ({ theme: { colors } }) => ({
    width: 20,
    fill: colors.blueGrey2,
});

export const text = ({ theme: { colors } }) => ({
    color: colors.blueGrey4,
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: 1.64,
});
