import { connect } from '../dependencies';

import Header from '../components/Header';

import { expenseDetailHeaderSelector } from '../services/selectors';

const mapStateToProps = (state, { expenseId }) => {
    return expenseDetailHeaderSelector(state, expenseId);
};

export default connect(mapStateToProps)(Header);
