import { React, PropTypes, FormattedMessage, ButtonDefault, ExpensesConsts } from '../../../../dependencies';

const { EXPENSES_RECURRING_ITEM_EDIT } = ExpensesConsts.routePaths;

function ButtonEdit({ id, push }) {
    const onClickHandler = React.useCallback(
        e => {
            e.stopPropagation();
            push(EXPENSES_RECURRING_ITEM_EDIT.replace(':expenseTriggerId', id));
        },
        [push, id],
    );

    return (
        <ButtonDefault onClick={onClickHandler} size="small">
            <FormattedMessage id="expenses.recurring.button.edit" />
        </ButtonDefault>
    );
}

ButtonEdit.propTypes = {
    id: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
};

export default ButtonEdit;
