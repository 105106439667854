import {
    React,
    UI,
    PropTypes,
    SelectField,
    Consts,
    useIntl,
    Utils,
    isSubmittingSelector,
    useSelector,
} from '../../dependencies';

const { InputWrapper } = UI;

const toUpperCase = (value = '') => (value ? value.toUpperCase() : value);

const CountriesSelectField = ({ formId, ...rest }) => {
    const intl = useIntl();
    const options = React.useMemo(() => {
        const options = Consts.countryCodes.map(code => ({
            label: intl.formatMessage({
                id: `country.${code.toLowerCase()}`,
            }),
            value: code,
        }));

        const collator = new Intl.Collator(intl.locale);
        return options.sort((optionA, optionB) => collator.compare(optionA.label, optionB.label));
    }, [intl]);
    const isSubmitting = useSelector(isSubmittingSelector());

    return (
        <InputWrapper>
            <SelectField
                {...{
                    name: 'country',
                    placeholder: intl.formatMessage({
                        id: `supplier.form.country.placeholder`,
                    }),
                    autoComplete: 'country',
                    parse: toUpperCase,
                    options,
                    filterOption: Utils.filterOption,
                    optionFilterProp: 'children',
                    showSearch: true,
                    allowClear: false,
                    notFoundContent: null,
                    disabled: isSubmitting,
                    ...rest,
                }}
            />
        </InputWrapper>
    );
};

CountriesSelectField.propTypes = {
    formId: PropTypes.string.isRequired,
};

export default CountriesSelectField;
