import { React, PropTypes, Checkbox, connectFela } from '../../dependencies';

import * as Styles from './CheckboxWithLabel.styles';

function CheckboxField({ label, input, meta, styles }) {
    return (
        <div className={styles.row}>
            <Checkbox
                {...{
                    id: input.name,
                    onChange: input.onChange,
                    checked: input.value,
                    disabled: meta.submitting,
                }}
            />
            <label htmlFor={input.name} className={styles.label}>
                {label}
            </label>
        </div>
    );
}

CheckboxField.propTypes = {
    label: PropTypes.string.isRequired,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.bool.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
        submitting: PropTypes.bool.isRequired,
    }).isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default connectFela(Styles)(CheckboxField);
