import { compose, injectIntl, connect, Suppliers } from '../dependencies';

import SupplierField from '../components/Filter/SupplierField';

const mapStateToProps = state => {
    return {
        options: Suppliers.selectors.supplierFormOptionsSelector(state),
    };
};

export default compose(
    connect(mapStateToProps),
    injectIntl,
)(SupplierField);
