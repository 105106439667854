import {
    config,
    authApi,
    Log,
    sagaEffects,
    ErrorsUtils,
    ActiveCompanyActions,
    AuthUserActions,
    MembershipsActions,
} from '../../dependencies';

import { types, createCompanySuccess, createCompanyFailure } from '../actions';

const { put, takeLeading, take } = sagaEffects;

function* createCompany(action) {
    try {
        const { data } = yield* authApi.post(config.api.companies, action.payload);

        const companyId = String(data.id);

        yield put(MembershipsActions.fetchMembershipsRequest(companyId));

        const { FETCH_MEMBERSHIPS_SUCCESS, FETCH_MEMBERSHIPS_FAILURE } = MembershipsActions.types;
        const result = yield take([FETCH_MEMBERSHIPS_SUCCESS, FETCH_MEMBERSHIPS_FAILURE]);

        if (result.type === FETCH_MEMBERSHIPS_SUCCESS) {
            yield put(createCompanySuccess(companyId, data));

            yield put(AuthUserActions.updateAuthUser({ companyId }));
            yield put(AuthUserActions.appendMembership(result.payload.ids[0]));

            yield put(ActiveCompanyActions.setActiveCompanyRequest(companyId, { newCompany: true }));
        } else {
            yield put(createCompanyFailure(result.error));
        }
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.companies.api.create',
            },
        });

        yield put(createCompanyFailure(errorMessage));
    }
}

export default function* createCompanyWatcher() {
    yield takeLeading(types.CREATE_COMPANY_REQUEST, createCompany);
}
