import React from 'react';

const Documnet = props => (
    <svg viewBox="0 0 16 20" {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-4.000000, -2.000000)" fill="#10092D">
                <path
                    d="M2,4 L2,20 L22,20 L22,10.209 L16,4 L2,4 Z M4,6 L14,6 L14,12 L20,12 L20,18 L4,18 L4,6 Z"
                    transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-12.000000, -12.000000) "
                />
            </g>
        </g>
    </svg>
);

export default Documnet;
