import { config, authApi, Log, sagaEffects, ErrorsUtils, Utils } from '../../dependencies';

import { DISPOSABLE_EXPORT_FILE_NAME } from '../../constants';

import { fetchDisposableExportSuccess, fetchDisposableExportFailure, types } from '../actions';
import { exportsParamsSelector } from '../selectors';

const { put, takeEvery, select } = sagaEffects;

const getJsFileDownload = () => import('js-file-download');

function* fetchDisposableExport(action) {
    const { startSubmit, stopSubmit } = action;
    const { markAsSynced, filters } = yield select(exportsParamsSelector);

    const payload = {
        markAsSynced,
        filters: Utils.preparePayload(filters),
    };

    try {
        yield startSubmit();

        const result = yield* authApi.post(config.api.exportFiles, payload, { responseDataType: 'blob' });
        yield put(fetchDisposableExportSuccess());

        const jsFileDownload = yield getJsFileDownload();
        yield jsFileDownload.default(result.data, DISPOSABLE_EXPORT_FILE_NAME);

        yield stopSubmit();
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.export.api.create',
            },
        });

        yield put(fetchDisposableExportFailure(errorMessage));
        yield stopSubmit({
            _error: errorMessage,
        });
    }
}

export default function* fetchDisposableExportWatcher() {
    yield takeEvery(types.FETCH_DISPOSABLE_EXPORT_REQUEST, fetchDisposableExport);
}
