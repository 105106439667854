import { createSelector, qs, Selectors, Consts } from '../dependencies';

export const selectSearchValue = createSelector(
    [Selectors.locationSelector],
    ({ pathname, search }) => {
        if (pathname === Consts.routes.SEARCH) {
            const { query } = qs.parse(search);
            return query;
        }
        return '';
    },
);
