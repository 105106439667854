import {
    React,
    FelaComponent,
    Form,
    TextField,
    InputWrapper,
    PropTypes,
    FormattedMessage,
    errorPropType,
} from '../../dependencies';

import ResetButton from './ResetButton';
import SubmitButton from './SubmitButton';

import style from './TagForm.style';

const TagForm = ({ handleSubmit, submitting, intl, closeModal, pristine, error }) => (
    <FelaComponent style={style}>
        <Form onSubmit={handleSubmit}>
            <div>
                <InputWrapper>
                    <TextField
                        name="name"
                        placeholder={intl.formatMessage({
                            id: 'tags.form.name.placeholder',
                        })}
                    />
                </InputWrapper>
                {/* TODO: write an Error component */}

                {error && (
                    <div className="Error">
                        <FormattedMessage {...error} />
                    </div>
                )}
            </div>
            <div>
                <ResetButton onClick={closeModal} disabled={submitting} />
                <SubmitButton disabled={submitting || pristine} />
            </div>
        </Form>
    </FelaComponent>
);

TagForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    error: errorPropType,
    intl: PropTypes.shape().isRequired,
};

TagForm.defaultProps = {
    error: null,
};

export default TagForm;
