import { connect, ReduxUtils, Consts, DeleteModal } from '../dependencies';

import { deleteSupplierRequest } from '../services/actions';

const { SUPPLIER } = Consts.entityKeys;
const { DELETE } = Consts.apiTypes;

export default connect(
    (state, { id }) => {
        const { inProgress: deleting, error } = ReduxUtils.apiSelector(state, SUPPLIER, DELETE, id);
        return {
            deleting,
            error,
        };
    },
    (dispatch, { id }) => ({
        handleDelete: () => {
            dispatch(deleteSupplierRequest(id));
        },
    }),
)(DeleteModal);
