import { createSelector, Utils, Selectors } from '../../dependencies';

// import { expenseTriggersApiSelector } from './api';

export const selectExpenses = state => state.entities.expenses;

const selectExpenseTriggers = state => state.entities.expenseTriggers;

export const expenseTriggerSelector = (state, id) => state.entities.expenseTriggers.byId[id];

export const expenseTriggerNameSelector = createSelector(
    expenseTriggerSelector,
    expenseTrigger => (expenseTrigger ? expenseTrigger.name : null),
);

export const expenseTriggersSelector = createSelector(
    [
        (state, intl) => intl,
        selectExpenseTriggers,
        // expenseTriggersApiSelector,
        Selectors.localeSelector,
    ],
    (
        { formatMessage },
        { byId, ids },
        // { offset, amount },
        locale,
    ) => {
        return (
            ids
                // .slice(offset, offset + amount)
                .map(id => byId[id])
                .filter(Boolean)
                .map(expenseTrigger => {
                    const { id, name, period, startAt, endAt, expenseId } = expenseTrigger;

                    return {
                        id,
                        period,
                        name,
                        startAt: Utils.formatDate(locale, startAt),
                        endAt:
                            endAt === null
                                ? formatMessage({
                                      id: 'expense.form.endAt.undefined',
                                  })
                                : Utils.formatDate(locale, endAt),
                        expenseId,
                    };
                })
        );
    },
);

export const expenseIdSelector = createSelector(
    expenseTriggerSelector,
    expenseTrigger => (expenseTrigger ? expenseTrigger.expenseId : ''),
);

export const selectExpenseTriggerId = (state, expenseId) => state.entities.expenseTriggers.byExpenseId[expenseId];

export const isRecurringExpense = createSelector(
    selectExpenseTriggerId,
    expenseTriggerId => Boolean(expenseTriggerId),
);
