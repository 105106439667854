import { React, FelaComponent, PropTypes, Chip, FormattedMessage } from '../../dependencies';

import { deleteModalIdFactory } from '../../utils';

import UpdateTagButton from './UpdateTagButton';
import DeleteTag from '../../containers/DeleteTag';

import style from './TagInfo.style';

export const tagProps = {
    name: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const TagInfo = ({ name, id, onUpdateClick, isContributor }) => (
    <FelaComponent style={style}>
        <div>
            <Chip isLast>{name}</Chip>
        </div>
        <div>
            <UpdateTagButton onClick={onUpdateClick} />
            {!isContributor && (
                <DeleteTag modalId={deleteModalIdFactory(id)} id={id}>
                    <FormattedMessage
                        id="tags.delete.modal.title"
                        values={{
                            name,
                        }}
                    />
                </DeleteTag>
            )}
        </div>
    </FelaComponent>
);

TagInfo.propTypes = {
    ...tagProps,
    onUpdateClick: PropTypes.func.isRequired,
    isContributor: PropTypes.bool.isRequired,
};

export default TagInfo;
