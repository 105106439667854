import { compose, reduxForm, submitForm, injectIntl, CompaniesActions, connect } from '../../dependencies';

import { Forms } from '../../constants';
import { InitialValues } from '../../config';

import CompanyForm from '../../components/CompanyForm';

import validate from '../EditCompanyForm/EditCompanyForm.validate';

const form = Forms.CREATE_COMPANY;

export default compose(
    injectIntl,
    connect(
        () => ({
            initialValues: InitialValues,
        }),
        {
            onSubmit: submitForm(form, CompaniesActions.submitCreateCompanyForm),
        },
    ),
    reduxForm({
        form,
        validate,
    }),
)(CompanyForm);
