export const nav = ({ theme: { colors } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${colors.blueGrey1}`,
    padding: '24px 30px',
});

export const navLeft = () => ({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '40px 1fr',
    gridColumnGap: '16px',
});

export const subtitle = ({ theme: { colors } }) => ({
    lineHeight: 1.25,
    letterSpacing: '0.1px',
    fontSize: '0.75rem',
    marginTop: 4,
    color: colors.blueGrey4,
});
