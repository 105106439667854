const config = {
    api: {
        base: 'https://expenses-api.ackee.cz/api',
    },
    googleAnalytics: {
        trackingId: 'UA-160812951-3',
    },
    sentry: {
        dsn: 'https://e19eb4eaa11145dd8fd1644dfc96c766@o481746.ingest.sentry.io/6128737',
    },
};

export default config;
