import { React, ReduxUtils, Consts, useSelector, useDispatch, useActiveCompanyId } from '../dependencies';

import { fetchTagsRequest } from '../services/actions';

const { TAGS } = Consts.entityKeys;
const { FETCH } = Consts.apiTypes;

export function useFetchTags() {
    const { inProgress } = useSelector(state => ReduxUtils.apiSelector(state, TAGS, FETCH));
    const dispatch = useDispatch();
    const companyId = useActiveCompanyId();

    React.useEffect(() => {
        if (!inProgress && companyId) {
            dispatch(fetchTagsRequest());
        }
        // eslint-disable-next-line
    }, [companyId]);
}
