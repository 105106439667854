import { compose, injectIntl, reduxForm, config, connect, bindActionCreators, submitForm } from '../dependencies';

import SignUpForm from '../components/SignUpForm';
import { signUpForm } from '../services/actions';
import validateSignUpForm from './utils/validateSignUpForm';

const formId = config.forms.signUp;
const submitSignUpForm = submitForm(formId, signUpForm);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            onSubmit: submitSignUpForm,
        },
        dispatch,
    );

const formCofig = {
    form: formId,
    validate: validateSignUpForm,
    initialValues: {
        dataAnalysis: true,
        company: config.companyFormInitialValues,
    },
};

export default compose(injectIntl, connect(null, mapDispatchToProps), reduxForm(formCofig))(SignUpForm);
