import { connect, goBack } from '../dependencies';

import Nav from '../components/Nav';

const mapDispatchToProps = {
    goBack,
};

export default connect(
    null,
    mapDispatchToProps,
)(Nav);
