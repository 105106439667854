import { ReduxUtils, Consts } from '../../dependencies';

const { apiTypes, entityKeys } = Consts;

const { apiSelector } = ReduxUtils;

export const updateCompanyApiSelector = (state, id) => apiSelector(state, entityKeys.COMPANY, apiTypes.UPDATE, id);

export const deleteCompanyApiSelector = (state, id) => apiSelector(state, entityKeys.COMPANY, apiTypes.DELETE, id);

export const createCompanyApiSelector = state => apiSelector(state, entityKeys.COMPANY, apiTypes.CREATE);
