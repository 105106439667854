import { entitiesTemplates, formatEntity } from '../../dependencies';
import types from './types';

export const createTemporaryReportRequest = (id, qs) => ({
    type: types.CREATE_TEMPORARY_REPORT_REQUEST,
    meta: { id },
    payload: qs,
});

export const createTemporaryReportSuccess = (id, report) => ({
    type: types.CREATE_TEMPORARY_REPORT_SUCCESS,
    meta: { id },
    payload: formatEntity({
        entity: report,
        template: entitiesTemplates.temporaryReport,
    }),
});

export const createTemporaryReportFailure = (id, error) => ({
    type: types.CREATE_TEMPORARY_REPORT_FAILURE,
    meta: { id },
    error,
});

export const createTemporaryReportInvalidate = id => ({
    type: types.CREATE_TEMPORARY_REPORT_CANCEL,
    meta: { id },
});

export const createTemporaryReportReset = id => ({
    type: types.CREATE_TEMPORARY_REPORT_RESET,
    meta: { id },
});
