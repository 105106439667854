import { config, formValueSelector, createSelector } from '../../dependencies';

import { forms } from '../../config';

import { selectSuppliers } from './suppliers';

export const createExpenseFormSelector = formValueSelector(config.forms.createExpense);
export const createSupplierFormSelector = formValueSelector(forms.createSupplier);

export const supplierFormOptionsSelector = createSelector(
    selectSuppliers,
    suppliers => {
        const { ids, byId } = suppliers;

        return ids
            .map(id => byId[id])
            .filter(Boolean)
            .map(({ id, name }) => ({
                value: String(id),
                label: name,
            }));
    },
);

export const supplierSelectedSelector = createSelector(
    state => createExpenseFormSelector(state, 'supplier'),
    supplierId => Boolean(supplierId),
);
