import { combineReducers, Consts } from '../../../dependencies';

import createMembership from './createMembership';
import deleteMembership from './deleteMembership';
import updateMembership from './updateMembership';
import fetchMemberships from './fetchMemberships';

const { FETCH, CREATE, UPDATE, DELETE } = Consts.apiTypes;

export const membership = combineReducers({
    [CREATE]: createMembership,
    [DELETE]: deleteMembership,
    [UPDATE]: updateMembership,
});

export const memberships = combineReducers({
    [FETCH]: fetchMemberships,
});
