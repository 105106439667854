export const searchBarForm = () => ({
    display: 'grid',
    gridTemplateColumns: 'minmax(auto, 736px) 97px',
    gridColumnGap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
});

export const iconSearch = () => ({
    height: '1.5rem',
    width: '1.5rem',
    transform: 'rotateY(180deg)',
    fill: 'currentColor',
});
