import { React, FelaComponent, FormattedMessage } from '../../../dependencies';

import style from './Header.style';

const items = ['export.name', 'export.serviceKey.name', 'export.latestExport'];

const Header = () => (
    <FelaComponent style={style}>
        {items.map((id, index) => (
            <FormattedMessage key={index} id={id} tagName="span" />
        ))}
    </FelaComponent>
);

export default Header;
