export const row = ({ theme: { colors }, isLast }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 64,
    borderBottom: isLast ? null : `1px solid ${colors.blueGrey1}`,
    margin: '0 30px',
    cursor: 'pointer',
    userSelect: 'none',
});

export const label = ({ theme: { colors } }) => ({
    color: colors.text,
    lineHeight: 1.56,
    letterSpacing: '0.15px',
    fontSize: '1rem',
});

export const input = () => ({
    '> .ant-form-item': {
        margin: 0,
    },
});
