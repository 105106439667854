import { React, Modal, useModal, Consts } from '../../dependencies';

import CreateMembershipForm from '../../containers/CreateMembershipForm';

const CreateMembershipModal = () => {
    const modal = useModal(Consts.modals.CREATE_MEMBERSHIP);

    return (
        <Modal visible={modal.isOpen} onCancel={modal.close}>
            <CreateMembershipForm onCancel={modal.close} />
        </Modal>
    );
};

export default CreateMembershipModal;
