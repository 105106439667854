import { React, ErrorCard, useDispatch, push, ExpensesList, Consts, Pagination } from '../../dependencies';

import { expensesApiParamsSelector } from '../../services/selectors';

const { useFetchExpenses, ListContent, ExpensesListLoader } = ExpensesList;

function List() {
    const dispatch = useDispatch();
    const groupId = Consts.expensesGroups.REPORTS;
    const { inProgress, success, error } = useFetchExpenses(groupId, expensesApiParamsSelector);

    return (
        <ErrorCard {...error}>
            <ExpensesListLoader show={inProgress || !success}>
                <ListContent groupId={groupId} push={location => dispatch(push(location))} />
                <Pagination entityKey={Consts.entityKeys.EXPENSES} itemId={Consts.expensesGroups.REPORTS} />
            </ExpensesListLoader>
        </ErrorCard>
    );
}

export default List;
