import { ReduxUtils } from '../../dependencies';

import { MODULE_NAME } from '../../constants';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: MODULE_NAME,
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        typePrefix: 'CREATE_MEMBERSHIP_',
    }),
    ...createTypes({
        typePrefix: 'UPDATE_MEMBERSHIP_',
    }),
    ...createTypes({
        typePrefix: 'DELETE_MEMBERSHIP_',
    }),
    ...createTypes({
        typePrefix: 'FETCH_MEMBERSHIPS_',
    }),
    ...createTypes({
        types: ['UPDATE_MEMBERSHIP_ROLE', 'SUBMIT_CREATE_MEMBERSHIP_FORM'],
    }),
});
