import { compose, connect, bindActionCreators, injectIntl, debounce } from '../dependencies';

import SuppliersFilter from '../components/SuppliersFilter';

import { FILTER_DEBOUNCE_TIME } from '../config';
import { setFilter } from '../services/actions';

export default compose(
    connect(
        null,
        dispatch => ({
            onChange: debounce(bindActionCreators(setFilter, dispatch), FILTER_DEBOUNCE_TIME),
        }),
    ),
    injectIntl,
)(SuppliersFilter);
