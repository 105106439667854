import {
    config,
    authApi,
    takeLatestRequest,
    Log,
    sagaEffects,
    push,
    Modals,
    Consts,
    ErrorsUtils,
} from '../../dependencies';

import { routePaths } from '../../constants';
import {
    createReportSuccess,
    createReportFailure,
    createReportInvalidate,
    createTemporaryReportReset,
    types,
} from '../actions';
import { createReportDataSelector, currentReportSlugSelector } from '../selectors';
import { formatResponse } from './utils';

const { put, select } = sagaEffects;

function* createReport(action, cancelToken) {
    const { data: formData, startSubmit, stopSubmit, reset } = action;
    let error = null;

    try {
        yield startSubmit();

        const { hiddenTags, filters, companyId } = yield select(createReportDataSelector);
        const { name } = formData;

        const payload = {
            name,
            filters,
            hiddenTags,
            companyId,
        };

        const { data } = yield* authApi.post(config.api.reports, payload, {
            cancelToken,
        });

        yield put(createReportSuccess(String(data.id), formatResponse(data)));

        yield put(Modals.actions.closeModal(Consts.modals.CREATE_REPORT));

        const slug = yield select(currentReportSlugSelector);
        yield put(createTemporaryReportReset(slug));

        yield put(push(routePaths.REPORT.replace(':id', data.id)));

        yield reset();
    } catch (e) {
        Log.error(e);

        error = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.report.api.create',
            },
        });

        yield put(createReportFailure(error));
    } finally {
        yield stopSubmit({
            _error: error,
        });
    }
}

export default function* createReportWatcher() {
    const actionTypes = {
        REQUEST: types.CREATE_REPORT_REQUEST,
        cancelTask: createReportInvalidate,
    };

    yield takeLatestRequest(actionTypes, createReport);
}
