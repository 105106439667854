import { combineReducers, Consts } from '../../../dependencies';

import fetchExports from './fetchExports';
import runExportManually from './runExportManually';

import createExport from './createExport';
import updateExport from './updateExport';
import fetchExport from './fetchExport';
import deleteExport from './deleteExport';

const { FETCH, CREATE, UPDATE, DELETE } = Consts.apiTypes;

export const exports = combineReducers({
    [CREATE]: runExportManually,
    [FETCH]: fetchExports,
});

export const singleExport = combineReducers({
    [CREATE]: createExport,
    [UPDATE]: updateExport,
    [FETCH]: fetchExport,
    [DELETE]: deleteExport,
});
