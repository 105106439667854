import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from './types';

export const updateReportRequest = id => ({
    type: types.UPDATE_REPORT_REQUEST,
    meta: { id },
});

export const updateReportSuccess = (id, report) => ({
    type: types.UPDATE_REPORT_SUCCESS,
    meta: { id },
    payload: formatEntity({
        entity: report,
        template: entitiesTemplates.report,
    }),
});

export const updateReportFailure = (id, error) => ({
    type: types.UPDATE_REPORT_FAILURE,
    meta: { id },
    error,
});

export const updateReportInvalidate = id => ({
    type: types.UPDATE_REPORT_CANCEL,
    meta: { id },
});

export const updateReportReset = id => ({
    type: types.UPDATE_REPORT_RESET,
    meta: { id },
});
