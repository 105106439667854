import React from 'react';

const Download = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-2.000000, -2.000000)" fill="#10092D">
                <path d="M11,2 L11,10.365 L8.456,7.938 L7.094,9.392 L12.042,14.063 L16.94,9.392 L15.592,7.938 L13,10.365 L13,2 L11,2 Z M18,12 L18,14 L20,14 L20,20 L4,20 L4,14 L6,14 L6,12 L2,12 L2,22 L22,22 L22,12 L18,12 Z" />
            </g>
        </g>
    </svg>
);

export default Download;
