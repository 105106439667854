import { React, childrenPropType, FelaComponent, PropTypes } from '../../dependencies';

import * as styles from './Row.styles';

function Row({ children, marginBottom }) {
    return (
        <FelaComponent style={styles.row} marginBottom={marginBottom}>
            {children}
        </FelaComponent>
    );
}

Row.propTypes = {
    children: childrenPropType.isRequired,
    marginBottom: PropTypes.number,
};

Row.defaultProps = {
    marginBottom: 0,
};

export default Row;
