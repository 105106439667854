import { getFormValues, createSelector, config } from '../../dependencies';
import { toolboxInitialValues } from '../../config';

const getExpenseToolboxFormValues = getFormValues(config.forms.createExpenseToolbox);

export const createExpenseFormValuesSelector = getFormValues(config.forms.createExpense);

export const toolboxFormValuesSelector = createSelector(
    getExpenseToolboxFormValues,
    values => ({
        ...toolboxInitialValues,
        ...values,
    }),
);
