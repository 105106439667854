import { React, PropTypes, FelaComponent, FormattedMessage, childrenPropType } from '../../dependencies';
import { ORDER_TYPE } from '../../constants';
import { getNextOrder } from '../../utils';

import Switcher from '../Switcher';

import * as styles from './OrderFilter.styles';

const OrderFilter = ({ onChange, messageId, orderBy, activeOrderType, activeOrderBy, children, hideSwitcher }) => {
    const order = activeOrderBy === orderBy ? activeOrderType : null;

    const handleOrder = React.useCallback(() => {
        onChange({
            orderBy,
            order: getNextOrder(order),
        });
    }, [onChange, orderBy, order]);

    return (
        <FelaComponent style={styles.filter}>
            {filter => (
                <div className={`OrderFilter ${filter.className}`}>
                    {children || <FormattedMessage id={messageId} tagName="span" />}
                    {!hideSwitcher && (
                        <Switcher
                            {...{
                                onClick: handleOrder,
                                order,
                            }}
                        />
                    )}
                </div>
            )}
        </FelaComponent>
    );
};

OrderFilter.propTypes = {
    messageId: PropTypes.string,
    orderBy: PropTypes.string,
    activeOrderBy: PropTypes.string,
    activeOrderType: PropTypes.oneOf(Object.values(ORDER_TYPE)),
    onChange: PropTypes.func.isRequired,
    children: childrenPropType,
    hideSwitcher: PropTypes.bool,
};

OrderFilter.defaultProps = {
    activeOrderType: ORDER_TYPE.not,
    children: null,
    messageId: null,
    orderBy: null,
    activeOrderBy: null,
    hideSwitcher: false,
};

export default OrderFilter;
