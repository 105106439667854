import { React, PropTypes, Tags, Consts, useIntl } from '../../../dependencies';

import { Fields } from '../../../constants';

const currencyOptionsSelector = () =>
    Object.values(Consts.currency).map(currency => ({
        label: currency,
        value: currency,
    }));

const createPaymentMethodOption = (method, intl) => ({
    label: intl.formatMessage({ id: `expense.form.paymentMethod.option.${method}` }),
    value: method,
});

const paymentMethodSelector = (_, { intl }) =>
    Object.values(Consts.paymentMethod).map(method => createPaymentMethodOption(method, intl));

const TagsField = ({ disabled, form, optionsSelector, paymentMethodField }) => {
    const intl = useIntl();

    const processSelectedPaymentMethodOptions = React.useCallback(
        options => {
            return options.map(option => createPaymentMethodOption(option.value, intl));
        },
        [intl],
    );

    return (
        <>
            <Tags.default
                {...{
                    disabled,
                    formId: form,
                    small: true,
                    color: Tags.colors.POSITIVE,
                    fieldId: Fields.INCLUDE_TAGS,
                    labelId: 'expenses.filters.tags.include.label',
                    optionsSelector,
                    buttonNewTag: true,
                }}
            />
            <Tags.default
                {...{
                    disabled,
                    formId: form,
                    small: true,
                    color: Tags.colors.NEGATIVE,
                    fieldId: Fields.EXCLUDE_TAGS,
                    labelId: 'expenses.filters.tags.exclude.label',
                    optionsSelector,
                }}
            />
            <Tags.default
                {...{
                    disabled,
                    formId: form,
                    small: true,
                    color: Tags.colors.NEUTRAL,
                    fieldId: Fields.CURRENCY_TAGS,
                    labelId: 'expenses.filters.tags.currency.label',
                    optionsSelector: currencyOptionsSelector,
                }}
            />
            {paymentMethodField && (
                <Tags.default
                    {...{
                        disabled,
                        formId: form,
                        small: true,
                        color: Tags.colors.NEUTRAL,
                        fieldId: Fields.PAYMENT_METHOD_TAGS,
                        labelId: 'expenses.filters.tags.paymentMethod.label',
                        optionsSelector: paymentMethodSelector,
                        processSelectedOptions: processSelectedPaymentMethodOptions,
                    }}
                />
            )}
        </>
    );
};

TagsField.propTypes = {
    disabled: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    optionsSelector: PropTypes.func,
    paymentMethodField: PropTypes.bool,
};

TagsField.defaultProps = {
    optionsSelector: undefined,
    paymentMethodField: false,
};

export default TagsField;
