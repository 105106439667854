import { CommonFiltersConsts } from '../dependencies';

import { NEW_REPORT } from '../constants';

const reportFilterFields = Object.values(CommonFiltersConsts.ParamsKeys);

export const urlIncludesReportFilters = params => {
    return reportFilterFields.some(filterFieldKey => params[filterFieldKey] !== undefined);
};

export function getReportId(match) {
    if (match) {
        const { id } = match.params;

        if (id !== NEW_REPORT && id) {
            return id;
        }
    }

    return undefined;
}

export function isReportIdNewReport(match) {
    return match && match.params.id === NEW_REPORT;
}

export * from './transformFormValues';
export * from './bucketTotalTagsReducer';
