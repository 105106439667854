import React from 'react';

const TagShapeFilled = props => (
    <svg viewBox="0 0 11 22" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <path d="M8.8,0 L8.4612,0 C7.96785,0 7.48935,0.16555 7.1016,0.4697 L0.8404,5.38945 C0.30965,5.8069 0,6.44435 0,7.1192 L0,14.8808 C0,15.55565 0.30965,16.1931 0.8404,16.61055 L7.1016,21.5303 C7.48935,21.83445 7.96785,22 8.4612,22 L8.8,22 L11,22 L11,0 L8.8,0 Z" />
        </g>
    </svg>
);

export default TagShapeFilled;
