import { React, PropTypes, FormattedMessage, connectFela } from '../../../dependencies';

import Dropzone from '../../../containers/Attachment/Dropzone';
import Files from '../../../containers/Attachment/Files';

import * as Styles from './Attachment.styles';

function Attachment({ disabled, formId, onDrop, styles }) {
    return (
        <div className={styles.attachment}>
            <div className={styles.label}>
                <FormattedMessage id="expense.form.attachment.label" />
            </div>

            <Dropzone
                {...{
                    disabled,
                    onDrop,
                    input: Attachment.inputOptions,
                    formId,
                }}
            />

            <Files {...{ formId }} />
        </div>
    );
}

Attachment.inputOptions = {
    accept: 'application/*|image/*',
    multiple: true,
};

Attachment.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formId: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(Attachment);
