import { apiReducer as disposableExport } from 'modules/exports/modules/disposable-export';
import { apiReducers as recurringExports } from 'modules/exports/modules/recurring-exports';

const { exports, singleExport } = recurringExports;

export default {
    disposableExport,
    exports,
    export: singleExport,
};
