import { push, useSelector, useDispatch } from '../../dependencies';
import { routePaths } from '../../constants';
import { selectPresetOptionsSelector } from '../../services/selectors';

export function useSelectPreset() {
    const dispatch = useDispatch();
    const options = useSelector(selectPresetOptionsSelector);

    return {
        options,
        onChange(value) {
            dispatch(push(routePaths.REPORT.replace(':id', value)));
        },
        onReset() {
            dispatch(push(routePaths.REPORT_NEW));
        },
    };
}
