import { ReduxUtils } from '../dependencies';

export const ErrorUIMessages = ReduxUtils.strictObjectAccess({
    code: {
        NotAuthenticated: {
            id: 'error.api.code.NotAuthenticated',
        },
        Acl: {
            id: 'error.api.code.Acl',
        },
        Expense0: {
            id: 'error.api.code.Expense0',
        },
        Tag0: {
            id: 'error.api.code.Tag0',
        },
        User0: {
            id: 'error.api.code.User0',
        },
    },
    status: {},
    fallback: {
        id: 'error.api.general',
    },
});
