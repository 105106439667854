import { ReduxUtils } from '../../dependencies';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: 'pagination',
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        types: ['SET_PAGE'],
    }),
});
