import { React, PropTypes, RadioField, useIntl, UI, useSelector, isSubmittingSelector } from '../../../../dependencies';

import { useRoleOptions } from '../../../../hooks';

const { InputWrapper } = UI;

function RoleField({ formId }) {
    const intl = useIntl();
    const options = useRoleOptions();
    const submitting = useSelector(isSubmittingSelector(formId));

    return (
        <InputWrapper labelMarginLeft={0}>
            <RadioField
                {...{
                    name: 'role',
                    label: intl.formatMessage({
                        id: 'memberships.create.role.label',
                    }),
                    options,
                    disabled: submitting,
                }}
            />
        </InputWrapper>
    );
}

RoleField.propTypes = {
    formId: PropTypes.string.isRequired,
};

export default RoleField;
