import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const actionTypes = {
    REQUEST: types.CREATE_COMPANY_REQUEST,
    SUCCESS: types.CREATE_COMPANY_SUCCESS,
    FAILURE: types.CREATE_COMPANY_FAILURE,
    CANCEL: types.CREATE_COMPANY_CANCEL,
};

export default ReduxUtils.basicApiReducer({
    actionTypes,
});
