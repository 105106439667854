import formatEntity from './formatEntity';

export default function formatEntities({ entities = {}, template = {} }) {
    const formatattedEntities = {};

    for (const [id, entity] of Object.entries(entities)) {
        formatattedEntities[id] = formatEntity({
            entity,
            template,
        });
    }

    return formatattedEntities;
}
