import { React, PropTypes, FelaComponent } from '../../dependencies';

import * as styles from './ErrorMessage.styles';

function ErrorMessage({ children, padding }) {
    if (!children) {
        return null;
    }

    return (
        <FelaComponent style={styles.error} padding={padding}>
            {children}
        </FelaComponent>
    );
}

ErrorMessage.propTypes = {
    children: PropTypes.node,
    padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ErrorMessage.defaultProps = {
    children: null,
    padding: '0 16px 16px',
};

export default ErrorMessage;
