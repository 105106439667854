import {
    React,
    PropTypes,
    Form,
    CheckboxField,
    UI,
    FormattedMessage,
    PasswordField,
    EmailField,
} from '../../dependencies';

const { Space, Container, ButtonPrimary } = UI;

const LoginForm = ({ handleSubmit, intl, submitting, styles, form }) => {
    return (
        <Container>
            <div className={styles.form}>
                <Form onSubmit={handleSubmit}>
                    <EmailField formId={form} />

                    <Space bottom="1rem" />

                    <PasswordField
                        name="password"
                        label={intl.formatMessage({ id: 'login.password' })}
                        autoComplete="current-password"
                        formId={form}
                    />

                    <Space bottom="1rem" />

                    <div className={styles.checkbox}>
                        <CheckboxField
                            name="dataAnalysis"
                            label={
                                <FormattedMessage
                                    id="login.dataAnalysis.text"
                                    values={{
                                        link: (
                                            <a
                                                href="https://support.google.com/analytics/answer/3123662"
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                <FormattedMessage id="login.dataAnalysis.link" />
                                            </a>
                                        ),
                                    }}
                                />
                            }
                            disabled={submitting}
                        />
                    </div>

                    <div className={styles.footer}>
                        <ButtonPrimary htmlType="submit" loading={submitting} id="submitButton">
                            {intl.formatMessage({ id: 'login.submit' })}
                        </ButtonPrimary>
                    </div>
                </Form>
            </div>
        </Container>
    );
};

LoginForm.propTypes = {
    styles: PropTypes.shape().isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
        formatHTMLMessage: PropTypes.func.isRequired,
    }).isRequired,
    submitting: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
};

export default LoginForm;
