import {
    connect,
    compose,
    withErrorBoundary,
    ExpenseTriggers,
    loadable,
    Loader,
    withErrorMessage,
    mapProps,
} from '../dependencies';
import RecurringExpenseEdit from '../components/RecurringExpenseEdit';

const mapStateToProps = (state, ownProps) => {
    const { expenseTriggerId } = ownProps.match.params;
    const expenseId = ExpenseTriggers.selectors.expenseIdSelector(state, expenseTriggerId);

    return {
        expenseTriggerId,
        expenseId,
    };
};

const mapFetchExpenseTriggerPropsToChildren = ({
    fetchExpenseTriggerApi,
    expenseId,
    expenseTriggerId,
    fetchExpenseTriggerRequest,
}) => {
    const { inProgress, success, error } = fetchExpenseTriggerApi;

    return {
        showLoader: inProgress || !success,
        error,
        expenseId,
        expenseTriggerId,
        onErrorMessageClick: error ? () => fetchExpenseTriggerRequest(expenseTriggerId) : null,
    };
};

export default compose(
    withErrorBoundary,
    connect(mapStateToProps),
    ExpenseTriggers.HOC.fetchExpenseTrigger(),
    mapProps(mapFetchExpenseTriggerPropsToChildren),
    withErrorMessage,
    loadable(Loader),
)(RecurringExpenseEdit);
