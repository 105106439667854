import { all } from 'redux-saga/effects';

import { saga as auth } from 'modules/auth';
import { saga as paging } from 'modules/paging';
import { saga as ui } from 'modules/ui';
import { saga as network } from 'modules/network';
import { saga as googleAnalytics } from 'modules/google-analytics';
import { saga as activeCompany } from 'modules/active-company';

export default function* modulesSaga() {
    yield all([auth(), paging(), ui(), network(), googleAnalytics(), activeCompany()]);
}
