import { authApi, config } from 'modules/auth/dependencies';

import { normalizeAndSaveUser } from './helpers';

export default function* getAuthUser(tokens) {
    const { data: user } = yield* authApi.get(config.api.user, {
        uriParams: {
            id: tokens.accessToken.userId,
        },
    });

    yield normalizeAndSaveUser(user);

    return {};
}
