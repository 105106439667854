import { React, PropTypes, Icons, connectFela } from '../../dependencies';

import SearchBarForm from '../../containers/SearchBarForm';

import * as Styles from './SearchBar.styles';

function SearchBar({ onClose, styles }) {
    React.useEffect(() => {
        function keyPressHandler(e) {
            if (e.key === 'Escape') {
                onClose();
            }
        }

        document.addEventListener('keyup', keyPressHandler);

        return () => {
            document.removeEventListener('keyup', keyPressHandler);
        };
    }, [onClose]);

    return (
        <div className={styles.searchBar}>
            <div />

            <SearchBarForm onSearch={onClose} />

            <div className={styles.button}>
                <button type="button" onClick={onClose}>
                    <Icons.Cross className={styles.icon} />
                </button>
            </div>
        </div>
    );
}

SearchBar.propTypes = {
    onClose: PropTypes.func.isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default connectFela(Styles)(SearchBar);
