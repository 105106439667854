import { ReduxUtils } from '../../dependencies';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: 'reports',
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        typePrefix: 'FETCH_REPORT_',
    }),
    ...createTypes({
        typePrefix: 'CREATE_REPORT_',
    }),
    ...createTypes({
        typePrefix: 'DELETE_REPORT_',
    }),
    ...createTypes({
        typePrefix: 'UPDATE_REPORT_',
    }),
    ...createTypes({
        typePrefix: 'FETCH_REPORTS_',
    }),
    ...createTypes({
        typePrefix: 'CREATE_TEMPORARY_REPORT_',
    }),
    ...createTypes({
        typePrefix: 'FETCH_NEW_REPORT_',
    }),
    ...createTypes({
        types: ['SELECT_BUCKET', 'SET_VIEWPORT_SIZE', 'SET_VIEWPORT_LEFT_OFFSET'],
    }),
    ...createTypes({
        types: ['INITIALIZE_TAGS_VISIBILITY', 'SET_TAG_VISIBILITY'],
    }),
    ...createTypes({
        types: ['REPORT_DID_UPDATE', 'REPORT_WILL_UNMOUNT'],
    }),
});
