import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FelaComponent } from 'react-fela';

import { theme } from 'styles';

import ShapeDefault from './ShapeDefault';
import ShapeOutline from './ShapeOutline';

import sizes from './Chip.sizes';
import types from './Chip.types';

import * as styles from './Chip.styles';

function Chip({ type, color, children, onClick, isLast, disabled, id, size, dot }) {
    const handleClick = React.useCallback(
        e => {
            e.stopPropagation();

            if (!disabled) {
                onClick(id);
            }
        },
        [onClick, disabled, id],
    );

    switch (type) {
        case types.ADD:
            return (
                <button type="button" {...{ onClick: handleClick, id, className: 'tag', disabled }}>
                    <ShapeOutline
                        {...{
                            color,
                            size,
                            dot,
                            isLast,
                        }}
                    >
                        {children}
                    </ShapeOutline>
                </button>
            );

        case types.REMOVABLE:
            return (
                <button type="button" {...{ onClick: handleClick, id, className: 'tag', disabled }}>
                    <ShapeDefault
                        {...{
                            color,
                            removable: true,
                            size,
                            dot,
                            isLast,
                        }}
                    >
                        {children}
                    </ShapeDefault>
                </button>
            );

        case types.LABEL:
            return (
                <ShapeDefault color={color} size={size} dot={false} isLast={isLast}>
                    {children}
                </ShapeDefault>
            );

        default:
            return (
                <FelaComponent {...{ isLast, style: styles.chipDefault }}>
                    {chipDefault => (
                        <button
                            type="button"
                            {...{ onClick: handleClick, id, disabled, className: `tag ${chipDefault.className}` }}
                        >
                            <ShapeDefault
                                {...{
                                    color,
                                    size,
                                    dot,
                                    isLast,
                                }}
                            >
                                {children}
                            </ShapeDefault>
                        </button>
                    )}
                </FelaComponent>
            );
    }
}

Chip.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
    isLast: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    type: PropTypes.oneOf(Object.keys(types)),
    color: PropTypes.oneOf(Object.values(theme.colors)),
    dot: PropTypes.bool,
    size: PropTypes.string,
};

Chip.defaultProps = {
    id: '',
    type: types.DEFAULT,
    onClick: noop,
    disabled: false,
    color: theme.colors.blueGrey2,
    isLast: false,
    size: sizes.L,
    dot: true,
};

Chip.sizes = sizes;

export default Chip;
