import { React, PropTypes, connectFela, SwitchInput, noop, InputWrapper } from 'modules/reports/dependencies';

import * as Styles from './TagVisibility.styles';

function TagVisibility({ id, label, checked, onChange, styles, onFocus, onBlur }) {
    const changeHandler = React.useCallback(
        nextValue => {
            onChange(id, !nextValue);
        },
        [id, onChange],
    );

    return (
        <label htmlFor={id} className={styles.row}>
            <span className={styles.label}>{label}</span>

            <InputWrapper>
                <SwitchInput
                    id={id}
                    className={styles.input}
                    input={{
                        value: checked,
                        onChange: changeHandler,
                        onFocus,
                        onBlur,
                    }}
                />
            </InputWrapper>
        </label>
    );
}

TagVisibility.propTypes = {
    styles: PropTypes.shape().isRequired,

    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};

TagVisibility.defaultProps = {
    onFocus: noop,
    onBlur: noop,
};

const isEqual = (prevProps, { label, checked }) => prevProps.label === label && prevProps.checked === checked;

export default React.memo(connectFela(Styles)(TagVisibility), isEqual);
