import { types } from 'modules/expenses/services/actions';

const initialState = '';

export default function expenseNumberReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_NEXT_EXPENSE_NUMBER_SUCCESS: {
            return action.payload.number;
        }

        case types.FETCH_NEXT_EXPENSE_NUMBER_RESET:
            return initialState;

        default:
            return state;
    }
}
