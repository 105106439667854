import { React, FelaComponent, childrenPropType } from '../../dependencies';

import * as styles from './ListWrapper.styles';

function ListWrapper({ children }) {
    return <FelaComponent style={styles.listWrapper}>{children}</FelaComponent>;
}

ListWrapper.propTypes = {
    children: childrenPropType.isRequired,
};

export default ListWrapper;
