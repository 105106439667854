import { ReduxUtils } from '../../../dependencies';
import types from '../../actionTypes';

const actionTypes = {
    REQUEST: types.CREATE_SUPPLIER_REQUEST,
    SUCCESS: types.CREATE_SUPPLIER_SUCCESS,
    FAILURE: types.CREATE_SUPPLIER_FAILURE,
    CANCEL: types.CREATE_SUPPLIER_CANCEL,
};

export default ReduxUtils.basicApiReducer({
    actionTypes,
});
