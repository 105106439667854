export const footer = ({ childrenLength }) => ({
    padding: '0 1.875rem 2rem',
    display: 'grid',
    gridTemplateColumns: `repeat(${childrenLength}, auto)`,
    gridColumnGap: 48,
    alignItems: 'center',
    justifyContent: 'flex-end',
});

export const filter = () => ({
    maxWidth: '50rem',
    paddingTop: '1px',
});
