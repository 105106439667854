export const noData = ({ theme: { colors }, padding }) => ({
    fontSize: '16px',
    lineHeight: 1.56,
    letterSpacing: '0.2px',
    color: colors.blueGrey2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding,
});
