import { fetchDependencies, connect, compose, ReduxUtils, Consts } from '../dependencies';

import * as Actions from '../services/actions';
import { listOrder, listOrderBy } from '../config';

const { EXPENSE_TRIGGERS } = Consts.entityKeys;
const { FETCH } = Consts.apiTypes;

function fetchExpenseTriggers() {
    const mapStateToProps = state => {
        return {
            fetchExpenseTriggersApi: ReduxUtils.apiSelector(state, EXPENSE_TRIGGERS, FETCH),
        };
    };

    const mapDispatchToProps = dispatch => ({
        fetchExpenseTriggersRequest() {
            dispatch(
                Actions.fetchExpenseTriggersRequest({
                    params: {
                        order: `${listOrder}${listOrderBy}`,
                    },
                }),
            );
        },
        fetchExpenseTriggersInvalidate() {
            dispatch(Actions.fetchExpenseTriggersInvalidate());
        },
    });

    const fetchConfig = {
        onLoad({ fetchExpenseTriggersApi: { inProgress }, fetchExpenseTriggersRequest }) {
            if (!inProgress) {
                fetchExpenseTriggersRequest();
            }
        },
        onUnload({ fetchExpenseTriggersApi: { inProgress }, fetchExpenseTriggersInvalidate }) {
            if (inProgress) {
                fetchExpenseTriggersInvalidate();
            }
        },
    };

    return WrappedComponent =>
        compose(
            connect(
                mapStateToProps,
                mapDispatchToProps,
            ),
            fetchDependencies(fetchConfig),
        )(WrappedComponent);
}

export default fetchExpenseTriggers;
