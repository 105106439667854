import { combineReducers, Consts } from '../../../dependencies';

import fetchTags from './fetchTags';
import createTag from './createTag';
import updateTag from './updateTag';
import deleteTag from './deleteTag';

const { FETCH, CREATE, UPDATE, DELETE } = Consts.apiTypes;

export const tags = combineReducers({
    [FETCH]: fetchTags,
});

export const tag = combineReducers({
    [CREATE]: createTag,
    [UPDATE]: updateTag,
    [DELETE]: deleteTag,
});
