import React from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from '@ackee/lucas';
import { FelaComponent } from 'react-fela';

import * as Icons from '../../../Icons';

import sizes from '../Chip.sizes';

import * as styles from './ShapeDefault.styles';

const { TagShapeFilled, TagShape } = Icons;

function ShapeDefault({ children, color, size, dot, removable, isLast }) {
    const IconShape = dot ? TagShape : TagShapeFilled;

    return (
        <FelaComponent
            {...{
                style: styles.shape,
                isLast,
                size,
            }}
        >
            <FelaComponent
                {...{
                    style: styles.front,
                    color,
                    size,
                }}
            >
                {({ className }) => <IconShape {...{ className }} />}
            </FelaComponent>

            <FelaComponent
                {...{
                    style: styles.body,
                    color,
                    size,
                }}
            >
                <span>{children}</span>
                {removable && (
                    <FelaComponent {...{ style: styles.icon, size }}>
                        {({ className }) => <Icons.Cross {...{ className }} />}
                    </FelaComponent>
                )}
            </FelaComponent>
        </FelaComponent>
    );
}

ShapeDefault.propTypes = {
    children: childrenPropType.isRequired,
    color: PropTypes.string.isRequired,
    size: PropTypes.oneOf(Object.values(sizes)).isRequired,
    removable: PropTypes.bool,
    dot: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
};

ShapeDefault.defaultProps = {
    removable: false,
};

export default ShapeDefault;
