import { commonFiltersSelectors, createSelector } from '../../dependencies';

import { transformUrlParamsToFormValues, transformUrlParamsToApiRequest } from '../../utils';

const { expensesUrlParamsSelector } = commonFiltersSelectors;

export const expensesParamsSelector = createSelector(expensesUrlParamsSelector, transformUrlParamsToApiRequest);

export const filterFormInitialValuesSelector = createSelector(
    [expensesUrlParamsSelector],
    transformUrlParamsToFormValues,
);
