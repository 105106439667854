import { ReduxUtils, Consts } from '../../dependencies';

const asyncType = ReduxUtils.createAsyncType({
    modulePrefix: Consts.entityKeys.EXPENSES,
});

export default ReduxUtils.strictObjectAccess({
    ...asyncType({
        types: ['EXPENSE_TAG_ADD', 'EXPENSE_TAG_REMOVE'],
    }),
});
