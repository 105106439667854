export const buttonPrimary = ({ theme: { colors } }) => {
    const activeStyle = {
        backgroundColor: colors.primaryHover,
        borderColor: colors.primaryHover,
    };

    return {
        '&.ant-btn-primary': {
            '&:hover': activeStyle,
            '&:focus': activeStyle,
            '&:active': activeStyle,
        },
    };
};
