import { combineReducers, Consts } from 'modules/expenses/dependencies';

import createServiceKey from './createServiceKey';
import deleteServiceKey from './deleteServiceKey';
import fetchServiceKey from './fetchServiceKey';
import updateServiceKey from './updateServiceKey';
import fetchServiceKeys from './fetchServiceKeys';

const { FETCH, CREATE, UPDATE, DELETE } = Consts.apiTypes;

export const serviceKey = combineReducers({
    [CREATE]: createServiceKey,
    [DELETE]: deleteServiceKey,
    [FETCH]: fetchServiceKey,
    [UPDATE]: updateServiceKey,
});

export const serviceKeys = combineReducers({
    [FETCH]: fetchServiceKeys,
});
