import { sagaEffects } from 'modules/auth/dependencies';

import storeValue from './storeValue';
import retrieveValue from './retrieveValue';

const { all } = sagaEffects;

export default function*() {
    yield all([retrieveValue(), storeValue()]);
}
