export const row = ({ theme: { colors } }) => ({
    alignItems: 'center',
    padding: '18px 30px',

    borderBottom: `1px solid ${colors.blueGrey1}`,

    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr) 100px',
    gridColumnGap: 8,
});

export const col = ({ theme: { colors } }) => ({
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.3px',
    lineHeight: 1.36,
    color: colors.blueGrey4,
});
