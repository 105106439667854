import { compose, injectIntl, reduxForm, config, connect, bindActionCreators, submitForm } from '../dependencies';

import LoginForm from '../components/LoginForm';
import { loginForm } from '../services/actions';
import validateSignInForm from './utils/validateSignInForm';

const submitLoginForm = submitForm(config.forms.login, loginForm);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            onSubmit: submitLoginForm,
        },
        dispatch,
    );

const formCofig = {
    form: config.forms.login,
    validate: validateSignInForm,
    initialValues: {
        dataAnalysis: true,
    },
};

export default compose(injectIntl, connect(null, mapDispatchToProps), reduxForm(formCofig))(LoginForm);
