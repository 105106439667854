import { React, connectFela, PropTypes } from '../../dependencies';

import SearchInfo from '../SearchInfo';

import * as Styles from './SearchHeader.styles';

function SearchHeader({ styles, search }) {
    return (
        <div className={styles.searchHeader}>
            <SearchInfo search={search} />
        </div>
    );
}

SearchHeader.propTypes = {
    styles: PropTypes.shape({}).isRequired,
    search: PropTypes.string.isRequired,
};

export default connectFela(Styles)(SearchHeader);
