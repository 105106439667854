import { React, PropTypes, Modals, FormattedMessage, connectFela, Icons } from '../../dependencies';

import * as Styles from './ChangeExportedExpenseModal.styles';

const { withModalPropTypes, Modal } = Modals;

function ChangeExportedExpenseModal({ onSubmit, closeModal, goBack, isOpen, styles }) {
    const handleOnOk = React.useCallback(() => {
        closeModal();
        onSubmit();
    }, [closeModal, onSubmit]);
    const handleOnCancel = React.useCallback(() => {
        closeModal();
        goBack();
    }, [closeModal, goBack]);

    const modalProps = {
        visible: isOpen,
        onOk: handleOnOk,
        onCancel: handleOnCancel,
        cancelText: <FormattedMessage id="expense.button.dontSave" />,
        okText: <FormattedMessage id="expense.button.save" />,
        title: null,
        footer: null,
        closable: false,
    };

    return (
        <Modal {...modalProps}>
            <div className={styles.modal}>
                <Icons.NeutralFace className={styles.icon} />

                <p className={styles.text}>
                    <FormattedMessage id="expense.edit.alert.exported" />
                </p>
            </div>
        </Modal>
    );
}

ChangeExportedExpenseModal.propTypes = {
    ...withModalPropTypes,
    onSubmit: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default connectFela(Styles)(ChangeExportedExpenseModal);
