import { React, PropTypes, FelaComponent, childrenPropType } from '../../../../../dependencies';

import * as styles from './Cell.styles';

function Cell({ children }) {
    return <FelaComponent style={styles.cell}>{children}</FelaComponent>;
}

Cell.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, childrenPropType]).isRequired,
};

export default Cell;
