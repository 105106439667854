import { Level } from '../../constants';
import { TRANSITION_DURATION } from '../../config';

const getMessageLevelStyle = (level, { colors, boxShadows }) => {
    switch (level) {
        case Level.SUCCESS:
            return {
                backgroundColor: colors.positive,
                boxShadow: boxShadows.alertSuccess,
            };
        case Level.ERROR:
            return {
                backgroundColor: colors.primarySelection,
                boxShadow: boxShadows.alertFailure,
            };
        default:
            return {};
    }
};

const getMessageStateStyle = state => {
    switch (state) {
        case 'entering':
        case 'entered':
            return {
                opacity: 1,
                transform: 'translateX(0%)',
            };

        case 'exiting':
        case 'exited':
            return {
                opacity: 0,
                transform: 'translateX(-25%)',
            };

        default:
            return {};
    }
};

export const content = ({ theme, level, state, button }) => ({
    display: 'grid',
    gridTemplateColumns: button ? 'auto 1fr auto' : 'auto 1fr',
    gridColumnGap: '1.13rem',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '0.63rem 1.5rem',
    transition: `transform ${TRANSITION_DURATION}ms, opacity ${TRANSITION_DURATION}ms`,
    opacity: 0,
    willChange: 'transform, opacity',
    ...getMessageStateStyle(state, theme),
    ...getMessageLevelStyle(level, theme),
});

const getIconLevelStyle = (level, { colors }) => {
    switch (level) {
        case Level.SUCCESS:
            return {
                fill: colors.text,
            };

        case Level.ERROR:
            return {
                fill: colors.primary,
            };

        default:
            return {};
    }
};

export const icon = ({ theme, level }) => ({
    ...getIconLevelStyle(level, theme),
    height: '1.25rem',
});

const getTextLevelStyle = (level, { colors }) => {
    switch (level) {
        case Level.SUCCESS:
            return {
                color: colors.text,
            };

        case Level.ERROR:
            return {
                color: colors.primary,
            };

        default:
            return {};
    }
};

export const text = ({ theme, level }) => ({
    ...getTextLevelStyle(level, theme),
    fontSize: '1rem',
    lineHeight: 1.56,
    letterSpacing: '0.15px',
});

export const button = ({ theme: { colors }, level }) => {
    return {
        marginLeft: 6,
        lineHeight: 1.71,
        letterSpacing: '0.8px',
        fontSize: '0.88rem',
    };
};
