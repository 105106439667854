import { React, PropTypes, FelaComponent, FormattedNumber, FormattedMessage, Config } from '../../dependencies';

import Row from './Row';
import TotalPrice from './TotalPrice';

import * as styles from './Summary.styles';

const { maximumFractionDigits } = Config.currency;

function Summary({ byVatRate, priceWithVatTotal, priceWithoutVatTotal, currency }) {
    return (
        <FelaComponent style={styles.summary}>
            <Row>
                {Summary.itemLabels.map(labelId => (
                    <FelaComponent key={labelId} style={styles.label}>
                        <FormattedMessage id={`expense.form.summary.${labelId}`} />
                    </FelaComponent>
                ))}
            </Row>

            {byVatRate.map(item => {
                const row = [
                    {
                        style: 'percent',
                        value: item.vatRate,
                        maximumFractionDigits,
                    },
                    {
                        ...Config.currency,
                        value: item.vat,
                        style: 'currency',
                        currency,
                    },
                    {
                        ...Config.currency,
                        style: 'currency',
                        value: item.priceWithoutVatTotal,
                        currency,
                    },
                ];

                return (
                    <Row key={item.vatRate}>
                        {row.map((cell, index) => (
                            <FelaComponent key={index} style={styles.value}>
                                <FormattedNumber {...cell} />
                            </FelaComponent>
                        ))}
                    </Row>
                );
            })}

            <TotalPrice
                {...{
                    priceWithVatTotal,
                    priceWithoutVatTotal,
                    currency,
                }}
            />
        </FelaComponent>
    );
}

Summary.itemLabels = ['vatRate', 'vat', 'base'];

Summary.propTypes = {
    byVatRate: PropTypes.arrayOf(
        PropTypes.shape({
            vatRate: PropTypes.number.isRequired,
            priceWithoutVatTotal: PropTypes.number.isRequired,
            vat: PropTypes.number.isRequired,
        }),
    ).isRequired,
    priceWithVatTotal: PropTypes.number.isRequired,
    priceWithoutVatTotal: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
};

export default Summary;
