import {
    config,
    ExpensesActions,
    reduxForm,
    connect,
    compose,
    submitForm,
    Loader,
    loadable,
    injectIntl,
    goBack,
    ExpensesSelectors,
    pure,
} from '../../../dependencies';

import { createExpenseInitialValuesSelector, isFormReadySelector } from '../../../services/selectors';
import { Form } from '../../../components/Forms';
import validate from './validate';

import fetchExpenseNumber from '../../../HOC/fetchExpenseNumber';

const formId = config.forms.createExpense;

const mapStateToProps = state => {
    const params = ExpensesSelectors.toolboxFormValuesSelector(state);

    return {
        initialValues: createExpenseInitialValuesSelector(state),
        showLoader: !isFormReadySelector(state),
        validate: validate(params),
        params,
    };
};

const mapDispatchToProps = {
    onSubmit: submitForm(formId, ExpensesActions.createExpenseRequest),
    goBack,
};

const formConfig = {
    form: formId,
    // Must be enabled due to the values from `toolboxFormValuesSelector`
    // -> form fields and init values differ based on values from the toolbox form
    enableReinitialize: true,
};

const mergeProps = (stateProps, dispatchProps, { RoundingTotal, Summary, intl }) => {
    return {
        ...stateProps,
        ...dispatchProps,
        intl,
        Summary,
        RoundingTotal,
    };
};

export default compose(
    injectIntl,
    fetchExpenseNumber,
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loadable(Loader),
    pure(),
    reduxForm(formConfig),
)(Form);
