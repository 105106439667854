import { ReduxUtils } from '../dependencies';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: 'suppliers',
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        typePrefix: 'FETCH_SUPPLIERS_',
    }),
    ...createTypes({
        typePrefix: 'FETCH_SUPPLIER_',
    }),
    ...createTypes({
        typePrefix: 'CREATE_SUPPLIER_',
    }),
    ...createTypes({
        typePrefix: 'UPDATE_SUPPLIER_',
    }),
    ...createTypes({
        typePrefix: 'DELETE_SUPPLIER_',
    }),
    ...createTypes({
        typePrefix: 'FETCH_ARES_DATA_',
    }),
    ...createTypes({
        typePrefix: 'SET_',
        types: ['FILTER'],
    }),
});
