import { React, PropTypes, useModal, Consts, Modal, UI, FormattedMessage } from '../../dependencies';

import { useDeleteMembership } from '../../hooks';

const { Icons } = UI;

const CreateMembershipModal = ({ styles }) => {
    const modalId = Consts.modals.DELETE_MEMBERSHIP;
    const modal = useModal(modalId);
    const { inProgress, error, deleteMembership } = useDeleteMembership(modal.isOpen ? modal.data.id : null);

    if (!modal.isOpen) {
        return null;
    }

    return (
        <Modal
            visible={modal.isOpen}
            onCancel={modal.close}
            onOk={deleteMembership}
            okText={<FormattedMessage id="modals.delete.ok" />}
            confirmLoading={inProgress}
            error={error}
        >
            <div className={styles.modal}>
                <Icons.NeutralFace width="20" height="20" />
                <FormattedMessage
                    id="memberships.delete.text"
                    values={{
                        email: modal.data.email,
                    }}
                />
            </div>
        </Modal>
    );
};

CreateMembershipModal.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default CreateMembershipModal;
