import { React, FelaComponent } from '../../dependencies';

import TagListFilter from '../../containers/TagListFilter';
import CreateTagButton from '../../containers/CreateTagButton';

import style from './TagListHeader.style';

const TagListHeader = () => (
    <FelaComponent style={style}>
        <TagListFilter />
        <CreateTagButton />
    </FelaComponent>
);

export default TagListHeader;
