import React from 'react';

/**
 * This hook attemps to solve the issue with static dependencies:
 * -> i.e. Values which must be fresh inside a hook scope, however not triggering update when they change.
 * -> e.g. You have useEffect hook. Inside the hook you need to have access to 'foo' variable.
 *         You want to have current 'foo' value in the hook scope without adding it to hook's dependencies.
 * @param {object} object
 */
export function useCurrentObjectRef(object) {
    const objectRef = React.useRef({});

    React.useEffect(() => {
        for (const [key, value] of Object.entries(object)) {
            objectRef.current[key] = value;
        }
        // eslint-disable-next-line
    }, Object.values(object));

    return objectRef;
}
