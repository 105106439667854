import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const actionTypes = {
    REQUEST: types.RUN_EXPORT_MANUALLY_REQUEST,
    SUCCESS: types.RUN_EXPORT_MANUALLY_SUCCESS,
    FAILURE: types.RUN_EXPORT_MANUALLY_FAILURE,
    CANCEL: types.RUN_EXPORT_MANUALLY_CANCEL,
};

const apiReducer = ReduxUtils.basicApiReducer({
    actionTypes,
});

export default ReduxUtils.containerReducer({
    childReducer: apiReducer,
    actionTypes: Object.values(actionTypes),
    selectors: {
        itemId: action => action.meta.exportId,
    },
});
