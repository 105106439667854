import { sagaEffects } from 'modules/auth/dependencies';

import { saga as petrus } from '../../config/petrus';

import loginForm from './loginForm';
import signUpForm from './signUpForm';
import dataAnalysisPermission from './dataAnalysisPermission';

const { all } = sagaEffects;

export default function*() {
    yield all([loginForm(), signUpForm(), petrus(), dataAnalysisPermission()]);
}
