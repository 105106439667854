import { React, PropTypes } from '../../dependencies';

import MembershipsListHeader from '../MembershipsListHeader';
import MembershipsFetcher from '../MembershipsFetcher';
import MembershipsList from '../MembershipsList';

const Memberships = ({ styles }) => (
    <div className={styles.container}>
        <MembershipsListHeader />
        <MembershipsFetcher>
            <MembershipsList />
        </MembershipsFetcher>
    </div>
);

Memberships.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
};

export default Memberships;
