import { sagaEffects, AUTH_SESSION_END, ReactGA } from '../../../dependencies';

import { Events, Timing } from '../../../config';
import { types } from '../../actions';

const { race, take, call } = sagaEffects;

function beforeUnload(authSessionStartedAt) {
    return new Promise(res => {
        window.addEventListener('unload', () => {
            Timing.finishAuthSessionDurationMeasurement(authSessionStartedAt);
            Events.userLeft();
            ReactGA.set({
                userId: null,
            });
            res();
        });
    });
}

export default function*() {
    while (true) {
        const action = yield take(types.TRACK_AUTH_SESSION_START);

        yield race({
            task: call(beforeUnload, action.payload),
            cancel: take(AUTH_SESSION_END),
        });
    }
}
