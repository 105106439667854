import { types } from 'modules/expenses/services/actions';

const initialState = null;

export default function duplicateExpense(state = initialState, action) {
    switch (action.type) {
        case types.DUPLICATE_EXPENSE_REQUEST:
            return action.payload.expenseId;

        case types.DUPLICATE_EXPENSE_SUCCESS:
            return initialState;

        default:
            return state;
    }
}
