import types from '../actionTypes';

const initialState = {
    isOpen: false,
};

export default function modalReducer(state = initialState, action) {
    switch (action.type) {
        case types.OPEN_MODAL:
            return {
                ...state,
                isOpen: true,
                data: action.payload.data,
            };

        case types.CLOSE_MODAL:
            return null;

        default:
            return state;
    }
}
