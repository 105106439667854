import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const actionTypes = {
    REQUEST: types.FETCH_SERVICE_KEYS_REQUEST,
    SUCCESS: types.FETCH_SERVICE_KEYS_SUCCESS,
    FAILURE: types.FETCH_SERVICE_KEYS_FAILURE,
    CANCEL: types.FETCH_SERVICE_KEYS_CANCEL,
};

export default ReduxUtils.basicApiReducer({
    actionTypes,
});
