import { React, PropTypes, Space } from '../../dependencies';

import Header from './Header';
import Item from './Item';

function List({ expenseTriggers, push }) {
    return (
        <>
            <Header />

            {expenseTriggers.map(expenseTrigger => (
                <Item
                    {...{
                        key: expenseTrigger.id,
                        expenseTrigger,
                        push,
                    }}
                />
            ))}

            <Space bottom={48} />
        </>
    );
}

List.propTypes = {
    expenseTriggers: PropTypes.arrayOf(PropTypes.shape(Item.expenseTriggerPropType)).isRequired,
    push: PropTypes.func.isRequired,
};

export default List;
