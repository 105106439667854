import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from './types';

export const createExpenseTriggerRequest = (expenseId, payload = {}) => ({
    type: types.CREATE_EXPENSE_TRIGGER_REQUEST,
    meta: { expenseId },
    payload,
});

export const createExpenseTriggerSuccess = (expenseId, payload) => ({
    type: types.CREATE_EXPENSE_TRIGGER_SUCCESS,
    meta: {
        expenseId,
        id: payload.id,
    },
    payload: formatEntity({
        entity: payload,
        template: entitiesTemplates.expenseTrigger,
    }),
});

export const createExpenseTriggerFailure = (expenseId, error) => ({
    type: types.CREATE_EXPENSE_TRIGGER_FAILURE,
    meta: { expenseId },
    error,
});

export const createExpenseTriggerInvalidate = expenseId => ({
    type: types.CREATE_EXPENSE_TRIGGER_CANCEL,
    meta: { expenseId },
});
