import { React, PropTypes, RadioField, injectIntl, Consts, InputWrapper } from '../../../dependencies';

function Period({ intl, disabled }) {
    const options = Period.translateOptions(intl);

    return (
        <InputWrapper>
            <RadioField
                {...{
                    name: 'period',
                    label: intl.formatMessage({
                        id: 'expense.form.period.label',
                    }),
                    // NOTE: store as number, display as string
                    parse: value => (value ? Number(value) : value),
                    format: value => (value ? String(value) : value),
                    options,
                    disabled,
                }}
            />
        </InputWrapper>
    );
}

Period.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

Period.translateOptions = ({ formatMessage }) => {
    const prefix = 'expense.form.period.option';
    const ids = Object.values(Consts.period);

    return ids.map(id => ({
        value: id,
        label: formatMessage({ id: `${prefix}.${id}` }),
    }));
};

export default injectIntl(Period);
