import { React, PropTypes, UI, useModal, Consts } from '../../dependencies';

const { Icons } = UI;

const EditCompanyButton = ({ styles }) => {
    const modal = useModal(Consts.modals.EDIT_COMPANY);

    return (
        <button type="button" onClick={() => modal.open()}>
            <Icons.Update className={styles.iconEdit} />
        </button>
    );
};

EditCompanyButton.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default EditCompanyButton;
