import { config, authApi, Log, sagaEffects, ErrorsUtils, Normalizers } from '../../dependencies';

import { fetchTagsFailure, fetchTagsSuccess } from '../actions';
import types from '../actionTypes';
import { formatPayload } from '../utils';

const { takeLeading, put } = sagaEffects;

function* fetchTags() {
    try {
        const { data } = yield* authApi.get(config.api.tags, {
            params: {
                // TODO: this is temp.:
                limit: Number.MAX_SAFE_INTEGER,
            },
        });

        const { entities } = Normalizers.tags(data);

        const payload = formatPayload(entities.tags);

        yield put(fetchTagsSuccess(payload));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.tags.api.get',
            },
        });

        yield put(fetchTagsFailure(errorMessage));
    }
}

export default function* watchFetchTags() {
    yield takeLeading(types.FETCH_TAGS_REQUEST, fetchTags);
}
