import { config, authApi, takeLatestRequest, Log, sagaEffects, Normalizers, ErrorsUtils } from '../../dependencies';

import { types, fetchServiceKeySuccess, fetchServiceKeyFailure, fetchServiceKeyInvalidate } from '../actions';

const { put } = sagaEffects;

function* fetchServiceKey(action, cancelToken) {
    const { id } = action.meta;

    try {
        const requestConfig = {
            uriParams: { id },
            cancelToken,
        };

        const { data } = yield* authApi.get(config.api.serviceKey, requestConfig);

        const { entities } = Normalizers.serviceKey(data);

        yield put(fetchServiceKeySuccess(id, entities.serviceKeys[id]));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e);

        yield put(fetchServiceKeyFailure(id, errorMessage));
    }
}

export default function* fetchServiceKeyWatcher() {
    const actionTypes = {
        REQUEST: types.FETCH_SERVICE_KEY_REQUEST,
        cancelTask: fetchServiceKeyInvalidate,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, fetchServiceKey);
}
