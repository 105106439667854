import { sagaEffects } from '../../dependencies';

import createServiceKey from './createServiceKey';
import deleteServiceKey from './deleteServiceKey';
import fetchServiceKey from './fetchServiceKey';
import updateServiceKey from './updateServiceKey';
import fetchServiceKeys from './fetchServiceKeys';

const { all } = sagaEffects;

export default function* expensesSaga() {
    yield all([fetchServiceKey(), deleteServiceKey(), createServiceKey(), updateServiceKey(), fetchServiceKeys()]);
}
