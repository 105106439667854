import { React, PropTypes, FelaComponent, Icons } from '../../dependencies';

import * as styles from './ExternalLink.styles';

function ExternalLink({ externalLink }) {
    return (
        <FelaComponent style={styles.row}>
            <FelaComponent style={styles.icon}>{({ className }) => <Icons.Link {...{ className }} />}</FelaComponent>
            <FelaComponent style={styles.link}>
                {({ className }) => (
                    <a
                        {...{
                            href: externalLink,
                            target: '_blank',
                            rel: 'noopener noreferrer',
                            className,
                        }}
                    >
                        {externalLink}
                    </a>
                )}
            </FelaComponent>
        </FelaComponent>
    );
}

ExternalLink.propTypes = {
    externalLink: PropTypes.string.isRequired,
};

export default ExternalLink;
