import d from '../defaultValues';
import { any } from '../helpers';

const dashboard = {
    from: d.string,
    to: d.string,
    buckets: any,
};

export default dashboard;
