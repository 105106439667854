import { createSelector } from '../../dependencies';

export const selectTags = state => state.entities.tags;
export const tagSelector = (state, name) => state.entities.tags.byName[name];

export const tagIdsSelector = createSelector(
    selectTags,
    ({ names, byName }) => names.map(name => byName[name].id),
);

export const tagsSelector = createSelector(
    selectTags,
    ({ byName, names }) => names.map(name => byName[name]),
);

export const tagNamesSelector = createSelector(
    tagsSelector,
    tags => tags.names,
);
