import { takeLatestRequest, sagaEffects, authApi, config, Log, Normalizers } from '../../dependencies';

import { types, fetchReportsSuccess, fetchReportsFailure, fetchReportsInvalidate } from '../actions';

const { put } = sagaEffects;

function* fetchReports(action, cancelToken) {
    try {
        const requestConfig = {
            cancelToken,
            params: {
                limit: Number.MAX_SAFE_INTEGER,
            },
        };

        const { data } = yield* authApi.get(config.api.reports, requestConfig);
        const { result, entities } = Normalizers.reports(data);

        yield put(
            fetchReportsSuccess({
                ids: result,
                byId: entities.reports,
            }),
        );
    } catch (e) {
        Log.error(e);

        yield put(
            fetchReportsFailure({
                id: 'error.reports.api.get',
            }),
        );
    }
}

export default function* () {
    const actionTypes = {
        REQUEST: types.FETCH_REPORTS_REQUEST,
        cancelTask: fetchReportsInvalidate,
    };

    yield takeLatestRequest(actionTypes, fetchReports);
}
