import { ReduxUtils } from '../dependencies';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: 'tags',
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        typePrefix: 'FETCH_TAGS_',
    }),
    ...createTypes({
        typePrefix: 'CREATE_TAG_',
    }),
    ...createTypes({
        typePrefix: 'UPDATE_TAG_',
    }),
    ...createTypes({
        typePrefix: 'DELETE_TAG_',
    }),
    ...createTypes({
        types: ['ADD_TAGS'],
    }),
    ...createTypes({
        types: ['SET_FILTER'],
    }),
});
