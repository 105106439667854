import types from './types';

export const deleteServiceKeyRequest = id => ({
    type: types.DELETE_SERVICE_KEY_REQUEST,
    meta: { id },
});

export const deleteServiceKeySuccess = id => ({
    type: types.DELETE_SERVICE_KEY_SUCCESS,
    meta: { id },
});

export const deleteServiceKeyFailure = (id, error) => ({
    type: types.DELETE_SERVICE_KEY_FAILURE,
    meta: { id },
    error,
});

export const deleteServiceKeyInvalidate = id => ({
    type: types.DELETE_SERVICE_KEY_CANCEL,
    meta: { id },
});
