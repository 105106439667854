export const button = ({ theme: { colors } }) => ({
    position: 'absolute',
    top: 29,
    right: 0,
    marginRight: 32,
    color: colors.primary,
    textDecoration: 'underline',
    fontSize: '16px',
    letterSpacing: '0.2px',
    lineHeight: 1.56,
    paddingRight: 8,
});
