const config = {
    api: {
        base: 'https://expenses-api.ack.ee/api',
    },
    googleAnalytics: {
        trackingId: 'UA-160812951-1',
    },
    sentry: {
        dsn: 'https://22a65f3e0efb40fa822ba3b24b1c6124@o481746.ingest.sentry.io/6128732',
    },
};

export default config;
