import { React, PropTypes, FelaRenderer } from '../../dependencies';

// TODO: consider:
// const renderer = useContext(FelaContext);
// const animationName = useMemo(() => renderer.renderKeyframe(keyframe), [keyframe]);
function FelaKeyframe({ children, keyframe }) {
    return (
        <FelaRenderer>
            {renderer => {
                const animationName = renderer.renderKeyframe(keyframe);
                return children(animationName);
            }}
        </FelaRenderer>
    );
}

FelaKeyframe.propTypes = {
    children: PropTypes.func.isRequired,
    keyframe: PropTypes.func.isRequired,
};

export default FelaKeyframe;
