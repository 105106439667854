export default ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0px, 200px))',
    gridColumnGap: '1rem',

    padding: '17px 30px',

    borderBottom: `1px solid ${theme.colors.blueGrey1}`,

    fontSize: '14px',
    lineHeight: '19px',

    color: theme.colors.blueGrey4,
});
