import { ReduxUtils } from '../../dependencies';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: 'users',
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        typePrefix: 'UPDATE_USER_',
    }),
    ...createTypes({
        typePrefix: 'FETCH_USER_',
    }),
    ...createTypes({
        types: ['UPDATE_AUTH_USER_PASSWORD'],
    }),
});
