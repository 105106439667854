import { strictObjectAccess } from '@ackee/redux-utils';

export const entityKeys = strictObjectAccess({
    EXPENSES: 'expenses',
    EXPENSE: 'expense',
    EXPENSE_TRIGGERS: 'expenseTriggers',
    EXPENSE_TRIGGER: 'expenseTrigger',
    NEXT_EXPENSE_NUMBER: 'nextExpenseNumber',

    TAGS: 'tags',
    TAG: 'tag',

    SUPPLIERS: 'suppliers',
    SUPPLIER: 'supplier',
    ARES: 'ares',

    REPORT: 'report',
    REPORTS: 'reports',
    TEMPORARY_REPORT: 'temporaryReport',
    NEW_REPORT: 'newReport',
    DASHBOARD: 'dashboard',

    EXPORTS: 'exports',
    EXPORT: 'export',

    USER: 'user',
    USERS: 'users',

    COMPANY: 'company',
    COMPANIES: 'companies',

    MEMBERSHIP: 'membership',
    MEMBERSHIPS: 'memberships',

    SERVICE_KEYS: 'serviceKeys',
    SERVICE_KEY: 'serviceKey',
});
