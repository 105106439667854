import { React, PropTypes, UI, useSelector, AuthSelectors, FormattedMessage } from '../../dependencies';

import ChangePasswordModal from '../ChangePasswordModal';

import OpenModalButton from './OpenModalButton';

const { Card, Label, Space } = UI;

const UserProfile = ({ styles }) => {
    const user = useSelector(AuthSelectors.authUserSelector);

    if (!user) {
        return null;
    }

    return (
        <>
            <Card>
                <h3 className={styles.fullName}>{[user.firstName, user.lastName].join(' ')}</h3>

                <Label>
                    <FormattedMessage id="login.email" />
                </Label>
                <div className={styles.text}>{user.email}</div>

                <Space bottom="1.5rem" />

                <Label>
                    <FormattedMessage id="login.password" />
                </Label>
                <div className={styles.textRow}>
                    <div className={styles.text}>************</div>
                    <OpenModalButton />
                </div>
            </Card>
            <ChangePasswordModal />
        </>
    );
};

UserProfile.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default UserProfile;
