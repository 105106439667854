import { React, PropTypes, FelaComponent, FormattedMessage, ExpensesConsts } from '../../../dependencies';

import * as styles from './Item.styles';

import Cell from './Cell';
import ButtonEdit from './ButtonEdit';

const { EXPENSES_RECURRING_ITEM } = ExpensesConsts.routePaths;

const expensesRecurringItem = id => EXPENSES_RECURRING_ITEM.replace(':expenseTriggerId', id);

function Item({ expenseTrigger: { id, name, period, startAt, endAt }, push }) {
    const onClickHandler = React.useCallback(
        e => {
            e.stopPropagation();
            push(expensesRecurringItem(id));
        },
        [push, id],
    );

    const onKeyDownHandler = React.useCallback(
        e => {
            const ENTER_KEY = 13;

            if (e.keyCode === ENTER_KEY) {
                push(expensesRecurringItem(id));
            }
        },
        [push, id],
    );

    return (
        <FelaComponent style={styles.listItem}>
            {({ className }) => (
                <div
                    tabIndex={0}
                    role="button"
                    onClick={onClickHandler}
                    onKeyDown={onKeyDownHandler}
                    className={className}
                >
                    <Cell>{name}</Cell>

                    <Cell>
                        <FormattedMessage id={`expense.form.period.option.${period}`} />
                    </Cell>

                    <Cell>{startAt}</Cell>

                    <Cell>{endAt}</Cell>

                    <ButtonEdit {...{ id, push }} />
                </div>
            )}
        </FelaComponent>
    );
}

Item.expenseTriggerPropType = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    period: PropTypes.number.isRequired,
    startAt: PropTypes.string.isRequired,
    endAt: PropTypes.string.isRequired,
};

Item.propTypes = {
    expenseTrigger: PropTypes.shape(Item.expenseTriggerPropType).isRequired,
    push: PropTypes.func.isRequired,
};

export default Item;
