import {
    React,
    PropTypes,
    AntModal,
    childrenPropType,
    errorPropType,
    ErrorMessage,
    FormattedMessage,
    noop,
} from '../../dependencies';

import ModalFooter from '../ModalFooter';

const Modal = ({
    styles,
    rules,
    theme,

    cancelText,
    onCancel,
    cancelButtonProps,
    okText,
    onOk,
    okButtonProps,
    confirmLoading,
    wrapClassName,
    children,
    error,
    ...props
}) => (
    <AntModal
        {...{
            footer: null,
            title: null,
            ...props,
            onCancel,
        }}
        wrapClassName={`Modal ${styles.modal} ${wrapClassName}`}
    >
        {children}
        {okText && (
            <ModalFooter
                {...{
                    cancelText,
                    onCancel,
                    cancelButtonProps,
                    okText,
                    onOk,
                    okButtonProps,
                    confirmLoading,
                    error,
                }}
            />
        )}
        {error && (
            <ErrorMessage>
                <FormattedMessage {...error} />
            </ErrorMessage>
        )}
    </AntModal>
);

Modal.propTypes = {
    styles: PropTypes.shape().isRequired,
    cancelText: PropTypes.node,
    onCancel: PropTypes.func,
    cancelButtonProps: PropTypes.shape(),
    okText: PropTypes.node,
    onOk: PropTypes.func,
    okButtonProps: PropTypes.shape(),
    confirmLoading: PropTypes.bool,
    wrapClassName: PropTypes.string,
    children: childrenPropType.isRequired,
    error: errorPropType,
    centered: PropTypes.bool,
    maskClosable: PropTypes.bool,
    destroyOnClose: PropTypes.bool,
    closable: PropTypes.bool,
};

Modal.defaultProps = {
    cancelText: <FormattedMessage id="modals.delete.cancel" />,
    onCancel: noop,
    cancelButtonProps: {},
    okText: null,
    onOk: noop,
    okButtonProps: {},
    confirmLoading: false,
    wrapClassName: '',
    error: null,
    centered: true,
    maskClosable: true,
    destroyOnClose: true,
    closable: false,
};

export default Modal;
