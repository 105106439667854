import { React } from '../../dependencies';

import { CreateMembershipModal, CreateMembershipButton } from '../../modules/membership-create';
import { Memberships } from '../../modules/memberships-list';
import { DeleteMembershipModal } from '../../modules/membership-delete';

import Header from './Header';

const MembershipsManagement = () => (
    <>
        <Header>
            <CreateMembershipButton />
        </Header>

        <Memberships />

        <CreateMembershipModal />
        <DeleteMembershipModal />
    </>
);

export default MembershipsManagement;
