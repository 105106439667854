import {
    React,
    PropTypes,
    FormattedMessage,
    UI,
    goBack,
    useDispatch,
    useSelector,
    isSubmittingSelector,
    isValidSelector,
} from '../../dependencies';

const { ButtonPrimary, ButtonDefault } = UI;

const ExportFooter = ({ styles, formId, submitButtonMessageId }) => {
    const submitting = useSelector(isSubmittingSelector(formId));
    const valid = useSelector(isValidSelector(formId));
    const dispatch = useDispatch();

    return (
        <div className={styles.footer}>
            <ButtonDefault onClick={() => dispatch(goBack())} disabled={submitting}>
                <FormattedMessage id="export.recurring.cancel" />
            </ButtonDefault>
            <ButtonPrimary type="primary" htmlType="submit" disabled={!valid} loading={submitting}>
                <FormattedMessage id={submitButtonMessageId} />
            </ButtonPrimary>
        </div>
    );
};

ExportFooter.propTypes = {
    styles: PropTypes.shape().isRequired,
    formId: PropTypes.string.isRequired,
    submitButtonMessageId: PropTypes.string.isRequired,
};

export default ExportFooter;
