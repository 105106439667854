export const formatPayload = (tagsById = {}) => {
    const byId = {};
    const byName = {};
    const names = [];
    const ids = [];

    for (const [id, tag] of Object.entries(tagsById)) {
        const { name } = tag;

        byId[id] = name;
        byName[name] = tag;
        names.push(name);
        ids.push(id);
    }

    return {
        byId,
        byName,
        names,
        ids,
    };
};
