import { React, PropTypes, FelaComponent, ButtonLink, FormattedMessage } from '../../dependencies';

import * as styles from './ButtonReset.styles';

function ButtonReset({ onClick }) {
    return (
        <FelaComponent style={styles.buttonWrapper}>
            <ButtonLink onClick={onClick}>
                <FormattedMessage id="dateRangePicker.button.cancelSelection" />
            </ButtonLink>
        </FelaComponent>
    );
}

ButtonReset.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default ButtonReset;
