import { React, PropTypes, FelaComponent, TooltipWrapper, ChartTooltip, FormattedNumber } from '../../../dependencies';

import * as styles from './Label.styles';

function Label({ data, dataIndex, currency, ...rest }) {
    if (!data) {
        return null;
    }

    const { value, withoutVat, withVat, tagNames, combinedSegment, opacity } = data[dataIndex];

    return (
        <FelaComponent style={styles.label} opacity={opacity}>
            {label => (
                <TooltipWrapper
                    title={<ChartTooltip {...{ currency, withVat, withoutVat, tagNames, combinedSegment }} />}
                >
                    <text className={label.className} {...rest}>
                        <FormattedNumber
                            {...{
                                value,
                                style: 'percent',
                            }}
                        />
                    </text>
                </TooltipWrapper>
            )}
        </FelaComponent>
    );
}

Label.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    dx: PropTypes.number,
    dy: PropTypes.number,
    textAnchor: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape()),
    dataIndex: PropTypes.number,
    currency: PropTypes.string,
};

Label.defaultProps = {
    x: null,
    y: null,
    dx: null,
    dy: null,
    textAnchor: null,
    data: null,
    dataIndex: null,
    currency: null,
};

export default Label;
