import { sagaEffects, Log, Consts } from '../../dependencies';

import pageView from './pageView';
import events from './events';
import init from './init';

const { all, spawn } = sagaEffects;

function* sagas() {
    try {
        yield all([pageView(), events(), init()]);
    } catch (e) {
        Log.error(e);
    }
}

export default function* () {
    if (!Consts.isLocalhost) {
        yield spawn(sagas);
    }
}
