import types from '../actionTypes';

export const updateTagRequest = id => ({
    type: types.UPDATE_TAG_REQUEST,
    meta: { id },
});

export const updateTagSuccess = (id, name, payload) => ({
    type: types.UPDATE_TAG_SUCCESS,
    meta: { id, name },
    payload,
});

export const updateTagFailure = (id, error) => ({
    type: types.UPDATE_TAG_FAILURE,
    meta: { id },
    error,
});

export const updateTagInvalidate = id => ({
    type: types.UPDATE_TAG_CANCEL,
    meta: { id },
});
