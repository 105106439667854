import { React, PropTypes, Divider, connectFela } from '../../dependencies';

import { nonSelectedTags } from '../../services/selectors';

import DateRangeField from './DateRangeField';
import TagsField from './TagsField';
import HasFilesField from './HasFilesField';
import SupplierField from '../../containers/SupplierField';

import * as Styles from './Filter.styles';

const Filter = ({ submitting, form, optionsSelector, filesField, paymentMethodField, styles }) => (
    <div className={styles.card}>
        <div className={styles.grid}>
            <DateRangeField {...{ disabled: submitting }} />
            <SupplierField {...{ disabled: submitting }} />
        </div>
        <Divider />
        <div className={styles.tagsGrid}>
            <TagsField {...{ form, disabled: submitting, optionsSelector, paymentMethodField }} />
        </div>
        {filesField && (
            <>
                <Divider />
                <div className={styles.grid}>
                    <HasFilesField {...{ disabled: submitting }} />
                </div>
            </>
        )}
    </div>
);

Filter.propTypes = {
    submitting: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    optionsSelector: PropTypes.func,
    filesField: PropTypes.bool,
    paymentMethodField: PropTypes.bool,
    styles: PropTypes.shape().isRequired,
};

Filter.defaultProps = {
    optionsSelector: nonSelectedTags,
    filesField: false,
    paymentMethodField: false,
};

export default connectFela(Styles)(Filter);
