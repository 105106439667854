import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { noop } from 'lodash';

import Chip, { types as chipTypes } from './Chip';

import * as styles from './Chips.styles';

function Chips({ items, removable, limit, onClick, disabled, size, color, dotted }) {
    return (
        <FelaComponent style={styles.wrap}>
            {items.slice(0, limit).map(({ value, label }, index, arr) => (
                <Chip
                    {...{
                        id: value,
                        type: removable ? chipTypes.REMOVABLE : chipTypes.DEFAULT,
                        key: value,
                        onClick,
                        isLast: index >= arr.length - 1 && items.length <= limit,
                        disabled,
                        size,
                        color,
                        dot: dotted,
                    }}
                >
                    {label}
                </Chip>
            ))}
            {items.length > limit && (
                <FelaComponent
                    {...{
                        style: styles.counter,
                        isLast: true,
                        size,
                    }}
                >
                    {`+${items.length - limit}`}
                </FelaComponent>
            )}
        </FelaComponent>
    );
}

Chips.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
    removable: PropTypes.bool,
    disabled: PropTypes.bool,
    limit: PropTypes.number,
    onClick: PropTypes.func,
    size: PropTypes.string,
    color: PropTypes.string,
    dotted: PropTypes.bool,
};

Chips.defaultProps = {
    removable: false,
    limit: Number.MAX_SAFE_INTEGER,
    onClick: noop,
    disabled: false,
    size: undefined,
    color: undefined,
    dotted: true,
};

Chips.sizes = Chip.sizes;

export default Chips;
