import { React, PropTypes, FelaComponent, FormattedMessage } from '../../dependencies';

import * as styles from './Title.styles';

function Title({ messageId, values, style }) {
    return (
        <FelaComponent style={styles.title} as="h2" {...style}>
            <FormattedMessage
                {...{
                    id: messageId,
                    values,
                }}
            />
        </FelaComponent>
    );
}

Title.propTypes = {
    messageId: PropTypes.string.isRequired,
    values: PropTypes.shape(),
    style: PropTypes.shape({
        marginBottom: PropTypes.number,
    }),
};

Title.defaultProps = {
    values: {},
    style: {},
};

export default Title;
