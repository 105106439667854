import { difference, ActiveCompanyActions, Utils, Actions, union } from '../../../dependencies';
import { types } from '../../actions';

const initialState = {
    byId: {},
    ids: [],
};

function companiesReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.types.SET_COMPANIES:
            return {
                ...state,
                ids: union(state.ids, action.payload.ids),
                byId: {
                    ...state.byId,
                    ...action.payload.byId,
                },
            };

        case types.CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: action.payload,
                },
                ids: [...state.ids, String(action.payload.id)],
            };

        case types.UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.id]: action.payload,
                },
            };

        case types.DELETE_COMPANY_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.id]: undefined,
                },
                ids: difference(state.ids, [String(action.meta.id)]),
            };

        default:
            return state;
    }
}

export default Utils.reducerWithIgnoredActions(companiesReducer, [
    ActiveCompanyActions.types.SET_ACTIVE_COMPANY_RESOLVE,
]);
