import { React, PropTypes, DatePickerField, injectIntl, config, InputWrapper, Utils } from '../../../dependencies';

function DueOn({ intl, disabled }) {
    return (
        <InputWrapper>
            <DatePickerField
                {...{
                    name: 'dueOn',
                    label: intl.formatMessage({
                        id: 'expense.form.dueOn.label',
                    }),
                    displayFormat: config.date,
                    disabled,
                    parse: Utils.parseMomentDateInput,
                }}
            />
        </InputWrapper>
    );
}

DueOn.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default injectIntl(DueOn);
