import { sagaEffects, Petrus, Storage } from '../../../dependencies';

import { setDataAnalysisPersmission } from '../../actions';
import { DATA_ANALYSIS_PERSMISSION_KEY } from '../../../config';

const { put, take } = sagaEffects;

export default function* () {
    yield take(Petrus.AUTH_SESSION_START);

    const dataAnalysis = yield Storage.get(DATA_ANALYSIS_PERSMISSION_KEY);

    if (dataAnalysis === null || dataAnalysis === undefined) {
        yield put(Petrus.logoutRequest());
    } else {
        yield put(setDataAnalysisPersmission(dataAnalysis));
    }
}
