import { React, PropTypes, DatePickerField, injectIntl, config, InputWrapper } from '../../../dependencies';

import * as styles from './EndAt.styles';

function EndAt({ intl: { formatMessage }, disabled }) {
    return (
        <InputWrapper style={styles.endAt}>
            <DatePickerField
                {...{
                    name: 'endAt',
                    label: formatMessage({
                        id: 'expense.form.endAt.label',
                    }),
                    placeholder: formatMessage({
                        id: 'expense.form.endAt.undefined',
                    }),
                    displayFormat: config.date,
                    disabled,
                    allowClear: true,
                }}
            />
        </InputWrapper>
    );
}

EndAt.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default injectIntl(EndAt);
