export const label = ({ theme: { colors } }) => ({
    fontSize: '16px',
    lineHeight: 1.31,
    letterSpacing: '0.3px',
    color: colors.text,
});

export const nav = () => ({
    display: 'grid',
    gridColumnGap: 12,
    gridTemplateColumns: '1fr 1fr',
});
