import { ExpensesUtils, Utils, config, resolveRelativePeriod, resolveRelativeRange } from '../dependencies';

const rangeDefaults = config.filters.defaultInitials.exports;
const defaults = config.filters.defaultInitials;

// decoded url params -> form values
export function transformUrlParamsToFormValues(urlParams) {
    const { markAsSynced } = urlParams;

    return {
        ...ExpensesUtils.transformUrlParamsToFormValues(urlParams),
        range: resolveRelativeRange(urlParams) || resolveRelativeRange(rangeDefaults),
        markAsSynced: markAsSynced === null ? defaults.markAsSynced : markAsSynced,
    };
}

// form values -> url params
export const { transformFormValuesToUrlParams } = ExpensesUtils;

export const transformUrlParamsToApiRequest = urlParams => {
    const { from, to } = resolveRelativePeriod(resolveRelativeRange(urlParams) || resolveRelativeRange(rangeDefaults));

    return {
        filters: {
            from: Utils.getISODateWithoutTimezone(from),
            to: Utils.getISODateWithoutTimezone(to),
            includeTags: Utils.parseStringIds(urlParams.includeTags),
            excludeTags: Utils.parseStringIds(urlParams.excludeTags),
            supplierId: Utils.parseStringIds([urlParams.supplierId]),
            currency: urlParams.currency,
            paymentMethod: urlParams.paymentMethod,
        },
        markAsSynced: urlParams.markAsSynced === null ? defaults.markAsSynced : urlParams.markAsSynced,
    };
};
