import { types } from 'modules/reports/services/actions';

const initialState = {
    byId: {},
    ids: [],
};

export default function reportTagsReducer(state = initialState, action) {
    switch (action.type) {
        case types.INITIALIZE_TAGS_VISIBILITY: {
            const byId = {};
            const ids = [];

            for (const tag of action.payload) {
                byId[tag.id] = tag;
                ids.push(tag.id);
            }

            return {
                byId,
                ids,
            };
        }

        case types.SET_TAG_VISIBILITY: {
            const { id, hidden } = action.payload;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [id]: {
                        ...state.byId[id],
                        hidden,
                    },
                },
            };
        }

        default:
            return state;
    }
}
