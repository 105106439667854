import { compose, connect, arrayRemove } from '../../dependencies';

import Files from '../../components/Fields/Attachment/Files';
import { filesSelector } from '../../services/selectors';

const mapStateToProps = (state, { formId }) => {
    return {
        files: filesSelector(state, formId),
    };
};

const mapDispatchToProps = (dispatch, { formId }) => ({
    removeFile({ index, url, localUrl }) {
        if (localUrl) {
            window.URL.revokeObjectURL(url);
        }
        dispatch(arrayRemove(formId, 'files', index));
    },
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(Files);
