import { React, PropTypes, DatePickerField, injectIntl, config, InputWrapper, Utils } from '../../../dependencies';

import * as styles from './StartAt.styles';

function StartAt({ intl, disabled }) {
    return (
        <InputWrapper style={styles.startAt}>
            <DatePickerField
                {...{
                    name: 'startAt',
                    label: intl.formatMessage({
                        id: 'expense.form.startAt.label',
                    }),
                    displayFormat: config.date,
                    disabled,
                    allowClear: false,
                    parse: Utils.parseMomentDateInput,
                }}
            />
        </InputWrapper>
    );
}

StartAt.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default injectIntl(StartAt);
