import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const actionTypes = {
    REQUEST: types.FETCH_REPORTS_REQUEST,
    SUCCESS: types.FETCH_REPORTS_SUCCESS,
    FAILURE: types.FETCH_REPORTS_FAILURE,
    CANCEL: types.FETCH_REPORTS_CANCEL,
    RESET: types.FETCH_REPORTS_RESET,
};

export default ReduxUtils.basicApiReducer({
    actionTypes,
});
