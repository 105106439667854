import { React, PropTypes, FormattedMessage, Icons, connectFela } from '../../dependencies';

import * as Styles from './AlertSuccess.styles';

function AlertSuccess({ timestamp, styles }) {
    if (!timestamp) {
        return null;
    }

    return (
        <div className={styles.card}>
            <Icons.Info className={styles.icon} />

            <span className={styles.text}>
                <FormattedMessage id="expense.exported" values={timestamp} />
            </span>
        </div>
    );
}

AlertSuccess.propTypes = {
    timestamp: PropTypes.shape({
        date: PropTypes.string.isRequired,
        time: PropTypes.string.isRequired,
    }),
    styles: PropTypes.shape({}).isRequired,
};

AlertSuccess.defaultProps = {
    timestamp: null,
};

export default connectFela(Styles)(AlertSuccess);
