import { React, PropTypes, FormattedMessage, Alert, UI, PasswordField } from '../../dependencies';

import { Fields } from '../../constants';

const { ButtonPrimary, Space, ButtonDefault } = UI;

const ChangePasswordForm = ({ styles, onCancel, handleSubmit, submitting, error, form }) => {
    return (
        <form onSubmit={handleSubmit}>
            <h2 className={styles.title}>
                <FormattedMessage id="settings.changepassword.title" />
            </h2>

            {error && (
                <>
                    <Alert showIcon type="error" message={<FormattedMessage {...error} />} />
                    <Space bottom="1.5rem" />
                </>
            )}

            <PasswordField
                name={Fields.OLD_PASSWORD}
                label={<FormattedMessage id={`settings.changepassword.${Fields.OLD_PASSWORD}.label`} />}
                autoComplete="current-password"
                formId={form}
            />

            <Space bottom="1.5rem" />

            <PasswordField
                name={Fields.NEW_PASSWORD}
                label={<FormattedMessage id={`settings.changepassword.${Fields.NEW_PASSWORD}.label`} />}
                autoComplete="new-password"
                formId={form}
            />

            <div className={styles.footer}>
                <ButtonDefault onClick={onCancel} disabled={submitting}>
                    <FormattedMessage id="settings.changePassword.cancel" />
                </ButtonDefault>

                <ButtonPrimary htmlType="submit" loading={submitting}>
                    <FormattedMessage id="settings.changePassword.submit" />
                </ButtonPrimary>
            </div>
        </form>
    );
};

ChangePasswordForm.propTypes = {
    styles: PropTypes.shape().isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    error: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    onCancel: PropTypes.func.isRequired,
};

ChangePasswordForm.defaultProps = {
    error: null,
};

export default ChangePasswordForm;
