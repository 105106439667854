import { ReactGA, config } from '../../dependencies';

import { initOptions } from '../../config';

function init() {
    ReactGA.initialize(config.googleAnalytics.trackingId, initOptions);
}

export default function() {
    if (window.requestIdleCallback) {
        window.requestIdleCallback(init);
    } else {
        window.requestAnimationFrame(init);
    }
}
