import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from './types';

export const updateExpenseTriggerRequest = (id, payload = {}) => ({
    type: types.UPDATE_EXPENSE_TRIGGER_REQUEST,
    meta: { id },
    payload,
});

export const updateExpenseTriggerSuccess = (id, payload) => ({
    type: types.UPDATE_EXPENSE_TRIGGER_SUCCESS,
    meta: { id },
    payload: formatEntity({
        entity: payload,
        template: entitiesTemplates.expenseTrigger,
    }),
});

export const updateExpenseTriggerFailure = (id, error) => ({
    type: types.UPDATE_EXPENSE_TRIGGER_FAILURE,
    meta: { id },
    error,
});

export const updateExpenseTriggerInvalidate = id => ({
    type: types.UPDATE_EXPENSE_TRIGGER_CANCEL,
    meta: { id },
});
