import { React, PropTypes, childrenPropType, FormattedMessage } from '../../../dependencies';

const Header = ({ styles, children }) => (
    <div className={styles.container}>
        <h3 className={styles.title}>
            <FormattedMessage id="memberships.title" />
        </h3>
        {children}
    </div>
);

Header.propTypes = {
    styles: PropTypes.shape().isRequired,
    children: childrenPropType.isRequired,
};

export default Header;
