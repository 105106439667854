import { React, PropTypes, RadioField, injectIntl, Consts, InputWrapper } from '../../../dependencies';

function DocumentType({ intl, disabled }) {
    const options = DocumentType.translateOptions(intl);

    return (
        <InputWrapper labelMarginLeft={0}>
            <RadioField
                {...{
                    name: 'documentType',
                    label: intl.formatMessage({
                        id: 'expense.form.documentType.label',
                    }),
                    options,
                    disabled,
                }}
            />
        </InputWrapper>
    );
}

DocumentType.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

DocumentType.translateOptions = ({ formatMessage }) => {
    const prefix = 'expense.form.documentType.option';
    const ids = Object.values(Consts.documentType);

    return ids.map(id => ({
        value: id,
        label: formatMessage({ id: `${prefix}.${id}` }),
    }));
};

export default injectIntl(DocumentType);
