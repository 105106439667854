import { React, createStaticRanges, FormattedMessage, Consts } from '../../dependencies';

import { StaticDateRangesCreators } from '../../config';

const ranges = Object.values(Consts.DateRanges).map(rangeId => {
    return {
        label: <FormattedMessage id={`dateRangePicker.staticRanges.${rangeId}`} />,
        range: () => {
            const { from, to } = StaticDateRangesCreators[rangeId]();
            return {
                startDate: from,
                endDate: to,
            };
        },
    };
});

export default createStaticRanges(ranges);
