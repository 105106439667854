import { ReduxUtils } from 'modules/expenses/dependencies';
import { types } from 'modules/expenses/services/actions';

const actionTypes = {
    REQUEST: types.CREATE_EXPENSE_REQUEST,
    SUCCESS: types.CREATE_EXPENSE_SUCCESS,
    FAILURE: types.CREATE_EXPENSE_FAILURE,
    CANCEL: types.CREATE_EXPENSE_CANCEL,
    RESET: types.CREATE_EXPENSE_RESET,
};

export default ReduxUtils.basicApiReducer({
    actionTypes,
});
