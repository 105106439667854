import { union } from '../../../dependencies';
import types from '../../actionTypes';

const initialState = {
    byRegistrationNumber: {},
    registrationNumbers: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_ARES_DATA_SUCCESS: {
            const { registrationNumber, data } = action.payload;

            return {
                ...state,
                byRegistrationNumber: {
                    ...state.byRegistrationNumber,
                    [registrationNumber]: data,
                },
                registrationNumbers: union(state.registrationNumbers, [registrationNumber]),
            };
        }

        default:
            return state;
    }
}
