import { React, FelaComponent } from '../../dependencies';

import SupplierList from '../../containers/SupplierList';
import SuppliersHeader from '../SuppliersHeader';

import style from './Suppliers.style';

const Suppliers = () => (
    <FelaComponent style={style}>
        <SuppliersHeader />
        <SupplierList />
    </FelaComponent>
);

export default Suppliers;
