import { createSelector, AuthUserSelectors } from '../../../dependencies';

import { Roles } from '../../../constants';
import { membershipsSelector } from './general';
import { isOwnerSelector } from './roles';

export const membershipsListSelector = createSelector(
    [membershipsSelector, AuthUserSelectors.authUserSelector, isOwnerSelector],
    (memberships, authUser, authUserIsOwner) => {
        const ownersWithoutAuthUser = memberships.filter(
            m => m.role === Roles.OWNER && String(m.userId) !== authUser.id,
        );

        return memberships.map(m => ({
            ...m,
            deletable: String(m.userId) === authUser.id && ownersWithoutAuthUser.length === 0 ? false : authUserIsOwner,
            roleUpdatable: String(m.userId) === authUser.id ? false : authUserIsOwner,
        }));
    },
);
