export const button = () => ({
    display: 'block',
    width: '100%',
});

export const content = ({ theme: { colors } }) => ({
    display: 'grid',
    gridTemplateColumns: '1.5rem 1fr',
    gridColumnGap: '0.25rem',
    alignItems: 'center',
    color: colors.primary,
    padding: '8px',
    onHover: {
        color: colors.primaryHover,
    },
});

export const text = () => ({
    color: 'currentColor',
    lineHeight: 1.56,
    letterSpacing: '0.15px',
    fontSize: '1rem',
    textAlign: 'left',
});

export const icon = () => ({
    height: '0.75rem',
    width: '0.75rem',
    fill: 'currentColor',
    margin: 'auto',
});
