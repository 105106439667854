import { sagaEffects } from '../../dependencies';

import fetchTags from './fetchTags';
import createTag from './createTag';
import updateTag from './updateTag';
import deleteTag from './deleteTag';

const { all } = sagaEffects;

export default function*() {
    yield all([fetchTags(), createTag(), updateTag(), deleteTag()]);
}
