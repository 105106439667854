import { createSelector } from '../../dependencies';

const selectUser = (state, id) => state.entities.users[id];
const selectUserPlaceholder = state => state.entities.users.placeholder;

export const userSelector = createSelector(
    [selectUser, selectUserPlaceholder],
    (user, placeholder) => user || placeholder,
);

export const userFullNameSelector = createSelector(
    selectUser,
    user => {
        return user ? `${user.firstName} ${user.lastName}` : '';
    },
);
