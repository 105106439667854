import { React, useActiveCompanyId, ActiveCompanyActions, useDispatch } from '../dependencies';

import useCompanies from './useCompanies';

export default function useSelectCompany(hardReload) {
    const companies = useCompanies();
    const options = React.useMemo(() => {
        return companies.map(({ id, name }) => ({
            label: name,
            value: String(id),
        }));
    }, [companies]);

    const dispatch = useDispatch();
    const activeCompanyId = useActiveCompanyId();

    const [value, setValue] = React.useState(null);

    const setOption = (companyId, setActiveCompany = true) => {
        setValue(companyId);

        if (setActiveCompany && companyId !== activeCompanyId) {
            dispatch(ActiveCompanyActions.setActiveCompanyRequest(companyId, { hardReload }));
        }
    };

    React.useEffect(() => {
        setOption(activeCompanyId, false);
        // eslint-disable-next-line
    }, [activeCompanyId]);

    return { value, onChange: setOption, options };
}
