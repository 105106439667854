import { config, authApi, takeLatestRequest, Log, sagaEffects, ErrorsUtils } from '../../dependencies';

import {
    createExpenseTriggerSuccess,
    createExpenseTriggerFailure,
    createExpenseTriggerInvalidate,
    types,
} from '../actions';
import { transformPayload } from './utils';

const { put } = sagaEffects;

function* createExpenseTrigger(action, cancelToken) {
    const { expenseId } = action.meta;

    try {
        const { data } = yield* authApi.post(config.api.expenseTriggers, transformPayload(action.payload), {
            cancelToken,
        });

        yield put(createExpenseTriggerSuccess(expenseId, data));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.expenseTrigger.api.create',
            },
        });

        yield put(createExpenseTriggerFailure(expenseId, errorMessage));
    }
}

export default function* createExpenseTriggerWatcher() {
    const actionTypes = {
        REQUEST: types.CREATE_EXPENSE_TRIGGER_REQUEST,
        cancelTask: createExpenseTriggerInvalidate,
        requestIdSelector: action => action.meta.expenseId,
    };

    yield takeLatestRequest(actionTypes, createExpenseTrigger);
}
