import { ReduxUtils, Consts } from '../../dependencies';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: Consts.entityKeys.EXPENSE_TRIGGERS,
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        typePrefix: 'FETCH_EXPENSE_TRIGGERS_',
    }),
    ...createTypes({
        typePrefix: 'FETCH_EXPENSE_TRIGGER_',
    }),
    ...createTypes({
        typePrefix: 'CREATE_EXPENSE_TRIGGER_',
    }),
    ...createTypes({
        typePrefix: 'UPDATE_EXPENSE_TRIGGER_',
    }),
    ...createTypes({
        typePrefix: 'DELETE_EXPENSE_TRIGGER_',
    }),
});
