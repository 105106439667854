import {
    React,
    PropTypes,
    SelectField,
    injectIntl,
    useIntl,
    UI,
    useSelector,
    isSubmittingSelector,
    ServiceKeysConsts,
} from '../../../dependencies';

const { InputWrapper } = UI;

function Service({ formId }) {
    const intl = useIntl();
    const submitting = useSelector(isSubmittingSelector(formId));

    return (
        <InputWrapper>
            <SelectField
                {...{
                    name: 'service',
                    label: intl.formatMessage({
                        id: 'export.recurring.form.service.label',
                    }),
                    options: [
                        {
                            label: intl.formatMessage({
                                id: 'export.recurring.form.service.fakturoid',
                            }),
                            value: ServiceKeysConsts.Service.FAKTUROID,
                        },
                        {
                            label: intl.formatMessage({
                                id: 'export.recurring.form.service.flexibee',
                            }),
                            value: ServiceKeysConsts.Service.FLEXIBEE,
                        },
                    ],
                    disabled: submitting,
                    defaultActiveFirstOption: false,
                    placeholder: null,
                }}
            />
        </InputWrapper>
    );
}

Service.propTypes = {
    formId: PropTypes.string.isRequired,
};

export default injectIntl(Service);
