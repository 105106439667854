import { sagaEffects } from '../../dependencies';

import updateUser from './updateUser';
import singles from './singles';

const { all } = sagaEffects;

export default function* () {
    yield all([updateUser(), singles()]);
}
