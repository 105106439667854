import { config } from '../dependencies';
import { StaticDateRangesCreators, RelativePeriods } from '../config';

export function resolveRelativePeriod({ from, to, relativePeriod }) {
    if (relativePeriod) {
        return StaticDateRangesCreators[relativePeriod]();
    }

    return {
        from,
        to,
    };
}

export function resolveIfFakeRelativePeriod(relativePeriod) {
    if (config.filters.fakeRelativePeriods.includes(relativePeriod)) {
        return {
            ...StaticDateRangesCreators[relativePeriod](),
            relativePeriod,
        };
    }

    return {
        from: null,
        to: null,
        relativePeriod,
    };
}

export function getRelativePeriod(range) {
    if (!range || !range.startDate || !range.endDate) {
        return null;
    }

    return RelativePeriods.get(`${range.startDate}${range.endDate}`) || null;
}

export function resolveRelativeRange({ from, to, relativePeriod }) {
    if (!from && !to && !relativePeriod) {
        return null;
    }

    if (relativePeriod) {
        return {
            from: null,
            to: null,
            relativePeriod,
        };
    }

    return {
        from,
        to,
        relativePeriod: null,
    };
}
