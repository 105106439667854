import { createSelector, difference, formValueSelector, Tags, union, Utils } from '../../dependencies';

import { Fields } from '../../constants';

const selectIncludeSelectedTags = (state, { formId }) => formValueSelector(formId)(state, Fields.INCLUDE_TAGS) || [];
const selectExcludeSelectedTags = (state, { formId }) => formValueSelector(formId)(state, Fields.EXCLUDE_TAGS) || [];

export const nonSelectedTagsFactory = tagIdsSelector =>
    createSelector(
        [selectIncludeSelectedTags, selectExcludeSelectedTags, tagIdsSelector, Tags.selectors.selectTags],
        (selectedIncludeTags, selectedExcludeTags, tagIds, { byId }) => {
            const nonSelectedTagIds = difference(tagIds, union(selectedIncludeTags.concat(selectedExcludeTags)));

            return Utils.createOptions(nonSelectedTagIds, byId);
        },
    );

export const nonSelectedTags = nonSelectedTagsFactory(Tags.selectors.tagIdsSelector);
