import {
    React,
    PropTypes,
    FelaComponent,
    FormattedMessage,
    OrderFilter,
    SelectOrderFilter,
    combineRules,
    config,
    connectFela,
} from '../../dependencies';

import * as Styles from './ListHeader.styles';

const { dateFieldOptions } = config.filters;
const options = Object.keys(dateFieldOptions).map(orderBy => ({
    orderBy,
    messageId: `expenses.list.header.${orderBy}`,
}));

function ListHeader({ hideFilters, staticDateField, styles }) {
    return (
        <div className={styles.row}>
            <div className={styles.col}>
                <FormattedMessage id="expenses.list.header.number" />
            </div>
            <div className={styles.col}>
                {staticDateField ? (
                    <OrderFilter
                        {...{
                            orderBy: staticDateField,
                            messageId: `expenses.list.header.${staticDateField}`,
                            hideSwitcher: hideFilters,
                        }}
                    />
                ) : (
                    <SelectOrderFilter
                        {...{
                            hideSwitcher: hideFilters,
                            options,
                        }}
                    />
                )}
            </div>
            <div className={styles.col}>
                <OrderFilter
                    {...{
                        hideSwitcher: hideFilters,
                        orderBy: 'supplierName',
                        messageId: 'expenses.list.header.supplier',
                    }}
                />
            </div>
            <div className={styles.col}>
                <FormattedMessage id="expenses.list.header.tags" />
            </div>
            <div className={styles.col}>
                <FormattedMessage id="expenses.list.header.hasAttachment" />
            </div>
            <FelaComponent style={combineRules(Styles.col, Styles.alignRight)} textAlign="right">
                <OrderFilter
                    {...{
                        hideSwitcher: hideFilters,
                        orderBy: 'totalPriceWithoutVatDefaultCurrency',
                        messageId: 'expenses.list.header.sumTotal',
                    }}
                />
            </FelaComponent>
        </div>
    );
}

ListHeader.propTypes = {
    hideFilters: PropTypes.bool,
    staticDateField: PropTypes.string,
    styles: PropTypes.shape().isRequired,
};

ListHeader.defaultProps = {
    hideFilters: false,
    staticDateField: null,
};

export default connectFela(Styles)(ListHeader);
