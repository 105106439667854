import { config, api } from 'modules/auth/dependencies';
import { transformCredentials } from '../utils';

import { normalizeAndSaveUser } from './helpers';

export default function* authenticate({ email, password }) {
    const response = yield* api.post(config.api.signIn, {
        email,
        password,
    });
    const { credentials, user } = response.data;
    const tokens = transformCredentials({ credentials, user });

    yield normalizeAndSaveUser(user);

    return {
        tokens,
        user: {},
    };
}
