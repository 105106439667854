import { union, difference, Utils } from '../../../../dependencies';
import { types } from '../../../actions';

const initialState = [];

export default function idsReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_EXPENSE_TRIGGERS_SUCCESS: {
            const { ids } = action.payload;
            const { offset } = action.meta;

            return Utils.insertIds(state, { ids, offset });
        }

        case types.CREATE_EXPENSE_TRIGGER_SUCCESS:
        case types.FETCH_EXPENSE_TRIGGER_SUCCESS: {
            const { id } = action.meta;

            return union(state, [id]);
        }

        case types.DELETE_EXPENSE_TRIGGER_SUCCESS: {
            const { id } = action.meta;

            return difference(state, [id]);
        }

        case types.FETCH_EXPENSE_TRIGGERS_RESET:
            return initialState;

        default:
            return state;
    }
}
