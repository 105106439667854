import { createSelector, Utils } from '../../dependencies';

import { selectSupplierListFilter } from './filter';

const { normalizeInput } = Utils;

export const selectSuppliers = state => state.entities.suppliers;

export const suppliersSelector = createSelector(
    selectSuppliers,
    ({ byId, ids }) => ids.map(id => byId[id]),
);

export const filteredSuppliersSelector = createSelector(
    [suppliersSelector, selectSupplierListFilter],
    (suppliers, filter) => {
        const normalizedFilter = normalizeInput(filter.trim());
        return suppliers.filter(({ name }) => normalizeInput(name).includes(normalizedFilter));
    },
);
