import { React, PropTypes, FieldArray, useState } from '../../../dependencies';

import ItemFields from '../../../containers/Items/ItemFields';
import QuantityDataField from './QuantityDataField';

function Items({ disabled, quantityDataField }) {
    const [quantityDataAreVisible, setQuantityDataVisibility] = useState(!quantityDataField);

    return (
        <>
            {quantityDataField && (
                <QuantityDataField
                    {...{
                        value: quantityDataAreVisible,
                        onChange() {
                            setQuantityDataVisibility(!quantityDataAreVisible);
                        },
                        disabled,
                    }}
                />
            )}

            <FieldArray
                {...{
                    name: 'items',
                    component: ItemFields,
                    quantityDataAreVisible,
                }}
            />
        </>
    );
}

Items.propTypes = {
    disabled: PropTypes.bool.isRequired,
    quantityDataField: PropTypes.bool,
};

Items.defaultProps = {
    quantityDataField: true,
};

export default Items;
