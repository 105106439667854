import { config, authApi, Log, sagaEffects, Normalizers, ErrorsUtils } from '../../dependencies';

import { fetchExportSuccess, fetchExportFailure, types } from '../actions';

const { put, takeEvery } = sagaEffects;

function* fetchExport(action) {
    const { id } = action.meta;
    try {
        const { data } = yield* authApi.get(config.api.export, {
            uriParams: {
                id,
            },
        });

        const { entities } = Normalizers.recurringExport(data);

        yield put(fetchExportSuccess(id, entities.exports[id]));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.export.api.get',
            },
        });

        yield put(fetchExportFailure(id, errorMessage));
    }
}

export default function* () {
    yield takeEvery(types.FETCH_EXPORT_REQUEST, fetchExport);
}
