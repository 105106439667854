import { React, FelaComponent, PropTypes, ButtonPrimary, FormattedMessage } from '../../dependencies';

import style from './CreateTagButton.style';

const CreateTagButton = ({ openModal }) => (
    <FelaComponent style={style}>
        <ButtonPrimary onClick={openModal}>
            <FormattedMessage id="tags.list.add.button" />
        </ButtonPrimary>
    </FelaComponent>
);

CreateTagButton.propTypes = {
    openModal: PropTypes.func.isRequired,
};

export default CreateTagButton;
