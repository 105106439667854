import { React, FelaComponent, PropTypes, Icons } from '../../../dependencies';

import style from './SubmitButton.style';

const SubmitButton = ({ disabled }) => (
    <FelaComponent as="span" style={style}>
        <button disabled={disabled} type="submit">
            <Icons.Ok width="12" height="12" />
        </button>
    </FelaComponent>
);

SubmitButton.propTypes = {
    disabled: PropTypes.bool,
};

SubmitButton.defaultProps = {
    disabled: false,
};

export default SubmitButton;
