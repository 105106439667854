import { connect, injectIntl, compose } from '../../dependencies';

import QuartersChart from '../../components/Charts/QuartersChart';

import { dashboardQuartersChartSelector } from '../../services/selectors';

const mapStateToProps = dashboardQuartersChartSelector;

export default compose(
    injectIntl,
    connect(mapStateToProps),
)(QuartersChart);
