import { React, PropTypes, TextField, UI, useIntl, isSubmittingSelector, useSelector } from '../../../dependencies';

const { InputWrapper } = UI;

const Name = ({ formId }) => {
    const intl = useIntl();
    const isSubmitting = useSelector(isSubmittingSelector(formId));

    return (
        <InputWrapper>
            <TextField
                name="name"
                label={intl.formatMessage({
                    id: 'export.recurring.form.name.label',
                })}
                placeholder={intl.formatMessage({
                    id: 'export.recurring.form.name.placeholder',
                })}
                disabled={isSubmitting}
                autoFocus
                autoCapitalize="words"
                autoComplete="disabled"
            />
        </InputWrapper>
    );
};
Name.propTypes = {
    formId: PropTypes.string.isRequired,
};

export default Name;
