import types from './types';

/**
 * @param {(string | number)?} companyId - force to use this companyId in the req.
 */
export const fetchMembershipsRequest = companyId => ({
    type: types.FETCH_MEMBERSHIPS_REQUEST,
    payload: { params: companyId ? { companyId } : undefined },
});

export const fetchMembershipsSuccess = (payload, meta) => ({
    type: types.FETCH_MEMBERSHIPS_SUCCESS,
    payload,
    meta,
});

export const fetchMembershipsFailure = error => ({
    type: types.FETCH_MEMBERSHIPS_FAILURE,
    error,
});

export const fetchMembershipsCancel = () => ({
    type: types.FETCH_MEMBERSHIPS_CANCEL,
});
