import { React, FelaComponent, PropTypes, ButtonPrimary, FormattedMessage } from '../../dependencies';

import style from './AddSupplier.style';

import CreateSupplierFormModal from '../../containers/SupplierFormModal/CreateSupplierFormModal';

const AddSupplier = ({ isOpen, openModal, closeModal }) => (
    <FelaComponent style={style}>
        <ButtonPrimary onClick={openModal}>
            <FormattedMessage id="supplier.list.add.button" />
        </ButtonPrimary>
        {isOpen && <CreateSupplierFormModal isOpen={isOpen} closeModal={closeModal} />}
    </FelaComponent>
);

AddSupplier.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default AddSupplier;
