import {
    React,
    PropTypes,
    UI,
    CompaniesManagement,
    MembershipsManagement,
    useSelector,
    useNoActiveCompanyId,
    MembershipsSelectors,
} from '../../dependencies';

import { UserProfile } from '../../modules/user-profile';

const { Container, Card } = UI;

function Settings({ styles }) {
    const isOwner = useSelector(MembershipsSelectors.isOwnerSelector);
    const noActiveCompany = useNoActiveCompanyId();

    return (
        <Container>
            <div className={styles.settings}>
                <UserProfile />
                <Card>
                    <CompaniesManagement />
                    {noActiveCompany || !isOwner ? null : <MembershipsManagement />}
                </Card>
            </div>
        </Container>
    );
}

Settings.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default Settings;
