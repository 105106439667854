import { ReduxUtils } from '../../../dependencies';
import types from '../../actionTypes';

const actionTypes = {
    REQUEST: types.FETCH_ARES_DATA_REQUEST,
    SUCCESS: types.FETCH_ARES_DATA_SUCCESS,
    FAILURE: types.FETCH_ARES_DATA_FAILURE,
    CANCEL: types.FETCH_ARES_DATA_CANCEL,
};

export default ReduxUtils.basicApiReducer({
    actionTypes,
});
