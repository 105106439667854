import { React } from '../../dependencies';

import { useCurrentReportId } from '../useCurrentReportId';

export function useReportId() {
    const currentReportId = useCurrentReportId();
    const [reportId, setReportId] = React.useState(currentReportId);

    React.useEffect(() => {
        setReportId(currentReportId);
    }, [currentReportId]);

    return [reportId, setReportId];
}
