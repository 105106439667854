export const modal = () => ({
    '& > .ant-modal-content > .ant-modal-body': {
        padding: '24px 16px 16px',
    },
});

export const title = ({ theme: { colors } }) => ({
    color: colors.text,
    lineHeight: 1.31,
    letterSpacing: '0.3px',
    fontSize: '16px',
    marginBottom: 16,
});

export const footer = () => ({
    display: 'grid',
    gridTemplateColumns: '1fr repeat(2, auto)',
    gridColumnGap: 16,
    alignItems: 'center',
    marginTop: 24,
});
