import { types } from 'modules/expenses/services/actions';

const initialState = [];

export default function idsReducer(state = initialState, action) {
    switch (action.type) {
        case types.SAVE_EXPENSES:
        case types.FETCH_EXPENSES_SUCCESS:
            return action.payload.ids;

        case types.FETCH_EXPENSES_RESET:
            return initialState;

        default:
            return state;
    }
}
