import {
    React,
    PropTypes,
    Dropdown as AntDropdown,
    Icons,
    connectFela,
    useSelector,
    Auth,
    useDispatch,
    CompaniesSelectors,
} from '../../dependencies';

import DropdownMenu from './DropdownMenu';

import * as Styles from './Dropdown.styles';

function Dropdown({ styles }) {
    const companyName = useSelector(CompaniesSelectors.activeCompanyNameSelector);
    const dispatch = useDispatch();

    return (
        <AntDropdown
            overlay={<DropdownMenu logout={() => dispatch(Auth.actions.logout())} />}
            overlayClassName={styles.dropdown}
        >
            <button type="button">
                <div className={styles.dropdownButton}>
                    <div className={styles.iconUserWrapper}>
                        <Icons.User className={styles.iconUser} />
                    </div>
                    <div className={styles.companyName}>{companyName}</div>
                    <Icons.ChevronDown className={styles.iconChevronDown} />
                </div>
            </button>
        </AntDropdown>
    );
}

Dropdown.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(Dropdown);
