import { React, PropTypes, FelaComponent, ButtonIcon, Link, Icons } from '../../dependencies';

import Title from '../Title';

import * as styles from './Nav.styles';

function Nav({ titleId }) {
    return (
        <FelaComponent style={styles.nav}>
            <ButtonIcon>
                {({ className }) => (
                    <Link to="/">
                        <Icons.ArrowLeft {...{ className }} />
                    </Link>
                )}
            </ButtonIcon>

            <Title messageId={titleId} />
        </FelaComponent>
    );
}

Nav.propTypes = {
    titleId: PropTypes.string.isRequired,
};

export default Nav;
