export default function transformResponse(response = []) {
    const ids = [];
    const byId = {};

    for (const item of response) {
        ids.push(String(item.id));
        byId[item.id] = {
            ...item,
            id: String(item.id),
        };
    }

    return {
        ids,
        byId,
    };
}
