import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from './types';

export const fetchExportRequest = id => ({
    type: types.FETCH_EXPORT_REQUEST,
    meta: { id },
});

export const fetchExportSuccess = (id, data) => ({
    type: types.FETCH_EXPORT_SUCCESS,
    meta: { id },
    payload: formatEntity({
        template: entitiesTemplates.export,
        entity: data,
    }),
});

export const fetchExportFailure = (id, error) => ({
    type: types.FETCH_EXPORT_FAILURE,
    meta: { id },
    error,
});

export const fetchExportCancel = id => ({
    type: types.FETCH_EXPORT_CANCEL,
    meta: { id },
});
