import { React, PropTypes, childrenPropType, connectFela } from '../../../../dependencies';

import * as Styles from './Button.styles';

function LinkDownload({ name, url, children, styles }) {
    return (
        <a href={`${url}&response-content-disposition=attachment`} download={name} className={styles.button}>
            {children}
        </a>
    );
}

LinkDownload.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    children: childrenPropType.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(LinkDownload);
