import { sagaEffects, Log } from '../../dependencies';

import { types, setActiveCompanyResolve } from '../actions';

import companyIdStorage from './storage';
import setRequestInterceptor from './requestInterceptor';

const { takeLeading, put } = sagaEffects;

export default function* () {
    yield takeLeading(types.SET_ACTIVE_COMPANY_REQUEST, function* ({ companyId, options }) {
        try {
            if (companyId) {
                yield companyIdStorage.set(companyId);

                setRequestInterceptor(companyId);
            } else {
                yield companyIdStorage.remove();
            }

            if (options.hardReload) {
                // FIXME: would be better do it with soft-reload -> fix refecthing data after a company switch
                window.location.reload();
            } else {
                yield put(setActiveCompanyResolve(companyId, options));
            }
        } catch (e) {
            Log.error(e);
        }
    });
}
