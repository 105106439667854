import {
    React,
    PropTypes,
    TextField,
    UI,
    useIntl,
    isSubmittingSelector,
    useSelector,
} from '../../../../../dependencies';

const { InputWrapper } = UI;

const Company = ({ formId }) => {
    const intl = useIntl();
    const isSubmitting = useSelector(isSubmittingSelector(formId));

    return (
        <InputWrapper gridArea="company">
            <TextField
                name="company"
                label={intl.formatMessage({
                    id: 'export.recurring.form.company.label',
                })}
                placeholder={intl.formatMessage({
                    id: 'export.recurring.form.company.placeholder',
                })}
                disabled={isSubmitting}
                autoComplete="organization"
            />
        </InputWrapper>
    );
};

Company.propTypes = {
    formId: PropTypes.string.isRequired,
};

export default Company;
