import { React, FelaComponent, PropTypes } from '../../dependencies';

import TagInfo, { tagProps } from '../TagInfo';
import UpdateTagForm from '../../containers/UpdateTagForm';

import style from './TagListItem.style';

const propTypes = {
    ...tagProps,
    isOpen: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export const tagShape = PropTypes.shape(tagProps);

const TagListItem = ({ name, id, isOpen, openModal, closeModal, isContributor }) => {
    return (
        <FelaComponent style={style}>
            {!isOpen ? (
                <TagInfo name={name} id={id} onUpdateClick={openModal} isContributor={isContributor} />
            ) : (
                <UpdateTagForm name={name} id={id} closeModal={closeModal} />
            )}
        </FelaComponent>
    );
};

TagListItem.propTypes = {
    ...propTypes,
    isContributor: PropTypes.bool.isRequired,
};

export default TagListItem;
