import types from './types';

export const updateMembershipRole = (id, role) => ({
    type: types.UPDATE_MEMBERSHIP_ROLE,
    payload: {
        id,
        role,
    },
});

export const submitCreateMembershipForm = () => ({
    type: types.SUBMIT_CREATE_MEMBERSHIP_FORM,
});
