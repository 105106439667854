import { ExpensesConsts, Config, round, Consts } from '../../dependencies';

const { VAT_RATE } = ExpensesConsts;
const { maximumFractionDigits } = Config.currency;

export const calcDecimalDifferenceWithoutVat = (value = 0, vatMode) => {
    const withVat = Math.round(value) - round(value, maximumFractionDigits);
    const withoutVat = vatMode === Consts.vatMode.BASE ? (1 / (1 + VAT_RATE)) * withVat : withVat;
    const decimalDifference = round(withoutVat, maximumFractionDigits + 1);

    return Number.parseFloat(decimalDifference);
};
