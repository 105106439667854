import { ReduxUtils } from '../../dependencies';

const asyncType = ReduxUtils.createAsyncType({
    modulePrefix: 'ui/toggle',
});

export default ReduxUtils.strictObjectAccess({
    ...asyncType({
        types: ['SET_TOGGLE'],
    }),
});
