import { sagaEffects, Log } from '../../dependencies';

import { initActiveCompany } from '../actions';

import companyIdStorage from './storage';
import setRequestInterceptor from './requestInterceptor';

const { put } = sagaEffects;

function findCompanyId(memberships = {}, companyId) {
    const membership = Object.values(memberships).find(membership => {
        if (companyId === undefined) {
            return membership.companyId;
        }

        return String(membership.companyId) === companyId;
    });

    return membership ? membership.companyId : null;
}

function* getLocalCompanyId(memberships) {
    const companyId = yield companyIdStorage.get();

    if (findCompanyId(memberships, companyId)) {
        return companyId;
    }

    yield companyIdStorage.remove();

    return null;
}

export default function* (memberships) {
    try {
        let companyId = (yield getLocalCompanyId(memberships)) ?? findCompanyId(memberships);

        if (companyId === null) {
            return;
        }

        companyId = String(companyId);

        yield companyIdStorage.set(companyId);

        setRequestInterceptor(companyId);

        yield put(initActiveCompany(companyId));
    } catch (e) {
        Log.error(e);
    }
}
