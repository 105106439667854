import { config, authApi, takeRequest, Log, sagaEffects, Normalizers, ErrorsUtils } from '../../dependencies';

import { fetchSuppliersSuccess, fetchSuppliersFailure } from '../actions';
import types from '../actionTypes';

const { put } = sagaEffects;

function* fetchSuppliers(action, cancelToken) {
    try {
        const requestConfig = {
            params: {
                // TODO: this is temp.:
                limit: Number.MAX_SAFE_INTEGER,
            },
            cancelToken,
        };

        const { data } = yield* authApi.get(config.api.suppliers, requestConfig);

        const { result: ids, entities } = Normalizers.suppliers(data);

        yield put(
            fetchSuppliersSuccess({
                byId: entities.suppliers,
                ids,
            }),
        );
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.suppliers.api.get',
            },
        });

        yield put(fetchSuppliersFailure(errorMessage));
    }
}

export default function* fetchSuppliersWatcher() {
    const actionTypes = {
        REQUEST: types.FETCH_SUPPLIERS_REQUEST,
        CANCEL: types.FETCH_SUPPLIERS_CANCEL,
    };

    yield takeRequest(actionTypes, fetchSuppliers);
}
