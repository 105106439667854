import { React, UI, PropTypes, SelectInput } from '../../dependencies';

import { useSelectCompany } from '../../hooks';

const { InputWrapper } = UI;

const SelectCompany = ({ styles }) => {
    const { value, onChange, options } = useSelectCompany();

    return (
        <InputWrapper>
            <SelectInput
                {...{
                    className: styles.selectInput,
                    options,
                    input: {
                        value,
                        onChange,
                    },
                }}
            />
        </InputWrapper>
    );
};

SelectCompany.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default SelectCompany;
