import { React, PropTypes, connectFela } from '../../dependencies';

import * as Styles from './Value.styles';

function Value({ children, title, styles }) {
    return (
        <div title={title} className={styles.value}>
            {children}
        </div>
    );
}

Value.propTypes = {
    // eslint-disable-next-line
    children: PropTypes.any,
    title: PropTypes.string,
    styles: PropTypes.shape({}).isRequired,
};

Value.defaultProps = {
    title: undefined,
};

export default connectFela(Styles)(Value);
