export const card = ({ theme: { colors } }) => ({
    textAlign: 'center',
    padding: '80px 24px',
    justifyContent: 'center',
    display: 'grid',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 4,
    gridRowGap: 8,
    margin: '0 16px',
});

export const message = ({ theme: { colors } }) => ({
    fontSize: '16px',
    lineHeight: 1.56,
    letterSpacing: '0.2px',
    color: colors.text,
    marginBottom: 8,
});

export const icon = ({ theme: { colors } }) => ({
    height: 20,
    fill: colors.primary,
    margin: '0 auto',
});
