import { sagaEffects } from '../../dependencies';

import createCompany from './createCompany';
import deleteCompany from './deleteCompany';
import updateCompany from './updateCompany';

import singles from './singles';

const { all } = sagaEffects;

export default function* expensesSaga() {
    yield all([deleteCompany(), createCompany(), updateCompany(), singles()]);
}
