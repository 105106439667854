import { connect } from '../../dependencies';

import ItemFields from '../../components/Fields/Items/ItemFields';

const mapStateToProps = () => {
    return {
        mayAddNewRow: true,
    };
};

export default connect(mapStateToProps)(ItemFields);
