import { React, Modal, useModal, Consts } from '../../dependencies';

import EditCompanyForm from '../../containers/EditCompanyForm';

const EditCompanyModal = () => {
    const modal = useModal(Consts.modals.EDIT_COMPANY);

    return (
        <Modal visible={modal.isOpen} onCancel={modal.close}>
            <EditCompanyForm onCancel={modal.close} />
        </Modal>
    );
};

export default EditCompanyModal;
