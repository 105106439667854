import {
    React,
    PropTypes,
    connectFela,
    PriceTooltip,
    TooltipWrapper,
    FormattedNumber,
    config,
    FormattedMessage,
} from '../../../dependencies';

import * as Styles from './Cell.styles';

function Cell({ label, value: { withVat, withoutVat }, styles, currency }) {
    const title = <PriceTooltip currency={currency} priceWithVat={withVat} />;

    return (
        <div>
            <div className={styles.label}>
                <FormattedMessage {...label} />
            </div>

            <TooltipWrapper title={title}>
                <div className={styles.value}>
                    <FormattedNumber
                        {...{
                            value: withoutVat,
                            ...config.currency,
                            currency,
                            style: 'currency',
                        }}
                    />
                </div>
            </TooltipWrapper>
        </div>
    );
}

Cell.propTypes = {
    label: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    value: PropTypes.shape({
        withVat: PropTypes.number.isRequired,
        withoutVat: PropTypes.number.isRequired,
    }).isRequired,
    styles: PropTypes.shape({}).isRequired,
    currency: PropTypes.string.isRequired,
};

export default connectFela(Styles)(Cell);
