const config = {
    api: {
        base: 'https://api-expenses-development.ack.ee/api',
    },
    googleAnalytics: {
        trackingId: 'UA-160812951-2',
    },
    devTools: true,
    sentry: {
        dsn: '',
    },
};

export default config;
