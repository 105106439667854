import { React, PropTypes, Chips, FelaComponent } from '../../dependencies';

import * as styles from './SelectedTags.styles';

function SelectedTags({ selectedTags, removeTag, disabled, small, color }) {
    if (selectedTags.length === 0) {
        return null;
    }

    return (
        <FelaComponent style={styles.wrap} small={small}>
            <Chips
                {...{
                    disabled,
                    items: selectedTags,
                    removable: true,
                    onClick(value) {
                        const index = selectedTags.findIndex(option => option.value === value);
                        if (index >= 0) {
                            removeTag({ index, value });
                        }
                    },
                    size: small ? Chips.sizes.S : undefined,
                    color,
                }}
            />
        </FelaComponent>
    );
}

SelectedTags.propTypes = {
    selectedTags: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ).isRequired,
    removeTag: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    small: PropTypes.bool.isRequired,
    color: PropTypes.string,
};

SelectedTags.defaultProps = {
    color: undefined,
};

export default SelectedTags;
