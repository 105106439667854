import { union, difference, Utils } from '../../../dependencies';
import types from '../../actionTypes';

const initialState = {
    byId: {},
    ids: [],
};

export default function expensesReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_SUPPLIERS_SUCCESS: {
            const { ids, byId } = action.payload;
            const nextById = {
                ...state.byId,
                ...byId,
            };

            return {
                ...state,
                byId: nextById,
                ids: union(state.ids, ids).sort((idA, idB) =>
                    Utils.alphaLocaleCompare(nextById[idA].name, nextById[idB].name),
                ),
            };
        }

        case types.UPDATE_SUPPLIER_SUCCESS:
        case types.CREATE_SUPPLIER_SUCCESS:
        case types.FETCH_SUPPLIER_SUCCESS: {
            const { id } = action.meta;
            const nextById = {
                ...state.byId,
                [id]: action.payload,
            };

            return {
                ...state,
                byId: nextById,
                ids: union(state.ids, [id]).sort((idA, idB) =>
                    Utils.alphaLocaleCompare(nextById[idA].name, nextById[idB].name),
                ),
            };
        }

        case types.DELETE_SUPPLIER_SUCCESS: {
            const { id } = action.meta;
            const nextById = { ...state.byId };

            delete nextById[id];

            return {
                ...state,
                ids: difference(state.ids, [id]),
                byId: nextById,
            };
        }

        default:
            return state;
    }
}
