import { connect } from '../dependencies';

import { expenseFilesSelector, externalLinkSelector } from '../services/selectors';

import Attachments from '../components/Attachments';

const mapStateToProps = (state, { expenseId }) => {
    return {
        files: expenseFilesSelector(state, expenseId),
        externalLink: externalLinkSelector(state, expenseId),
    };
};

export default connect(mapStateToProps)(Attachments);
