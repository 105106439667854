import { React, useSelector, useDispatch, useCurrentObjectRef } from '../dependencies';

import { fetchExportRequest, fetchExportCancel } from '../services/actions';
import { fetchExportApiSelector } from '../services/selectors';

export function useFetchExport(id) {
    const api = useSelector(state => fetchExportApiSelector(state, id));
    const apiRef = useCurrentObjectRef(api);
    const dispatch = useDispatch();
    const request = () => dispatch(fetchExportRequest(id));

    React.useEffect(() => {
        const { inProgress, success } = apiRef.current;
        if (!inProgress && !success) {
            request();
        }

        return () => {
            if (inProgress) {
                dispatch(fetchExportCancel(id));
            }
        };
        // eslint-disable-next-line
    }, [id]);

    const { inProgress, success, error } = api;

    return {
        inProgress,
        success,
        error: {
            retry: request,
            message: error,
        },
    };
}
