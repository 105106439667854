import { React, PropTypes, Icons, connectFela, FelaComponent } from '../../../../dependencies';

import * as Styles from './File.styles';

import Button from './Button';
import LinkDownload from './LinkDownload';

const { Document, Download, Remove } = Icons;

function File({ name, url, onRemove, styles }) {
    return (
        <FelaComponent style={Styles.file} iconsCount={Boolean(url) + Boolean(onRemove)}>
            <Document className={styles.documentIcon} />
            <a
                className={styles.name}
                href={`${url}&response-content-disposition=inline`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {name}
            </a>

            {url && (
                <LinkDownload {...{ name, url }}>
                    <Download className={styles.downloadIcon} />
                </LinkDownload>
            )}

            {onRemove && (
                <Button onClick={onRemove}>
                    <Remove className={styles.removeIcon} />
                </Button>
            )}
        </FelaComponent>
    );
}

File.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    onRemove: PropTypes.func,
    styles: PropTypes.shape().isRequired,
};

File.defaultProps = {
    url: null,
    onRemove: null,
};

export default connectFela(Styles)(File);
