import React from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonAntd } from 'antd';
import { childrenPropType } from '@ackee/lucas';

import * as styles from './Danger.styles';

import Button from '../Button';

function Danger({ children, outline, size, ...props }) {
    return (
        <Button
            {...{
                style: styles.danger,
                outline,
                size,
            }}
        >
            {({ className }) => (
                <ButtonAntd
                    {...{
                        className,
                        ...props,
                        type: 'danger',
                    }}
                >
                    {children}
                </ButtonAntd>
            )}
        </Button>
    );
}

Danger.propTypes = {
    children: childrenPropType.isRequired,
    outline: PropTypes.bool,
    size: PropTypes.string,
};

Danger.defaultProps = {
    outline: false,
    size: undefined,
};

export default Danger;
