import { React, PropTypes, InputWrapper, CheckboxGroupField, injectIntl } from '../../../dependencies';

import { HAS_FILES_OPTION, Fields } from '../../../constants';

const HasFilesField = ({ disabled, intl }) => (
    <InputWrapper>
        <CheckboxGroupField
            {...{
                options: [
                    {
                        value: HAS_FILES_OPTION,
                        label: intl.formatMessage({
                            id: 'exports.disposable.hasFiles',
                        }),
                    },
                ],
                name: Fields.HAS_FILES,
                disabled,
            }}
        />
    </InputWrapper>
);

HasFilesField.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default injectIntl(HasFilesField);
