// Polyfill missing API manually as automatic polyfilling based on browserslist
// configuration doesnt work, ref https://github.com/AckeeCZ/create-react-app/issues/6
import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';

import Core from 'modules/core';
import Application from 'modules/application';

ReactDOM.render(
    <Core>
        <Application />
    </Core>,
    document.getElementById('root'),
);
