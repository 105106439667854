import { React, PropTypes, TextField, useIntl, isSubmittingSelector, UI, useSelector } from '../../dependencies';

const { InputWrapper } = UI;

const EmailField = ({ formId, inputWrapper, ...rest }) => {
    const intl = useIntl();
    const submitting = useSelector(isSubmittingSelector(formId));

    return (
        <InputWrapper {...inputWrapper}>
            <TextField
                name="email"
                label={intl.formatMessage({ id: 'login.email' })}
                disabled={submitting}
                autoComplete="email"
                {...rest}
                type="email"
            />
        </InputWrapper>
    );
};

EmailField.propTypes = {
    formId: PropTypes.string.isRequired,
    inputWrapper: PropTypes.shape(),
};

export default EmailField;
