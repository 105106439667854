import { compose, connect, reduxForm, injectIntl, submitForm } from '../dependencies';

import TagForm from '../components/TagForm';

import { updateFormIdFactory } from '../utils';
import { tagSelector } from '../services/selectors';
import { updateTagRequest } from '../services/actions';

import validate from './TagForm.validate';

export default compose(
    connect((state, { name, id }) => ({
        initialValues: tagSelector(state, name),
        form: updateFormIdFactory(id),
    })),
    injectIntl,
    reduxForm({
        validate,
        enableReinitialize: true,
        onSubmit: (values, dispatch, { id }) => {
            const submit = submitForm(updateFormIdFactory(id), () => updateTagRequest(id));
            dispatch(submit(values));
        },
    }),
)(TagForm);
