import { Paging, sagaEffects, Consts } from '../../dependencies';

import { fetchExpenseTriggersRequest } from '../actions';
import { expenseTriggersApiSelector } from '../selectors';

const { takeEvery, select, put } = sagaEffects;

function* fetchExpenseTriggers(action) {
    const { amount } = yield select(expenseTriggersApiSelector);
    const { page } = action.payload;

    yield put(
        fetchExpenseTriggersRequest({
            params: {
                offset: (page - 1) * amount,
            },
        }),
    );
}

function setPageFilter(action) {
    const { type, meta } = action;
    return (
        type === Paging.actionTypes.SET_PAGE &&
        meta.entityKey === Consts.entityKeys.EXPENSE_TRIGGERS &&
        meta.typeId === Consts.apiTypes.FETCH
    );
}

export default function*() {
    yield takeEvery(setPageFilter, fetchExpenseTriggers);
}
