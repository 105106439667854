export const line = ({ bottomOffset }) => ({
    position: 'absolute',
    left: 0,
    width: '100%',
    bottom: Math.round(bottomOffset),
    zIndex: 1,
});

export const iconLine = ({ theme: { colors } }) => ({
    height: 2,
    fill: colors.primarySelection,
});

export const tooltipSpace = () => ({
    display: 'flex',
    width: '100%',
    height: 15,
    transform: 'translateY(50%)',
    alignItems: 'center',
});
