import { normalize } from 'normalizr';
import * as schemas from './schemas';

const normalizeReponse = (schema, list = false) => (values = []) => {
    const { result, entities } = normalize(values, schema);

    return {
        result: list ? result.map(String) : String(result),
        entities,
    };
};

export const user = normalizeReponse(schemas.user);

// suppliers
export const suppliers = normalizeReponse(schemas.suppliersList, true);

// expenses
export const expenses = normalizeReponse(schemas.expensesList, true);

export const expense = normalizeReponse(schemas.expense);

export const expenseTrigger = normalizeReponse(schemas.expenseTrigger);

// reports
export const reports = normalizeReponse(schemas.reportsList, true);

export const report = normalizeReponse(schemas.report);

export const dashboard = normalizeReponse(schemas.dashboard);

// exports
export const recurringExports = normalizeReponse(schemas.exportList, true);

export const recurringExport = normalizeReponse(schemas.exportItem, false);

// tags
export const tags = normalizeReponse(schemas.tagsList, true);

// service keys
export const serviceKey = normalizeReponse(schemas.serviceKey);

export const serviceKeys = normalizeReponse(schemas.serviceKeyList, true);

export const memberships = normalizeReponse(schemas.membershipsList, true);
