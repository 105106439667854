import {
    React,
    PropTypes,
    useSelector,
    FormattedMessage,
    withErrorBoundary,
    UI,
    useFela,
    CompaniesSelectors,
    MembershipsSelectors,
    MembershipsConsts,
} from '../../dependencies';

import EditCompanyButton from '../EditCompanyButton';

const { Chip, ChipTypes } = UI;

const CompanyDetail = ({ styles }) => {
    const { theme } = useFela();
    const role = useSelector(MembershipsSelectors.activeMembershipRoleSelector);
    const company = useSelector(CompaniesSelectors.activeCompanySelector);

    if (!company) {
        return (
            <div className={styles.noCompany}>
                <FormattedMessage id="settings.authUserWithoutCompany" />
            </div>
        );
    }

    const { name, street, zipCode, country, city, vatNumber, registrationNumber } = company;

    return (
        <div className={styles.card}>
            <div>
                <h4 className={styles.name}>{name}</h4>

                <div className={styles.roleRow}>
                    <div className={styles.roleLabel}>
                        <FormattedMessage id="settings.role.yours" />
                    </div>
                    <Chip type={ChipTypes.LABEL} color={theme.colors.secondary} size={Chip.sizes.XS}>
                        <span className={styles.roleValue}>
                            <FormattedMessage id={`role.${role}`} />
                        </span>
                    </Chip>
                </div>

                <div className={styles.details}>
                    {street ? <div className={styles.text}>{street}</div> : <div />}
                    {registrationNumber ? (
                        <div className={styles.text}>
                            <FormattedMessage
                                id="settings.company.registrationNumber"
                                values={{
                                    value: registrationNumber,
                                }}
                            />
                        </div>
                    ) : (
                        <div />
                    )}
                    {zipCode || city ? (
                        <div className={styles.text}>{zipCode && city ? `${zipCode}, ${city}` : zipCode || city}</div>
                    ) : (
                        <div />
                    )}
                    {vatNumber ? (
                        <div className={styles.text}>
                            {
                                <FormattedMessage
                                    id="settings.company.vatNumber"
                                    values={{
                                        value: vatNumber,
                                    }}
                                />
                            }
                        </div>
                    ) : (
                        <div />
                    )}
                    {country ? (
                        <div className={styles.text}>
                            <FormattedMessage id={`country.${country.toLowerCase()}`} />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            </div>

            <div>{role === MembershipsConsts.Roles.OWNER && <EditCompanyButton />}</div>
        </div>
    );
};

CompanyDetail.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default withErrorBoundary(CompanyDetail);
