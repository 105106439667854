import { React, PropTypes, AntdPagination, FelaComponent, Icons } from '../../dependencies';

import * as styles from './Pagination.styles';

function Pagination({ page, totalCount, amount, setPage }) {
    if (totalCount === 0) {
        return null;
    }

    return (
        <FelaComponent style={styles.pagination}>
            <AntdPagination
                {...{
                    current: page,
                    total: totalCount,
                    pageSize: amount,
                    onChange: setPage,
                    itemRender: (current, type, originalElement) =>
                        Pagination.itemRender({ type, originalElement, amount, totalCount, page }),
                    hideOnSinglePage: true,
                }}
            />
        </FelaComponent>
    );
}

Pagination.itemRender = ({ page, totalCount, amount, type, originalElement }) => {
    switch (type) {
        case 'next': {
            const lastPage = Math.ceil(totalCount / amount);
            const disabled = page >= lastPage;

            return (
                <button type="button" disabled={disabled}>
                    <FelaComponent style={styles.icon} rotateZ={180} disabled={disabled}>
                        {({ className }) => <Icons.Chevron2Left {...{ className }} />}
                    </FelaComponent>
                </button>
            );
        }

        case 'prev': {
            const disabled = page === 1;

            return (
                <button type="button" disabled={disabled}>
                    <FelaComponent style={styles.icon} disabled={disabled}>
                        {({ className }) => <Icons.Chevron2Left {...{ className }} />}
                    </FelaComponent>
                </button>
            );
        }

        default:
            return originalElement;
    }
};

Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
};

export default Pagination;
