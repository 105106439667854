import {
    React,
    Fragment,
    PropTypes,
    FormattedMessage,
    connectFela,
    FormattedNumber,
    config,
} from '../../../dependencies';

import * as Styles from './ChartTooltip.styles';

const ChartTooltip = ({ currency, withVat, withoutVat, tagNames, combinedSegment, styles }) => (
    <>
        <div className={styles.header} title={withVat}>
            <FormattedNumber
                {...{
                    value: withoutVat,
                    currency,
                    style: 'currency',
                    ...config.currency,
                }}
            />
        </div>

        {combinedSegment && (
            <div className={styles.noTags}>
                <FormattedMessage id="report.others" />
            </div>
        )}

        {tagNames.length === 0 && !combinedSegment && (
            <div className={styles.noTags}>
                <FormattedMessage id="report.noTags" />
            </div>
        )}

        {tagNames.length > 0 && !combinedSegment && (
            <div className={styles.body}>
                {tagNames.map((tagName, i) => (
                    <Fragment key={i}>
                        <div className={styles.dot} />
                        <div className={styles.tag}>{tagName}</div>
                    </Fragment>
                ))}
            </div>
        )}
    </>
);

ChartTooltip.propTypes = {
    currency: PropTypes.string.isRequired,
    withVat: PropTypes.number.isRequired,
    withoutVat: PropTypes.number.isRequired,
    tagNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    combinedSegment: PropTypes.bool.isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default connectFela(Styles)(ChartTooltip);
