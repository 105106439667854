import { connect, compose, injectIntl } from '../dependencies';

import SelectOrderFilter from '../components/SelectOrderFilter';

import { setOrderUrlParam } from '../services/actions';
import { dateFieldSelector, orderSelector } from '../services/selectors';

const mapStateToProps = state => ({
    orderBy: dateFieldSelector(state),
    order: orderSelector(state),
});

const mapDispatchToProps = {
    onChange: setOrderUrlParam,
};

export default compose(
    injectIntl,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(SelectOrderFilter);
