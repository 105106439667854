import {
    React,
    FormattedMessage,
    UI,
    useSelector,
    useDispatch,
    CompaniesActions,
    CompaniesSelectors,
    useActiveCompanyId,
} from '../../dependencies';

const { ButtonDanger } = UI;

const DeleteCompanyButton = () => {
    const companyId = useActiveCompanyId();
    const api = useSelector(state => CompaniesSelectors.deleteCompanyApiSelector(state, companyId));
    const dispatch = useDispatch();

    return (
        <ButtonDanger
            onClick={() => dispatch(CompaniesActions.deleteCompanyRequest(companyId))}
            loading={api.inProgress}
            outline
        >
            <FormattedMessage id="settings.company.button.remove" />
        </ButtonDanger>
    );
};

export default DeleteCompanyButton;
