import React from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonAntd } from 'antd';
import { childrenPropType } from '@ackee/lucas';

import Button from '../Button';

import * as styles from './Primary.styles';

function Primary({ children, size, ...props }) {
    return (
        <Button {...{ size, style: styles.buttonPrimary }}>
            {({ className }) => (
                <ButtonAntd
                    {...{
                        className,
                        ...props,
                        type: 'primary',
                    }}
                >
                    {children}
                </ButtonAntd>
            )}
        </Button>
    );
}

Primary.propTypes = {
    children: childrenPropType.isRequired,
    size: PropTypes.string,
};

Primary.defaultProps = {
    size: undefined,
};

export default Primary;
