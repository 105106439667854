import { ReduxUtils, Consts } from '../../dependencies';

const { apiTypes, entityKeys } = Consts;

const { apiSelector } = ReduxUtils;

export const fetchMembershipsApiSelector = state => apiSelector(state, entityKeys.MEMBERSHIPS, apiTypes.FETCH);

export const fetchMembershipApiSelector = (state, id) => apiSelector(state, entityKeys.MEMBERSHIP, apiTypes.FETCH, id);

export const updateMembershipApiSelector = (state, id) =>
    apiSelector(state, entityKeys.MEMBERSHIP, apiTypes.UPDATE, id);

export const deleteMembershipApiSelector = (state, id) =>
    apiSelector(state, entityKeys.MEMBERSHIP, apiTypes.DELETE, id);

export const createMembershipApiSelector = state => apiSelector(state, entityKeys.MEMBERSHIP, apiTypes.CREATE);
