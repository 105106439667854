export const modal = () => ({
    display: 'grid',
    gridTemplateColumns: '1.5rem 1fr',
    gridColumnGap: '1rem',
    paddingRight: 16,
});

export const icon = ({ theme: { colors } }) => ({
    width: '1.25rem',
    fill: colors.text,
    margin: '0.125rem auto 0',
});

export const text = ({ theme: { colors } }) => ({
    color: colors.text,
    fontSize: '1rem',
    lineHeight: 1.56,
    letterSpacing: '0.15px',
});
