import { React, PropTypes, connectFela, FormattedMessage, Icons } from '../../../dependencies';

import * as Styles from './ButtonAdd.styles';

function ButtonAdd({ styles, onClick }) {
    return (
        <button type="button" onClick={onClick} className={styles.button}>
            <div className={styles.content}>
                <Icons.Plus className={styles.icon} />
                <span className={styles.text}>
                    <FormattedMessage id="tags.addNew" />
                </span>
            </div>
        </button>
    );
}

ButtonAdd.propTypes = {
    styles: PropTypes.shape().isRequired,
    onClick: PropTypes.func.isRequired,
};

export default connectFela(Styles)(ButtonAdd);
