import { React, PropTypes, FelaComponent, FormattedMessage, Config, injectIntl } from '../../../dependencies';

import style from './PriceTooltip.style';

const PriceTooltip = ({
    priceWithVat,
    currency,

    priceWithoutVatDefaultCurrency,
    priceWithVatDefaultCurrency,
    defaultCurrency,

    intl: { formatNumber },
}) => {
    if (priceWithVat === null) {
        return null;
    }

    const renderDefaultCurrency = defaultCurrency && currency !== defaultCurrency;
    return (
        <FelaComponent style={style}>
            <FormattedMessage
                id="expenses.list.item.totalPriceWithVat"
                values={{
                    price: formatNumber(priceWithVat, {
                        style: 'currency',
                        currency,
                        ...Config.currency,
                    }),
                }}
                tagName="div"
            />
            {renderDefaultCurrency && Number.isFinite(priceWithoutVatDefaultCurrency) && (
                <FormattedMessage
                    id="expenses.list.item.totalPriceWithoutVat"
                    values={{
                        price: formatNumber(priceWithoutVatDefaultCurrency, {
                            style: 'currency',
                            currency: defaultCurrency,
                            ...Config.currency,
                        }),
                    }}
                    tagName="div"
                />
            )}
            {renderDefaultCurrency && Number.isFinite(priceWithVatDefaultCurrency) && (
                <FormattedMessage
                    id="expenses.list.item.totalPriceWithVat"
                    values={{
                        price: formatNumber(priceWithVatDefaultCurrency, {
                            style: 'currency',
                            currency: defaultCurrency,
                            ...Config.currency,
                        }),
                    }}
                    tagName="div"
                />
            )}
        </FelaComponent>
    );
};

PriceTooltip.propTypes = {
    priceWithVat: PropTypes.number,
    priceWithoutVatDefaultCurrency: PropTypes.number,
    priceWithVatDefaultCurrency: PropTypes.number,
    currency: PropTypes.string.isRequired,
    defaultCurrency: PropTypes.string,
    intl: PropTypes.shape().isRequired,
};

PriceTooltip.defaultProps = {
    priceWithVat: null,
    priceWithoutVatDefaultCurrency: null,
    priceWithVatDefaultCurrency: null,
    defaultCurrency: null,
};

export default injectIntl(PriceTooltip);
