import { React, PropTypes, config, UI } from '../../../../dependencies';

import AccountSlug from '../AccountSlug';
import ServiceNote from '../ServiceNote';

import Username from './Username';
import Password from './Password';
import Company from './Company';

const { Space } = UI;

const FlexibeeService = ({ formId, styles }) => {
    return (
        <>
            <Space bottom="1rem" />
            <ServiceNote
                messages={{
                    note: 'export.recurring.form.flexibee.note',
                    link: 'export.recurring.form.flexibee.link',
                }}
                url={config.links.serviceKey.flexibee}
            />
            <Space bottom="2.5rem" />
            <div className={styles.grid}>
                <Username formId={formId} />
                <Password formId={formId} />
                <Company formId={formId} />
                <AccountSlug formId={formId} />
            </div>
        </>
    );
};

FlexibeeService.propTypes = {
    formId: PropTypes.string.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default FlexibeeService;
