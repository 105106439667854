import types from './types';

export const fetchServiceKeyRequest = id => ({
    type: types.FETCH_SERVICE_KEY_REQUEST,
    meta: { id },
});

export const fetchServiceKeySuccess = (id, payload) => ({
    type: types.FETCH_SERVICE_KEY_SUCCESS,
    meta: { id },
    payload,
});

export const fetchServiceKeyFailure = (id, error) => ({
    type: types.FETCH_SERVICE_KEY_FAILURE,
    meta: { id },
    error,
});

export const fetchServiceKeyInvalidate = id => ({
    type: types.FETCH_SERVICE_KEY_CANCEL,
    meta: { id },
});
