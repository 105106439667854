import { Utils } from '../../../dependencies';

export default function transformPayload({ expenseId, period, startAt, endAt, name }) {
    return Utils.preparePayload({
        expenseId,
        period,
        startAt,
        endAt,
        name,
    });
}
