import { ReduxUtils, connect, Consts, ExpensesActions } from '../dependencies';

import ButtonRemove from '../components/Nav/ButtonRemove';

const { DELETE } = Consts.apiTypes;
const { EXPENSE } = Consts.entityKeys;

const mapStateToProps = (state, { expenseId }) => {
    const { inProgress } = ReduxUtils.apiSelector(state, EXPENSE, DELETE, expenseId);

    return {
        inProgress,
    };
};

const mapDispatchToProps = (dispatch, { expenseId }) => ({
    deleteExpenseRequest() {
        dispatch(ExpensesActions.deleteExpenseRequest(expenseId));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ButtonRemove);
