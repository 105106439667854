import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from './types';

export const updateExportRequest = (id, data) => ({
    type: types.UPDATE_EXPORT_REQUEST,
    meta: { id },
    payload: data,
});

export const updateExportSuccess = (id, data) => ({
    type: types.UPDATE_EXPORT_SUCCESS,
    meta: { id },
    payload: formatEntity({
        template: entitiesTemplates.export,
        entity: data,
    }),
});

export const updateExportFailure = (id, error) => ({
    type: types.UPDATE_EXPORT_FAILURE,
    meta: { id },
    error,
});

export const updateExportCancel = id => ({
    type: types.UPDATE_EXPORT_CANCEL,
    meta: { id },
});
