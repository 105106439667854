import { ReduxUtils } from 'modules/expenses/dependencies';
import * as Consts from 'modules/expenses/constants';
import { types } from 'modules/expenses/services/actions';

import typeReducer from './typeReducer';

const { DEFAULT, STATISTIC } = Consts.types;

const typeReducerInitialState = typeReducer(undefined, {});

const initialState = {
    [DEFAULT]: typeReducerInitialState,
    [STATISTIC]: typeReducerInitialState,
};

export default ReduxUtils.containerReducer({
    childReducer: typeReducer,
    actionTypes: [types.FETCH_NEXT_EXPENSE_NUMBER_RESET, types.FETCH_NEXT_EXPENSE_NUMBER_SUCCESS],
    initialState,
    selectors: {
        itemId: action => action.meta.type,
    },
});
