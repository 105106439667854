export const row = ({ presetId }) => {
    if (!presetId) {
        return {};
    }

    return {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: '1rem',
    };
};

export const input = () => ({
    width: '100%',
    '& .ant-select': {
        width: '100%',
    },
});
