import { React, FelaComponent, childrenPropType } from '../../dependencies';

import * as styles from './Body.styles';

function Body({ children }) {
    return <FelaComponent style={styles.body}>{children}</FelaComponent>;
}

Body.propTypes = {
    children: childrenPropType.isRequired,
};

export default Body;
