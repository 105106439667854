import { React, PropTypes, FormattedMessage, useDispatch, useSelector } from 'modules/reports/dependencies';

import { setViewportLeftOffset } from 'modules/reports/services/actions';
import { barChartHeaderSelector } from 'modules/reports/services/selectors';

import ButtonIcon from './ButtonIcon';

const Header = ({ styles }) => {
    const dispatch = useDispatch();
    const { viewportLeftOffset, maxViewportLeftOffset } = useSelector(barChartHeaderSelector);

    return (
        <>
            <div className={styles.label}>
                <FormattedMessage id="report.chart.expenses.montlhy" />
            </div>

            <div className={styles.nav}>
                <ButtonIcon
                    {...{
                        direction: 'left',
                        onClick() {
                            dispatch(setViewportLeftOffset(Math.max(0, viewportLeftOffset - 1)));
                        },
                        disabled: viewportLeftOffset === 0,
                    }}
                />
                <ButtonIcon
                    {...{
                        direction: 'right',
                        onClick() {
                            const lastAvailableOffset = Math.max(0, maxViewportLeftOffset);

                            dispatch(setViewportLeftOffset(Math.min(lastAvailableOffset, viewportLeftOffset + 1)));
                        },
                        disabled: viewportLeftOffset + 1 > maxViewportLeftOffset,
                    }}
                />
            </div>
        </>
    );
};

Header.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default React.memo(Header);
