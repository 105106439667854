import {
    React,
    PropTypes,
    FelaComponent,
    SwitchInput,
    FormattedMessage,
    noop,
    InputWrapper,
} from '../../../../dependencies';

import * as styles from './QuantityDataField.styles';

function QuantityDataField({ value, onChange, disabled }) {
    return (
        <FelaComponent style={styles.grid} as="label">
            <FelaComponent style={styles.label}>
                <FormattedMessage id="expense.create.form.quantityData" />
            </FelaComponent>

            <InputWrapper>
                <SwitchInput
                    {...{
                        input: {
                            value,
                            onChange,
                            onFocus: noop,
                            onBlur: noop,
                        },
                        disabled,
                    }}
                />
            </InputWrapper>
        </FelaComponent>
    );
}

QuantityDataField.propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default QuantityDataField;
