import { React, PropTypes, UI } from '../../dependencies';

import { useServiceKey } from '../../hooks';

import * as ExtendStyles from './ServiceKeyName.extendStyles';

const { DataLoader } = UI;

const ServiceKeyName = ({ id }) => {
    const serviceKey = useServiceKey(id);

    if (!serviceKey) {
        return <DataLoader extend={ExtendStyles.DataLoader} size={20} />;
    }

    return <span>{serviceKey.service}</span>;
};

ServiceKeyName.propTypes = {
    id: PropTypes.string.isRequired,
};

export default ServiceKeyName;
