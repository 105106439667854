import { React, PropTypes, FormattedMessage } from '../../dependencies';

const MembershipsListHeader = ({ styles }) => (
    <div className={styles.header}>
        <FormattedMessage id="memberships.table.email" tagName="span" />
        <FormattedMessage id="memberships.table.role" tagName="span" />
        <FormattedMessage id="memberships.table.addedAt" tagName="span" />
        <span />
    </div>
);

MembershipsListHeader.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default MembershipsListHeader;
