import { config, authApi, Log, sagaEffects, Normalizers, ErrorsUtils } from '../../dependencies';

import { updateExportSuccess, updateExportFailure, types } from '../actions';

const { put, takeEvery } = sagaEffects;

function* updateExport(action) {
    const { id } = action.meta;
    try {
        const { data } = yield* authApi.put(config.api.export, action.payload, {
            uriParams: {
                id,
            },
        });

        const { entities } = Normalizers.recurringExport(data);

        yield put(updateExportSuccess(id, entities.exports[id]));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.export.api.put',
            },
        });

        yield put(updateExportFailure(id, errorMessage));
    }
}

export default function* () {
    yield takeEvery(types.UPDATE_EXPORT_REQUEST, updateExport);
}
