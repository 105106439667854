export const searchInfo = () => ({
    display: 'grid',
    gridColumnGap: '1rem',
    gridTemplateColumns: 'repeat(2, auto)',
    alignItems: 'center',
});

export const label = ({ theme: { colors } }) => ({
    fontSize: '1.5rem',
    lineHeight: '2rem',
    color: colors.text,
});

export const value = ({ theme: { colors } }) => ({
    color: colors.text,
    lineHeight: '1.93rem',
    fontSize: '1.5rem',
    fontWeight: 700,
});
