import { qs } from '../dependencies';

const injectOrderIntoSearch = (searchString, { order, orderBy }) => {
    const search = qs.parse(searchString);
    const newSearch = {
        ...search,
        order,
        orderBy,
    };

    if (!order) {
        delete newSearch.order;
        delete newSearch.orderBy;
    }

    delete newSearch.page;

    return qs.stringify(newSearch);
};

export default injectOrderIntoSearch;
