export const button = () => ({
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const icon = ({ theme: { colors } }) => ({
    fill: colors.primary,
    height: 20,
});
