import {
    React,
    PropTypes,
    Modal,
    Form,
    TextField,
    connectFela,
    Utils,
    FormattedMessage,
    ButtonDefault,
    ButtonPrimary,
    errorPropType,
    ErrorMessage,
    InputWrapper,
} from 'modules/reports/dependencies';

import { NewReportFields } from 'modules/reports/config';
import * as Styles from './CreateReportModal.styles';

function CreateReportModal({
    styles,
    isOpen,
    closeModal,
    handleSubmit,
    submitting,
    error,
    intl: { formatMessage },
    reset,
}) {
    return (
        <Modal
            {...{
                className: styles.modal,
                visible: isOpen,
                destroyOnClose: true,
                centered: true,
                footer: null,
                closable: false,
                onCancel: closeModal,
            }}
        >
            <h3 className={styles.title} as="h3">
                <FormattedMessage id="report.create.form.title" />
            </h3>

            <Form onSubmit={handleSubmit}>
                <InputWrapper>
                    <TextField
                        {...{
                            name: NewReportFields.NAME,
                            placeholder: formatMessage({ id: 'report.create.form.placeholder' }),
                            disabled: submitting,
                            tabIndex: 1,
                            autoFocus: true,
                            parse: Utils.firstCharUpper,
                        }}
                    />
                </InputWrapper>

                <div className={styles.footer}>
                    {error ? (
                        <ErrorMessage padding={0}>
                            <FormattedMessage {...error} />
                        </ErrorMessage>
                    ) : (
                        <div />
                    )}

                    <ButtonDefault
                        {...{
                            onClick() {
                                closeModal();
                                reset();
                            },
                            disabled: submitting,
                            tabIndex: 2,
                        }}
                    >
                        <FormattedMessage id="report.create.form.cancel" />
                    </ButtonDefault>

                    <ButtonPrimary
                        {...{
                            onClick: handleSubmit,
                            loading: submitting,
                            tabIndex: 3,
                        }}
                    >
                        <FormattedMessage id="report.create.form.submit" />
                    </ButtonPrimary>
                </div>
            </Form>
        </Modal>
    );
}

CreateReportModal.propTypes = {
    styles: PropTypes.shape().isRequired,

    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: errorPropType,
    intl: PropTypes.shape().isRequired,
};

CreateReportModal.defaultProps = {
    error: null,
};

export default connectFela(Styles)(CreateReportModal);
