import moment from 'moment';
import { normalizeInput } from '../format';

export const isRequired = value => {
    if (
        value === undefined ||
        value === null ||
        String(value).trim() === '' ||
        (Array.isArray(value) && value.length === 0)
    ) {
        return false;
    }

    return true;
};

export const minLength = min => (value = '') => value.length >= min;

export const maxLength = max => (value = '') => value.length >= max;

export const isEmail = (value = '') => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test(String(value).toLowerCase());
};

export const isWord = (value = '') => {
    const pattern = /^[a-zA-ZÀ-ž]+(([',’`. -][a-zA-ZÀ-ž ])?[a-zA-ZÀ-ž]*)*$/;

    return pattern.test(String(value).trim());
};

export const isPastDay = value => {
    const today = moment();
    const pastDay = moment(value);

    return pastDay.diff(today, 'days') < 0;
};

export const isNowOrFutureDay = value => {
    const today = moment();
    const todayOrFutureDay = moment(value);

    return todayOrFutureDay.diff(today, 'days') >= 0;
};

export const isAlphaNumeric = value => {
    if (!isRequired(value)) {
        return true;
    }

    const result = String(normalizeInput(value)).match(/[a-zA-Z0-9.\s,]/g);

    return Boolean(result && result.length === value.length);
};
