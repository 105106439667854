import d from '../defaultValues';
import { convertValue, any } from '../helpers';

const expenseTrigger = {
    expenseId: convertValue(String),
    period: d.zero,
    startAt: d.date,
    endAt: d.null,
    companyId: convertValue(String),
    id: convertValue(String),
    lastRun: d.string,
    createdIds: d.array,
    createdAt: d.string,
    updatedAt: d.string,
    name: d.string,
    expense: convertValue(String),
    company: any,
};

export default expenseTrigger;
