import { config, authApi } from '../../dependencies';

export default function* uploadFiles(inputFiles = []) {
    if (inputFiles.length === 0) {
        return [];
    }

    const formData = new FormData();

    for (const file of inputFiles) {
        formData.append(file.name, file);
    }

    const { data: files } = yield* authApi.post(config.api.files, formData);

    return files.map(file => file.id);
}
