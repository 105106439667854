import { formatEntities, entitiesTemplates } from '../../dependencies';
import types from '../actionTypes';

export const addTags = (payload = {}) => ({
    type: types.ADD_TAGS,
    payload: {
        ...payload,
        byName: formatEntities({
            entities: payload.byName,
            template: entitiesTemplates.tag,
        }),
    },
});
