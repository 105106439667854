import { config, authApi, takeLatestRequest, Log, sagaEffects, Modals, ErrorsUtils } from '../../dependencies';

import { deleteModalIdFactory } from '../../utils';

import types from '../actionTypes';
import { deleteSupplierSuccess, deleteSupplierFailure, deleteSupplierInvalidate } from '../actions';
import { supplierNameSelector } from '../selectors';

const { put, select } = sagaEffects;

function* deleteSupplier(action, cancelToken) {
    const { id } = action.meta;

    try {
        const requestConfig = {
            uriParams: { id },
            cancelToken,
        };

        yield* authApi.delete(config.api.supplier, requestConfig);

        yield put(deleteSupplierSuccess(id));

        yield put(Modals.actions.closeModal(deleteModalIdFactory(id)));
    } catch (e) {
        Log.error(e);

        const supplierName = yield select(supplierNameSelector, id);
        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.supplier.api.delete',
                values: {
                    name: supplierName,
                },
            },
        });

        yield put(deleteSupplierFailure(id, errorMessage));
    }
}

export default function* deleteSupplierWatcher() {
    const actionTypes = {
        REQUEST: types.DELETE_SUPPLIER_REQUEST,
        cancelTask: deleteSupplierInvalidate,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, deleteSupplier);
}
