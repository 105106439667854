import { createSelector, ServiceKeysSelectors } from '../../dependencies';

import { selectExport } from './entities';

export const editExportFormInitialValuesSelector = createSelector(
    [selectExport, ServiceKeysSelectors.serviceKeySelector],
    ({ name, period } = {}, { service, credentials } = {}) => {
        return {
            name,
            service,
            period,
            credentials: {
                username: '',
                password: '',
                ...credentials,
            },
        };
    },
);
