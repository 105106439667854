import { config, authApi, takeLatestRequest, Log, sagaEffects, ErrorsUtils } from '../../dependencies';

import { types, updateCompanySuccess, updateCompanyFailure, updateCompanyCancel } from '../actions';

const { put } = sagaEffects;

function* updateCompany(action, cancelToken) {
    const { id } = action.meta;

    try {
        const { data } = yield* authApi.put(config.api.company, action.payload, {
            uriParams: { id },
            cancelToken,
        });

        yield put(updateCompanySuccess(id, data));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.companies.api.update',
            },
        });

        yield put(updateCompanyFailure(id, errorMessage));
    }
}

export default function* updateCompanyWatcher() {
    const actionTypes = {
        REQUEST: types.UPDATE_COMPANY_REQUEST,
        cancelTask: updateCompanyCancel,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, updateCompany);
}
