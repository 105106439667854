import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from './types';

export const createExportRequest = data => ({
    type: types.CREATE_EXPORT_REQUEST,
    payload: data,
});

export const createExportSuccess = data => ({
    type: types.CREATE_EXPORT_SUCCESS,
    payload: formatEntity({
        template: entitiesTemplates.export,
        entity: data,
    }),
});

export const createExportFailure = error => ({
    type: types.CREATE_EXPORT_FAILURE,
    error,
});

export const createExportCancel = () => ({
    type: types.CREATE_EXPORT_CANCEL,
});
