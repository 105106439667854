import { Consts } from '../../dependencies';
import types from './types';

export { types };

export const setPage = ({ entityKey, page, typeId = Consts.apiTypes.FETCH, itemId }) => ({
    type: types.SET_PAGE,
    meta: {
        entityKey,
        typeId,
        itemId,
    },
    payload: {
        page,
    },
});
