import types from './types';

export const duplicateExpenseRequest = expenseId => ({
    type: types.DUPLICATE_EXPENSE_REQUEST,
    payload: { expenseId },
});

export const duplicateExpenseSuccess = expenseId => ({
    type: types.DUPLICATE_EXPENSE_SUCCESS,
    payload: { expenseId },
});

export const duplicateExpenseFailure = expenseId => ({
    type: types.DUPLICATE_EXPENSE_FAILURE,
    payload: { expenseId },
});
