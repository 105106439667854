import types from './types';

export const createReportRequest = () => ({
    type: types.CREATE_REPORT_REQUEST,
});

export const createReportSuccess = (id, report) => ({
    type: types.CREATE_REPORT_SUCCESS,
    meta: { id },
    payload: report,
});

export const createReportFailure = error => ({
    type: types.CREATE_REPORT_FAILURE,
    error,
});

export const createReportInvalidate = () => ({
    type: types.CREATE_REPORT_CANCEL,
});

export const createReportReset = () => ({
    type: types.CREATE_REPORT_RESET,
});
