export default () => ({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 6rem',
    gridColumnGap: '0.5rem',

    '& > div:last-of-type': {
        justifySelf: 'end',
    },
    '& > div:last-of-type button': {
        marginLeft: '1.75rem',
    },
});
