import { sagaEffects, AUTH_SESSION_END, ReactGA } from '../../../dependencies';

import { Events, Timing } from '../../../config';

import { trackAuthSessionEnd } from '../../actions';
import { authSessionStartAtSelector } from '../../selectors';

const { takeEvery, put, select } = sagaEffects;

export default function*() {
    yield takeEvery(AUTH_SESSION_END, function*() {
        const authSessionStartAt = yield select(authSessionStartAtSelector);

        Timing.finishAuthSessionDurationMeasurement(authSessionStartAt);
        Events.logout();
        ReactGA.set({
            userId: null,
        });

        yield put(trackAuthSessionEnd());
    });
}
