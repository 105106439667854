import { React, useNoActiveCompanyId, Consts, push, useDispatch } from '../dependencies';

import { AuthRoutes } from '../config';

function useSettingsPage(redirect) {
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (redirect) {
            dispatch(push(Consts.routes.SETTINGS));
        }
        // eslint-disable-next-line
    }, [redirect]);
}

function useActiveRoutes(routes) {
    const hasNoActiveCompany = useNoActiveCompanyId();

    useSettingsPage(hasNoActiveCompany);

    return React.useMemo(() => {
        if (hasNoActiveCompany) {
            return routes.filter(route => !route.path || route.path === Consts.routes.SETTINGS);
        }

        return routes;
    }, [hasNoActiveCompany, routes]);
}

export function useAuthRoutes() {
    return useActiveRoutes(AuthRoutes);
}
