import {
    connect,
    compose,
    ReduxUtils,
    Consts,
    withErrorBoundary,
    withErrorMessage,
    fetchExpense,
} from '../dependencies';
import NormalExpenseEdit from '../components/NormalExpenseEdit';

const { EXPENSE } = Consts.entityKeys;
const { FETCH } = Consts.apiTypes;

const mapStateToProps = (state, { match }) => {
    const { expenseId } = match.params;
    const { error } = ReduxUtils.apiSelector(state, EXPENSE, FETCH, expenseId);

    return {
        error,
        expenseId,
    };
};

const mergeProps = (stateProps, dispatchProps, { fetchExpenseRequest }) => ({
    ...stateProps,
    onErrorMessageClick: fetchExpenseRequest,
});

export default compose(
    withErrorBoundary,
    fetchExpense,
    connect(
        mapStateToProps,
        null,
        mergeProps,
    ),
    withErrorMessage,
)(NormalExpenseEdit);
