import { ReduxUtils, Actions, combineReducers } from 'modules/reports/dependencies';

import byId from './byId';
import ids from './ids';
import temporary from './temporary';
import tags from './tags';
import newReport from './new';

const reportsReducer = combineReducers({
    // all fetched reports
    byId,

    // all fetched report IDs
    ids,

    // temporary reports
    temporary,

    // new report
    new: newReport,

    // tag visibility
    tags,
});

export default ReduxUtils.basicResetReducer(reportsReducer, Actions.types.RESET_ALL_REPORTS);
