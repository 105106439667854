import { connect, isToggledSelector, setToggle, Toggles } from '../dependencies';

import Header from '../components/Header';

const toggleId = Toggles.SEARCH_BAR;
const INIT_SHOW_SEARCH = false;

const mapStateToProps = state => {
    return {
        searchIsVisible: isToggledSelector(state, toggleId) || INIT_SHOW_SEARCH,
    };
};

const mapDispatchToProps = dispatch => ({
    showSearch() {
        dispatch(setToggle(toggleId, true));
    },
    hideSearch() {
        dispatch(setToggle(toggleId, false));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Header);
