import { React, FelaComponent, Link, FormattedMessage, PropTypes } from '../../dependencies';

import * as styles from './NavItem.styles';

const NavItem = ({ to, messageId, isActive }) => {
    return (
        <FelaComponent {...{ style: styles.navItem, isActive }}>
            {({ className }) => (
                <Link
                    {...{
                        to,
                        className,
                    }}
                >
                    <FormattedMessage id={messageId} />
                </Link>
            )}
        </FelaComponent>
    );
};

NavItem.propTypes = {
    to: PropTypes.string.isRequired,
    messageId: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
};

export default React.memo(NavItem);
