import { React, PropTypes, FormattedMessage } from '../../../../dependencies';

const ServiceNote = ({ styles, messages, url }) => {
    return (
        <p className={styles.serviceNote}>
            <FormattedMessage
                id={messages.note}
                values={{
                    br: <br />,
                    link: (
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            <FormattedMessage id={messages.link} />
                        </a>
                    ),
                }}
            />
        </p>
    );
};

ServiceNote.propTypes = {
    styles: PropTypes.shape().isRequired,
    messages: PropTypes.shape({
        note: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
};

export default ServiceNote;
