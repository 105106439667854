import { ReduxUtils, Consts, Actions } from '../../dependencies';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: Consts.entityKeys.EXPENSES,
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        typePrefix: 'FETCH_EXPENSES_',
    }),
    ...createTypes({
        typePrefix: 'FETCH_EXPENSE_',
    }),
    ...createTypes({
        typePrefix: 'CREATE_EXPENSE_',
    }),
    ...createTypes({
        typePrefix: 'UPDATE_EXPENSE_',
    }),
    ...createTypes({
        typePrefix: 'DELETE_EXPENSE_',
    }),
    ...createTypes({
        typePrefix: 'FETCH_NEXT_EXPENSE_NUMBER_',
    }),
    ...createTypes({
        typePrefix: 'DUPLICATE_EXPENSE_',
    }),
    ...createTypes({
        types: ['SAVE_EXPENSES'],
    }),
    FETCH_EXPENSE_SUCCESS: Actions.types.FETCH_EXPENSE_SUCCESS,
});
