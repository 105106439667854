import { combineReducers } from 'redux';
import { AUTH_SESSION_END } from '@ackee/petrus';
import * as ReduxUtils from '@ackee/redux-utils';

import { entityReducers as expenses } from 'modules/expenses';
import { entityReducer as tags } from 'modules/tags';
import { entityReducers as suppliers } from 'modules/suppliers';
import { entityReducers as reports } from 'modules/reports';
import { entityReducers as exports } from 'modules/exports';
import { entityReducers as dashboard } from 'modules/dashboard';
import { entityReducer as users } from 'modules/users';
import { entityReducers as serviceKeys } from 'modules/service-keys';
import { entityReducers as companies } from 'modules/companies';
import { entityReducers as memberships } from 'modules/memberships';
import { entityReducer as authUser } from 'modules/auth/modules/user';

const reducer = combineReducers({
    ...expenses,
    ...suppliers,
    ...reports,
    ...exports,
    ...dashboard,
    ...serviceKeys,
    ...companies,
    ...memberships,
    tags,
    users,
    authUser,
});

export default ReduxUtils.basicResetReducer(reducer, AUTH_SESSION_END);
