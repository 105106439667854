export const range = ({ theme: { colors } }) => ({
    color: colors.grey4,
    fontSize: '16px',
    lineHeight: 1.31,
    letterSpacing: '0.3px',
    marginBottom: 4,
    display: 'flex',
    alignItems: 'center',
});

export const divider = () => ({
    margin: '0 4px',
});
