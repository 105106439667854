export const nav = ({ theme: { colors } }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto) 1fr',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridColumnGap: '1rem',

    padding: '1.5rem 2rem',
    borderBottom: `1px solid ${colors.blueGrey1}`,
});

export const title = ({ theme: { colors } }) => ({
    color: colors.text,
    fontSize: '1.5rem',
    fontWeight: '700',
    letterSpacing: '0.3px',
    marginBottom: 0,
});

export const rightContent = () => ({
    marginLeft: 'auto',
    marginRight: 0,
});
