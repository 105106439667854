import { sagaEffects, ServiceKeysActions, messageActions, push, routePaths } from '../../../dependencies';

import { updateExportRequest, types } from '../../actions';

import { pickCredentials } from './utils';

const { put, takeLeading, take } = sagaEffects;

function* updateServiceKey(serviceKeyId, { name, service, credentials }) {
    yield put(
        ServiceKeysActions.updateServiceKeyRequest(serviceKeyId, {
            name,
            service,
            credentials: pickCredentials(service, credentials),
        }),
    );

    const { UPDATE_SERVICE_KEY_SUCCESS, UPDATE_SERVICE_KEY_FAILURE } = ServiceKeysActions.types;

    const result = yield take([UPDATE_SERVICE_KEY_SUCCESS, UPDATE_SERVICE_KEY_FAILURE]);

    switch (result.type) {
        case UPDATE_SERVICE_KEY_SUCCESS:
            break;
        case UPDATE_SERVICE_KEY_FAILURE:
            yield put(messageActions.displayErrorMessage(result.error));
            break;
        default:
    }
}

function* submitUpdateExportForm({ startSubmit, stopSubmit, reset, data, exportId, serviceKeyId }) {
    yield startSubmit();

    const { name, service, credentials, period } = data;

    yield updateServiceKey(serviceKeyId, {
        name,
        service,
        credentials,
    });

    yield put(
        updateExportRequest(exportId, {
            name,
            period,
            serviceKeyId,
        }),
    );

    const { UPDATE_EXPORT_SUCCESS, UPDATE_EXPORT_FAILURE } = types;

    const result = yield take([UPDATE_EXPORT_SUCCESS, UPDATE_EXPORT_FAILURE]);

    switch (result.type) {
        case UPDATE_EXPORT_SUCCESS:
            yield put(
                messageActions.displaySuccessMessage({
                    id: 'success.export.api.put',
                }),
            );
            yield put(
                push(
                    routePaths.EXPORT_RECURRING.replace(':exportId', result.payload.id).replace(
                        ':serviceKeyId',
                        serviceKeyId,
                    ),
                ),
            );
            yield reset();
            break;

        case UPDATE_EXPORT_FAILURE:
            yield put(messageActions.displayErrorMessage(result.error));
            break;

        default:
    }

    yield stopSubmit();
}

export default function* () {
    yield takeLeading(types.SUBMIT_UPDATE_EXPORT_FORM, submitUpdateExportForm);
}
