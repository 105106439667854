import { React, PropTypes, childrenPropType, connectFela } from '../../dependencies';

import { cardContent } from './Card.styles';

function Card({ children, styles }) {
    return <div className={styles.cardContent}>{children}</div>;
}

Card.propTypes = {
    children: childrenPropType.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default connectFela({
    cardContent,
})(Card);
