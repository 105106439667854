export const listItem = ({ theme: { colors } }) => ({
    padding: '15px 0 13px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(4, 1fr) 100px',
    gridColumnGap: 8,
    margin: '0 30px',
    borderBottom: `1px solid ${colors.blueGrey1}`,
    cursor: 'pointer',
});
