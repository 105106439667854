import {
    React,
    PropTypes,
    connectFela,
    Icons,
    childrenPropType,
    ButtonIcon,
    FormattedMessage,
} from '../../dependencies';

import Title from '../Title';

import * as Styles from './Nav.styles';

function Nav({ goBack, titleProps, children, styles, authorFullName }) {
    return (
        <div className={styles.nav}>
            <div className={styles.navLeft}>
                <ButtonIcon onClick={goBack}>{icon => <Icons.ArrowLeft className={icon.className} />}</ButtonIcon>

                <div>
                    <Title {...titleProps} />
                    {authorFullName && (
                        <span className={styles.subtitle}>
                            <FormattedMessage
                                id="expense.detail.subtitle"
                                values={{
                                    author: authorFullName,
                                }}
                            />
                        </span>
                    )}
                </div>
            </div>

            {children}
        </div>
    );
}

Nav.propTypes = {
    goBack: PropTypes.func.isRequired,
    titleProps: PropTypes.shape({
        messageId: PropTypes.string.isRequired,
    }).isRequired,
    authorFullName: PropTypes.string,
    children: childrenPropType.isRequired,
    styles: PropTypes.shape().isRequired,
};

Nav.defaultProps = {
    authorFullName: null,
};

export default connectFela(Styles)(Nav);
