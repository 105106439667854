import { React, Container, Page } from '../../dependencies';

import SearchHeader from '../../containers/SearchHeader';
import SearchResults from '../SearchResults';

function Search() {
    return (
        <Container>
            <Page>
                <SearchHeader />

                <SearchResults />
            </Page>
        </Container>
    );
}

export default Search;
