import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const actionTypes = {
    REQUEST: types.CREATE_REPORT_REQUEST,
    SUCCESS: types.CREATE_REPORT_SUCCESS,
    FAILURE: types.CREATE_REPORT_FAILURE,
    CANCEL: types.CREATE_REPORT_CANCEL,
    RESET: types.CREATE_REPORT_RESET,
};

export default ReduxUtils.basicApiReducer({
    actionTypes,
});
