import { createSelector } from '../../../dependencies';
import { Roles } from '../../../constants';

import { activeMembershipSelector } from './general';

export const activeMembershipRoleSelector = createSelector(activeMembershipSelector, m => m?.role);

export const isOwnerSelector = createSelector(activeMembershipRoleSelector, role => role === Roles.OWNER);

export const isAccountantSelector = createSelector(activeMembershipRoleSelector, role => role === Roles.ACCOUNTANT);

export const isContributorSelector = createSelector(activeMembershipRoleSelector, role => role === Roles.CONTRIBUTOR);

export const isNotOwnerSelector = createSelector(activeMembershipRoleSelector, role => role !== Roles.OWNER);
