import {
    React,
    PropTypes,
    connectFela,
    TooltipWrapper,
    PriceTooltip,
    FormattedNumber,
    config,
    FormattedMessage,
    useSelector,
} from 'modules/reports/dependencies';

import { reportTagsTotalSelector } from 'modules/reports/services/selectors';

import * as Styles from './Total.styles';

function Total({ styles }) {
    const { withVat, withoutVat, currency } = useSelector(reportTagsTotalSelector);
    const title = <PriceTooltip priceWithVat={withVat} currency={currency} />;

    return (
        <div className={styles.footer}>
            <span className={styles.label}>
                <FormattedMessage id="report.chart.expenses.yourExpenses" />
            </span>

            <TooltipWrapper title={title}>
                <span className={styles.value}>
                    {withoutVat === null ? (
                        '-'
                    ) : (
                        <FormattedNumber
                            {...{
                                ...config.currency,
                                style: 'currency',
                                value: withoutVat,
                                currency,
                            }}
                        />
                    )}
                </span>
            </TooltipWrapper>
        </div>
    );
}

Total.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(Total);
