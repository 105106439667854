import {
    reduxForm,
    connect,
    compose,
    config,
    Loader,
    loadable,
    Form,
    injectIntl,
    getValidate,
    ExpensesConsts,
    ExpensesSelectors,
    goBack,
} from '../dependencies';

import { isFormReadySelector, isRecurringFormReadySelector } from '../services/selectors';
import { editExpenseFormSubmitRequest } from '../services/actions';

const formId = config.forms.updateExpense;

const mapStateToProps = (state, { expenseId, expenseTriggerId }) => {
    const recurring = Boolean(expenseTriggerId);
    const showLoader = recurring
        ? !isRecurringFormReadySelector(state, { expenseId, expenseTriggerId })
        : !isFormReadySelector(state, expenseId);
    const initialValues = ExpensesSelectors.initialValuesSelector(state, {
        recurring,
        expenseId,
        expenseTriggerId,
        showLoader,
    });
    const params = {
        recurring,
        statistic: initialValues.type === ExpensesConsts.types.STATISTIC,
    };

    return {
        initialValues,
        validate: getValidate(params),
        showLoader,
        params,
    };
};

const mapDispatchToProps = (dispatch, { expenseId }) => ({
    onSubmit() {
        dispatch(editExpenseFormSubmitRequest(expenseId, formId));
    },
    goBack() {
        dispatch(goBack());
    },
});

const mergeProps = (stateProps, dispatchProps, { intl, Summary, RoundingTotal, expenseId }) => {
    return {
        ...stateProps,
        ...dispatchProps,
        intl,
        Summary,
        RoundingTotal,
        expenseId,
    };
};

const formConfig = {
    form: formId,
    enableReinitialize: true,
};

export default compose(
    injectIntl,
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps,
    ),
    loadable(Loader),
    reduxForm(formConfig),
)(Form);
