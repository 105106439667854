export const tooltip = () => ({
    padding: '12px 16px 10px',
});

export const title = ({ theme: { colors } }) => ({
    lineHeight: 1.36,
    letterSpacing: '0.3px',
    fontSize: '0.875rem',
    color: colors.primaryLight1,
    marginBottom: 2,
});

export const value = ({ theme: { colors } }) => ({
    color: colors.text,
    lineHeight: 1.56,
    letterSpacing: '0.15px',
    fontSize: '1rem',
});
