import { config, authApi, Log, sagaEffects, takeRequest, Normalizers, ErrorsUtils } from '../../dependencies';

import { fetchExportsSuccess, fetchExportsFailure, types } from '../actions';

const { put } = sagaEffects;

function* fetchExports(action, cancelToken) {
    try {
        const requestConfig = {
            params: { limit: Number.MAX_SAFE_INTEGER },
            cancelToken,
        };

        const { data } = yield* authApi.get(config.api.exports, requestConfig);

        const { result: ids, entities } = Normalizers.recurringExports(data);

        yield put(
            fetchExportsSuccess({
                byId: entities.exports,
                ids,
            }),
        );
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.exports.api.get',
            },
        });

        yield put(fetchExportsFailure(errorMessage));
    }
}

export default function* fetchSuppliersWatcher() {
    const actionTypes = {
        REQUEST: types.FETCH_EXPORTS_REQUEST,
        CANCEL: types.FETCH_EXPORTS_CANCEL,
    };

    yield takeRequest(actionTypes, fetchExports);
}
