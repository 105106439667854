export const file = ({ theme: { colors, dimensions }, iconsCount }) => ({
    backgroundColor: colors.grey1,
    maxWidth: dimensions.inputMaxWidth,
    width: '100%',
    borderRadius: 2,
    padding: '20px 16px',
    marginBottom: 8,

    display: 'grid',
    gridTemplateColumns: `20px 1fr repeat(${iconsCount}, 20px)`,
    gridColumnGap: '16px',
    alignItems: 'center',
});

export const name = ({ theme: { colors } }) => ({
    color: colors.text,
    lineHeight: 0.94,
    fontSize: 16,
    fontWeight: 700,
    textDecoration: 'underline',
});

const icon = {
    width: 20,
    height: 20,
};

export const documentIcon = () => ({
    ...icon,
});

export const downloadIcon = () => ({
    ...icon,
});

export const removeIcon = ({ theme: { colors } }) => ({
    ...icon,
    fill: colors.primary,
});
