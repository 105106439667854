import { connect, injectIntl, compose } from '../../dependencies';

import MonthsCharts from '../../components/Charts/MonthsChart';

import { dashboardMonthsChartSelector } from '../../services/selectors';

const mapStateToProps = dashboardMonthsChartSelector;

export default compose(
    injectIntl,
    connect(mapStateToProps),
)(MonthsCharts);
