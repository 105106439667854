import types from './types';

export const fetchDisposableExportRequest = () => ({
    type: types.FETCH_DISPOSABLE_EXPORT_REQUEST,
});

export const fetchDisposableExportSuccess = () => ({
    type: types.FETCH_DISPOSABLE_EXPORT_SUCCESS,
});

export const fetchDisposableExportFailure = error => ({
    type: types.FETCH_DISPOSABLE_EXPORT_FAILURE,
    error,
});

export const fetchDisposableExportInvalidate = () => ({
    type: types.FETCH_DISPOSABLE_EXPORT_CANCEL,
});
