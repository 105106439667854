export const dropdownHeader = ({ theme: { colors } }) => ({
    padding: '5px 6px',
    borderBottom: `1px solid ${colors.blueGrey1}`,
});

export const wrapper = ({ theme: { zIndex } }) => ({
    marginTop: 12,
    position: 'relative',
    zIndex: zIndex.addTagDropdownOverlay,
});

export const divider = ({ theme: { colors } }) => ({
    borderBottom: `1px solid ${colors.blueGrey2}`,
    margin: '0 8px 8px 8px',
});

export const overlay = () => ({
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
});

export const inputWrapper = () => ({
    '& .ant-form-item-control': {
        minWidth: 210,
    },

    '& .ant-select-selection': {
        minWidth: 210,
    },
});
