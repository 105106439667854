import {
    React,
    PropTypes,
    TooltipWrapper,
    PriceTooltip,
    FormattedNumber,
    config,
    connectFela,
    useSelector,
} from 'modules/reports/dependencies';

import { barChartTotalSumSelector } from 'modules/reports/services/selectors';

import * as Styles from './TotalSum.styles';

function TotalSum({ styles }) {
    const { withVat, withoutVat, currency } = useSelector(barChartTotalSumSelector);

    const title = <PriceTooltip priceWithVat={withVat} currency={currency} />;

    return (
        <div className={styles.wrapper}>
            <TooltipWrapper title={title}>
                <div className={styles.total}>
                    <FormattedNumber
                        {...{
                            value: withoutVat,
                            style: 'currency',
                            currency,
                            ...config.currency,
                        }}
                    />
                </div>
            </TooltipWrapper>
        </div>
    );
}

TotalSum.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(TotalSum);
