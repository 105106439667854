import { Utils } from '../../dependencies';

const { InputValidation } = Utils;
const { getFieldsErrors, validatorsWithMessage, translate } = InputValidation;
const { isRequired } = validatorsWithMessage;

const validators = {
    name: [isRequired],
    country: [isRequired],
    currency: [isRequired],
};

export default function validate(values, { intl }) {
    const errors = getFieldsErrors(validators, values);

    return translate(intl.formatMessage, errors);
}
