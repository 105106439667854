export const form = ({ theme: { dimensions } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: dimensions.inputRowGap,

    '& .ant-btn-primary': {
        display: 'none',
    },
});

export const inputSmall = () => ({
    width: 260,
    maxWidth: '100%',
});
