import { merge } from 'lodash';
import { DateRanges, currency as Currency, CountryCode } from 'constants/index';
import { strictObjectAccess } from '@ackee/redux-utils';

const { BUILD_ENV, REACT_APP_NAME, REACT_APP_BUILD_ENV } = process.env;

// eslint-disable-next-line
const envConfig = require(`./config.${REACT_APP_BUILD_ENV ?? BUILD_ENV}.js`).default;

const defaults = {
    appName: REACT_APP_NAME,
    ares: {
        base: 'https://ares.gov.cz/ekonomicke-subjekty-v-be/rest',
        economicalSubjects: '/ekonomicke-subjekty/:ico',
    },
    api: {
        // auth
        signIn: '/v1/auth/sign-in',
        signUp: '/v1/auth/sign-up',
        refresh: '/v1/auth/refresh',

        // users
        user: '/v1/users/:id',

        // expenses
        expenses: '/v1/expenses',
        expense: '/v1/expenses/:id',
        nextExpenseNumber: '/v1/expenses/next',

        // expense triggers
        expenseTriggers: '/v1/expense-triggers',
        expenseTrigger: '/v1/expense-triggers/:id',

        // tags
        tags: '/v1/tags',
        tag: '/v1/tags/:id',

        // suppliers
        suppliers: '/v1/suppliers',
        supplier: '/v1/suppliers/:id',

        // files
        files: '/v1/files',
        file: '/v1/files/:id',

        // ares
        ares: '/v1/proxy/ares',

        // dashboard
        dashboard: '/v1/dashboard',

        // reports
        reports: '/v1/reports',
        report: '/v1/reports/:id',

        // exports
        exportFiles: '/v1/export-files',
        exports: '/v1/exports',
        export: '/v1/exports/:id',

        // service keys
        serviceKey: '/v1/service-keys/:id',
        serviceKeys: '/v1/service-keys',

        // companies
        companies: '/v1/companies',
        company: '/v1/companies/:id',

        // memberships
        memberships: '/v1/memberships',
        membership: '/v1/memberships/:id',
    },
    date: 'DD. MM. YYYY',
    forms: {
        login: 'login',
        signUp: 'signUp',
        createExpense: 'createExpense',
        updateExpense: 'updateExpense',
        tags: 'tags',
        expensesFilters: 'expensesFilters',
        report: 'report',
        createExpenseToolbox: 'createExpenseToolbox',
        disposableExport: 'disposableExport',
        searchBar: 'searchBar',
        createExport: 'createExport',
        updateExport: 'updateExport',
    },
    currency: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    },
    numberInput: {
        decimalSeparator: ',',
    },
    filters: {
        defaultInitials: {
            // NOTE: this value is going to be used only if filter params aren't loaded from anywhere else (e.g. URL or any other persistent memory)
            expenses: {
                from: null,
                to: null,
                relativePeriod: DateRanges.ALL_THE_TIME,
            },
            reports: {
                from: null,
                to: null,
                relativePeriod: DateRanges.THIS_YEAR,
            },
            exports: {
                from: null,
                to: null,
                relativePeriod: DateRanges.THIS_YEAR,
            },
            dateRangeField: 'issuedOn',
            orderBy: 'createdAt',
            order: 'desc',

            markAsSynced: true,
        },

        // On FE it behaves as relative period (date picker shows for example "This year" instead of numerical date range value),
        // but when a API request is created, such a `relativePeriod` is converted to `from` and `to` date values.
        fakeRelativePeriods: [DateRanges.ALL_THE_TIME],

        dateFieldOptions: {
            issuedOn: true,
            createdAt: true,
            receivedOn: true,
        },
    },
    links: {
        serviceKey: {
            fakturoid: 'https://www.fakturoid.cz/podpora/automatizace/api',
            flexibee: 'https://my.flexibee.eu/my/',
        },
    },
    companyFormInitialValues: {
        currency: Currency.CZK,
        country: CountryCode.CZ,
    },
};

const config = merge(defaults, envConfig);

export default strictObjectAccess({
    ...config,
    api: strictObjectAccess(config.api),
});
