import { strictObjectAccess } from '@ackee/redux-utils';

// type IDs for API reducer categories
// TODO: api types should be equal to HTTP methods (e.g. PUT, POST, etc.)
export const apiTypes = strictObjectAccess({
    FETCH: 'fetch',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
});
