import types from './types';

export const deleteExpenseRequest = id => ({
    type: types.DELETE_EXPENSE_REQUEST,
    meta: { id },
});

export const deleteExpenseSuccess = id => ({
    type: types.DELETE_EXPENSE_SUCCESS,
    meta: { id },
});

export const deleteExpenseFailure = (id, error) => ({
    type: types.DELETE_EXPENSE_FAILURE,
    meta: { id },
    error,
});

export const deleteExpenseInvalidate = id => ({
    type: types.DELETE_EXPENSE_CANCEL,
    meta: { id },
});
