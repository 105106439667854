import { ReduxUtils } from '../dependencies';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: 'modals',
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        types: ['OPEN_MODAL', 'CLOSE_MODAL'],
    }),
});
