import { ReactGA } from '../dependencies';

import { Categories } from '../constants';

export const login = () =>
    ReactGA.event({
        category: Categories.AUTHENTICATION,
        action: `login`,
        transport: 'xhr',
    });

export const logout = () =>
    ReactGA.event({
        category: Categories.AUTHENTICATION,
        action: `logout`,
        transport: 'xhr',
    });

export const userLeft = () => {
    ReactGA.event({
        category: Categories.VISIT,
        action: 'page closed',
        transport: 'beacon',
    });
};
