export const row = () => ({
    display: 'grid',
    gridTemplateColumns: '1rem auto',
    gridColumnGap: '0.5rem',
    alignItems: 'center',
});

export const label = ({ theme: { colors } }) => ({
    color: colors.text,
    fontSize: '1rem',
    lineHeight: 1.56,
    letterSpacing: '0.15px',
});
