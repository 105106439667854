export const wrap = () => ({
    display: 'flex',
    flexWrap: 'wrap',
});

export const counter = ({ theme: { colors } }) => ({
    color: colors.text,
    letterSpacing: '0.1px',
    lineHeight: 1.64,
    fontSize: '14px',
    textDecoration: 'underline',
});
