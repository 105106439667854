export const getMonthIndex = (date = Date.now()) => new Date(date).getMonth();
export const getYear = (date = Date.now()) => new Date(date).getFullYear();

export const findCurrentMonth = (months = []) => {
    const currentMonthIndex = getMonthIndex();

    const result = months.find(({ date }) => {
        const monthIndex = getMonthIndex(date);

        return monthIndex === currentMonthIndex;
    });

    if (!result) {
        return null;
    }

    const { withVat, withoutVat } = result;

    return {
        withVat,
        withoutVat,
    };
};

export const findCurrentQuarter = (quarters = []) => {
    const pastQuartersCount = Math.floor((getMonthIndex() + 1) / 3);
    const currentYear = getYear();
    const currentQuarterStartedAt = Date.parse(new Date(currentYear, pastQuartersCount * 3));

    const result = quarters.find(quarter => {
        return Date.parse(quarter.date) >= currentQuarterStartedAt;
    });

    if (!result) {
        return null;
    }

    const { withVat, withoutVat } = result;

    return {
        withVat,
        withoutVat,
    };
};

export const calcBarHeight = (maxValue, value) => {
    const normalizedHeight = value / maxValue;

    return Math.round(normalizedHeight * 100) / 100;
};

export const getTotalSums = (bucketItems = []) => {
    const withVat = bucketItems.reduce((total, item) => total + item.withVat, 0);
    const withoutVat = bucketItems.reduce((total, item) => total + item.withoutVat, 0);

    return {
        withVat,
        withoutVat,
    };
};

function ignoreBucketsWithFutureDate(bucketItems = []) {
    const now = Date.now();

    return bucketItems.filter(item => Date.parse(item.date) <= now);
}

export function calcAverage(slicedBucketItems = []) {
    const bucketItems = ignoreBucketsWithFutureDate(slicedBucketItems);

    const totalWithoutVat = bucketItems.reduce((acc, item) => acc + item.withoutVat, 0);

    const withoutVatItems = bucketItems.map(item => item.withoutVat);
    const maxWithoutVat = Math.max(...withoutVatItems);

    const averageWithoutVat = totalWithoutVat / bucketItems.length;

    return {
        bottomRelativeOffset: averageWithoutVat / maxWithoutVat,
        value: averageWithoutVat,
    };
}

export function calcSegmentsOpacity(data = []) {
    const totalWithoutVat = data.reduce((acc, item) => acc + item.withoutVat, 0);
    const maxWithoutVat = Math.max(...data.map(item => item.withoutVat));

    const normalizedMaxWithoutVat = maxWithoutVat / totalWithoutVat;

    return data.map(item => ({
        ...item,
        opacity: item.value / normalizedMaxWithoutVat,
    }));
}
