import { ReduxUtils } from '../../dependencies';

const asyncType = ReduxUtils.createAsyncType({
    modulePrefix: 'dashboard',
});

export default ReduxUtils.strictObjectAccess({
    ...asyncType({
        typePrefix: 'FETCH_DASHBOARD_',
    }),
});
