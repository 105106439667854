import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect as connectFela } from 'react-fela';

import * as Styles from './NoData.styles';

function NoData({ styles }) {
    return (
        <div className={styles.noData}>
            <FormattedMessage id="noData" />
        </div>
    );
}

NoData.propTypes = {
    // eslint-disable-next-line
    padding: PropTypes.string,
};

NoData.defaultProps = {
    padding: '120px 0',
};

export default connectFela(Styles)(NoData);
