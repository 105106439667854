import { compose, connect, reduxForm, Tags, config, ReduxUtils, Consts } from '../dependencies';

import { expenseTagsSelector } from '../services/selectors';
import { expenseTagAdd, expenseTagRemove } from '../services/actions';

const { UPDATE } = Consts.apiTypes;
const { EXPENSE } = Consts.entityKeys;
const formId = config.forms.tags;

const mapStateToProps = (state, { expenseId }) => {
    const { inProgress } = ReduxUtils.apiSelector(state, EXPENSE, UPDATE, expenseId);

    return {
        initialValues: {
            tags: expenseTagsSelector(state, expenseId),
        },
        formId,
        disabled: inProgress,
        buttonNewTag: true,
    };
};

const mapDispatchToProps = (dispatch, { expenseId }) => ({
    onAdd(tag) {
        dispatch(expenseTagAdd(expenseId, tag));
    },
    onRemove(tag) {
        dispatch(expenseTagRemove(expenseId, tag));
    },
});

const formConfig = {
    form: formId,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    reduxForm(formConfig),
)(Tags.default);
