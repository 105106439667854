import { shortid, Storage } from '../../../dependencies';
import { DATA_ANALYSIS_USER_ID_KEY } from '../../../config';

export default class UserId {
    static *get() {
        const userId = yield Storage.get(DATA_ANALYSIS_USER_ID_KEY);

        if (userId) {
            return userId;
        }

        const newUserId = shortid.generate();

        yield Storage.set(DATA_ANALYSIS_USER_ID_KEY, newUserId);

        return newUserId;
    }

    static *remove() {
        yield Storage.remove(DATA_ANALYSIS_USER_ID_KEY);
    }
}
