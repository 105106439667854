export const button = ({ disabled }) => ({
    padding: '2px 13px',
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
});

export const icon = ({ theme: { colors }, direction }) => {
    return {
        height: 20,
        width: 11,
        fill: colors.text,
        transform: direction === 'right' ? 'rotateZ(-180deg)' : 'unset',
    };
};
