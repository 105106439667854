import { config, authApi, takeLatestRequest, Log, sagaEffects, Modals, ErrorsUtils } from '../../dependencies';

import { updateModalIdFactory } from '../../utils';
import { updateTagSuccess, updateTagFailure, updateTagInvalidate } from '../actions';
import types from '../actionTypes';

const { put } = sagaEffects;

function* updateTag(action, cancelToken) {
    const { meta, data: payload, startSubmit, stopSubmit } = action;

    try {
        yield startSubmit();

        const requestConfig = {
            uriParams: { id: meta.id },
            cancelToken,
        };

        const { data: tag } = yield* authApi.put(config.api.tag, payload, requestConfig);

        yield put(updateTagSuccess(meta.id, tag.name, tag));
        yield put(Modals.actions.closeModal(updateModalIdFactory(meta.id)));

        yield stopSubmit();
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.tag.api.update',
            },
        });

        yield put(updateTagFailure(meta.id, errorMessage));

        yield stopSubmit({
            _error: errorMessage,
        });
    }
}

export default function* updateTagWatcher() {
    const actionTypes = {
        REQUEST: types.UPDATE_TAG_REQUEST,
        cancelTask: updateTagInvalidate,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, updateTag);
}
