import { sagaEffects, ModalsActions, Consts, MessagesActions } from '../../../dependencies';

import { types, createCompanyRequest } from '../../actions';

const { put, takeEvery, take } = sagaEffects;

function* submitCreateCompanyForm({ startSubmit, stopSubmit, reset, data }) {
    yield startSubmit();

    yield put(createCompanyRequest(data));

    const { CREATE_COMPANY_SUCCESS, CREATE_COMPANY_FAILURE } = types;

    const result = yield take([CREATE_COMPANY_SUCCESS, CREATE_COMPANY_FAILURE]);

    switch (result.type) {
        case CREATE_COMPANY_SUCCESS:
            yield stopSubmit();
            yield reset();
            yield put(ModalsActions.closeModal(Consts.modals.CREATE_COMPANY));
            yield put(
                MessagesActions.displaySuccessMessage({
                    id: 'success.company.create',
                }),
            );
            break;

        case CREATE_COMPANY_FAILURE:
            yield stopSubmit({
                _error: result.error,
            });
            break;

        default:
    }
}

export default function* createCompanyWatcher() {
    yield takeEvery(types.SUBMIT_CREATE_COMPANY_FORM, submitCreateCompanyForm);
}
