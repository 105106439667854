import types from './types';

export const updateServiceKeyRequest = (id, data) => ({
    type: types.UPDATE_SERVICE_KEY_REQUEST,
    meta: { id },
    payload: data,
});

export const updateServiceKeySuccess = (id, payload) => ({
    type: types.UPDATE_SERVICE_KEY_SUCCESS,
    meta: { id },
    payload,
});

export const updateServiceKeyFailure = (id, error) => ({
    type: types.UPDATE_SERVICE_KEY_FAILURE,
    meta: { id },
    error,
});

export const updateServiceKeyInvalidate = id => ({
    type: types.UPDATE_SERVICE_KEY_CANCEL,
    meta: { id },
});
