import {
    React,
    Container,
    ExpensesList,
    ButtonDefault,
    Link,
    FormattedMessage,
    FelaComponent,
    config,
    Consts,
} from '../dependencies';

import Charts from '../containers/Charts';

import * as styles from './Dashboard.styles';

const { ListWrapper, ListHeader } = ExpensesList;

function Dashboard() {
    return (
        <Container>
            <Charts />

            <ListWrapper>
                <ListHeader hideFilters staticDateField={config.filters.defaultInitials.orderBy} />

                <ExpensesList.List groupId={Consts.expensesGroups.DASHBOARD} />

                <FelaComponent style={styles.button}>
                    <Link to="/expenses">
                        <ButtonDefault size="small">
                            <FormattedMessage id="dashboard.expenses.all.button" />
                        </ButtonDefault>
                    </Link>
                </FelaComponent>
            </ListWrapper>
        </Container>
    );
}

export default Dashboard;
