import React from 'react';

const Update = props => (
    <svg viewBox="0 0 20 21" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <path d="M15.839,2.23820962e-13 L6,10.004 L6,14.107 L9.842,14.107 L20,4.138 L15.839,2.23820962e-13 Z M-2.02948769e-13,20.108 L20,20.108 L20,18.108 L-2.02948769e-13,18.108 L-2.02948769e-13,20.108 Z" />
        </g>
    </svg>
);

export default Update;
