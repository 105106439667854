import {
    React,
    PropTypes,
    Form as ReduxForm,
    Tags,
    Space,
    errorPropType,
    AlertSuccess,
    Currency,
} from '../../dependencies';

import {
    IssuedOn,
    ReceivedOn,
    TaxableFulfillmentDue,
    DueOn,
    ExpenseNumber,
    ExpenseOriginalNumber,
    VariableSymbol,
    VatMode,
    DocumentType,
    TaxDeductible,
    PaymentMethod,
    Items,
    ExternalLink,
    Note,
    Name,
    Period,
    StartAt,
    EndAt,
} from '../Fields';

import Attachment from '../../containers/Attachment';
import Supplier from '../../containers/Supplier';

import Divider from '../Divider';
import ShowMore from '../ShowMore';
import Row from '../Row';
import Grid from '../Grid';
import Footer from '../Footer';
import DatesWrapper from '../DatesWrapper';
import Body from '../Body';

function Form({
    expenseId,
    goBack,
    handleSubmit,
    submitting,
    error,
    Summary,
    RoundingTotal,
    form,
    params: { recurring, statistic },
    intl,
}) {
    return (
        <ReduxForm onSubmit={handleSubmit}>
            <Body>
                {expenseId && <AlertSuccess expenseId={expenseId} />}

                {recurring && (
                    <>
                        <Name disabled={submitting} />

                        <Space bottom={16} />

                        <StartAt disabled={submitting} />

                        <Space bottom={16} />

                        <EndAt disabled={submitting} />

                        <Space bottom={24} />

                        <Period disabled={submitting} />

                        <Space bottom={24} />
                    </>
                )}

                <Supplier disabled={submitting} />

                <Row marginBottom={24}>
                    <Tags.default disabled={submitting} formId={form} buttonNewTag />
                </Row>

                <Grid marginBottom={0}>
                    {!recurring && <ExpenseNumber disabled={submitting} />}
                    {!statistic && <ExpenseOriginalNumber disabled={submitting} />}
                </Grid>

                {!recurring && (
                    <DatesWrapper>
                        {statistic && <IssuedOn disabled={submitting} labelId="expense.form.date" />}
                        {!statistic && (
                            <>
                                <IssuedOn disabled={submitting} />
                                <ReceivedOn disabled={submitting} />
                                <TaxableFulfillmentDue disabled={submitting} />
                                <DueOn disabled={submitting} />
                            </>
                        )}
                    </DatesWrapper>
                )}

                <Divider />

                {!statistic && (
                    <>
                        <DocumentType disabled={submitting} />

                        <Space bottom={38} />

                        <PaymentMethod disabled={submitting} />

                        <Space bottom={38} />

                        <Currency formId={form} />

                        <ShowMore>
                            <Grid>
                                <VariableSymbol disabled={submitting} />
                            </Grid>

                            <Space bottom={38} />

                            <Row>
                                <VatMode disabled={submitting} />
                                <TaxDeductible formatMessage={intl.formatMessage} />
                            </Row>
                        </ShowMore>
                    </>
                )}

                {statistic && (
                    <>
                        <Row>
                            <VatMode disabled={submitting} />
                            <Currency formId={form} />
                        </Row>

                        <Divider />
                    </>
                )}

                <Items disabled={submitting} />

                <Summary />

                <RoundingTotal />

                <Divider />

                {!statistic && <Attachment disabled={submitting} formId={form} />}

                {!statistic && <ExternalLink disabled={submitting} />}

                {statistic && <Note disabled={submitting} />}
            </Body>

            <Footer {...{ goBack, submitting, error }} />
        </ReduxForm>
    );
}

Form.propTypes = {
    expenseId: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    error: errorPropType,
    submitting: PropTypes.bool.isRequired,
    Summary: PropTypes.shape().isRequired,
    RoundingTotal: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    goBack: PropTypes.func.isRequired,
    params: PropTypes.shape({
        recurring: PropTypes.bool.isRequired,
        statistic: PropTypes.bool.isRequired,
    }).isRequired,
    intl: PropTypes.shape().isRequired,
};

Form.defaultProps = {
    error: null,
    expenseId: null,
};

export default Form;
