import { AuthActions } from '../../dependencies';
import { types } from '../actions';

const initState = {
    authSessionStartAt: null,
    permissionGiven: null,
};

export default function googleAnalyticsReducer(state = initState, action) {
    switch (action.type) {
        case AuthActions.types.SET_DATA_ANALYSIS_PERSMISSION:
            return {
                ...state,
                permissionGiven: action.payload,
            };

        case types.TRACK_AUTH_SESSION_START:
            return {
                ...state,
                authSessionStartAt: action.payload,
            };

        case types.TRACK_AUTH_SESSION_END:
            return {
                ...state,
                authSessionStartAt: null,
            };

        default:
            return state;
    }
}
