export const buttonLink = () => ({
    display: 'flex',
    justifyContent: 'center',
});

export const icon = ({ theme: { colors } }) => ({
    fill: colors.white,
    height: 14,
    marginRight: 10,
});
