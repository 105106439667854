import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const actionTypes = {
    REQUEST: types.UPDATE_USER_REQUEST,
    SUCCESS: types.UPDATE_USER_SUCCESS,
    FAILURE: types.UPDATE_USER_FAILURE,
    CANCEL: types.UPDATE_USER_CANCEL,
};

export default ReduxUtils.basicApiReducer({
    actionTypes,
});
