export const button = ({ theme: { colors, transitions } }) => {
    const active = {
        backgroundColor: colors.primarySelection,
    };

    return {
        height: 40,
        width: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        transition: `background-color ${transitions.button}`,

        '&:hover': active,
        '&:focus': active,
        '&:active': {
            backgroundColor: colors.primary,
        },
    };
};

export const icon = ({ theme: { colors } }) => {
    return {
        height: 20,
        fill: colors.text,
    };
};
