import { createSelector, ActiveCompanySelectors } from '../../../dependencies';
import { trasformReportFiltersToApiFilters } from '../../../utils';
import { selectTempReport, selectReport, hiddenTagsSelector } from '../entities';

export const updateReportDataSelector = createSelector(
    [selectReport, selectTempReport, ActiveCompanySelectors.activeCompanyIdSelector, hiddenTagsSelector],
    (report, tempReport, companyId, hiddenTags) => {
        return {
            filters: trasformReportFiltersToApiFilters(tempReport ? tempReport.filters : report.filters),
            hiddenTags,
            companyId,
        };
    },
);
