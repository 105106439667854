import { ReduxUtils } from '../../dependencies';

import { MODULE_NAME } from '../../constants';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: MODULE_NAME,
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        typePrefix: 'CREATE_COMPANY_',
    }),
    ...createTypes({
        typePrefix: 'UPDATE_COMPANY_',
    }),
    ...createTypes({
        typePrefix: 'DELETE_COMPANY_',
    }),
    ...createTypes({
        types: ['SUBMIT_EDIT_COMPANY_FORM', 'SUBMIT_CREATE_COMPANY_FORM'],
    }),
});
