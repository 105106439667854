import { ServiceKeysConsts, pick } from '../../../../dependencies';

export function pickCredentials(service, credentials) {
    const { FAKTUROID, FLEXIBEE } = ServiceKeysConsts.Service;

    switch (service) {
        case FAKTUROID:
            return pick(credentials, ['email', 'accountSlug', 'apiToken']);

        case FLEXIBEE:
            return pick(credentials, ['username', 'password', 'accountSlug', 'company']);

        default:
            return null;
    }
}
