import { React, PropTypes, FelaComponent, FormattedMessage } from '../../dependencies';

import * as styles from './Label.styles';

function Label({ messageId }) {
    return (
        <FelaComponent style={styles.label}>
            <FormattedMessage id={messageId} />
        </FelaComponent>
    );
}

Label.propTypes = {
    messageId: PropTypes.string.isRequired,
};

export default Label;
