import { strictObjectAccess } from '@ackee/redux-utils';

export const currency = strictObjectAccess({
    CZK: 'CZK',
    USD: 'USD',
    EUR: 'EUR',
    GBP: 'GBP',
    AUD: 'AUD',
    BGN: 'BGN',
    BRL: 'BRL',
    CAD: 'CAD',
    CHF: 'CHF',
    CNY: 'CNY',
    DKK: 'DKK',
    HKD: 'HKD',
    HRK: 'HRK',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    JPY: 'JPY',
    KWD: 'KWD',
    MXN: 'MXN',
    MYR: 'MYR',
    PLN: 'PLN',
    NOK: 'NOK',
    NZD: 'NZD',
    RON: 'RON',
    RUB: 'RUB',
    SEK: 'SEK',
    SGD: 'SGD',
    THB: 'THB',
    TRY: 'TRY',
    PHP: 'PHP',
    // NOTE: waiting for BE to implement another service that would support this currency
    // QAR: 'QAR',
});
