import { React, PropTypes, FormattedMessage, ButtonDefault, connectFela, Icons, Container } from '../../dependencies';

import { errorPropType } from '../utils';

import * as Styles from './ErrorWithAction.styles';

function ErrorWithAction({ error, onErrorMessageClick, styles, buttonTextId }) {
    return (
        <Container>
            <div className={styles.card}>
                <Icons.SadFace className={styles.icon} />

                <h3 className={styles.message}>
                    <FormattedMessage {...error} />
                </h3>

                {onErrorMessageClick && (
                    <div>
                        <ButtonDefault onClick={() => onErrorMessageClick()} size="small">
                            <FormattedMessage id={buttonTextId} />
                        </ButtonDefault>
                    </div>
                )}
            </div>
        </Container>
    );
}

ErrorWithAction.propTypes = {
    error: errorPropType.isRequired,
    onErrorMessageClick: PropTypes.func,
    styles: PropTypes.shape().isRequired,
    buttonTextId: PropTypes.string,
};

ErrorWithAction.defaultProps = {
    onErrorMessageClick: null,
    buttonTextId: 'error.button.retry',
};

export default connectFela(Styles)(ErrorWithAction);
