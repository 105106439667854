import { CommonFilterSelectors, createSelector } from '../../dependencies';

import { transformUrlParamsToFormValues, transformUrlParamsToApiRequest } from '../../utils';

const { decodeExportsFilterParamsSelector } = CommonFilterSelectors;

export const exportsParamsSelector = createSelector(
    decodeExportsFilterParamsSelector,
    transformUrlParamsToApiRequest,
);

export const filterFormInitialValuesSelector = createSelector(
    decodeExportsFilterParamsSelector,
    transformUrlParamsToFormValues,
);
