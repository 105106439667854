import { React, FelaComponent } from '../../dependencies';

import SuppliersFilter from '../../containers/SuppliersFilter';
import AddSupplier from '../../containers/AddSupplier';

import style from './SuppliersHeader.style';

const SuppliersHeader = () => (
    <FelaComponent style={style}>
        <SuppliersFilter />
        <AddSupplier />
    </FelaComponent>
);

export default SuppliersHeader;
