import { sagaEffects, ModalsActions, Consts, MessagesActions } from '../../../dependencies';

import { types, createMembershipRequest } from '../../actions';

const { put, takeEvery, take } = sagaEffects;

function* submitCreateMembershipForm({ startSubmit, stopSubmit, reset, data }) {
    yield startSubmit();

    yield put(createMembershipRequest(data));

    const { CREATE_MEMBERSHIP_SUCCESS, CREATE_MEMBERSHIP_FAILURE } = types;

    const result = yield take([CREATE_MEMBERSHIP_SUCCESS, CREATE_MEMBERSHIP_FAILURE]);

    switch (result.type) {
        case CREATE_MEMBERSHIP_SUCCESS: {
            yield stopSubmit();
            yield reset();
            yield put(ModalsActions.closeModal(Consts.modals.CREATE_MEMBERSHIP));

            const membership = result.payload;
            yield put(
                MessagesActions.displaySuccessMessage({
                    id: 'success.membership.create',
                    values: {
                        email: membership.user.email,
                    },
                }),
            );
            break;
        }

        case CREATE_MEMBERSHIP_FAILURE:
            yield stopSubmit({
                _error: result.error,
            });
            break;

        default:
    }
}

export default function* createMembershipWatcher() {
    yield takeEvery(types.SUBMIT_CREATE_MEMBERSHIP_FORM, submitCreateMembershipForm);
}
