import { Petrus } from '../dependencies';
import types from './actionTypes';

export { types };

export const login = Petrus.loginRequest;
export const logout = Petrus.logoutRequest;

export const loginForm = () => ({
    type: types.LOGIN_FORM,
});

export const signUpForm = () => ({
    type: types.SIGN_UP_FORM,
});

export const setDataAnalysisPersmission = value => ({
    type: types.SET_DATA_ANALYSIS_PERSMISSION,
    payload: value,
});
