export const wrapper = ({ theme: { colors, boxShadows } }) => ({
    width: '572px',
    marginTop: '15px',
    backgroundColor: colors.white,
    position: 'absolute',
    top: 'calc(100% - 6px)',
    boxShadow: boxShadows.dropdown,
});

export const footer = ({ theme: { colors } }) => ({
    width: '333px',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    marginLeft: 'auto',
    justifyContent: 'flex-end',
    borderColor: colors.blueGrey1,
    borderWidth: '1px',
    borderLeftStyle: 'solid',
    borderTopStyle: 'solid',
    padding: '12px',
    gridColumnGap: 12,
});

export const dateRangePicker = ({ theme: { colors } }) => {
    const textStyle = {
        fontSize: '14px',
        lineHeight: 1.64,
        letterSpacing: '0.1px',
        fontWeight: 500,
    };

    const selectedText = {
        color: colors.text,
    };

    const primarySelectionBg = {
        backgroundColor: colors.primarySelection,
    };

    const borderRadiusRight = {
        borderTopRightRadius: '2px',
        borderBottomRightRadius: '2px',
    };
    const borderRadiusLeft = {
        borderTopLeftRadius: '2px',
        borderBottomLeftRadius: '2px',
    };

    return {
        '& .rdrMonth': {
            padding: '0 19px 16px',
        },
        '& .rdrWeekDay': {
            color: colors.blueGrey3,
            fontSize: '14px',
            lineHeight: 1.64,
            letterSpacing: '0.1px',
        },
        '& .rdrDayNumber span': {
            color: colors.text,
            '&:after': {
                backgroundColor: colors.info,
            },
        },
        '& .rdrDay': {
            ...textStyle,
            color: colors.text,
        },

        '& .rdrDayStartOfMonth > .rdrInRange': borderRadiusLeft,
        '& .rdrDayStartOfMonth > .rdrEndEdge': borderRadiusLeft,
        '& .rdrDayStartOfWeek > .rdrInRange': borderRadiusLeft,
        '& .rdrDayStartOfWeek > .rdrEndEdge': borderRadiusLeft,
        '& .rdrStartEdge': borderRadiusLeft,

        '& .rdrDayEndOfMonth > .rdrInRange': borderRadiusRight,
        '& .rdrEndEdge': borderRadiusRight,
        '& .rdrDayWeekend > .rdrInRange': borderRadiusRight,
        '& .rdrDefinedRangesWrapper': {
            ...textStyle,
            borderRightColor: colors.blueGrey1,
        },
        '& .rdrDefinedRangesWrapper .rdrStaticRangeSelected': {
            color: `${colors.text} !important`,
            fontWeight: 500,
        },
        '& .rdrStaticRange': {
            borderBottom: 'none',
        },
        '& .rdrStaticRangeLabel': {
            ...textStyle,
            padding: '5px 16px',
        },
        '& .rdrStaticRanges': {
            marginTop: 19,
        },
        '& .rdrInputRanges': {
            display: 'none',
        },
        '& .rdrDay:not(.rdrDayPassive)': {
            '& .rdrInRange ~ .rdrDayNumber span': selectedText,
            '& .rdrStartEdge ~ .rdrDayNumber span': selectedText,
            '& .rdrEndEdge ~ .rdrDayNumber span': selectedText,
            '& .rdrSelected ~ .rdrDayNumber span': selectedText,
        },
        '& .rdrStaticRange:hover .rdrStaticRangeLabel': primarySelectionBg,
        '& .rdrStaticRange:focus .rdrStaticRangeLabel': primarySelectionBg,
        '& .rdrNextPrevButton': {
            backgroundColor: 'transparent',
            borderRadius: '4px',
        },
    };
};
