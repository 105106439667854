export const wrapper = () => ({
    marginLeft: 124,
    marginTop: 180,
});

export const error = ({ theme: { colors } }) => ({
    lineHeight: 1,
    letterSpacing: '1.5px',
    color: colors.primary,
    fontSize: '7.5rem',
    marginBottom: 24,
    fontWeight: 700,
});

export const title = ({ theme: { colors } }) => ({
    fontSize: '2rem',
    lineHeight: 1.44,
    letterSpacing: '0.4px',
    color: colors.text,
    marginBottom: 8,
    fontWeight: 700,
});

export const subtitle = ({ theme: { colors } }) => ({
    fontSize: '1.25rem',
    lineHeight: 1.35,
    letterSpacing: '0.3px',
    color: colors.blueGrey4,
});
