import { React, PropTypes, FormattedMessage } from '../../../dependencies';

const Header = ({ styles }) => (
    <span className={styles.title}>
        <FormattedMessage id="report.select.label" />
    </span>
);

Header.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default Header;
