import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { noop } from 'lodash';

import { buttonSizes } from '../../constants';

import * as styles from './Button.styles';

function Button({ children, size, style, ...props }) {
    return (
        <FelaComponent
            {...{
                ...props,
                style: styles.button,
                size,
                customStyle: style,
            }}
        >
            {children}
        </FelaComponent>
    );
}

Button.propTypes = {
    children: PropTypes.func.isRequired,
    size: PropTypes.oneOf(Object.values(buttonSizes)),
    style: PropTypes.func,
};

Button.defaultProps = {
    size: buttonSizes.MEDIUM,
    style: noop,
};

export default Button;
