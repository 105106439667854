import { types } from 'modules/reports/services/actions';

const initialState = null;

export default function newReport(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_NEW_REPORT_SUCCESS: {
            return action.payload;
        }

        case types.FETCH_NEW_REPORT_RESET:
            return initialState;

        default:
            return state;
    }
}
