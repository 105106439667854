import {
    config,
    authApi,
    takeLatestRequest,
    Log,
    sagaEffects,
    Normalizers,
    Suppliers,
    ErrorsUtils,
    Tags,
    Users,
} from '../../dependencies';

import { types, fetchExpenseSuccess, fetchExpenseFailure, fetchExpenseInvalidate } from '../actions';

const { put } = sagaEffects;

function* fetchExpense(action, cancelToken) {
    const { id } = action.meta;

    try {
        const requestConfig = {
            uriParams: { id },
            cancelToken,
        };

        const { data } = yield* authApi.get(config.api.expense, requestConfig);

        const { entities } = Normalizers.expense(data);

        for (const [supplierId, supplier] of Object.entries(entities.suppliers || {})) {
            yield put(Suppliers.actions.fetchSupplierSuccess(supplierId, supplier));
        }

        yield put(Tags.actions.addTags(Tags.utils.formatPayload(entities.tags)));

        for (const [userId, user] of Object.entries(entities.users || {})) {
            yield put(Users.actions.fetchUserSuccess(userId, user));
        }

        yield put(fetchExpenseSuccess(id, entities.expenses[id]));
    } catch (e) {
        Log.error(e);

        const values = { id };
        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            status: {
                404: {
                    id: 'error.expense.api.status.404',
                    values,
                },
            },
            fallback: {
                id: 'error.expense.api.get',
                values,
            },
        });

        yield put(fetchExpenseFailure(id, errorMessage));
    }
}

export default function* fetchExpenseWatcher() {
    const actionTypes = {
        REQUEST: types.FETCH_EXPENSE_REQUEST,
        cancelTask: fetchExpenseInvalidate,
        requestIdSelector: action => action.meta.userId,
    };

    yield takeLatestRequest(actionTypes, fetchExpense);
}
