import { reducer as reduxForm } from 'redux-form';

import { reducer as auth } from 'modules/auth';
import { reducer as modals } from 'modules/modals';
import { reducer as barChart } from 'modules/reports';
import { reducer as suppliers } from 'modules/suppliers';
import { reducer as tags } from 'modules/tags';
import { reducer as ui } from 'modules/ui';
import { reducer as cache } from 'modules/cache';
import { reducer as googleAnalytics } from 'modules/google-analytics';
import { reducer as activeCompany } from 'modules/active-company';

import entities from './entities';
import api from './api';

// NOTE:
// Do not use combineReducers() here,
// export reducers as plain object, not as a function.
const reducers = {
    auth,
    entities,
    api,
    modals,
    barChart,
    suppliers,
    tags,
    ui,
    cache,
    googleAnalytics,
    form: reduxForm,
    activeCompany,
};

export default reducers;
