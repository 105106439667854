export const wrapper = () => ({
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
    height: `calc(100% - 64px)`,
});

export const extendNoData = {
    noData: () => ({
        height: '100%',
    }),
};
