import { types } from '../actions';

const initState = {
    id: null,
};

export default function activeCompanyReducer(state = initState, action) {
    switch (action.type) {
        case types.INIT_ACTIVE_COMPANY:
        case types.SET_ACTIVE_COMPANY_RESOLVE:
            return {
                ...state,
                id: action.companyId,
            };

        case types.REMOVE_ACTIVE_COMPANY:
            return initState;

        default:
            return state;
    }
}
