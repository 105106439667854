import { combineReducers, Consts } from '../../../dependencies';

import updateUser from './updateUser';
import fetchUser from './fetchUser';

const { UPDATE, FETCH } = Consts.apiTypes;

export const user = combineReducers({
    [UPDATE]: updateUser,
    [FETCH]: fetchUser,
});
