import types from './types';

export const runExportManuallyRequest = exportId => ({
    type: types.RUN_EXPORT_MANUALLY_REQUEST,
    meta: { exportId },
});

export const runExportManuallySuccess = exportId => ({
    type: types.RUN_EXPORT_MANUALLY_SUCCESS,
    meta: { exportId },
});

export const runExportManuallyFailure = (exportId, error) => ({
    type: types.RUN_EXPORT_MANUALLY_FAILURE,
    meta: { exportId },
    error,
});

export const runExportManuallyInvalidate = exportId => ({
    type: types.RUN_EXPORT_MANUALLY_CANCEL,
    meta: { exportId },
});
