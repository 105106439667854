import { combineReducers, Consts, ExpenseTriggers, ReduxUtils, Actions } from 'modules/expenses/dependencies';

import fetchExpenses from './fetchExpenses';
import fetchExpense from './fetchExpense';
import deleteExpense from './deleteExpense';
import createExpense from './createExpense';
import updateExpense from './updateExpense';
import fetchNextExpenseNumber from './fetchNextExpenseNumber';

const { CREATE, UPDATE, FETCH, DELETE } = Consts.apiTypes;
const { RESET_ALL_EXPENSES } = Actions.types;

export const { expenseTriggers, expenseTrigger } = ExpenseTriggers.apiReducers;

export const nextExpenseNumber = combineReducers({
    [FETCH]: fetchNextExpenseNumber,
});

const expenseReducer = combineReducers({
    [CREATE]: createExpense,
    [UPDATE]: updateExpense,
    [FETCH]: fetchExpense,
    [DELETE]: deleteExpense,
});

const expensesReducer = combineReducers({
    [FETCH]: fetchExpenses,
});

export const expense = ReduxUtils.basicResetReducer(expenseReducer, RESET_ALL_EXPENSES);
export const expenses = ReduxUtils.basicResetReducer(expensesReducer, RESET_ALL_EXPENSES);
