import { ReduxUtils } from '../../../dependencies';
import types from '../../actionTypes';

const actionTypes = {
    REQUEST: types.FETCH_TAGS_REQUEST,
    SUCCESS: types.FETCH_TAGS_SUCCESS,
    FAILURE: types.FETCH_TAGS_FAILURE,
    CANCEL: types.FETCH_TAGS_CANCEL,
};

export default ReduxUtils.basicApiReducer({
    actionTypes,
});
