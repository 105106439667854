import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from '../actionTypes';

export const createSupplierRequest = (meta = {}) => ({
    type: types.CREATE_SUPPLIER_REQUEST,
    meta: {
        // NOTE: fill supplier field with the new supplier name at createExpense form
        // (This isn't expected behaviour by default.)
        fillSupplierField: false,
        ...meta,
    },
});

export const createSupplierRequestFactory = meta => () => createSupplierRequest(meta);

export const createSupplierSuccess = (id, payload) => ({
    type: types.CREATE_SUPPLIER_SUCCESS,
    meta: { id },
    payload: formatEntity({
        entity: payload,
        template: entitiesTemplates.supplier,
    }),
});

export const createSupplierFailure = (id, error) => ({
    type: types.CREATE_SUPPLIER_FAILURE,
    meta: { id },
    error,
});

export const createSupplierInvalidate = () => ({
    type: types.CREATE_SUPPLIER_CANCEL,
});
