import { ReduxUtils } from '../dependencies';

const { strictObjectAccess } = ReduxUtils;

export const NewReportFields = strictObjectAccess({
    NAME: 'name',
});

export const Forms = strictObjectAccess({
    CREATE_REPORT: 'createReport',
});

export const DoughnutChart = strictObjectAccess({
    MAX_SEGMENTS_COUNT: 10,
    MIN_SEGMENT_VALUE: 0.04,
});
