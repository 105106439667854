export const listItem = ({ theme: { colors } }) => ({
    padding: '15px 0 13px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(2, 1fr) 2fr 2fr 80px 1fr',
    gridColumnGap: 8,
    margin: '0 30px',
    borderBottom: `1px solid ${colors.blueGrey1}`,
    cursor: 'pointer',
});

export const cell = ({ theme: { colors }, textAlign }) => ({
    color: colors.text,
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    lineHeight: 1.56,
    textAlign,
});

export const documentIcon = ({ theme: { colors } }) => ({
    height: 20,
    fill: colors.text,
});

export const link = ({ theme: { colors } }) => ({
    textDecoration: 'underline',
    color: colors.text,

    onHover: {
        textDecoration: 'underline',
    },
});

export const tagsLink = () => ({
    textDecoration: 'underline',
    color: 'currentColor',
    onHover: {
        color: 'currentColor',
        textDecoration: 'underline',
    },
});
