import { types } from '../actions';

const initialState = {};

export default function toggleReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_TOGGLE:
            return {
                ...state,
                [action.meta.id]: action.payload,
            };

        default:
            return state;
    }
}
