import { combineReducers } from 'modules/expenses/dependencies';

import groups from './groups';
import byId from './byId';
import totalPrice from './totalPrice';

export default combineReducers({
    byId,
    groups,
    totalPrice,
});
