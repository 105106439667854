import { React, PropTypes, ChartBar, PriceTooltip, ReportUtils, config, FormattedNumber } from '../../dependencies';

import Overview from './Overview';
import { calcBarWidth, barShape } from './utils';

const BARS_COUNT = 5;
const GAP = 17;

function QuartersChart({ intl, total, currentPeriod, currency, buckets, average }) {
    return (
        <>
            <Overview
                {...{
                    total,
                    currentPeriod,
                    currency,
                    currentPeriodLabelId: 'dashboard.currentQuarter',
                }}
            />
            <ChartBar
                {...{
                    bottomLabelHeight: 29,
                    topLabelHeight: 29,
                    height: 229,
                    gap: GAP,
                    barsCount: BARS_COUNT,
                    data: QuartersChart.mapBars(buckets, currency, intl),
                    calcBarWidth: QuartersChart.calcBarWidth,
                    average: {
                        ...average,
                        value: (
                            <FormattedNumber
                                {...config.currency}
                                // eslint-disable-next-line
                                style="currency"
                                value={average.value}
                                currency={currency}
                            />
                        ),
                    },
                }}
            />
        </>
    );
}

QuartersChart.calcBarWidth = viewportWidth => {
    return calcBarWidth(BARS_COUNT, GAP, viewportWidth);
};

QuartersChart.mapBars = (bars = [], currency, intl) => {
    return bars.map(({ withVat, withoutVat, monthIndex, ...bar }) => ({
        ...bar,
        title: <PriceTooltip currency={currency} priceWithVat={withVat} />,
        topLabel: ReportUtils.shortenedNumber(withoutVat, intl),
        bottomLabel: intl.formatMessage(
            {
                id: 'quarter.short',
            },
            {
                number: ReportUtils.getQuarterFromMonthIndex(monthIndex),
            },
        ),
    }));
};

QuartersChart.propTypes = {
    intl: PropTypes.shape().isRequired,
    currency: PropTypes.string.isRequired,
    buckets: PropTypes.arrayOf(barShape.isRequired).isRequired,

    total: PropTypes.shape({
        yearFrom: PropTypes.string.isRequired,
        yearTo: PropTypes.string.isRequired,
        withVat: PropTypes.number.isRequired,
        withoutVat: PropTypes.number.isRequired,
    }).isRequired,

    currentPeriod: PropTypes.shape({
        withVat: PropTypes.number.isRequired,
        withoutVat: PropTypes.number.isRequired,
    }),

    average: PropTypes.shape({
        bottomRelativeOffset: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    }).isRequired,
};

QuartersChart.defaultProps = {
    currentPeriod: null,
};

export default QuartersChart;
