import { borderDashed } from '../../../../dependencies';

export const row = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const dropArea = ({ theme: { colors, dimensions } }) => ({
    marginTop: 12,
    height: 112,
    maxWidth: dimensions.inputMaxWidth,
    width: '100%',

    ...row(),
    flexDirection: 'column',

    borderRadius: '3px',
    border: `1px dashed ${colors.blueGrey3}`,

    borderImageSource: `url(${borderDashed})`,
    borderImageWidth: '1px',
    borderImageSlice: '1 fill',
    padding: '27px 8px 24px',
});

const text = {
    fontSize: 14,
    lineHeight: 1.64,
    letterSpacing: 0.1,
};

export const description = ({ theme: { colors } }) => ({
    ...text,
    color: colors.text,
    marginRight: 4,
});

export const link = ({ theme: { colors } }) => ({
    ...text,
    color: colors.primary,
    textDecoration: 'underline',
    padding: 0,
});

export const icon = () => ({
    height: 22,
    width: 22,
    marginBottom: 16,
});
