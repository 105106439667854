import { React, PropTypes, childrenPropType } from '../../../dependencies';

const Header = ({ styles, children }) => <div className={styles.container}>{children}</div>;

Header.propTypes = {
    styles: PropTypes.shape().isRequired,
    children: childrenPropType.isRequired,
};

export default Header;
