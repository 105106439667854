import { React, PropTypes, RadioField, injectIntl, Consts, InputWrapper } from '../../../dependencies';

function PaymentMethod({ intl, disabled }) {
    const options = PaymentMethod.translateOptions(intl);
    return (
        <InputWrapper labelMarginLeft={0}>
            <RadioField
                {...{
                    name: 'paymentMethod',
                    label: intl.formatMessage({
                        id: 'expense.form.paymentMethod.label',
                    }),
                    options,
                    disabled,
                }}
            />
        </InputWrapper>
    );
}

PaymentMethod.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

PaymentMethod.translateOptions = ({ formatMessage }) => {
    const prefix = 'expense.form.paymentMethod.option';
    const ids = Object.values(Consts.paymentMethod);

    return ids.map(id => ({
        value: id,
        label: formatMessage({ id: `${prefix}.${id}` }),
    }));
};

export default injectIntl(PaymentMethod);
