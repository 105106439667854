import types from './types';

export const deleteExportRequest = id => ({
    type: types.DELETE_EXPORT_REQUEST,
    meta: { id },
});

export const deleteExportSuccess = id => ({
    type: types.DELETE_EXPORT_SUCCESS,
    meta: { id },
});

export const deleteExportFailure = (id, error) => ({
    type: types.DELETE_EXPORT_FAILURE,
    meta: { id },
    error,
});

export const deleteExportCancel = id => ({
    type: types.DELETE_EXPORT_CANCEL,
    meta: { id },
});
