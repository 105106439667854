import d from '../defaultValues';
import { convertValue } from '../helpers';

const reportListItem = {
    name: d.string,
    id: convertValue(String),
    companyId: convertValue(String),
    hiddenTags: d.reports.hiddenTags,
    createdAt: d.string,
    updatedAt: d.string,
    filters: d.reports.filters,
    tagIds: d.array,
};

export default reportListItem;
