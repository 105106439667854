import { ReduxUtils, Paging, Consts } from '../../../dependencies';

import { types } from '../../actions';

export const actionTypes = {
    REQUEST: types.FETCH_EXPENSE_TRIGGERS_REQUEST,
    SUCCESS: types.FETCH_EXPENSE_TRIGGERS_SUCCESS,
    FAILURE: types.FETCH_EXPENSE_TRIGGERS_FAILURE,
    CANCEL: types.FETCH_EXPENSE_TRIGGERS_CANCEL,
    SET_PAGE: Paging.actionTypes.SET_PAGE,
    RESET: types.FETCH_EXPENSE_TRIGGERS_RESET,
};

const paginationReducer = ReduxUtils.paginationApiReducer({
    actionTypes,
    initialState: {
        limit: 13, // items per request
        amount: 13, // items per page
    },
    selectors: {
        totalCount: action => action.meta.totalCount,
        currentCount: action => action.payload.ids.length,
    },
    actionFilters: {
        setPage: action => {
            const { entityKey, typeId } = action.meta;

            return entityKey === Consts.entityKeys.EXPENSE_TRIGGERS && typeId === Consts.apiTypes.FETCH;
        },
    },
});

export default paginationReducer;
