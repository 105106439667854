export const cell = ({ theme: { colors } }) => ({
    borderRadius: '4px',
    border: `1px solid ${colors.blueGrey3}`,
    padding: '8px 12px 7px',
    letterSpacing: '0.2px',
    lineHeight: 1.56,
    fontSize: '16px',
    color: colors.blueGrey4,
    textAlign: 'left',
});
