import { useSelector } from '../dependencies';

import { serviceKeySelector } from '../services/selectors';

export default function useServiceKey(id) {
    return useSelector(state =>
        serviceKeySelector(state, {
            serviceKeyId: id,
        }),
    );
}
