export const empty = ({ theme }) => ({
    padding: '3rem 1rem 0.75rem',
    marginBottom: 0,

    fontSize: '1rem',
    lineHeight: 1.56,

    color: theme.colors.blueGrey3,

    textAlign: 'center',
});
