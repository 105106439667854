import { config, authApi, Log, sagaEffects, Normalizers, ErrorsUtils } from '../../dependencies';

import { createExportSuccess, createExportFailure, types } from '../actions';

const { put, takeLeading } = sagaEffects;

function* createExport(action) {
    try {
        const { data } = yield* authApi.post(config.api.exports, action.payload);

        const { result: id, entities } = Normalizers.recurringExport(data);

        yield put(createExportSuccess(entities.exports[id]));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.export.api.post',
            },
        });

        yield put(createExportFailure(errorMessage));
    }
}

export default function* () {
    yield takeLeading(types.CREATE_EXPORT_REQUEST, createExport);
}
