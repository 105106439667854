import {
    Utils,
    startOfDay,
    endOfDay,
    config,
    resolveRelativePeriod,
    getRelativePeriod,
    endOfToday,
    parseISO,
} from '../dependencies';

// api values (from, to, relativePeriod) to form values
function transformReportRangeValuesToFormRangeValues({ from, to, relativePeriod }) {
    if (relativePeriod) {
        return {
            relativePeriod,
            from,
            to,
        };
    }

    // NOTE: since there are fake relative period and those won't come from API ever,
    // so it's required to manually detect them through value of `from` and `to`.
    const relativePeriodResult = getRelativePeriod({
        startDate: from,
        // Format endDate as they're in StaticDateRangesCreators otherwise a relative period couldn't be found:
        // the key for `RelativePeriods` map of would be incorrect.
        endDate: Date.parse(to) < Date.parse(endOfToday(to)) ? to : Utils.getISODateWithoutTimezone(endOfToday(to)),
    });

    return {
        from: relativePeriodResult ? null : from,
        to: relativePeriodResult ? null : to,
        relativePeriod: relativePeriodResult,
    };
}

// report filters -> form values
export function transformReportFiltersToFormValues(reportFilters) {
    const { from, to, relativePeriod, ...rest } = reportFilters;
    const range = transformReportRangeValuesToFormRangeValues({
        from,
        to,
        relativePeriod,
    });

    return {
        ...rest,
        range,
    };
}

// decoded url params -> form values
export function transformUrlParamsToFormValues(urlParams) {
    const { from, to, relativePeriod, includeTags, excludeTags, supplierId, currency } = urlParams;

    return {
        range: {
            from,
            to,
            relativePeriod,
        },
        includeTags,
        excludeTags,
        supplierId,
        currency,
    };
}

// form values -> url params
export function transformFormValuesToUrlParams(formValues) {
    const { range, ...rest } = formValues;

    return {
        ...range,
        ...rest,
    };
}

export function removeTimezoneFromDates(reportFilters) {
    let { from, to } = reportFilters;

    if (!from || !to) {
        return reportFilters;
    }

    if (typeof from === 'string' && typeof to === 'string') {
        from = parseISO(from);
        to = parseISO(to);
    }

    return {
        ...reportFilters,
        from: Utils.getISODateWithoutTimezone(startOfDay(from)),
        to: Utils.getISODateWithoutTimezone(endOfDay(to)),
    };
}

function resolveDateRangeFilters(fitlers) {
    const { from, to } = resolveRelativePeriod(fitlers);
    const isFakeRelativePeriod = config.filters.fakeRelativePeriods.includes(fitlers.relativePeriod);

    return removeTimezoneFromDates({
        from,
        to,
        relativePeriod: isFakeRelativePeriod ? null : fitlers.relativePeriod,
    });
}

// report filters -> report API filters
export function trasformReportFiltersToApiFilters(reportFilters) {
    const { excludeTags, includeTags, supplierId, ...rest } = reportFilters;
    const { from, to, relativePeriod } = resolveDateRangeFilters(reportFilters);

    return {
        ...rest,
        from,
        to,
        relativePeriod,
        excludeTags: Utils.parseStringIds(excludeTags),
        includeTags: Utils.parseStringIds(includeTags),
        supplierIds: Utils.parseStringIds([supplierId]),
    };
}
