import { uniqueSuffix } from 'services/utilities';

export const modals = {
    CREATE_SUPPLIER: uniqueSuffix('CREATE_SUPPLIER'),
    CREATE_REPORT: uniqueSuffix('CREATE_REPORT'),
    UPDATE_SUPPLIER: 'UPDATE_SUPPLIER',
    DELETE_SUPPLIER: 'DELETE_SUPPLIER',
    CREATE_TAG: uniqueSuffix('CREATE_TAG'),
    UPDATE_TAG: 'UPDATE_TAG',
    DELETE_TAG: 'DELETE_TAG',

    CHANGE_PASSWORD: 'CHANGE_PASSWORD',

    EDIT_COMPANY: 'EDIT_COMPANY',
    CREATE_COMPANY: 'CREATE_COMPANY',

    CREATE_MEMBERSHIP: 'CREATE_MEMBERSHIP',
    DELETE_MEMBERSHIP: 'DELETE_MEMBERSHIP',
};
