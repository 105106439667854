import { React, FelaComponent, PropTypes, Icons } from '../../dependencies';

import style from './UpdateSupplier.style';

import UpdateSupplierFormModal from '../../containers/SupplierFormModal/UpdateSupplierFormModal';

const UpdateSupplier = ({ id, isOpen, openModal, closeModal }) => (
    <FelaComponent as="span" style={style}>
        <button type="button" onClick={openModal}>
            <Icons.Update width="20" height="21" />
        </button>
        {isOpen && <UpdateSupplierFormModal id={id} isOpen={isOpen} closeModal={closeModal} />}
    </FelaComponent>
);

UpdateSupplier.propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default UpdateSupplier;
