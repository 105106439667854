import React from 'react';

const User = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <g transform="translate(-2.000000, -2.000000)">
                <path d="M12,12 C9.794,12 8,10.205 8,8 C8,5.795 9.794,4 12,4 C14.206,4 16,5.795 16,8 C16,10.205 14.206,12 12,12 M15.758,12.674 C17.124,11.574 18,9.891 18,8 C18,4.687 15.313,2 12,2 C8.687,2 6,4.687 6,8 C6,9.891 6.876,11.574 8.242,12.674 C4.583,14.048 2,17.445 2,22 L4,22 C4,17 7.589,14 12,14 C16.411,14 20,17 20,22 L22,22 C22,17.445 19.417,14.048 15.758,12.674" />
            </g>
        </g>
    </svg>
);

export default User;
