export const list = ({ theme: { colors } }) => ({
    display: 'grid',
    gridRowGap: '0.75rem',
    padding: '16px 20px',
    borderBottom: `1px solid ${colors.blueGrey1}`,
});

export const row = ({ theme: { colors } }) => ({
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gridColumnGap: '1rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
});

export const iconWrapper = () => ({
    height: 24,
    width: 24,
});

export const iconSelected = ({ theme: { colors } }) => ({
    height: 10,
    fill: colors.secondary,
});

export const label = ({ theme: { colors } }) => ({
    color: colors.text,
    fontSize: '15px',
    letterSpacing: '0.15px',
    lineHeight: 1.56,
    textAlign: 'left',
    whiteSpace: 'nowrap',

    onHover: {
        color: colors.textHover,
        fill: colors.textHover,
    },
});
