import * as validators from './validators';
import { setValidatorMessage } from './helpers';

export const isRequired = setValidatorMessage(validators.isRequired, 'error.required');

export const isSafePassword = setValidatorMessage(validators.minLength(8), 'error.password.minLength');

export const isAlphaNumeric = setValidatorMessage(validators.isAlphaNumeric, 'error.alpaNumberic');

export const isNowOrFutureDay = setValidatorMessage(validators.isNowOrFutureDay, 'error.isNowOrFutureDay');

export const isPastDay = setValidatorMessage(validators.isPastDay, 'error.isPastDay');
