import { createSelector, max, StaticDateRangesCreators } from '../../../dependencies';

import { NEW_REPORT } from '../../../constants';

import { getMonthIndex, getYear, calcBarHeight } from '../utils';
import { currentReportIdSelector, isNewReportSelector } from '../location';

import { selectReports } from './general';
import { isTempReportSelector } from './isTempReport';

export const currentReportSelector = createSelector(
    [currentReportIdSelector, isNewReportSelector, isTempReportSelector, selectReports],
    (reportId, isNewReport, isTempReport, reports) => {
        if (isNewReport) {
            return isTempReport ? reports.temporary[NEW_REPORT] : reports.new;
        }

        if (!reportId) {
            return null;
        }

        return isTempReport ? reports.temporary[reportId] : reports.byId[reportId];
    },
);

export const mapBucketItemsToBars = bucketItems => {
    const maxWithoutVat = max(bucketItems.map(month => month.withoutVat));

    if (maxWithoutVat === 0) {
        return [];
    }

    return bucketItems.map(({ date, withVat, withoutVat }, index) => {
        const monthIndex = getMonthIndex(date);
        const year = getYear(date);

        return {
            id: String(index),
            height: calcBarHeight(maxWithoutVat, withoutVat),
            monthIndex,
            year,
            withoutVat,
            withVat,
        };
    });
};

export const currentReportFiltersSelector = createSelector(
    currentReportSelector,
    currentReport => {
        return currentReport ? currentReport.filters : null;
    },
);

export const hasCurrentReportFiltersSelector = createSelector(
    currentReportFiltersSelector,
    filters => Boolean(filters),
);

export const currentReportDateRangeSelector = createSelector(
    currentReportFiltersSelector,
    filters => {
        if (!filters) {
            return null;
        }

        const { from, to, relativePeriod } = filters;

        if (filters && relativePeriod) {
            return StaticDateRangesCreators[relativePeriod]();
        }

        return {
            from,
            to,
        };
    },
);
