export const setValidatorMessage = (validator, messageId) => value => {
    const isValid = validator(value);

    return isValid ? undefined : messageId;
};

const getFirstError = (methods = [], value) => {
    if (Array.isArray(methods)) {
        for (const method of methods) {
            const error = method(value);

            if (error) {
                return error;
            }
        }

        return null;
    }

    return typeof methods === 'function' ? methods(value) : null;
};

// TODO: replace this recursion solution with iterative one
export const getFieldsErrors = (fieldsValidators = {}, values = {}) => {
    const errors = {};

    for (const [fieldId, fieldValidators] of Object.entries(fieldsValidators)) {
        const value = values[fieldId];

        const error = getFirstError(fieldValidators, value);

        if (error) {
            errors[fieldId] = error;
        }
    }

    return errors;
};

export function translate(formatMessage, messageKeys = {}, depth = 3) {
    if (depth < 0) {
        throw new RangeError(`translate: Too deep recursion. Max depth is 3 levels.`);
    }

    const messages = {};

    for (const [key, messageId] of Object.entries(messageKeys)) {
        if (typeof messageId === 'string') {
            messages[key] = formatMessage({
                id: messageId,
            });
        } else {
            messages[key] = translate(formatMessage, messageId, depth - 1);
        }
    }

    return messages;
}
