import { React, PropTypes, DateRangePickerField } from '../../../dependencies';

import { Fields } from '../../../constants';

const DateRangeField = ({ disabled }) => (
    <DateRangePickerField
        {...{
            disabled,
            name: Fields.RANGE,
        }}
    />
);

DateRangeField.propTypes = {
    disabled: PropTypes.bool.isRequired,
};

export default DateRangeField;
