import { React, PropTypes, FelaComponent, FormattedMessage } from '../../../../dependencies';

import * as styles from './CreateSupplierButton.styles';

function CreateSupplierButton({ onClick, onMouseEnter, onMouseLeave }) {
    return (
        <FelaComponent style={styles.button}>
            {({ className }) => (
                <button
                    type="button"
                    {...{
                        className,
                        onClick,
                        onMouseEnter,
                        onMouseLeave,
                    }}
                >
                    <FormattedMessage id="expense.form.supplier.create" />
                </button>
            )}
        </FelaComponent>
    );
}

CreateSupplierButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
};

export default CreateSupplierButton;
