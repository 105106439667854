import {
    compose,
    connect,
    ExpenseTriggers,
    loadable,
    Loader,
    injectIntl,
    push,
    withErrorMessage,
} from '../dependencies';

import List from '../components/List';

const mapStateToProps = (
    state,
    { fetchExpenseTriggersApi: { success, inProgress, error }, fetchExpenseTriggersRequest, intl },
) => {
    return {
        showLoader: inProgress || !success,
        expenseTriggers: ExpenseTriggers.selectors.expenseTriggersSelector(state, intl),
        error,
        onErrorMessageClick: fetchExpenseTriggersRequest,
    };
};

const mapDispatchToProps = {
    push,
};

const mergeProps = (stateProps, dispatchProps, { intl }) => ({
    ...stateProps,
    ...dispatchProps,
    intl,
});

export default compose(
    ExpenseTriggers.HOC.fetchExpenseTriggers(),
    injectIntl,
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps,
    ),
    withErrorMessage,
    loadable(Loader),
)(List);
