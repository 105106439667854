import {
    React,
    PropTypes,
    SelectInput,
    InputWrapper,
    ButtonLink,
    FormattedMessage,
    Utils,
} from 'modules/reports/dependencies';

import { useSelectPreset } from 'modules/reports/hooks/ReportControl';

const SelectPresetInput = ({ styles, rules, onPresetChange, presetId }) => {
    const { options, onChange, onReset } = useSelectPreset();

    return (
        <div className={styles.row}>
            <InputWrapper style={rules.input}>
                <SelectInput
                    {...{
                        options,
                        placeholder: <FormattedMessage id="report.select.placeholder" />,
                        input: {
                            value: presetId,
                            onChange(val) {
                                onChange(val);
                                onPresetChange(val);
                            },
                        },
                        showSearch: true,
                        allowClear: false,
                        filterOption: Utils.filterOption,
                    }}
                />
            </InputWrapper>

            {Boolean(presetId) && (
                <ButtonLink
                    onClick={() => {
                        onReset();
                        onPresetChange();
                    }}
                >
                    <FormattedMessage id="report.button.resetPreset" />
                </ButtonLink>
            )}
        </div>
    );
};

SelectPresetInput.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    onPresetChange: PropTypes.func.isRequired,
    presetId: PropTypes.string,
};

SelectPresetInput.defaultProps = {
    presetId: undefined,
};

export default SelectPresetInput;
