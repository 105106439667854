import { formatEntity, entitiesTemplates } from 'services/utilities/formatEntities';

import types from './types';

export const filterExpensesRequest = (groupId, params, meta) => ({
    type: types.FILTER_EXPENSES_REQUEST,
    meta: {
        forceUpdate: false,
        ...meta,
        groupId,
    },
    payload: params,
});

export const fetchExpenseSuccess = (id, payload) => ({
    type: types.FETCH_EXPENSE_SUCCESS,
    meta: { id },
    payload: formatEntity({
        entity: payload,
        template: entitiesTemplates.expense,
    }),
});

export const resetAllExpenses = () => ({
    type: types.RESET_ALL_EXPENSES,
});

export const resetAllReports = () => ({
    type: types.RESET_ALL_REPORTS,
});
