import { React, PropTypes, Icons, connectFela, TooltipWrapper } from '../../../dependencies';

import TooltipAverage from '../TooltipAverage';

import * as Styles from './AverageLine.styles';

function AverageLine({ styles, value }) {
    return (
        <div className={styles.line}>
            <TooltipWrapper title={<TooltipAverage value={value} />}>
                <div className={styles.tooltipSpace}>
                    <Icons.LineDotted className={styles.iconLine} />
                </div>
            </TooltipWrapper>
        </div>
    );
}

AverageLine.propTypes = {
    // eslint-disable-next-line
    bottomOffset: PropTypes.number.isRequired,
    value: PropTypes.node.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(AverageLine);
