import { React, PropTypes, FormattedMessage, UI, goBack, childrenPropType, useDispatch } from '../../dependencies';

const { Icons, ButtonIcon } = UI;

const ExportHeader = ({ styles, children, title }) => {
    const dispatch = useDispatch();

    return (
        <div className={styles.nav}>
            <ButtonIcon onClick={() => dispatch(goBack())}>
                {icon => <Icons.ArrowLeft className={icon.className} />}
            </ButtonIcon>
            <h3 className={styles.title}>
                <FormattedMessage {...title} />
            </h3>

            <div className={styles.rightContent}>{children}</div>
        </div>
    );
};

ExportHeader.propTypes = {
    styles: PropTypes.shape().isRequired,
    title: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    children: childrenPropType,
};

ExportHeader.defualtProps = {
    children: null,
};

export default ExportHeader;
