import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const actionTypes = {
    REQUEST: types.CREATE_TEMPORARY_REPORT_REQUEST,
    SUCCESS: types.CREATE_TEMPORARY_REPORT_SUCCESS,
    FAILURE: types.CREATE_TEMPORARY_REPORT_FAILURE,
    CANCEL: types.CREATE_TEMPORARY_REPORT_CANCEL,
    RESET: types.CREATE_TEMPORARY_REPORT_RESET,
};

const basicApiReducer = ReduxUtils.basicApiReducer({
    actionTypes,
});

export default ReduxUtils.containerReducer({
    childReducer: basicApiReducer,
    actionTypes: Object.values(actionTypes),
    selectors: {
        itemId: action => action.meta.id,
    },
});
