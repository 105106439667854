import {
    reduxForm,
    connect,
    compose,
    config,
    submitForm,
    injectIntl,
    withErrorBoundary,
    ExportsActions,
    ExportsSelectors,
} from '../../dependencies';

import ExportEdit from '../../components/ExportEdit';

import { validate } from '../ExportAddForm';

const form = config.forms.updateExport;

export default compose(
    withErrorBoundary,
    connect(
        (state, { exportId, serviceKeyId }) => {
            return {
                initialValues: ExportsSelectors.editExportFormInitialValuesSelector(state, { exportId, serviceKeyId }),
            };
        },
        (dispatch, { exportId, serviceKeyId }) => ({
            onSubmit() {
                const submit = submitForm(form, () => ExportsActions.submitUpdateExportForm(exportId, serviceKeyId));
                dispatch(submit());
            },
        }),
    ),
    injectIntl,
    reduxForm({
        form,
        validate,
        enableReinitialize: true,
    }),
)(ExportEdit);
