import { React, useNoActiveCompanyId } from '../../dependencies';
import { EditCompanyModal, CreateCompanyModal, CompanyDetail, CreateCompanyButton } from '../../modules/company';

import SelectCompany from '../SelectCompany';

import Header from './Header';

const CompaniesManagement = () => {
    const hasNoActiveCompany = useNoActiveCompanyId();
    return (
        <>
            <Header>
                {hasNoActiveCompany ? <div /> : <SelectCompany />}
                <CreateCompanyButton />
            </Header>

            <CompanyDetail />

            {!hasNoActiveCompany && <EditCompanyModal />}
            <CreateCompanyModal />
        </>
    );
};

export default CompaniesManagement;
