import { React, PropTypes, UI, childrenPropType, ErrorCard } from '../../dependencies';

import { useFetchServiceKey } from '../../hooks';

const { DataLoader } = UI;

const ServiceKeyFetcher = ({ id, children }) => {
    const { inProgress, success, error } = useFetchServiceKey(id);

    return (
        <ErrorCard {...error}>
            <DataLoader show={inProgress || !success} minHeight={500}>
                {children}
            </DataLoader>
        </ErrorCard>
    );
};

ServiceKeyFetcher.propTypes = {
    id: PropTypes.string.isRequired,
    children: childrenPropType.isRequired,
};

export default ServiceKeyFetcher;
