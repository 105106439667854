import {
    React,
    PropTypes,
    FelaComponent,
    FormattedMessage,
    Filter,
    ButtonPrimary,
    Divider,
    ErrorMessage,
    errorPropType,
    CheckboxField,
} from '../../dependencies';

import * as styles from './DisposableExport.style';

const DisposableExport = ({ handleSubmit, submitting, form, error, intl: { formatMessage } }) => (
    <form onSubmit={handleSubmit}>
        <FelaComponent style={styles.filter}>
            <Filter.Filter {...{ form, submitting, paymentMethodField: true }} />
        </FelaComponent>

        <Divider />

        <FelaComponent style={styles.footer} childrenLength={error ? 3 : 2}>
            <CheckboxField
                {...{
                    name: 'markAsSynced',
                    label: formatMessage({
                        id: 'exports.disposable.markAsSynced',
                    }),
                }}
            />
            {error && (
                <ErrorMessage>
                    <FormattedMessage {...error} />
                </ErrorMessage>
            )}
            <ButtonPrimary loading={submitting} disabled={submitting} htmlType="submit">
                <FormattedMessage id="exports.disposable.submit" />
            </ButtonPrimary>
        </FelaComponent>
    </form>
);

DisposableExport.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    error: errorPropType,
    intl: PropTypes.shape().isRequired,
};

DisposableExport.defaultProps = {
    error: null,
};

export default DisposableExport;
