import {
    React,
    PropTypes,
    FelaComponent,
    ButtonDefault,
    ButtonPrimary,
    FormattedMessage,
    ErrorMessage,
    errorPropType,
} from '../../dependencies';

import * as styles from './Footer.styles';

function Footer({ error, submitting, goBack }) {
    return (
        <FelaComponent style={styles.footer}>
            {error ? (
                <ErrorMessage padding={0}>
                    <FormattedMessage {...error} />
                </ErrorMessage>
            ) : (
                <div />
            )}

            <ButtonDefault disabled={submitting} onClick={goBack}>
                <FormattedMessage id="expense.create.form.cancel" />
            </ButtonDefault>

            <ButtonPrimary htmlType="submit" loading={submitting}>
                <FormattedMessage id="expense.create.form.submit" />
            </ButtonPrimary>
        </FelaComponent>
    );
}

Footer.propTypes = {
    error: errorPropType,
    submitting: PropTypes.bool.isRequired,
    goBack: PropTypes.func.isRequired,
};

Footer.defaultProps = {
    error: null,
};

export default Footer;
