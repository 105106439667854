import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from './types';

export const fetchUserRequest = id => ({
    type: types.FETCH_USER_REQUEST,
    meta: { id },
});

export const fetchUserSuccess = (id, payload) => ({
    type: types.FETCH_USER_SUCCESS,
    meta: { id },
    payload: formatEntity({
        entity: payload,
        template: entitiesTemplates.user,
    }),
});

export const fetchUserFailure = (id, error) => ({
    type: types.FETCH_USER_FAILURE,
    meta: { id },
    error,
});

export const fetchUserInvalidate = id => ({
    type: types.FETCH_USER_CANCEL,
    meta: { id },
});
