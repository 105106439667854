export const header = ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 24px',
    gridColumnGap: '1rem',

    padding: '16px 8px',

    borderBottom: `1px solid ${theme.colors.blueGrey1}`,

    fontSize: '0.875rem',
    lineHeight: '0.74',
    letterSpacing: '0.3px',

    color: theme.colors.blueGrey4,
});
