import { sagaEffects, Consts, ReduxUtils, Cache } from '../../dependencies';

import {
    types,
    createTemporaryReportRequest,
    fetchNewReportRequest,
    fetchReportRequest,
    createTemporaryReportReset,
} from '../actions';
import { isNewReportSelector, isTempReportSelector } from '../selectors';

const { take, race, takeLatest, select, put } = sagaEffects;
const { FETCH, CREATE } = Consts.apiTypes;
const { REPORT, NEW_REPORT, TEMPORARY_REPORT } = Consts.entityKeys;

function* createTempReport(slug, searchParams) {
    const { inProgress } = yield select(state => ReduxUtils.apiSelector(state, TEMPORARY_REPORT, CREATE, slug));

    if (!inProgress) {
        yield put(createTemporaryReportRequest(slug, searchParams));
    }
}

function* fetchNewReport() {
    const { inProgress, success } = yield select(state => ReduxUtils.apiSelector(state, NEW_REPORT, FETCH));
    // TODO: implement expiration flag (lastSuccessAt)
    if (!inProgress && !success) {
        yield put(fetchNewReportRequest());
    }
}

function* fetchReport(reportId) {
    const { inProgress, success } = yield select(state => ReduxUtils.apiSelector(state, REPORT, FETCH, reportId));

    // TODO: implement expiration flag (lastSuccessAt)
    if (!inProgress && !success) {
        yield put(fetchReportRequest(reportId));
    }
}

const CACHE_ID = 'fetchReport';

function* handleReportUpdate(action) {
    const { slug, searchParams } = action.payload;

    const areRequestsEqual = yield select(Cache.areGroupValuesEqualSelector, CACHE_ID, action.payload);

    if (!action.meta.forceUpdate && areRequestsEqual) {
        return;
    }

    yield put(Cache.storeGroupValues(CACHE_ID, action.payload));

    const isNewReport = yield select(isNewReportSelector);
    const isTempReport = yield select(isTempReportSelector);

    if (isTempReport) {
        yield createTempReport(slug, searchParams);
        return;
    }

    yield put(createTemporaryReportReset(slug));

    if (isNewReport) {
        yield fetchNewReport();
    } else {
        yield fetchReport(slug);
    }
}

function* reportDidMount() {
    yield takeLatest(types.REPORT_DID_UPDATE, handleReportUpdate);
}

export default function*() {
    while (true) {
        yield race({
            task: reportDidMount(),
            cancel: take(types.REPORT_WILL_UNMOUNT),
        });
    }
}
