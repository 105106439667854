import d from '../defaultValues';
import { convertValue, any } from '../helpers';

const exportTemplate = {
    companyId: convertValue(String),
    id: convertValue(String),
    createdAt: d.string,
    updatedAt: d.string,
    name: d.string,
    period: d.zero,
    serviceKeyId: convertValue(String),
    filters: any,
};

export default exportTemplate;
