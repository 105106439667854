import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from './types';

export const createExpenseRequest = () => ({
    type: types.CREATE_EXPENSE_REQUEST,
});

export const createExpenseSuccess = (id, payload) => ({
    type: types.CREATE_EXPENSE_SUCCESS,
    meta: { id },
    payload: formatEntity({
        entity: payload,
        template: entitiesTemplates.expense,
    }),
});

export const createExpenseFailure = error => ({
    type: types.CREATE_EXPENSE_FAILURE,
    error,
});

export const createExpenseInvalidate = () => ({
    type: types.CREATE_EXPENSE_CANCEL,
});

export const createExpenseReset = () => ({
    type: types.CREATE_EXPENSE_RESET,
});
