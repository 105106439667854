import { React, PropTypes, FelaComponent, Suppliers } from '../../../dependencies';

import SupplierField from '../../../containers/Supplier/SupplierField';

import * as styles from './Supplier.styles';

function Supplier({ disabled, isOpen, openModal, closeModal }) {
    return (
        <FelaComponent style={styles.supplier}>
            <SupplierField {...{ disabled, openModal }} />

            <Suppliers.CreateSupplierModal
                {...{
                    isOpen,
                    closeModal,
                    fillSupplierField: true,
                }}
            />
        </FelaComponent>
    );
}

Supplier.propTypes = {
    disabled: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default Supplier;
