import { PropTypes } from '../../dependencies';

export const calcBarWidth = (barsCount, gap, viewportWidth) => {
    return Math.floor((viewportWidth - (barsCount - 1) * gap) / barsCount);
};

export const barShape = PropTypes.shape({
    id: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    withVat: PropTypes.number.isRequired,
    withoutVat: PropTypes.number.isRequired,
    monthIndex: PropTypes.number.isRequired,
});
