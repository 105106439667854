import types from '../actionTypes';
import { FILTER_DEFAULT_VALUE } from '../../config';

const initialState = FILTER_DEFAULT_VALUE;

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_FILTER: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
};
