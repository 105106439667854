import { sagaEffects, filterSelectors } from '../../dependencies';

import { types, createTemporaryReportRequest, updateReportRequest, fetchNewReportRequest } from '../actions';
import { currentReportIdSelector, isNewReportSelector, isTempReportSelector } from '../selectors';

const { takeEvery, select, put } = sagaEffects;

function* triggerReportUpdate() {
    const activeReportId = yield select(currentReportIdSelector);
    const isNewReport = yield select(isNewReportSelector);
    const isTempReport = yield select(isTempReportSelector);

    if (isTempReport) {
        const reportParams = yield select(filterSelectors.decodeReportFilterParamsSelector);
        yield put(createTemporaryReportRequest(activeReportId, reportParams));
        return;
    }

    if (isNewReport) {
        yield put(fetchNewReportRequest());
        return;
    }

    yield put(updateReportRequest(activeReportId));
}

export default function*() {
    yield takeEvery(types.SET_TAG_VISIBILITY, triggerReportUpdate);
}
