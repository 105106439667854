import { filterSelectors, createSelector } from '../../dependencies';

import { transformReportFiltersToFormValues, transformUrlParamsToFormValues } from '../../utils';

import { currentReportFiltersSelector, isTempReportSelector } from './entities';

export const reportFiltersValuesSelector = createSelector(
    [isTempReportSelector, currentReportFiltersSelector, filterSelectors.decodeReportFilterParamsSelector],
    (isTempReport, currentReportFilters, urlParams) => {
        if (!currentReportFilters) {
            return null;
        }

        if (isTempReport) {
            return transformUrlParamsToFormValues(urlParams);
        }

        return transformReportFiltersToFormValues(currentReportFilters);
    },
);
