import { createSelector, CompaniesSelectors } from 'modules/reports/dependencies';

import { mapBucketItemsToBars, currentReportSelector, currentReportDateRangeSelector } from '../entities';
import { calcAverage, getTotalSums } from '../utils';

const selectBarChart = state => state.barChart;

export const barChartViewportSelector = createSelector(
    [currentReportSelector, selectBarChart],
    (report, { bucket, viewportSize, viewportLeftOffset }) => {
        if (!report || !report.buckets) {
            return null;
        }

        const { buckets, filters } = report;
        const selectedBucket = buckets[bucket];

        return {
            bucket,
            bucketItems: selectedBucket,
            slicedBucketItems: selectedBucket.slice(viewportLeftOffset, viewportLeftOffset + viewportSize),
            total: buckets.total,
            filters,
        };
    },
);

export const barChartSelector = createSelector(
    [CompaniesSelectors.activeCompanyCurrencySelector, barChartViewportSelector, selectBarChart],
    (currency, report, barChart) => {
        if (!report || !report.bucketItems) {
            return {
                ...barChart,
                currency: '',
                data: [],
            };
        }

        return {
            ...barChart,
            currency,
            data: mapBucketItemsToBars(report.bucketItems),
            average: calcAverage(report.slicedBucketItems),
        };
    },
);

export const barChartHeaderSelector = createSelector(
    [selectBarChart, barChartViewportSelector],
    ({ viewportSize, viewportLeftOffset }, report) => {
        return {
            viewportLeftOffset,
            maxViewportLeftOffset: report ? report.bucketItems.length - viewportSize : 0,
        };
    },
);

export const barChartDateRangeSelector = createSelector(currentReportDateRangeSelector, dateRange => {
    if (!dateRange) {
        return {
            from: null,
            to: null,
        };
    }

    return dateRange;
});

export const barChartTotalSumSelector = createSelector(
    [CompaniesSelectors.activeCompanyCurrencySelector, barChartViewportSelector],
    (currency, report) => {
        if (!report) {
            return {
                currency,
            };
        }

        const { withVat, withoutVat } = getTotalSums(report.slicedBucketItems);

        return {
            currency,
            withVat,
            withoutVat,
        };
    },
);
