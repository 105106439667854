export function immutablePropDelete(obj, key) {
    if (obj[key] === undefined) {
        return obj;
    }

    const copy = { ...obj };

    delete copy[key];

    return copy;
}
