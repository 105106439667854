import { strictObjectAccess } from '@ackee/redux-utils';

export const DateRanges = strictObjectAccess({
    // NOTE: this is only for FE to correctly display "Za celou dobu",
    // It has nothing to do with relativePeriod.
    ALL_THE_TIME: 'allTheTime',

    THIS_YEAR: 'thisYear',
    PREV_YEAR: 'prevYear',
    THIS_QUARTER: 'thisQuarter',
    PREV_QUARTER: 'prevQuarter',
    THIS_MONTH: 'thisMonth',
    PREV_MONTH: 'prevMonth',
});
