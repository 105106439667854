import sizes from '../Chip.sizes';

export const shape = ({ isLast, size }) => ({
    display: 'flex',
    marginRight: isLast
        ? 0
        : {
              [sizes.S]: 4,
              [sizes.L]: 8,
          }[size],
    marginBottom: {
        [sizes.S]: 4,
        [sizes.L]: 8,
    }[size],
});

const sizeEnum = {
    [sizes.XS]: {
        height: 22,
        padding: '3px 8px 3px 1px',
        letterSpacing: '0.1px',
        lineHeight: 1.25,
        fontSize: '12px',
        borderRadius: '0 2px 2px 0',
    },
    [sizes.S]: {
        height: 24,
        padding: '0 7px 0 4px',
        lineHeight: 1.64,
        letterSpacing: '0.1px',
        fontSize: '14px',
        borderRadius: '0 2px 2px 0',
    },
    [sizes.L]: {
        height: 40,
        padding: '7px 12px 8px 8px',
        borderRadius: '0 4px 4px 0',
        lineHeight: 1.56,
        letterSpacing: '0.2px',
        fontSize: '16px',
    },
};

export const front = ({ size, color }) => ({
    height: sizeEnum[size].height,
    fill: color,
});

export const body = ({ size, color, theme: { colors } }) => ({
    backgroundColor: color,
    display: 'flex',
    alignItems: 'baseline',
    borderRadius: '0 4px 4px 0',
    color: colors.text,
    ...sizeEnum[size],
});

export const icon = ({ size, theme: { colors } }) => ({
    fill: colors.text,
    ...{
        [sizes.S]: {
            marginLeft: 7,
            height: 8,
            width: 8,
        },
        [sizes.L]: {
            marginLeft: 8,
            height: 11.4,
            width: 11.4,
        },
    }[size],
});
