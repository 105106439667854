import { sagaEffects } from '../../../dependencies';

import submitEditCompanyForm from './submitEditCompanyForm';
import submitCreateCompanyForm from './submitCreateCompanyForm';

const { all } = sagaEffects;

export default function* expensesSaga() {
    yield all([submitEditCompanyForm(), submitCreateCompanyForm()]);
}
