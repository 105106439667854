import types from '../actionTypes';

export const deleteTagRequest = id => ({
    type: types.DELETE_TAG_REQUEST,
    meta: { id },
});

export const deleteTagSuccess = id => ({
    type: types.DELETE_TAG_SUCCESS,
    meta: { id },
});

export const deleteTagFailure = (id, error) => ({
    type: types.DELETE_TAG_FAILURE,
    meta: { id },
    error,
});

export const deleteTagInvalidate = id => ({
    type: types.DELETE_TAG_CANCEL,
    meta: { id },
});
