import {
    config,
    api,
    sagaEffects,
    Petrus,
    Log,
    push,
    ErrorsUtils,
    MessagesActions,
    Consts,
} from 'modules/auth/dependencies';
import types from '../actionTypes';
import { transformCredentials } from '../utils';

import { normalizeAndSaveUser } from './helpers';

const { put, takeEvery } = sagaEffects;

function* handleSignUpForm(action) {
    yield action.startSubmit();

    try {
        const { email, password, firstName, lastName, company } = action.data;
        const response = yield* api.post(config.api.signUp, {
            firstName,
            lastName,
            email,
            password,
            company,
        });
        const { user, credentials } = response.data;
        const tokens = transformCredentials({ credentials, user });

        yield normalizeAndSaveUser(user);

        yield put(Petrus.setUserWithTokens({}, tokens));

        yield put(push(Consts.routes.DASHBOARD));
    } catch (e) {
        Log.error(e);

        const error = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.signUp.api',
            },
        });
        yield put(MessagesActions.displayErrorMessage(error));
    } finally {
        yield action.stopSubmit();
    }
}

export default function* () {
    yield takeEvery(types.SIGN_UP_FORM, handleSignUpForm);
}
