import { React, FelaComponent, childrenPropType } from '../../../dependencies';

import * as styles from './Row.styles';

function Row({ children }) {
    return <FelaComponent style={styles.row}>{children}</FelaComponent>;
}

Row.propTypes = {
    children: childrenPropType.isRequired,
};

export default Row;
