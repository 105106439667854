export const searchBar = () => ({
    display: 'grid',
    gridTemplateColumns: '15fr 70fr 15fr',
    alignItems: 'center',
    width: '100%',
});

export const button = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
});

export const icon = ({ theme: { colors } }) => ({
    height: '0.875rem',
    width: '0.875rem',
    fill: colors.text,
});
