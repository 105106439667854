function navItem({ isActive, theme }) {
    const { colors, transitions } = theme;
    const activeStyle = {
        backgroundColor: colors.primarySelection,
        color: colors.text,
        textDecoration: 'none',
    };
    return {
        color: colors.text,
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: 1.56,
        letterSpacing: '0.2px',
        backgroundColor: isActive ? colors.primarySelection : '',
        borderRadius: '4px',
        padding: '4px 8px 3px',
        textAlign: 'center',

        transition: `background-color ${transitions.text}`,

        onHover: activeStyle,
        onFocus: activeStyle,
        onActive: activeStyle,
    };
}

export { navItem };
