import { config, authApi, Log, sagaEffects, Normalizers, ErrorsUtils } from '../../dependencies';

import { types, fetchServiceKeysSuccess, fetchServiceKeysFailure } from '../actions';

const { put, takeLeading } = sagaEffects;

function* fetchServiceKeys(action) {
    try {
        const { data } = yield* authApi.get(config.api.serviceKeys);

        const { result, entities } = Normalizers.serviceKeys(data);

        yield put(
            fetchServiceKeysSuccess({
                ids: result,
                byId: entities.serviceKeys,
            }),
        );
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e);

        yield put(fetchServiceKeysFailure(errorMessage));
    }
}

export default function* () {
    yield takeLeading(types.FETCH_SERVICE_KEYS_REQUEST, fetchServiceKeys);
}
