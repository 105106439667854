import {
    config,
    authApi,
    takeLatestRequest,
    Log,
    sagaEffects,
    Normalizers,
    ErrorsUtils,
    Tags,
} from '../../dependencies';

import { types, fetchExpensesSuccess, fetchExpensesFailure, fetchExpensesInvalidate } from '../actions';
import { expensesApiSelector } from '../selectors';
import { encodeSearchParams } from 'services/utilities';

const { put, select } = sagaEffects;

function appendParamsListToParams(searchParams, paramsList = []) {
    paramsList.forEach(param => {
        const dividerIndex = param.indexOf('=');
        const key = param.slice(0, dividerIndex);
        const value = param.slice(dividerIndex + 1);

        searchParams.append(key, value);
    });
}

function* fetchExpenses(action, signal) {
    const { groupId } = action.meta;

    try {
        const { offset, limit } = yield select(expensesApiSelector, groupId);
        const { params, paramsList } = action.payload;
        const searchParams = encodeSearchParams({
            offset,
            limit,
            ...params,
        });

        appendParamsListToParams(searchParams, paramsList);

        const { data, headers } = yield* authApi.get(config.api.expenses, {
            signal,
            params: searchParams,
        });

        const { result: ids, entities } = Normalizers.expenses(data);

        yield put(Tags.actions.addTags(Tags.utils.formatPayload(entities.tags)));

        const totalCount = Number(headers['x-total-count']);
        const totalPriceWithVatDefaultCurrency = Number(headers['x-total-price-with-vat-default-currency']) || null;
        const totalPriceWithoutVatDefaultCurrency =
            Number(headers['x-total-price-without-vat-default-currency']) || null;
        const totalCompanyPriceWithVatDefaultCurrency =
            Number(headers['x-total-company-price-with-vat-default-currency']) || null;
        const totalCompanyPriceWithoutVatDefaultCurrency =
            Number(headers['x-total-company-price-without-vat-default-currency']) || null;

        const payload = {
            byId: entities.expenses,
            ids,
            totalPriceWithVatDefaultCurrency,
            totalPriceWithoutVatDefaultCurrency,
            totalCompanyPriceWithVatDefaultCurrency,
            totalCompanyPriceWithoutVatDefaultCurrency,
        };

        const meta = {
            totalCount,
            offset,
        };

        yield put(fetchExpensesSuccess(groupId, payload, meta));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.expenses.api.get',
            },
        });

        yield put(fetchExpensesFailure(groupId, errorMessage));
    }
}

export default function* fetchExpensesWatcher() {
    const actionTypes = {
        REQUEST: types.FETCH_EXPENSES_REQUEST,
        cancelTask: fetchExpensesInvalidate,
        requestIdSelector: action => action.meta.groupId,
    };

    yield takeLatestRequest(actionTypes, fetchExpenses);
}
