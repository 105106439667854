import { React } from '../dependencies';

import Header from './Header';
import List from '../containers/List';

function ListRecurring() {
    return (
        <>
            <Header />
            <List />
        </>
    );
}

export default ListRecurring;
