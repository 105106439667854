import { React, Petrus, UI } from '../dependencies';

import Login from '../containers/LoginForm';

const { Loader } = UI;

function firewall(AuthContent) {
    const WrappedComponent = Petrus.authorizable(AuthContent, Login, Loader);

    return props => <WrappedComponent {...props} />;
}

export default firewall;
