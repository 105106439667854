import { createSelector, AuthUserSelectors, Utils, ActiveCompanySelectors } from '../../../dependencies';

const selectMemberships = state => state.entities.memberships;

export const membershipsSelector = createSelector(selectMemberships, ({ byId, ids }) => ids.map(id => byId[id]));

export const membershipSelector = createSelector([selectMemberships, Utils.passParam], ({ byId }, id) => byId[id]);

export const activeMembershipSelector = createSelector(
    [AuthUserSelectors.authUserSelector, ActiveCompanySelectors.activeCompanyIdSelector, selectMemberships],
    (authUser, activeCompanyId, memberships) => {
        return (authUser?.memberships || [])
            .map(membershipId => memberships.byId[membershipId])
            .filter(Boolean)
            .find(membership => String(membership.companyId) === activeCompanyId);
    },
);

export const companyMembershipIdSelector = createSelector(
    [membershipsSelector, Utils.passParam],
    (memberships, companyId) => {
        const membership = memberships.find(m => String(m.companyId) === String(companyId));
        return String(membership?.id);
    },
);

export const activeMembershipIdSelector = createSelector(
    [activeMembershipSelector],
    activeMembership => activeMembership?.id,
);

export const authUserMembershipSelector = createSelector(
    [membershipSelector, AuthUserSelectors.authUserSelector],
    (membership, authUser) => {
        return authUser.memberships.find(membershipId => membershipId === String(membership.id));
    },
);
