import { sagaEffects } from '../../../dependencies';

import authSessionStart from './authSessionStart';
import authSessionEnd from './authSessionEnd';
import onPageUnload from './onPageUnload';

const { all } = sagaEffects;

export default function*() {
    yield all([authSessionStart(), authSessionEnd(), onPageUnload()]);
}
