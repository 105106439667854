import {
    React,
    PropTypes,
    SelectField,
    Consts,
    UI,
    Utils,
    useIntl,
    useSelector,
    isSubmittingSelector,
    Popover,
} from '../../dependencies';

import * as styles from './Currency.styles';

const { InputWrapper } = UI;

function Currency({ formId, title, ...rest }) {
    const intl = useIntl();
    const submitting = useSelector(isSubmittingSelector(formId));

    const options = React.useMemo(() => {
        return Object.values(Consts.currency).map(value => ({
            label: value,
            value,
        }));
    }, []);

    return (
        <InputWrapper style={styles.currency}>
            <Popover content={title} placement="right">
                <SelectField
                    {...{
                        name: 'currency',
                        label: intl.formatMessage({
                            id: 'expense.form.currency.label',
                        }),
                        options,
                        disabled: submitting,
                        filterOption: Utils.filterOption,
                        showSearch: true,
                        notFoundContent: null,
                        ...rest,
                    }}
                />
            </Popover>
        </InputWrapper>
    );
}

Currency.propTypes = {
    formId: PropTypes.string.isRequired,
    title: PropTypes.string,
};

export default React.memo(Currency);
