import { sagaEffects, ServiceKeysActions, messageActions, push, routePaths } from '../../../dependencies';

import { deleteExportRequest, types } from '../../actions';

const { put, takeLeading, take, all } = sagaEffects;

function* deleteExportWithServiceKey({ exportId, serviceKeyId }) {
    yield put(ServiceKeysActions.deleteServiceKeyRequest(serviceKeyId));
    yield put(deleteExportRequest(exportId));

    const { DELETE_EXPORT_SUCCESS, DELETE_EXPORT_FAILURE } = types;
    const { DELETE_SERVICE_KEY_SUCCESS, DELETE_SERVICE_KEY_FAILURE } = ServiceKeysActions.types;

    const { deleteExport, deleteServiceKey } = yield all({
        deleteExport: take([DELETE_EXPORT_SUCCESS, DELETE_EXPORT_FAILURE]),
        deleteServiceKey: take([DELETE_SERVICE_KEY_SUCCESS, DELETE_SERVICE_KEY_FAILURE]),
    });

    if (deleteExport.type === DELETE_EXPORT_FAILURE) {
        yield put(messageActions.displayErrorMessage(deleteExport.error || deleteServiceKey.error));
    } else {
        yield put(push(routePaths.EXPORTS_RECURRING));
        yield put(
            messageActions.displaySuccessMessage({
                id: 'success.export.api.delete',
            }),
        );
    }
}

export default function* () {
    yield takeLeading(types.DELETE_EXPORT_WITH_SERVICE_KEY, deleteExportWithServiceKey);
}
