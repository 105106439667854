import { takeLatestRequest, sagaEffects, authApi, config, Log, ErrorsUtils, Normalizers } from '../../dependencies';

import { types, fetchReportSuccess, fetchReportFailure, fetchReportInvalidate } from '../actions';

import { formatResponse } from './utils';

const { put } = sagaEffects;

function* fetchReport(action, cancelToken) {
    const { id } = action.meta;

    try {
        const { data } = yield* authApi.get(config.api.report, {
            uriParams: { id },
            cancelToken,
        });
        const { entities } = Normalizers.report(data);

        // TODO: store company entity

        yield put(fetchReportSuccess(id, formatResponse(entities.reports[id])));
    } catch (e) {
        Log.error(e);

        const errorMessages = {
            status: {
                404: {
                    id: 'error.report.api.status.404',
                    values: { id },
                },
            },
            fallback: {
                id: 'error.report.api.get',
                values: { id },
            },
        };

        const uiError = ErrorsUtils.createUIErrorMessage(e, errorMessages);

        yield put(fetchReportFailure(id, uiError));
    }
}

export default function* () {
    const actionTypes = {
        REQUEST: types.FETCH_REPORT_REQUEST,
        cancelTask: fetchReportInvalidate,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, fetchReport);
}
