import {
    React,
    PropTypes,
    FormattedMessage,
    Alert,
    UI,
    CountriesSelectField,
    RegistrationNumberFieldGroup,
    OrganizationNameField,
    VatNumberField,
    StreetField,
    CityField,
    ZipCodeField,
    useIntl,
    Currency,
} from '../../dependencies';

import { Forms } from '../../constants';

import DeleteCompanyButton from '../DeleteCompanyButton';

const { ButtonPrimary, ButtonDefault } = UI;

const Title = {
    [Forms.CREATE_COMPANY]: 'settings.createCompany.title',
    [Forms.EDIT_COMPANY]: 'settings.editCompany.title',
};

const SubmitButton = {
    [Forms.CREATE_COMPANY]: 'settings.createCompany.button.submit',
    [Forms.EDIT_COMPANY]: 'settings.editCompany.button.submit',
};

const CompanyForm = ({ styles, onCancel, handleSubmit, submitting, error, form, initialValues }) => {
    const intl = useIntl();
    const currecyIsNotEditable = form === Forms.EDIT_COMPANY && initialValues.currency !== null;

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.header}>
                <h2 className={styles.title}>
                    <FormattedMessage id={Title[form]} />
                </h2>
                {form === Forms.EDIT_COMPANY && <DeleteCompanyButton />}
            </div>

            {error && (
                <Alert className={styles.error} showIcon type="error" message={<FormattedMessage {...error} />} />
            )}

            <div className={styles.fields}>
                <OrganizationNameField
                    formId={form}
                    tabIndex={1}
                    autoFocus={true}
                    placeholder={intl.formatMessage({
                        id: 'settings.company.name',
                    })}
                />

                <RegistrationNumberFieldGroup formId={form} tabIndex={2} />

                <VatNumberField formId={form} tabIndex={3} />

                <StreetField formId={form} tabIndex={4} />

                <CityField formId={form} tabIndex={5} />

                <ZipCodeField formId={form} tabIndex={6} />

                <CountriesSelectField formId={form} tabIndex={7} />

                <Currency
                    formId={form}
                    tabIndex={8}
                    label={null}
                    placeholder={intl.formatMessage({
                        id: 'settings.company.currency.placeholder',
                    })}
                    disabled={currecyIsNotEditable}
                    title={
                        currecyIsNotEditable &&
                        intl.formatMessage({
                            id: 'settings.company.currency.info',
                        })
                    }
                />
            </div>

            <div className={styles.footer}>
                <ButtonDefault onClick={onCancel} disabled={submitting}>
                    <FormattedMessage id="settings.changePassword.cancel" />
                </ButtonDefault>

                <ButtonPrimary htmlType="submit" loading={submitting}>
                    <FormattedMessage id={SubmitButton[form]} />
                </ButtonPrimary>
            </div>
        </form>
    );
};

CompanyForm.propTypes = {
    styles: PropTypes.shape().isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    onCancel: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
        currency: PropTypes.string,
    }).isRequired,
};

CompanyForm.defaultProps = {
    error: null,
};

export default CompanyForm;
