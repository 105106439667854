import { ReduxUtils, Consts, createSelector } from '../../dependencies';

import { currentReportSlugSelector, isNewReportSelector, currentReportIdSelector } from './location';
import { isTempReportSelector } from './entities';
import { reportFiltersValuesSelector } from './filters';

const { FETCH, CREATE, UPDATE } = Consts.apiTypes;
const { REPORT, NEW_REPORT, TEMPORARY_REPORT, TAGS, SUPPLIERS } = Consts.entityKeys;
const { apiSelector } = ReduxUtils;

export const reportApiSelector = state => {
    const isNewReport = isNewReportSelector(state);
    const isTempReport = isTempReportSelector(state);
    const slug = currentReportSlugSelector(state);

    if (isTempReport) {
        return apiSelector(state, TEMPORARY_REPORT, CREATE, slug);
    }

    if (isNewReport) {
        return apiSelector(state, NEW_REPORT, FETCH);
    }

    return apiSelector(state, REPORT, FETCH, slug);
};

export const updateReportApiSelector = state => {
    const reportId = currentReportIdSelector(state);

    return apiSelector(state, REPORT, UPDATE, reportId);
};

const fetchSuppliersApiSelector = state => apiSelector(state, SUPPLIERS, FETCH);
const fetchTagsApiSelector = state => apiSelector(state, TAGS, FETCH);

export const filtersShowLoaderSelector = createSelector(
    [reportFiltersValuesSelector, fetchSuppliersApiSelector, fetchTagsApiSelector],
    (initialValues, fetchSuppliers, fetchTags) => {
        return !initialValues || fetchSuppliers.inProgress || fetchTags.inProgress;
    },
);
