import { React, PropTypes, DataLoader, connectFela, childrenPropType } from '../../dependencies';

import * as Styles from './Loader.styles';

function ExpensesListLoader({ styles, show, children }) {
    if (!show) {
        return <>{children}</>;
    }

    return (
        <div className={styles.wrapper}>
            <DataLoader show />
        </div>
    );
}

ExpensesListLoader.propTypes = {
    styles: PropTypes.shape().isRequired,
    show: PropTypes.bool.isRequired,
    children: childrenPropType.isRequired,
};

export default connectFela(Styles)(ExpensesListLoader);
