import {
    config,
    aresApi,
    takeLatestRequest,
    Log,
    sagaEffects,
    formValueSelector,
    change,
    ErrorsUtils,
    startAsyncValidation,
    stopAsyncValidation,
    getIntl,
} from '../../dependencies';

import { fetchAresDataSuccess, fetchAresDataFailure, fetchAresDataInvalidate } from '../actions';
import types from '../actionTypes';
import { aresSelector } from '../selectors';

const { put, select } = sagaEffects;

function extractDataFromAresResponse(response) {
    const { psc, cisloOrientacni, kodStatu, nazevUlice, nazevObce, cisloDomovni } = response.sidlo;
    const street = `${nazevUlice} ${cisloDomovni}`;

    return {
        name: response.obchodniJmeno,
        registrationNumber: String(response.ico),
        zipCode: String(psc),
        street: cisloOrientacni ? `${street}/${cisloOrientacni}` : street,
        city: nazevObce,
        country: kodStatu,
        vatNumber: response.dic,
    };
}

function* fillForm(formId, data) {
    const { name, zipCode, street, city, country, vatNumber } = data;

    yield put(change(formId, 'name', name));
    yield put(change(formId, 'zipCode', zipCode));
    yield put(change(formId, 'street', street));
    yield put(change(formId, 'city', city));
    yield put(change(formId, 'country', country));
    yield put(change(formId, 'vatNumber', vatNumber));
}

function* handleFailure(formId, errorMessage) {
    const intl = yield getIntl();
    const translatedErrorMessage = yield intl.formatMessage(errorMessage, errorMessage.values);

    yield put(
        stopAsyncValidation(formId, {
            registrationNumber: translatedErrorMessage,
        }),
    );

    yield put(fetchAresDataFailure(errorMessage));
}

function* fetchAresData(action, cancelToken) {
    const { formId } = action.payload;

    try {
        const selectorFormValues = formValueSelector(formId);
        const registrationNumber = yield select(state => selectorFormValues(state, 'registrationNumber'));

        if (!registrationNumber) {
            yield put(fetchAresDataInvalidate());
            return;
        }

        const aresData = yield select(aresSelector, registrationNumber);

        if (aresData) {
            yield fillForm(formId, aresData);
            yield put(fetchAresDataInvalidate());
            return;
        }

        yield put(startAsyncValidation(formId));

        const { data } = yield* aresApi.get(config.ares.economicalSubjects, {
            uriParams: {
                ico: registrationNumber,
            },
            cancelToken,
        });

        if (!data) {
            yield handleFailure(formId, {
                id: 'error.registrationNumber.api.status.404',
                values: {
                    registrationNumber,
                },
            });
        } else {
            const subjectInfo = yield extractDataFromAresResponse(data);

            yield fillForm(formId, subjectInfo);

            yield put(fetchAresDataSuccess(registrationNumber, subjectInfo));
        }
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.registrationNumber.api.get',
            },
        });

        yield handleFailure(formId, errorMessage);
    }
}

export default function* fetchAresDataWatcher() {
    const actionTypes = {
        REQUEST: types.FETCH_ARES_DATA_REQUEST,
        cancelTask: fetchAresDataInvalidate,
    };

    yield takeLatestRequest(actionTypes, fetchAresData);
}
