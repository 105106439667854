import { schema } from 'normalizr';
import { entityKeys } from 'constants/index';

// companies
export const company = new schema.Entity(entityKeys.COMPANIES);

// memberships
export const membership = new schema.Entity(entityKeys.MEMBERSHIPS, {
    company,
});
export const membershipsList = [membership];

// users
export const user = new schema.Entity(entityKeys.USERS, {
    company,
    memberships: membershipsList,
});

// suppliers
export const supplier = new schema.Entity(entityKeys.SUPPLIERS);

export const suppliersList = new schema.Array(supplier);

// tags
export const tag = new schema.Entity(entityKeys.TAGS);
export const tagsList = new schema.Array(tag);

// expenses
export const expense = new schema.Entity(entityKeys.EXPENSES, {
    supplier,
    tags: tagsList,
    author: user,
});

export const expensesList = new schema.Array(expense);

export const expenseTrigger = new schema.Entity(entityKeys.EXPENSE_TRIGGERS, {
    company,
    expense,
});

// reports
export const report = new schema.Entity(entityKeys.REPORTS, {
    company,
});

export const reportsList = new schema.Array(report);

export const dashboard = new schema.Entity(entityKeys.DASHBOARD, {
    sample: expensesList,
});

// exports
export const exportItem = new schema.Entity(entityKeys.EXPORTS);

export const exportList = new schema.Array(exportItem);

export const serviceKey = new schema.Entity(entityKeys.SERVICE_KEYS);

export const serviceKeyList = new schema.Array(serviceKey);
