import { connect, ExpensesConfig, arrayPush, injectIntl, compose } from '../dependencies';

function withRoundingTotal({ formId, decimalDifferenceSelector }) {
    const { expenseItemInitialValues } = ExpensesConfig;

    const mapStateToProps = state => {
        return {
            decimalDifference: decimalDifferenceSelector(state),
        };
    };

    const mapDispatchToProps = (dispatch, { intl: { formatMessage } }) => ({
        addRoundingItem(decimalDifference) {
            const item = {
                ...expenseItemInitialValues,
                quantity: 1,
                name: formatMessage({
                    id: 'expense.create.round.name',
                }),
                pricePerUnit: decimalDifference,
            };

            dispatch(arrayPush(formId, 'items', item));
        },
    });

    const mergeProps = (stateProps, dispatchProps) => ({
        ...stateProps,
        ...dispatchProps,
    });

    return WrappedComponent =>
        compose(
            injectIntl,
            connect(
                mapStateToProps,
                mapDispatchToProps,
                mergeProps,
            ),
        )(WrappedComponent);
}

export default withRoundingTotal;
