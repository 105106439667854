import { React, useDispatch, useSelector } from '../dependencies';

import { initializeTagVisibility, setTagVisibility } from '../services/actions';
import {
    initialTagsVisibilityValuesSelector,
    currentTagsVisibilityValuesSelector,
    currentReportSlugSelector,
} from '../services/selectors';

export function useTagVisibility() {
    const dispatch = useDispatch();
    const slug = useSelector(currentReportSlugSelector);
    const initialValues = useSelector(initialTagsVisibilityValuesSelector);
    const values = useSelector(currentTagsVisibilityValuesSelector);

    React.useEffect(() => {
        dispatch(initializeTagVisibility(initialValues));
        // eslint-disable-next-line
    }, [slug]);

    return React.useMemo(() => {
        return {
            values,
            setTagVisibility: (tagId, nextValue) => {
                dispatch(setTagVisibility(tagId, nextValue));
            },
        };
        // eslint-disable-next-line
    }, [JSON.stringify(values)]);
}
