import { React, useSelector, useDispatch, useCurrentObjectRef } from '../dependencies';

import { fetchServiceKeysRequest, fetchServiceKeysCancel } from '../services/actions';
import { fetchServiceKeysApiSelector } from '../services/selectors';

export default function useFetchServiceKeys() {
    const api = useSelector(fetchServiceKeysApiSelector);
    const apiRef = useCurrentObjectRef(api);
    const dispatch = useDispatch();
    const request = () => dispatch(fetchServiceKeysRequest());

    React.useEffect(() => {
        const { inProgress, success } = apiRef.current;
        if (!inProgress && !success) {
            request();
        }

        return () => {
            if (inProgress) {
                dispatch(fetchServiceKeysCancel());
            }
        };
        // eslint-disable-next-line
    }, []);

    const { inProgress, success, error } = api;

    return {
        inProgress,
        success,
        error: {
            retry: request,
            message: error,
        },
    };
}
