import { connect, compose } from '../dependencies';

import Pagination from '../components/Pagination';
import { setPage } from '../services/actions';
import { paginationSelector } from '../services/selectors';

const mapStateToProps = (state, { entityKey, itemId }) => {
    const { page, totalCount, amount } = paginationSelector(state, { entityKey, itemId });

    return {
        page,
        totalCount,
        amount,
    };
};

const mapDispatchToProps = (dispatch, { entityKey, itemId }) => ({
    setPage(page) {
        dispatch(
            setPage({
                entityKey,
                page,
                itemId,
            }),
        );
    },
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(Pagination);
