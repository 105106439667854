import { React, FelaComponent, PropTypes, InputWrapper, AntInput, AntForm } from '../../dependencies';

import style from './SuppliersFilter.style';

const AntFormItem = AntForm.Item;

const SuppliersFilter = ({ onChange, intl }) => (
    <FelaComponent style={style}>
        <InputWrapper>
            <AntFormItem>
                <AntInput
                    placeholder={intl.formatMessage({ id: 'supplier.list.filter.placeholder' })}
                    onChange={e => {
                        const { value } = e.target;
                        onChange(value);
                    }}
                />
            </AntFormItem>
        </InputWrapper>
    </FelaComponent>
);

SuppliersFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    intl: PropTypes.shape().isRequired,
};

export default SuppliersFilter;
