import types from './types';

export const fetchServiceKeysRequest = () => ({
    type: types.FETCH_SERVICE_KEYS_REQUEST,
});

export const fetchServiceKeysSuccess = (payload, meta) => ({
    type: types.FETCH_SERVICE_KEYS_SUCCESS,
    payload,
    meta,
});

export const fetchServiceKeysFailure = error => ({
    type: types.FETCH_SERVICE_KEYS_FAILURE,
    error,
});

export const fetchServiceKeysCancel = () => ({
    type: types.FETCH_SERVICE_KEYS_CANCEL,
});
