import { Normalizers, sagaEffects, Actions, initActiveCompany } from '../../../dependencies';

import { Actions as UserActions } from '../../../modules/user';

const { put } = sagaEffects;

export default function* (data) {
    const { entities } = Normalizers.user(data);

    const user = entities.users[data.id];

    const result = {
        ...user,
        id: String(user.id),
        companyId: typeof user.company === 'number' ? String(user.company) : user.company,
        memberships: user.memberships.map(String),
    };

    yield put(UserActions.setAuthUser(result));
    yield put(Actions.setMemberships(entities.memberships));
    yield put(Actions.setCompanies(entities.companies));

    yield initActiveCompany(entities.memberships);
}
