import { React, PropTypes, FormattedMessage, ButtonDanger } from '../../dependencies';

function ButtonRemove({ inProgress, deleteExpenseRequest }) {
    return (
        <ButtonDanger
            {...{
                disabled: inProgress,
                loading: inProgress,
                onClick: deleteExpenseRequest,
                outline: true,
            }}
        >
            <FormattedMessage id="expense.edit.delete" />
        </ButtonDanger>
    );
}

ButtonRemove.propTypes = {
    inProgress: PropTypes.bool.isRequired,
    deleteExpenseRequest: PropTypes.func.isRequired,
};

export default ButtonRemove;
