import {
    compose,
    connect,
    loadable,
    Loader,
    ReduxUtils,
    Consts,
    withErrorBoundary,
    fetchDependencies,
    withErrorMessage,
    mapProps,
} from '../dependencies';

import Dashboard from '../components/Dashboard';
import * as Actions from '../services/actions';

const { FETCH } = Consts.apiTypes;
const { DASHBOARD } = Consts.entityKeys;

const mapStateToProps = state => {
    const fetchDashboard = ReduxUtils.apiSelector(state, DASHBOARD, FETCH);

    return {
        fetchDashboard,
        showLoader: fetchDashboard.inProgress || !fetchDashboard.success,
    };
};

const mapDispatchToProps = {
    fetchDashboardRequest: Actions.fetchDashboardRequest,
    fetchDashboardInvalidate: Actions.fetchDashboardInvalidate,
    fetchDashboardReset: Actions.fetchDashboardReset,
};

const mergeProps = (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
});

const fetchConfig = {
    onLoad({ fetchDashboard: { inProgress }, fetchDashboardRequest }) {
        if (!inProgress) {
            fetchDashboardRequest();
        }
    },
    onUnload({ fetchDashboard: { inProgress }, fetchDashboardInvalidate, fetchDashboardReset }) {
        if (inProgress) {
            fetchDashboardInvalidate();
        }

        fetchDashboardReset();
    },
};

const mapOwnProps = ({ fetchDashboard: { error }, fetchDashboardRequest, showLoader }) => {
    return {
        showLoader,
        error,
        onErrorMessageClick: fetchDashboardRequest,
    };
};

export default compose(
    withErrorBoundary,
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps,
    ),
    fetchDependencies(fetchConfig),
    mapProps(mapOwnProps),
    withErrorMessage,
    loadable(Loader),
)(Dashboard);
