import { config, authApi, Log, sagaEffects, Normalizers, ErrorsUtils } from '../../dependencies';

import { types, createServiceKeySuccess, createServiceKeyFailure } from '../actions';

const { put, takeLeading } = sagaEffects;

function* createServiceKey(action) {
    try {
        const { data } = yield* authApi.post(config.api.serviceKeys, action.payload);

        const { result: id, entities } = Normalizers.serviceKey(data);

        yield put(createServiceKeySuccess(id, entities.serviceKeys[id]));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e);

        yield put(createServiceKeyFailure(errorMessage));
    }
}

export default function* createServiceKeyWatcher() {
    yield takeLeading(types.CREATE_SERVICE_KEY_REQUEST, createServiceKey);
}
