import { React, Suspense, PropTypes, FelaComponent, theme, isEqual } from '../../dependencies';

import Label from './Label';

import * as styles from './ChartDoughnut.styles';

const PieChart = React.lazy(() => import('react-minimal-pie-chart'));

const NO_DATA = [
    {
        value: 100,
        color: theme.colors.blueGrey1,
        tagNames: [],
        withVat: '',
        withoutVat: '',
        label: '',
    },
];

function ChartDoughnut({ data, height, currency, ...props }) {
    return (
        <FelaComponent style={styles.wrapper}>
            <FelaComponent
                {...{
                    style: styles.chart,
                    data,
                    height,
                }}
            >
                {chart => (
                    <Suspense fallback={null}>
                        <PieChart
                            {...{
                                ...props,
                                label: data.length === 0 ? false : <Label currency={currency} />,
                                data: data.length === 0 ? NO_DATA : data,
                                className: chart.className,
                            }}
                        />
                    </Suspense>
                )}
            </FelaComponent>
        </FelaComponent>
    );
}

export const dataShape = PropTypes.arrayOf(
    PropTypes.shape({
        value: PropTypes.number.isRequired,
        withVat: PropTypes.node.isRequired,
        withoutVat: PropTypes.node.isRequired,
        tagNames: PropTypes.arrayOf(PropTypes.string).isRequired,
        combinedSegment: PropTypes.bool.isRequired,
    }),
);

ChartDoughnut.propTypes = {
    data: dataShape.isRequired,
    lineWidth: PropTypes.number,
    labelPosition: PropTypes.number,
    height: PropTypes.number,
    currency: PropTypes.string.isRequired,
};

ChartDoughnut.defaultProps = {
    lineWidth: 38,
    labelPosition: 80,
    height: 303,
};

export default React.memo(ChartDoughnut, isEqual);
