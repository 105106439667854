import {
    config,
    authApi,
    takeLatestRequest,
    Log,
    sagaEffects,
    push,
    messageActions,
    ErrorsUtils,
} from '../../dependencies';

import {
    deleteReportSuccess,
    deleteReportFailure,
    deleteReportInvalidate,
    types,
    updateReportReset,
    fetchReportReset,
} from '../actions';

import { routePaths } from '../../constants';

const { put } = sagaEffects;

function* deleteReport(action, cancelToken) {
    const { id } = action.meta;

    try {
        const requestConfig = {
            uriParams: { id },
            cancelToken,
        };

        yield* authApi.delete(config.api.report, requestConfig);

        yield put(push(routePaths.REPORT_NEW));

        yield put(updateReportReset(id));
        yield put(fetchReportReset(id));

        yield put(deleteReportSuccess(id));

        yield put(
            messageActions.displaySuccessMessage({
                id: 'success.report.delete',
                values: { id },
            }),
        );
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            status: {
                404: {
                    id: 'error.report.api.delete.status.404',
                    values: { id },
                },
            },
            fallback: {
                id: 'error.report.api.delete',
                values: { id },
            },
        });

        yield put(deleteReportFailure(id, errorMessage));

        yield put(messageActions.displayErrorMessage(errorMessage));
    }
}

export default function* deleteReportWatcher() {
    const actionTypes = {
        REQUEST: types.DELETE_REPORT_REQUEST,
        cancelTask: deleteReportInvalidate,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, deleteReport);
}
