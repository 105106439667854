import { formatEntities, entitiesTemplates } from '../../dependencies';
import types from './types';

export const fetchExpensesRequest = (groupId, { params = {}, paramsList = [] } = {}) => ({
    type: types.FETCH_EXPENSES_REQUEST,
    meta: {
        groupId,
    },
    payload: {
        params,
        paramsList,
    },
});

export const fetchExpensesSuccess = (groupId, { ids, byId, ...payloadRest }, meta = {}) => ({
    type: types.FETCH_EXPENSES_SUCCESS,
    meta: {
        ...meta,
        groupId,
    },
    payload: {
        ...payloadRest,
        ids,
        byId: formatEntities({
            template: entitiesTemplates.expenseListItem,
            entities: byId,
        }),
    },
});

export const fetchExpensesFailure = (groupId, error) => ({
    type: types.FETCH_EXPENSES_FAILURE,
    meta: { groupId },
    error,
});

export const fetchExpensesInvalidate = groupId => ({
    type: types.FETCH_EXPENSES_CANCEL,
    meta: { groupId },
});

export const fetchExpensesReset = groupId => ({
    type: types.FETCH_EXPENSES_RESET,
    meta: { groupId },
});

export const saveExpenses = (groupId, { byId, ids }, meta) => ({
    type: types.SAVE_EXPENSES,
    meta: {
        offset: 0,
        ...meta,
        groupId,
    },
    payload: {
        ids,
        byId: formatEntities({
            template: entitiesTemplates.expenseListItem,
            entities: byId,
        }),
    },
});
