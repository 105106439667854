import { round, Config } from '../../../../dependencies';

const { decimalSeparator } = Config.numberInput;

function handleDecimalPoint(value) {
    return value ? String(value).replace(decimalSeparator, '.') : value;
}

function parseNumber(value) {
    const parsedValue = Number.parseFloat(handleDecimalPoint(value));
    return Number.isNaN(parsedValue) ? 0 : parsedValue;
}

const { maximumFractionDigits } = Config.currency;

export const parseFields = {
    quantity(value) {
        return Math.max(1, parseNumber(round(value)));
    },
    vatRate(value) {
        value = parseNumber(round(value, 2)) / 100;

        return Math.min(1, value);
    },
    pricePerUnit(value) {
        const parsedValue = parseNumber(value);
        return round(parsedValue, maximumFractionDigits + 1);
    },
};

export const formatFields = {
    vatRate(value) {
        return round(value * 100, 2);
    },
};
