import { React, useSelector } from '../../dependencies';

import { exportsSelector } from '../../services/selectors';

import Item from './Item';
import Empty from './Empty';

const RecurringExportList = () => {
    const exports = useSelector(exportsSelector);
    return (
        <>
            {exports.length === 0 && <Empty />}
            {exports.map(item => (
                <Item key={item.id} {...item} />
            ))}
        </>
    );
};

export default RecurringExportList;
