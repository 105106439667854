import {
    config,
    authApi,
    takeLatestRequest,
    Log,
    sagaEffects,
    Normalizers,
    Actions,
    ErrorsUtils,
    Suppliers,
} from '../../dependencies';

import {
    fetchExpenseTriggerSuccess,
    fetchExpenseTriggerFailure,
    fetchExpenseTriggerInvalidate,
    types,
} from '../actions';

const { put } = sagaEffects;

function* fetchExpenseTrigger(action, cancelToken) {
    const { id } = action.meta;

    try {
        const { data } = yield* authApi.get(config.api.expenseTrigger, {
            uriParams: {
                id,
            },
            cancelToken,
        });

        const { entities } = Normalizers.expenseTrigger(data);

        // NOTE: Even though there is used a 'for cycle', there is expected to be only one item.
        for (const [expenseId, expense] of Object.entries(entities.expenses || {})) {
            yield put(Actions.fetchExpenseSuccess(expenseId, expense));
        }

        for (const [supplierId, supplier] of Object.entries(entities.suppliers || {})) {
            yield put(Suppliers.actions.fetchSupplierSuccess(supplierId, supplier));
        }

        yield put(fetchExpenseTriggerSuccess(id, entities.expenseTriggers[id]));
    } catch (e) {
        Log.error(e);

        const values = { id };
        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            status: {
                404: {
                    id: 'error.expenseTrigger.api.status.404',
                    values,
                },
            },
            fallback: {
                id: 'error.expenseTrigger.api.get',
                values,
            },
        });

        yield put(fetchExpenseTriggerFailure(id, errorMessage));
    }
}

export default function* fetchExpenseTriggerWatcher() {
    const actionTypes = {
        REQUEST: types.FETCH_EXPENSE_TRIGGER_REQUEST,
        cancelTask: fetchExpenseTriggerInvalidate,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, fetchExpenseTrigger);
}
