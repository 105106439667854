import {
    React,
    Switch,
    Route,
    Header,
    firewall,
    useFetchSuppliers,
    useFetchTags,
    useSelector,
    MembershipsSelectors,
} from '../dependencies';

import { useAuthRoutes } from '../hooks';

import TopOffset from './TopOffset';

const AuthApp = () => {
    useFetchSuppliers();
    useFetchTags();

    const routes = useAuthRoutes();
    const isNotOwner = useSelector(MembershipsSelectors.isNotOwnerSelector);

    return (
        <>
            <Header />
            <TopOffset />
            <Switch>
                {routes.map((route, index) => {
                    if (route.render) {
                        return <Route key={index} {...route} render={() => route.render({ isNotOwner })} />;
                    }

                    return <Route key={index} {...route} />;
                })}
            </Switch>
        </>
    );
};

export default firewall(AuthApp);
