import { formatEntity, entitiesTemplates } from '../../dependencies';
import types from '../actionTypes';

export const updateSupplierRequest = id => ({
    type: types.UPDATE_SUPPLIER_REQUEST,
    meta: { id },
});

export const updateSupplierSuccess = (id, payload) => ({
    type: types.UPDATE_SUPPLIER_SUCCESS,
    meta: { id },
    payload: formatEntity({
        entity: payload,
        template: entitiesTemplates.supplier,
    }),
});

export const updateSupplierFailure = (id, error) => ({
    type: types.UPDATE_SUPPLIER_FAILURE,
    meta: { id },
    error,
});

export const updateSupplierInvalidate = id => ({
    type: types.UPDATE_SUPPLIER_CANCEL,
    meta: { id },
});
