import { React, PropTypes, UI, useModal, Consts, CustomPropTypes } from '../../dependencies';

const { Icons } = UI;

const DeleteMembershipButton = ({ styles, id, email }) => {
    const modal = useModal(Consts.modals.DELETE_MEMBERSHIP);

    return (
        <button
            type="button"
            onClick={() =>
                modal.open({
                    id,
                    email,
                })
            }
            className={styles.button}
        >
            <Icons.Remove className={styles.icon} />
        </button>
    );
};

DeleteMembershipButton.propTypes = {
    styles: PropTypes.shape().isRequired,
    id: CustomPropTypes.id.isRequired,
    email: PropTypes.string.isRequired,
};

export default DeleteMembershipButton;
