import { config } from '../dependencies';

const { forms } = config;
const { expenses, reports, exports } = config.filters.defaultInitials;

export const DefaultValue = {
    [forms.expensesFilters]: expenses,
    [forms.report]: reports,
    [forms.disposableExport]: exports,
};

export * from './staticRanges';
