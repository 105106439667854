import {
    React,
    PropTypes,
    Link,
    FormattedMessage,
    Nav,
    ButtonDefault,
    FelaComponent,
    ExpensesConsts,
} from '../../dependencies';

import * as styles from './Nav.styles';

const { routePaths } = ExpensesConsts;

function NavWrapper({
    expenseId,
    expenseNumber,
    expenseTriggerId,
    onExpenseDuplicate,
    goBack,
    expenseTriggerName,
    authorFullName,
    canEditExpense,
}) {
    const navProps = {
        goBack,
        authorFullName,
        titleProps: {
            messageId: 'expense.detail.title',
            values: {
                number: expenseNumber || expenseTriggerName,
            },
            style: {
                marginBottom: 0,
            },
        },
    };

    return (
        <Nav {...navProps}>
            <FelaComponent style={styles.buttons}>
                <ButtonDefault onClick={onExpenseDuplicate}>
                    <FormattedMessage id="expense.detail.duplicate" />
                </ButtonDefault>

                {canEditExpense && (
                    <ButtonDefault>
                        <Link
                            to={
                                expenseTriggerId
                                    ? routePaths.EXPENSES_RECURRING_ITEM_EDIT.replace(
                                          ':expenseTriggerId',
                                          expenseTriggerId,
                                      )
                                    : routePaths.EXPENSES_ITEM_EDIT.replace(':expenseId', expenseId)
                            }
                        >
                            <FormattedMessage id="expense.detail.edit" />
                        </Link>
                    </ButtonDefault>
                )}
            </FelaComponent>
        </Nav>
    );
}

NavWrapper.propTypes = {
    goBack: PropTypes.func.isRequired,
    expenseId: PropTypes.string.isRequired,
    expenseNumber: PropTypes.string,
    expenseTriggerId: PropTypes.string,
    expenseTriggerName: PropTypes.string,
    onExpenseDuplicate: PropTypes.func.isRequired,
    authorFullName: PropTypes.string.isRequired,
    canEditExpense: PropTypes.bool,
};

NavWrapper.defaultProps = {
    expenseTriggerName: null,
    expenseNumber: null,
    expenseTriggerId: null,
    canEditExpense: true,
};

export default React.memo(NavWrapper);
