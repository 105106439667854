import {
    reduxForm,
    connect,
    compose,
    config,
    submitForm,
    injectIntl,
    withErrorBoundary,
    ServiceKeysConsts,
    ExportsActions,
} from '../../dependencies';

import ExportAdd from '../../components/ExportAdd';

import validate from './ExportAddForm.validate';

const form = config.forms.createExport;

export default compose(
    withErrorBoundary,
    connect(null, {
        onSubmit: submitForm(form, ExportsActions.submitCreateExportForm),
    }),
    injectIntl,
    reduxForm({
        form,
        validate,
        initialValues: {
            period: 1,
            service: ServiceKeysConsts.Service.FAKTUROID,
            credentials: {
                username: '',
                password: '',
            },
        },
    }),
)(ExportAdd);
