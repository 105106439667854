import { createSelector, ActiveCompanySelectors } from '../../../dependencies';
import { NEW_REPORT } from '../../../constants';
import { trasformReportFiltersToApiFilters } from '../../../utils';

import { selectTempReport, selectNewReport, hiddenTagsSelector } from '../entities';

const tempNewReportSelector = state => selectTempReport(state, NEW_REPORT);

export const createReportDataSelector = createSelector(
    [selectNewReport, tempNewReportSelector, ActiveCompanySelectors.activeCompanyIdSelector, hiddenTagsSelector],
    (newReport, tempReport, companyId, hiddenTags) => {
        return {
            filters: trasformReportFiltersToApiFilters(tempReport ? tempReport.filters : newReport.filters),
            hiddenTags,
            companyId,
        };
    },
);
