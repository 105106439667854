import { Utils } from '../../dependencies';

const { validatorsWithMessage, translate, getFieldsErrors } = Utils.InputValidation;

const { isRequired } = validatorsWithMessage;

const fieldsValidators = {
    email: isRequired,
    password: isRequired,
};

export default function validateSignInForm(values, { intl }) {
    return translate(intl.formatMessage, getFieldsErrors(fieldsValidators, values));
}
