import { useSelector, useDispatch } from '../dependencies';

import { deleteMembershipApiSelector } from '../../../services/selectors';
import { deleteMembershipRequest } from '../../../services/actions';

export function useDeleteMembership(id) {
    const dispatch = useDispatch();
    const { inProgress, error } = useSelector(state => deleteMembershipApiSelector(state, id));

    return {
        inProgress,
        error,
        deleteMembership() {
            dispatch(deleteMembershipRequest(id));
        },
    };
}
