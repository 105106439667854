import { Utils } from '../../dependencies';

const { validatorsWithMessage, translate, getFieldsErrors } = Utils.InputValidation;

const { isRequired, isAlphaNumeric, isSafePassword } = validatorsWithMessage;

const fieldsValidators = {
    email: isRequired,
    password: [isRequired, isSafePassword],
    firstName: [isRequired, isAlphaNumeric],
    lastName: [isRequired, isAlphaNumeric],
};

const companyFieldsValidators = {
    name: [isRequired, isAlphaNumeric],
};

export default function validateSignUpForm(values, { intl }) {
    return {
        ...translate(intl.formatMessage, getFieldsErrors(fieldsValidators, values)),
        company: translate(intl.formatMessage, getFieldsErrors(companyFieldsValidators, values.company)),
    };
}
