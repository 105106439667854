import * as React from 'react';

export { default as ArrowLeft } from './ArrowLeft';
export { default as Remove } from './Remove';
export { default as LogoExpenses } from './LogoExpenses';
export { default as Calender } from './Calender';
export { default as DocumentNew } from './DocumentNew';
export { default as Document } from './Document';
export { default as Plus } from './Plus';
export { default as User } from './User';
export { default as Link } from './Link';
export { default as Download } from './Download';
export { default as ChevronDown } from './ChevronDown';
export { default as Chevron2Left } from './Chevron2Left';
export { default as Cross } from './Cross';
export { default as TagShape } from './TagShape';
export { default as TagShapeFilled } from './TagShapeFilled';
export { default as Exit } from './Exit';
export { default as Update } from './Update';
export { default as NeutralFace } from './NeutralFace';
export { default as Rotate } from './Rotate';
export { default as Ok } from './Ok';
export { default as Info } from './Info';
export { default as Triangel } from './Triangel';
export { default as HappyFace } from './HappyFace';
export { default as SadFace } from './SadFace';
export { default as Search } from './Search';
export { default as Logout } from './Logout';
export { default as LineDotted } from './LineDotted';
export { default as EyeOn } from './EyeOn';
export { default as EyeOff } from './EyeOff';

export const TagBorder = React.lazy(() => import('./TagBorder'));
export const TagBorderSmall = React.lazy(() => import('./TagBorderSmall'));
