import { sagaEffects, Log, Petrus } from '../../dependencies';

import { removeActiveCompany } from '../actions';

import companyIdStorage from './storage';
import setRequestInterceptor from './requestInterceptor';

const { put, takeEvery } = sagaEffects;

export default function* () {
    yield takeEvery(Petrus.AUTH_SESSION_END, function* () {
        try {
            yield companyIdStorage.remove();

            setRequestInterceptor(null);

            yield put(removeActiveCompany());
        } catch (e) {
            Log.error(e);
        }
    });
}
