import { ReduxUtils, Consts, Selectors } from '../../dependencies';

export const paginationSelector = (state, { entityKey, itemId }) => {
    const page = Selectors.pageUrlParamSelector(state);
    const api = ReduxUtils.apiSelector(state, entityKey, Consts.apiTypes.FETCH, itemId);

    return {
        ...api,
        page: Number(page || api.page),
    };
};
