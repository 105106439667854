export const buttonRemove = () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: 10,
    alignItems: 'center',
    height: 'calc(100% - 10px)',
});

export const iconRemove = ({ theme: { colors } }) => ({
    height: 20,
    fill: colors.primary,
});
