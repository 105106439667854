import types from './types';

export const updateUserRequest = (id, payload) => ({
    type: types.UPDATE_USER_REQUEST,
    meta: { id },
    payload,
});

export const updateUserSuccess = (id, payload) => ({
    type: types.UPDATE_USER_SUCCESS,
    meta: { id },
    payload,
});

export const updateUserFailure = (id, error) => ({
    type: types.UPDATE_USER_FAILURE,
    meta: { id },
    error,
});

export const updateUserInvalidate = id => ({
    type: types.UPDATE_USER_CANCEL,
    meta: { id },
});
