import { fetchDependencies, connect, compose, ReduxUtils, Consts, ExpensesActions } from '../dependencies';

const { EXPENSE } = Consts.entityKeys;
const { FETCH } = Consts.apiTypes;

function fetchExpense(WrappedComponent) {
    const mapStateToProps = (
        state,
        {
            match: {
                params: { expenseId },
            },
        },
    ) => {
        return {
            fetchExpenseApi: ReduxUtils.apiSelector(state, EXPENSE, FETCH, expenseId),
            expenseId,
        };
    };

    const mapDispatchToProps = (
        dispatch,
        {
            match: {
                params: { expenseId },
            },
        },
    ) => ({
        fetchExpenseRequest() {
            dispatch(ExpensesActions.fetchExpenseRequest(expenseId));
        },
        fetchExpenseInvalidate() {
            dispatch(ExpensesActions.fetchExpenseInvalidate(expenseId));
        },
    });

    const fetchConfig = {
        onLoad({ fetchExpenseApi: { inProgress, success }, fetchExpenseRequest }) {
            if (!inProgress && !success) {
                fetchExpenseRequest();
            }
        },
        onUnload({ fetchExpenseApi: { inProgress }, fetchExpenseInvalidate }) {
            if (inProgress) {
                fetchExpenseInvalidate();
            }
        },
    };

    return compose(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
        fetchDependencies(fetchConfig),
    )(WrappedComponent);
}

export default fetchExpense;
