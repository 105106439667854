import { React, PropTypes, TextField, injectIntl, InputWrapper } from '../../../dependencies';

import * as styles from './ExternalLink.styles';

function ExternalLink({ intl, disabled }) {
    return (
        <InputWrapper style={styles.input}>
            <TextField
                {...{
                    name: 'fileUrl',
                    label: intl.formatMessage({
                        id: 'expense.form.externalLink.label',
                    }),
                    disabled,
                    type: 'url',
                    pattern: '*.',
                }}
            />
        </InputWrapper>
    );
}

ExternalLink.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default injectIntl(ExternalLink);
