import { createSelector, moment, Selectors } from '../../dependencies';

export const selectExports = state => state.entities.exports;
export const selectExport = (state, { exportId }) => state.entities.exports.byId[exportId];

export const exportsSelector = createSelector(selectExports, Selectors.localeSelector, ({ byId, ids }, locale) =>
    ids
        .map(id => byId[id])
        .map(item => ({
            ...item,
            period: moment.duration(item.period, 's').locale(locale).humanize(false),
        })),
);
