import { compose, connect, fetchDependencies } from '../dependencies';

import { FILTER_DEFAULT_VALUE } from '../config';
import { setFilter } from '../services/actions';

import Suppliers from '../components/Suppliers';

export default compose(
    connect(
        null,
        dispatch => ({
            fetch: () => {
                dispatch(setFilter(FILTER_DEFAULT_VALUE));
            },
        }),
    ),
    fetchDependencies(),
)(Suppliers);
