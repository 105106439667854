import { ReduxUtils } from '../../../dependencies';
import { types } from '../../actions';

const actionTypes = {
    REQUEST: types.FETCH_SERVICE_KEY_REQUEST,
    SUCCESS: types.FETCH_SERVICE_KEY_SUCCESS,
    FAILURE: types.FETCH_SERVICE_KEY_FAILURE,
    CANCEL: types.FETCH_SERVICE_KEY_CANCEL,
};

const basicReducer = ReduxUtils.basicApiReducer({
    actionTypes,
});

export default ReduxUtils.containerReducer({
    childReducer: basicReducer,
    actionTypes: Object.values(actionTypes),
    selectors: {
        itemId: action => action.meta.id,
    },
});
