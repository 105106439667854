import { createSelector, ReduxUtils, Consts } from '../../dependencies';

const { EXPENSE, TAGS, EXPENSE_TRIGGER } = Consts.entityKeys;
const { FETCH } = Consts.apiTypes;

export const isFormReadySelector = createSelector(
    [
        (state, expenseId) => ReduxUtils.apiSelector(state, EXPENSE, FETCH, expenseId),
        state => ReduxUtils.apiSelector(state, TAGS, FETCH),
    ],
    (...fetchStates) => {
        return fetchStates.every(({ inProgress, success }) => !inProgress && success);
    },
);

export const isRecurringFormReadySelector = createSelector(
    [
        (state, { expenseId }) => isFormReadySelector(state, expenseId),
        (state, { expenseTriggerId }) => ReduxUtils.apiSelector(state, EXPENSE_TRIGGER, FETCH, expenseTriggerId),
    ],
    (tagsAndExpenseAreReady, expenseTriggerApi) => {
        const { success, inProgress } = expenseTriggerApi;

        return tagsAndExpenseAreReady && success && !inProgress;
    },
);
