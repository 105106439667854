export const types = {
    TRACK_AUTH_SESSION_START: 'google-analytics/TRACK_AUTH_SESSION_START',
    TRACK_AUTH_SESSION_END: 'google-analytics/TRACK_AUTH_SESSION_END',
};

export const trackAuthSessionStart = () => ({
    type: types.TRACK_AUTH_SESSION_START,
    payload: performance.now(),
});

export const trackAuthSessionEnd = () => ({
    type: types.TRACK_AUTH_SESSION_END,
});
