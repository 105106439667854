import { ReduxUtils } from '../../dependencies';

import { MODULE_NAME } from '../../constants';

const createTypes = ReduxUtils.createAsyncType({
    modulePrefix: MODULE_NAME,
});

export default ReduxUtils.strictObjectAccess({
    ...createTypes({
        typePrefix: 'FETCH_SERVICE_KEY_',
    }),
    ...createTypes({
        typePrefix: 'CREATE_SERVICE_KEY_',
    }),
    ...createTypes({
        typePrefix: 'UPDATE_SERVICE_KEY_',
    }),
    ...createTypes({
        typePrefix: 'DELETE_SERVICE_KEY_',
    }),
    ...createTypes({
        typePrefix: 'FETCH_SERVICE_KEYS_',
    }),
});
