const text = colors => ({
    color: colors.text,
    lineHeight: 1.56,
    letterSpacing: '0.2px',
    fontSize: '16px',
});

export const header = ({ theme: { colors } }) => ({
    borderBottom: `1px solid ${colors.blueGrey1}`,
    padding: '8px 12px 4px',
    ...text(colors),
});

export const body = () => ({
    padding: '8px 12px',
    display: 'grid',
    gridTemplateColumns: '8px 1fr',
    gridColumnGap: '8px',
    gridRowGap: '4px',
    alignItems: 'center',
    maxHeight: 190,
    overflowY: 'auto',
});

export const dot = ({ theme: { colors } }) => ({
    height: 8,
    width: 8,
    borderRadius: '50%',
    backgroundColor: colors.blueGrey2,
});

export const tag = ({ theme: { colors } }) => ({
    ...text(colors),
});

export const noTags = ({ theme: { colors } }) => ({
    color: colors.blueGrey4,
    lineHeight: 1.56,
    letterSpacing: '0.2px',
    fontSize: '14px',
    padding: '8px 12px',
    textAlign: 'center',
});
