import { config, Utils, resolveRelativeRange, resolveRelativePeriod } from '../dependencies';

const rangeDefaults = config.filters.defaultInitials.expenses;
const defaults = config.filters.defaultInitials;

// decoded url params -> form values
export function transformUrlParamsToFormValues(urlParams) {
    const { includeTags, excludeTags, supplierId, currency, paymentMethod } = urlParams;

    return {
        range: resolveRelativeRange(urlParams) || resolveRelativeRange(rangeDefaults),
        includeTags,
        excludeTags,
        supplierId,
        currency,
        paymentMethod,
    };
}

// form values -> url params
export function transformFormValuesToUrlParams(formValues) {
    const { range, ...rest } = formValues;

    return {
        ...range,
        ...rest,
    };
}

export function transformUrlParamsToApiRequest(urlParams) {
    const { from, to } = resolveRelativePeriod(resolveRelativeRange(urlParams) || resolveRelativeRange(rangeDefaults));

    return {
        ...urlParams,
        from: Utils.getISODateWithoutTimezone(from),
        to: Utils.getISODateWithoutTimezone(to),
        excludeTags: Utils.parseStringIds(urlParams.excludeTags),
        includeTags: Utils.parseStringIds(urlParams.includeTags),
        supplierId: urlParams.supplierId || null,
        orderBy: urlParams.orderBy || defaults.orderBy,
        order: urlParams.order || defaults.order,
        currency: urlParams.currency,
        paymentMethod: urlParams.paymentMethod,
    };
}
