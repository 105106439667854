import React from 'react';

const EyeOff = props => (
    <svg viewBox="0 0 22 22" {...props}>
        <defs>
            <polygon points="0.0001 0.586 5.4141 0.586 5.4141 5.9999 0.0001 5.9999" />
            <polygon points="0.0001 0.586 5.414 0.586 5.414 6 0.0001 6" />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(0.000000, -1.000000)">
                <g>
                    <path
                        d="M10.7109,15.6358 C7.7219,15.6358 4.9059,14.1618 3.1099,11.7218 C4.9059,9.2818 7.7219,7.8078 10.7109,7.8078 C13.6999,7.8078 16.5159,9.2818 18.3119,11.7218 C16.5159,14.1618 13.6999,15.6358 10.7109,15.6358 M20.7109,11.7218 C18.7119,8.1478 14.9869,5.7378 10.7109,5.7378 C6.4349,5.7378 2.7099,8.1478 0.7109,11.7218 C2.7099,15.2958 6.4349,17.7068 10.7109,17.7068 C14.9869,17.7068 18.7119,15.2958 20.7109,11.7218"
                        fill="#000000"
                    />
                    <path
                        d="M10.7109,9.6504 C9.6069,9.6504 8.7109,10.5784 8.7109,11.7214 C8.7109,12.8664 9.6069,13.7934 10.7109,13.7934 C11.8149,13.7934 12.7109,12.8664 12.7109,11.7214 C12.7109,10.5784 11.8149,9.6504 10.7109,9.6504"
                        fill="#000000"
                    />
                    <polygon fill="#000000" points="16 18.4141 20 22.4141 21.414 21.0001 17.414 17.0001" />
                    <g transform="translate(0.000000, 0.414100)">
                        <mask fill="white">
                            <use xlinkHref="#path-1" />
                        </mask>
                        <g />
                        <polygon
                            fill="#000000"
                            mask="url(#mask-2)"
                            points="5.4141 4.5859 1.4141 0.5859 0.0001 1.9999 4.0001 5.9999"
                        />
                    </g>
                    <polygon fill="#000000" points="0 21 1.414 22.414 5.414 18.414 4 17" />
                    <g transform="translate(16.000000, 0.414100)">
                        <mask fill="white">
                            <use xlinkHref="#path-3" />
                        </mask>
                        <g />
                        <polygon
                            fill="#000000"
                            mask="url(#mask-4)"
                            points="5.4141 2 4.0001 0.586 0.0001 4.586 1.4141 6"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default EyeOff;
