import types from './types';

export const updateMembershipRequest = (id, data) => ({
    type: types.UPDATE_MEMBERSHIP_REQUEST,
    meta: { id: String(id) },
    payload: data,
});

export const updateMembershipSuccess = (id, payload) => ({
    type: types.UPDATE_MEMBERSHIP_SUCCESS,
    meta: { id: String(id) },
    payload,
});

export const updateMembershipFailure = (id, error) => ({
    type: types.UPDATE_MEMBERSHIP_FAILURE,
    meta: { id: String(id) },
    error,
});

export const updateMembershipCancel = id => ({
    type: types.UPDATE_MEMBERSHIP_CANCEL,
    meta: { id: String(id) },
});
