import { React, PropTypes, connectFela, Navbar, SearchBar } from '../../dependencies';

import * as Styles from './Header.style';

const Header = ({ styles, searchIsVisible, showSearch, hideSearch }) => {
    return (
        <div className={styles.headerWrapper}>
            <div className={styles.header}>
                {searchIsVisible ? <SearchBar onClose={hideSearch} /> : <Navbar onSearchClick={showSearch} />}
            </div>
        </div>
    );
};

Header.propTypes = {
    styles: PropTypes.shape().isRequired,
    searchIsVisible: PropTypes.bool.isRequired,
    showSearch: PropTypes.func.isRequired,
    hideSearch: PropTypes.func.isRequired,
};

export default connectFela(Styles)(Header);
