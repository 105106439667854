import types from './types';

export const updateCompanyRequest = (id, data) => ({
    type: types.UPDATE_COMPANY_REQUEST,
    meta: { id },
    payload: data,
});

export const updateCompanySuccess = (id, payload) => ({
    type: types.UPDATE_COMPANY_SUCCESS,
    meta: { id },
    payload,
});

export const updateCompanyFailure = (id, error) => ({
    type: types.UPDATE_COMPANY_FAILURE,
    meta: { id },
    error,
});

export const updateCompanyCancel = id => ({
    type: types.UPDATE_COMPANY_CANCEL,
    meta: { id },
});
