export const item = ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0px, 200px)) 1fr',
    gridColumnGap: '1rem',
    alignItems: 'center',

    padding: '15px 0 13px',
    margin: '0 30px',

    fontSize: '1rem',
    lineHeight: '1.56rem',
    letterSpacing: '0.15px',

    borderBottom: `1px solid ${theme.colors.blueGrey1}`,

    color: theme.colors.text,
});

export const buttons = () => ({
    display: 'grid',
    justifyContent: 'flex-end',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: '1rem',
});
