import { React, Icons, ExpensesConsts } from '../../dependencies';

import ButtonLink from './ButtonLink';

function ButtonCreate() {
    return (
        <ButtonLink
            {...{
                Icon: Icons.Plus,
                messageId: 'expense.create.button.oneTime',
                to: ExpensesConsts.routePaths.EXPENSES_NEW,
            }}
        />
    );
}

export default React.memo(ButtonCreate);
