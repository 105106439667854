import sizes from '../Chip.sizes';

export const shape = ({ isLast, size }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginRight: isLast
        ? 0
        : {
              [sizes.S]: 8,
              [sizes.L]: 16,
          }[size],
});

const sizeEnum = {
    [sizes.XS]: {
        height: 22,
        padding: '3px 8px 3px 1px',
        letterSpacing: '0.1px',
        lineHeight: 1.25,
        fontSize: '12px',
        borderRadius: '0 2px 2px 0',
    },
    [sizes.S]: {
        height: 24,
        lineHeight: 1.64,
        letterSpacing: '0.1px',
        fontSize: '14px',
        borderRadius: '0 2px 2px 0',
    },
    [sizes.L]: {
        height: 40,
        padding: '8px 8px 7px 8px',
        borderRadius: '0 4px 4px 0',
        lineHeight: 1.56,
        letterSpacing: '0.2px',
        fontSize: '16px',
    },
};

export const body = ({ size, theme: { colors } }) => ({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    color: colors.blueGrey4,
    ...sizeEnum[size],
});

export const icon = ({ size, theme: { colors } }) => ({
    fill: colors.blueGrey4,

    ...{
        [sizes.S]: {
            marginRight: 7,
            height: 8,
            width: 8,
        },
        [sizes.L]: {
            marginRight: 8,
            height: 11.4,
            width: 11.4,
        },
    }[size],
});

export const borderIcon = ({ size, theme: { colors } }) => ({
    fill: colors.blueGrey3,
    ...{
        [sizes.XS]: {},
        [sizes.S]: {
            height: 24,
            width: 73,
        },
        [sizes.L]: {
            width: 88,
            height: 40,
        },
    }[size],
});
