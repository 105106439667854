import React from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from '@ackee/lucas';
import { connect as connectFela } from 'react-fela';

import * as Styles from './Container.styles';

const Container = ({ children, styles }) => {
    return <div className={styles.container}>{children}</div>;
};

Container.propTypes = {
    children: childrenPropType,
    styles: PropTypes.shape().isRequired,
};

Container.defaultProps = {
    children: null,
};

export default connectFela(Styles)(Container);
