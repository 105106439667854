import { createSelector, Selectors, qs } from '../../dependencies';
import { decodeExpensesFilterParams, decodeReportFilterParams, decodeExportsFilterParams } from '../../utils';

const searchSelector = createSelector(
    Selectors.locationSelector,
    location => qs.parse(location.search),
);

export const decodeReportFilterParamsSelector = createSelector(
    searchSelector,
    decodeReportFilterParams,
);

export const decodeExpensesFilterParamsSelector = createSelector(
    searchSelector,
    decodeExpensesFilterParams,
);

export const decodeExportsFilterParamsSelector = createSelector(
    searchSelector,
    decodeExportsFilterParams,
);
