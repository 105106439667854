import {
    reduxForm,
    config,
    isEmpty,
    Filter,
    commonFiltersActions,
    compose,
    connect,
    loadable,
    DataLoader,
} from '../dependencies';

import { transformFormValuesToUrlParams } from '../utils';
import { filterFormInitialValuesSelector } from '../services/selectors';

const mapStateToProps = state => {
    const initialValues = filterFormInitialValuesSelector(state);

    return {
        initialValues,
        // TODO:
        // showLoader: filtersShowLoaderSelector(state),
        showLoader: !initialValues,
    };
};

const mapDispatchToProps = {
    setFilterUrlParams: commonFiltersActions.setFilterUrlParams,
};

const formConfig = {
    form: config.forms.expensesFilters,
    enableReinitialize: true,
    onChange(values, dispatch, { pristine, setFilterUrlParams }, previousValues) {
        // Check if onChange is called on initialValues (there are no previousValues)
        if (pristine || isEmpty(previousValues)) {
            return;
        }

        const formValuesAsUrlParams = transformFormValuesToUrlParams(values);

        setFilterUrlParams(formValuesAsUrlParams);
    },
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    loadable(DataLoader),
    reduxForm(formConfig),
)(Filter);
