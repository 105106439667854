import { React, FelaComponent, PropTypes, Icons } from '../../../dependencies';

import style from './ResetButton.style';

const ResetButton = ({ onClick, disabled }) => (
    <FelaComponent as="span" style={style}>
        <button disabled={disabled} type="button" onClick={onClick}>
            <Icons.Cross width="12" height="12" />
        </button>
    </FelaComponent>
);

ResetButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

ResetButton.defaultProps = {
    onClick: () => {},
    disabled: false,
};

export default ResetButton;
