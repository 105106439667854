import { React, PropTypes, FormattedMessage, ButtonDefault } from '../../../dependencies';

function ButtonRunExport(props) {
    return (
        <ButtonDefault size="small" {...props}>
            <FormattedMessage id="export.button.export" />
        </ButtonDefault>
    );
}

ButtonRunExport.propTypes = {
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default ButtonRunExport;
