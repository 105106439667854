import { sagaEffects, Storage } from '../../../dependencies';

import types from '../../actionTypes';
import { setDataAnalysisPersmission } from '../../actions';
import { DATA_ANALYSIS_PERSMISSION_KEY } from '../../../config';

const { takeEvery, put } = sagaEffects;

export default function* () {
    yield takeEvery([types.LOGIN_FORM, types.SIGN_UP_FORM], function* ({ data: { dataAnalysis } }) {
        yield Storage.set(DATA_ANALYSIS_PERSMISSION_KEY, dataAnalysis);

        yield put(setDataAnalysisPersmission(dataAnalysis));
    });
}
