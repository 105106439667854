import { React, PropTypes, UI, TextField, useIntl, useSelector, isSubmittingSelector } from '../../dependencies';

const { InputWrapper } = UI;

const OrganizationNameField = ({ formId, ...rest }) => {
    const intl = useIntl();
    const submitting = useSelector(isSubmittingSelector(formId));

    return (
        <InputWrapper>
            <TextField
                name="name"
                autoComplete="organization"
                placeholder={intl.formatMessage({
                    id: `supplier.form.name.placeholder`,
                })}
                disabled={submitting}
                {...rest}
            />
        </InputWrapper>
    );
};

OrganizationNameField.propTypes = {
    formId: PropTypes.string.isRequired,
    tabIndex: PropTypes.number.isRequired,
    style: PropTypes.func,
};

export default React.memo(OrganizationNameField);
