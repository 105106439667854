import { ORDER_TYPE } from '../constants';

export function getNextOrder(order) {
    switch (order) {
        case ORDER_TYPE.asc:
            return ORDER_TYPE.desc;

        case ORDER_TYPE.desc:
            return ORDER_TYPE.not;

        default:
            return ORDER_TYPE.asc;
    }
}
