import { React, PropTypes, TextField, injectIntl, InputWrapper } from '../../../dependencies';

import * as styles from './Name.styles';

function Name({ intl: { formatMessage }, disabled }) {
    return (
        <InputWrapper style={styles.name}>
            <TextField
                {...{
                    name: 'name',
                    placeholder: formatMessage({
                        id: 'expense.form.name.label',
                    }),
                    label: formatMessage({
                        id: 'expense.form.name.label',
                    }),
                    disabled,
                }}
            />
        </InputWrapper>
    );
}

Name.propTypes = {
    intl: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default injectIntl(Name);
