import { sagaEffects, MessagesActions, getIntl } from '../../../dependencies';

import { types, updateMembershipRequest } from '../../actions';

const { put, takeEvery, take } = sagaEffects;

function* updateMembershipRole(action) {
    const { id, role } = action.payload;

    yield put(
        updateMembershipRequest(id, {
            role,
        }),
    );

    const { UPDATE_MEMBERSHIP_SUCCESS, UPDATE_MEMBERSHIP_FAILURE } = types;

    const result = yield take([UPDATE_MEMBERSHIP_SUCCESS, UPDATE_MEMBERSHIP_FAILURE]);

    switch (result.type) {
        case UPDATE_MEMBERSHIP_SUCCESS: {
            const intl = yield getIntl();
            const {
                role,
                user: { email },
            } = result.payload;

            yield put(
                MessagesActions.displaySuccessMessage({
                    id: 'success.memberships.update.role',
                    values: {
                        email,
                        role: `'${intl.formatMessage({ id: `role.${role}` })}'`,
                    },
                }),
            );
            break;
        }

        case UPDATE_MEMBERSHIP_FAILURE:
            yield put(
                MessagesActions.displayErrorMessage({
                    id: 'error.memberships.api.update.role',
                }),
            );
            break;

        default:
    }
}

export default function* createMembershipWatcher() {
    yield takeEvery(types.UPDATE_MEMBERSHIP_ROLE, updateMembershipRole);
}
