import { config, authApi, takeLatestRequest, Log, sagaEffects, Modals, ErrorsUtils, Actions } from '../../dependencies';

import { deleteModalIdFactory } from '../../utils';

import types from '../actionTypes';
import { deleteTagSuccess, deleteTagFailure, deleteTagInvalidate } from '../actions';

const { put } = sagaEffects;

function* deleteTag(action, cancelToken) {
    const { id } = action.meta;

    try {
        const requestConfig = {
            uriParams: { id },
            cancelToken,
        };

        yield* authApi.delete(config.api.tag, requestConfig);

        yield put(deleteTagSuccess(id));

        yield put(Modals.actions.closeModal(deleteModalIdFactory(id)));

        yield put(Actions.resetAllExpenses());
        yield put(Actions.resetAllReports());
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.tag.api.delete',
            },
        });

        yield put(deleteTagFailure(id, errorMessage));
    }
}

export default function* deleteTagWatcher() {
    const actionTypes = {
        REQUEST: types.DELETE_TAG_REQUEST,
        cancelTask: deleteTagInvalidate,
        requestIdSelector: action => action.meta.id,
    };

    yield takeLatestRequest(actionTypes, deleteTag);
}
