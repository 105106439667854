import { ReduxUtils, Paging, Consts } from 'modules/expenses/dependencies';

import { types } from 'modules/expenses/services/actions';

export const actionTypes = {
    REQUEST: types.FETCH_EXPENSES_REQUEST,
    SUCCESS: types.FETCH_EXPENSES_SUCCESS,
    FAILURE: types.FETCH_EXPENSES_FAILURE,
    CANCEL: types.FETCH_EXPENSES_CANCEL,
    SET_PAGE: Paging.actionTypes.SET_PAGE,
    RESET: types.FETCH_EXPENSES_RESET,
};

const paginationReducer = ReduxUtils.paginationApiReducer({
    actionTypes,
    initialState: {
        limit: 13, // items per request
        amount: 13, // items per page
    },
    selectors: {
        totalCount: action => action.meta.totalCount,
        currentCount: action => action.payload.ids.length,
    },
    actionFilters: {
        setPage: action => {
            const { entityKey, typeId } = action.meta;

            return entityKey === Consts.entityKeys.EXPENSES && typeId === Consts.apiTypes.FETCH;
        },
    },
});

export default paginationReducer;
