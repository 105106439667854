import qs from 'query-string';

export const deleteSearchParams = (locationSearch = '', keys = []) => {
    const search = qs.parse(locationSearch);

    for (const key of keys) {
        delete search[key];
    }

    return qs.stringify(search);
};

export const safeJsonParse = str => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return null;
    }
};
