import {
    sagaEffects,
    initialize,
    config,
    push,
    ExpenseTriggers,
    Log,
    messageActions,
    ErrorsUtils,
} from '../../dependencies';

import { types, duplicateExpenseFailure, duplicateExpenseSuccess, fetchNextExpenseNumberRequest } from '../actions';
import {
    expenseSelector,
    updateExpenseInitialValuesSelector,
    updateRecurringExpenseInitialValuesSelector,
} from '../selectors';
import { types as ExpenseTypes, routePaths } from '../../constants';

const { take, select, put, takeLeading } = sagaEffects;

function* initializeNewExpense(expense) {
    const recurring = expense.type === ExpenseTypes.TEMPLATE;

    // toolbox form - set initial values
    yield put(
        initialize(config.forms.createExpenseToolbox, {
            recurring,
            statistic: expense.type === ExpenseTypes.STATISTIC || expense.number === null,
        }),
    );

    // fetch new next expense number
    yield put(fetchNextExpenseNumberRequest(expense.type));
    yield take([
        types.FETCH_NEXT_EXPENSE_NUMBER_SUCCESS,
        types.FETCH_NEXT_EXPENSE_NUMBER_FAILURE,
        types.FETCH_NEXT_EXPENSE_NUMBER_CANCEL,
    ]);

    const expenseId = expense.id;
    const expenseTriggerId = yield select(ExpenseTriggers.selectors.selectExpenseTriggerId, expenseId);

    // main form - set initial values
    const selector = recurring ? updateRecurringExpenseInitialValuesSelector : updateExpenseInitialValuesSelector;
    const selectorParams = recurring
        ? {
              expenseId,
              expenseTriggerId,
          }
        : expenseId;
    const initialValues = yield select(selector, selectorParams);

    yield put(
        initialize(config.forms.createExpense, {
            ...initialValues,
            files: [],
        }),
    );
}

function* expenseDuplication(action) {
    const { expenseId } = action.payload;

    try {
        const expense = yield select(expenseSelector, expenseId);

        if (!expense) {
            throw new Error(`Can't duplicate unfetched expense, expense ID: '${expense.id}'`);
        }

        yield put(
            push(expense.type === ExpenseTypes.TEMPLATE ? routePaths.EXPENSES_RECURRING_NEW : routePaths.EXPENSES_NEW),
        );

        // reset expense number for the duplication
        yield put(duplicateExpenseSuccess(expenseId));

        yield initializeNewExpense(expense);
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.expense.duplicate',
            },
        });

        yield put(duplicateExpenseFailure(expenseId, errorMessage));

        yield put(messageActions.displayErrorMessage(errorMessage));
    }
}

export default function*() {
    yield takeLeading(types.DUPLICATE_EXPENSE_REQUEST, expenseDuplication);
}
