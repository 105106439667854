import React from 'react';

const Remove = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <g transform="translate(-2.000000, -2.000000)">
                <path d="M8,13 L16,13 L16,11 L8,11 L8,13 Z M12,20 C7.589,20 4,16.411 4,12 C4,7.589 7.589,4 12,4 C16.411,4 20,7.589 20,12 C20,16.411 16.411,20 12,20 L12,20 Z M12,2 C6.477,2 2,6.478 2,12 C2,17.522 6.477,22 12,22 C17.523,22 22,17.522 22,12 C22,6.478 17.523,2 12,2 L12,2 Z" />
            </g>
        </g>
    </svg>
);

export default Remove;
