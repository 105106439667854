import { React, Switch, Route, Redirect, Consts } from '../dependencies';

import { routePaths } from '../constants';

import ExpensesList from '../modules/list';
import ExpenseCreate from '../modules/create';
import ExpenseDetail, { RecurringExpenseDetail } from '../modules/detail';
import { NormalExpenseEdit, RecurringExpenseEdit } from '../modules/edit';

const routes = [
    {
        path: routePaths.EXPENSES,
        render: () => <ExpensesList />,
    },
    {
        path: routePaths.EXPENSES_NEW,
        render: () => <ExpenseCreate />,
    },
    {
        path: routePaths.EXPENSES_ITEM,
        component: ExpenseDetail,
    },
    {
        path: routePaths.EXPENSES_ITEM_EDIT,
        component: NormalExpenseEdit,
    },
    {
        path: routePaths.EXPENSES_RECURRING_NEW,
        render: () => (
            <ExpenseCreate
                toolboxFormInitialValues={{
                    recurring: true,
                }}
            />
        ),
    },
    {
        path: routePaths.EXPENSES_RECURRING_ITEM,
        // eslint-disable-next-line
        render: ({ match }) => <RecurringExpenseDetail {...{ match }} />,
    },
    {
        path: routePaths.EXPENSES_RECURRING_ITEM_EDIT,
        // eslint-disable-next-line
        render: ({ match }) => {
            return <RecurringExpenseEdit {...{ match }} />;
        },
    },
    {
        path: routePaths.EXPENSES_RECURRING,
        render: () => <Redirect to={Consts.routes.LISTS_EXPENSES_RECURRING} />,
    },
    {
        render: () => <Redirect to={routePaths.EXPENSES} />,
    },
];

export default function Expenses() {
    return (
        <Switch>
            {routes.map((route, index) => (
                <Route key={index} exact {...route} />
            ))}
        </Switch>
    );
}
