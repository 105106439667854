import { Utils, ReduxUtils, Actions, AUTH_SESSION_END } from '../../dependencies';
import { types } from '../actions';

function cacheReducer(state = {}, action) {
    switch (action.type) {
        case types.STORE_GROUP_VALUES:
            return {
                ...state,
                [action.meta.id]: action.payload,
            };

        case types.DELETE_GROUP_VALUES:
            return Utils.immutablePropDelete(state, action.meta.id);

        default:
            return state;
    }
}

const { RESET_ALL_EXPENSES, RESET_ALL_REPORTS } = Actions.types;

export default ReduxUtils.basicResetReducer(cacheReducer, [RESET_ALL_EXPENSES, RESET_ALL_REPORTS, AUTH_SESSION_END]);
