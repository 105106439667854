import { createSelector, Selectors } from '../../dependencies';

export const selectExpense = (state, id) => state.entities.expenses.details[id];

export const expenseSelector = createSelector(
    selectExpense,
    expense => expense || {},
);

export const expenseTypeSelector = createSelector(
    expenseSelector,
    expense => expense.type || '',
);

export const expenseIsSyncedSelector = createSelector(
    expenseSelector,
    expense => Boolean(expense.synced),
);

export const syncedExportsSelector = createSelector(
    [selectExpense, Selectors.localeSelector],
    (expense, locale) => {
        if (!expense || !expense.synced) {
            return null;
        }

        const date = new Date(expense.synced);

        const formattedDate = Intl.DateTimeFormat(locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        }).format(date);

        const formattedTime = Intl.DateTimeFormat(locale, {
            hour: 'numeric',
            minute: 'numeric',
        }).format(date);

        return {
            date: formattedDate,
            time: formattedTime,
        };
    },
);
