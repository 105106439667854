import {
    sagaEffects,
    Log,
    authApi,
    config,
    takeLatestRequest,
    Config,
    getFormValues,
    messageActions,
    ErrorsUtils,
} from '../../dependencies';

import {
    types,
    fetchNextExpenseNumberSuccess,
    fetchNextExpenseNumberFailure,
    fetchNextExpenseNumberInvalidate,
} from '../actions';
import { expenseStatisticalInitialValues } from '../../config';

const { put, select } = sagaEffects;

function* fetchNextExpenseNumber(action) {
    const { type } = action.meta;

    try {
        const formValues = yield select(getFormValues(Config.forms.createExpense));
        const issuedOn =
            formValues && formValues.issuedOn ? formValues.issuedOn : expenseStatisticalInitialValues.issuedOn;

        const { data } = yield* authApi.get(config.api.nextExpenseNumber, {
            params: {
                type,
                issuedOn,
            },
        });

        yield put(fetchNextExpenseNumberSuccess(type, data));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.expenseNumber.api.get',
            },
        });

        yield put(
            messageActions.displayErrorMessage(errorMessage, {
                duration: 4000,
            }),
        );

        yield put(fetchNextExpenseNumberFailure(type, errorMessage));
    }
}

export default function* () {
    const actionTypes = {
        REQUEST: types.FETCH_NEXT_EXPENSE_NUMBER_REQUEST,
        cancelTask: fetchNextExpenseNumberInvalidate,
        requestIdSelector: action => action.meta.type,
    };

    yield takeLatestRequest(actionTypes, fetchNextExpenseNumber);
}
