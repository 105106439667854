import { React, PropTypes, Field } from '../dependencies';

import DateRangePicker from '../containers/DateRangePicker';

const DateRangePickerField = ({ name, ...props }) => {
    return (
        <Field
            {...{
                name,
                component: DateRangePicker,
                props,
            }}
        />
    );
};

DateRangePickerField.propTypes = {
    name: PropTypes.string.isRequired,
};

export default DateRangePickerField;
