import d from '../defaultValues';
import { convertValue, any } from '../helpers';

const report = {
    name: d.string,
    id: convertValue(String),
    companyId: convertValue(String),
    hiddenTags: d.reports.hiddenTags,
    buckets: any,
    createdAt: d.string,
    updatedAt: d.string,
    company: convertValue(String),
    filters: d.reports.filters,
    tagIds: d.array,
};

export default report;
