import { React, FelaComponent } from '../../dependencies';
import Loader from '../Loader';

import * as styles from './AppLoader.styles';

const AppLoader = () => (
    <FelaComponent style={styles.appLoader}>
        <Loader show />
    </FelaComponent>
);

export default AppLoader;
