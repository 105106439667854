export const fullName = ({ theme: { colors } }) => ({
    color: colors.text,
    fontSize: '1.5rem',
    lineHeight: 1.94,
    letterSpacing: '0.3px',

    marginBottom: '2rem',
});

export const text = ({ theme: { colors } }) => ({
    color: colors.text,
    letterSpacing: '0.15px',
    lineHeight: 1.56,
    fontSize: '1rem',
    marginTop: 4,
});

export const textRow = () => ({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: '1rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
});
