import { React, PropTypes, FelaComponent, useSelector, MembershipsSelectors } from '../../dependencies';

import { updateModalIdFactory } from '../../utils';

import TagListHeader from '../TagListHeader';

import CreateTag from '../../containers/CreateTag';

import { tagShape } from '../TagListItem';
import TagListItem from '../../containers/TagListItem';

import style from './TagList.style';

const TagList = ({ tags }) => {
    const isContributor = useSelector(MembershipsSelectors.isContributorSelector);

    return (
        <FelaComponent style={style}>
            <TagListHeader />
            <CreateTag />
            {tags.map(tag => (
                <TagListItem
                    modalId={updateModalIdFactory(tag.id)}
                    isContributor={isContributor}
                    key={tag.id}
                    {...tag}
                />
            ))}
        </FelaComponent>
    );
};

TagList.propTypes = {
    tags: PropTypes.arrayOf(tagShape),
};

TagList.defaultProps = {
    tags: [],
};

export default TagList;
