import {
    config,
    authApi,
    Log,
    sagaEffects,
    ErrorsUtils,
    ModalsActions,
    Consts,
    MessagesActions,
    ActiveCompanyActions,
    AuthUserActions,
    MembershipsSelectors,
    AuthUserSelectors,
    difference,
    MembershipsActions,
} from '../../dependencies';

import { types, deleteCompanySuccess, deleteCompanyFailure } from '../actions';
import { selectCompanies } from '../selectors';

const { put, takeEvery, select } = sagaEffects;

function* findNextActiveCompanyId(deletedId) {
    const { ids } = yield select(selectCompanies);

    const index = ids.findIndex(id => id === deletedId);
    const prevId = ids[index - 1];
    const nextId = ids[index + 1];

    return prevId ?? nextId ?? null;
}

function* deleteCompany(action) {
    const { id } = action.meta;

    try {
        const nextActiveId = yield findNextActiveCompanyId(id);
        const obsoleteMembershipId = yield select(MembershipsSelectors.companyMembershipIdSelector, id);
        const authUser = yield select(AuthUserSelectors.authUserSelector);

        yield put(MembershipsActions.deleteMembershipRequest(obsoleteMembershipId));

        yield* authApi.delete(config.api.company, {
            uriParams: { id },
        });

        yield put(
            AuthUserActions.updateAuthUser({
                companyId: nextActiveId,
                memberships: difference(authUser.memberships, [obsoleteMembershipId]),
            }),
        );

        yield put(deleteCompanySuccess(id));

        yield put(ActiveCompanyActions.setActiveCompanyRequest(nextActiveId));

        yield put(ModalsActions.closeModal(Consts.modals.EDIT_COMPANY));

        yield put(
            MessagesActions.displaySuccessMessage({
                id: 'success.company.delete',
            }),
        );
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            fallback: {
                id: 'error.companies.api.delete',
            },
        });

        yield put(deleteCompanyFailure(id, errorMessage));
    }
}

export default function* deleteCompanyWatcher() {
    yield takeEvery(types.DELETE_COMPANY_REQUEST, deleteCompany);
}
