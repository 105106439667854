export const inputWrapper = () => ({
    position: 'relative',
});

export const button = {
    position: 'absolute',
    top: 25,
    right: 0,
    marginRight: 16,

    height: 32,
    width: 32,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const buttonOff = button;

export const buttonOn = ({ theme: { colors } }) => ({
    ...button,
    borderRadius: '50%',
    backgroundColor: colors.primarySelection,
});

export const icon = () => ({
    height: 22,
});
