import React from 'react';

const IconOk = props => (
    <svg viewBox="0 0 13 10" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <polygon points="11.452 7.81597009e-14 4.875 6.803 1.547 3.356 1.24344979e-14 4.956 4.875 10.003 4.875 10.002 4.876 10.003 12.999 1.6" />
        </g>
    </svg>
);

export default IconOk;
