import { config, authApi, takeLatestRequest, Log, sagaEffects, ErrorsUtils } from '../../dependencies';

import { fetchSupplierSuccess, fetchSupplierFailure, fetchSupplierInvalidate } from '../actions';
import types from '../actionTypes';

const { put } = sagaEffects;

function* fetchSupplier(action, cancelToken) {
    const { id } = action.meta;

    try {
        const requestConfig = {
            uriParams: { id },
            cancelToken,
        };

        const response = yield* authApi.get(config.api.supplier, requestConfig);

        yield put(fetchSupplierSuccess(id, response.data));
    } catch (e) {
        Log.error(e);

        const errorMessage = ErrorsUtils.createUIErrorMessage(e, {
            status: {
                404: {
                    id: 'error.supplier.api.status.404',
                    values: { id },
                },
            },
            fallback: {
                id: 'error.supplier.api.get',
                values: { id },
            },
        });

        yield put(fetchSupplierFailure(id, errorMessage));
    }
}

export default function* fetchSupplierWatcher() {
    const actionTypes = {
        REQUEST: types.FETCH_SUPPLIER_REQUEST,
        cancelTask: fetchSupplierInvalidate,
        requestIdSelector: action => action.meta.userId,
    };

    yield takeLatestRequest(actionTypes, fetchSupplier);
}
