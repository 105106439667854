import { sagaEffects } from '../../dependencies';

import setFilterUrlParams from './setFilterUrlParams';
import setOrderUrlParam from './setOrderUrlParam';

const { all } = sagaEffects;

export default function*() {
    yield all([setFilterUrlParams(), setOrderUrlParam()]);
}
