import { union, difference } from 'modules/reports/dependencies';
import { types } from 'modules/reports/services/actions';

const initialState = [];

export default function idsReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_REPORTS_SUCCESS:
            return union(state, action.payload.ids);

        case types.FETCH_REPORT_SUCCESS:
        case types.CREATE_REPORT_SUCCESS:
            return union(state, [action.meta.id]);

        case types.DELETE_REPORT_SUCCESS:
            return difference(state, [action.meta.id]);

        default:
            return state;
    }
}
