import { cs as reactDateRangeCs } from 'react-date-range/dist/locale';
import 'moment/locale/cs';

import csIntl from './translations/cs.yml';
import csCountriesIntl from './translations/cs.countries.yml';

export const intlData = {
    cs: {
        ...csIntl,
        ...csCountriesIntl,
    },
};

export const pickerData = {
    cs: reactDateRangeCs,
};

export const monthsIndex = [
    'month.january',
    'month.february',
    'month.march',
    'month.april',
    'month.may',
    'month.june',
    'month.july',
    'month.august',
    'month.september',
    'month.october',
    'month.november',
    'month.december',
];

export const daysIndex = ['day.monday', 'day.tuesday', 'day.wendsday', 'day.thursday', 'day.friday'];
