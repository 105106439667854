import {
    startOfYear,
    startOfQuarter,
    endOfQuarter,
    startOfMonth,
    endOfMonth,
    subQuarters,
    subMonths,
    endOfToday,
    subYears,
    endOfYear,
    startOfDay,
} from 'date-fns';
import { Consts, Utils } from '../dependencies';

const { DateRanges } = Consts;

export const StaticDateRangesCreators = {
    [DateRanges.ALL_THE_TIME]: () => ({
        from: startOfDay(Date.UTC(2012, 6, 11)),
        to: endOfToday(),
    }),
    [DateRanges.THIS_YEAR]: () => ({
        from: startOfYear(new Date()),
        to: endOfToday(),
    }),
    [DateRanges.PREV_YEAR]: () => {
        const prevYear = subYears(new Date(), 1);

        return {
            from: startOfYear(prevYear),
            to: endOfYear(prevYear),
        };
    },
    [DateRanges.THIS_QUARTER]: () => ({
        from: startOfQuarter(new Date()),
        to: endOfToday(),
    }),
    [DateRanges.PREV_QUARTER]: () => {
        const prevQuarter = subQuarters(startOfQuarter(new Date()), 1);
        const startOfPrevQuarter = startOfQuarter(prevQuarter);
        const endOfPrevQuarter = endOfQuarter(prevQuarter);

        return {
            from: startOfPrevQuarter,
            to: endOfPrevQuarter,
        };
    },
    [DateRanges.THIS_MONTH]: () => ({
        from: startOfMonth(new Date()),
        to: endOfToday(),
    }),
    [DateRanges.PREV_MONTH]: () => {
        const prevMonth = subMonths(startOfMonth(new Date()), 1);
        const startOfPrevMonth = startOfMonth(prevMonth);
        const endOfPrevMonth = endOfMonth(prevMonth);

        return {
            from: startOfPrevMonth,
            to: endOfPrevMonth,
        };
    },
};

// revert the object from <relativePeriod, range> to <range, relativePeriod>
function revertStaticDateRanges(staticRanges = {}) {
    const revertedStaticDateRanges = new Map();

    for (const [relativePeriod, range] of Object.entries(staticRanges)) {
        const { from, to } = range();

        const rangeWithoutTimezone = {
            from: Utils.getISODateWithoutTimezone(from),
            to: Utils.getISODateWithoutTimezone(to),
        };

        revertedStaticDateRanges.set(`${rangeWithoutTimezone.from}${rangeWithoutTimezone.to}`, relativePeriod);
    }

    return revertedStaticDateRanges;
}

export const RelativePeriods = revertStaticDateRanges(StaticDateRangesCreators);
