import { types } from '../actions';
import { Buckets } from '../../constants';

const initialState = {
    bucket: Buckets.MONTHS,
    viewportSize: 12,
    viewportLeftOffset: 0,
};

export default function barChartReducer(state = initialState, action) {
    switch (action.type) {
        case types.SELECT_BUCKET: {
            const { bucket } = action.payload;

            return {
                ...state,
                bucket,
            };
        }

        case types.SET_VIEWPORT_SIZE: {
            const { viewportSize } = action.payload;

            return {
                ...state,
                viewportSize,
            };
        }

        case types.SET_VIEWPORT_LEFT_OFFSET: {
            const { viewportLeftOffset } = action.payload;

            return {
                ...state,
                viewportLeftOffset,
            };
        }

        default:
            return state;
    }
}
